/* For use when tracking an event eg., page view, form submit */
import { adapter } from '@seek/tealium-adapter';
import { AUTHENTICATION_TYPES } from '../../types';
import type { PageTrackingDataLayer } from './TealiumAdapterTypes';
import type { AnalyticsEvent } from '@seek/adv-header-footer';

export const tealiumAdapter = adapter(undefined);

export const initTealiumTracker = (eventProperties: PageTrackingDataLayer) => {
  setPageTrackingData(eventProperties);
};

export const getPageTrackingData = () => {
  if (typeof window !== 'undefined') {
    return window.pageTrackingDataLayer;
  }
  return {};
};

const setPageTrackingData = (eventProperties: PageTrackingDataLayer) => {
  if (typeof window !== 'undefined') {
    window.pageTrackingDataLayer = {
      ...window.pageTrackingDataLayer,
      ...eventProperties,
    };
  }
};

export const trackEvent = (
  eventName: string,
  eventProperties: PageTrackingDataLayer,
) => {
  if (!tealiumAdapter) {
    throw new Error('Tealium adapter is not initialised');
  }
  const pageTrackingDataLayer = {
    ...getPageTrackingData(),
    ...eventProperties,
    eventName,
  };
  setPageTrackingData(pageTrackingDataLayer);
  tealiumAdapter.view(eventName, pageTrackingDataLayer);
};

export const getLoggedInStatus = (status: string) => ({
  isPending: status === AUTHENTICATION_TYPES.AUTH_PENDING,
  isLoggedIn: status === AUTHENTICATION_TYPES.AUTHENTICATED,
});

export const deHyphen = (str?: string) => str?.replace(/-/g, ' ') || '';

export const setPreviousSiteSection = (siteSection: string) => {
  window.localStorage.setItem('previousSiteSection', siteSection);
};
export const getPreviousSiteSection = () =>
  window.localStorage.getItem('previousSiteSection');

export const trackHeaderFooterInteractions = (
  analyticsEvent: AnalyticsEvent,
) => {
  const currentPageTrackingData = getPageTrackingData();
  tealiumAdapter.link(analyticsEvent.eventName, {
    ...currentPageTrackingData,
    ...analyticsEvent,
  });
};
