export const HIGHEST_PAYING_SALARIES_NZ = [
  {
    industryIdFilter: -1,
    roles: [
      {
        rank: 1,
        roleTitle: 'General Practitioner',
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        avgSalary: 208181,
        avgSalaryChange: 3,
      },
      {
        rank: 2,
        roleTitle: 'Financial Controller',
        industryId: 1200,
        industryTitle: 'Accounting',
        avgSalary: 155361,
        avgSalaryChange: -4,
      },
      {
        rank: 3,
        roleTitle: 'Senior Associate',
        industryId: 1216,
        industryTitle: 'Legal',
        avgSalary: 153529,
        avgSalaryChange: -2,
      },
      {
        rank: 4,
        roleTitle: 'Sonographer',
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        avgSalary: 141378,
        avgSalaryChange: 10,
      },
      {
        rank: 5,
        roleTitle: 'Finance Manager',
        industryId: 1200,
        industryTitle: 'Accounting',
        avgSalary: 133047,
        avgSalaryChange: 1,
      },
      {
        rank: 6,
        roleTitle: 'Data Engineer',
        industryId: 6281,
        industryTitle: 'Information & Communication Technology',
        avgSalary: 127118,
        avgSalaryChange: 1,
      },
      {
        rank: 7,
        roleTitle: 'Project Manager',
        industryId: 1209,
        industryTitle: 'Engineering',
        avgSalary: 126561,
        avgSalaryChange: 5,
      },
      {
        rank: 8,
        roleTitle: 'Product Owner',
        industryId: 6281,
        industryTitle: 'Information & Communication Technology',
        avgSalary: 124650,
        avgSalaryChange: -2,
      },
      {
        rank: 9,
        roleTitle: 'Site Manager',
        industryId: 6251,
        industryTitle: 'Construction',
        avgSalary: 123496,
        avgSalaryChange: 0,
      },
      {
        rank: 10,
        roleTitle: 'Network Engineer',
        industryId: 6281,
        industryTitle: 'Information & Communication Technology',
        avgSalary: 122162,
        avgSalaryChange: 17,
      },
      {
        rank: 11,
        roleTitle: 'Human Resources Business Partner',
        industryId: 6317,
        industryTitle: 'Human Resources & Recruitment',
        avgSalary: 121885,
        avgSalaryChange: 2,
      },
      {
        rank: 12,
        roleTitle: 'Business Advisory Manager',
        industryId: 1200,
        industryTitle: 'Accounting',
        avgSalary: 121274,
        avgSalaryChange: 4,
      },
      {
        rank: 13,
        roleTitle: 'DevOps Engineer',
        industryId: 6281,
        industryTitle: 'Information & Communication Technology',
        avgSalary: 118972,
        avgSalaryChange: -7,
      },
      {
        rank: 14,
        roleTitle: 'Systems Engineer',
        industryId: 1209,
        industryTitle: 'Engineering',
        avgSalary: 117542,
        avgSalaryChange: 10,
      },
      {
        rank: 15,
        roleTitle: 'Adviser',
        industryId: 1210,
        industryTitle: 'Government & Defence',
        avgSalary: 115561,
        avgSalaryChange: 4,
      },
      {
        rank: 16,
        roleTitle: 'Veterinarian',
        industryId: 6205,
        industryTitle: 'Farming, Animals & Conservation',
        avgSalary: 114058,
        avgSalaryChange: 9,
      },
      {
        rank: 17,
        roleTitle: 'Project Engineer',
        industryId: 1209,
        industryTitle: 'Engineering',
        avgSalary: 113884,
        avgSalaryChange: -1,
      },
      {
        rank: 18,
        roleTitle: 'Security Analyst',
        industryId: 6281,
        industryTitle: 'Information & Communication Technology',
        avgSalary: 111326,
        avgSalaryChange: 12,
      },
      {
        rank: 19,
        roleTitle: 'Business Analyst',
        industryId: 6281,
        industryTitle: 'Information & Communication Technology',
        avgSalary: 110901,
        avgSalaryChange: -2,
      },
      {
        rank: 20,
        roleTitle: 'Financial Analyst',
        industryId: 1200,
        industryTitle: 'Accounting',
        avgSalary: 110067,
        avgSalaryChange: 2,
      },
    ],
  },
  {
    industryIdFilter: 1200,
    roles: [
      {
        industryId: 1200,
        industryTitle: 'Accounting',
        roleTitle: 'Financial Controller',
        rank: 1,
        avgSalary: 155361,
        avgSalaryChange: -4,
      },
      {
        industryId: 1200,
        industryTitle: 'Accounting',
        roleTitle: 'Finance Manager',
        rank: 2,
        avgSalary: 133047,
        avgSalaryChange: 1,
      },
      {
        industryId: 1200,
        industryTitle: 'Accounting',
        roleTitle: 'Business Advisory Manager',
        rank: 3,
        avgSalary: 121274,
        avgSalaryChange: 4,
      },
      {
        industryId: 1200,
        industryTitle: 'Accounting',
        roleTitle: 'Financial Analyst',
        rank: 4,
        avgSalary: 110067,
        avgSalaryChange: 2,
      },
      {
        industryId: 1200,
        industryTitle: 'Accounting',
        roleTitle: 'Accountant',
        rank: 5,
        avgSalary: 92069,
        avgSalaryChange: 4,
      },
      {
        industryId: 1200,
        industryTitle: 'Accounting',
        roleTitle: 'Payroll Specialist',
        rank: 6,
        avgSalary: 87201,
        avgSalaryChange: 17,
      },
      {
        industryId: 1200,
        industryTitle: 'Accounting',
        roleTitle: 'Auditor',
        rank: 7,
        avgSalary: 85466,
        avgSalaryChange: 10,
      },
      {
        industryId: 1200,
        industryTitle: 'Accounting',
        roleTitle: 'Payroll Officer',
        rank: 8,
        avgSalary: 75618,
        avgSalaryChange: 1,
      },
      {
        industryId: 1200,
        industryTitle: 'Accounting',
        roleTitle: 'Payroll Administrator',
        rank: 9,
        avgSalary: 70105,
        avgSalaryChange: 9,
      },
      {
        industryId: 1200,
        industryTitle: 'Accounting',
        roleTitle: 'Financial Administrator',
        rank: 10,
        avgSalary: 68125,
        avgSalaryChange: 9,
      },
      {
        industryId: 1200,
        industryTitle: 'Accounting',
        roleTitle: 'Accounts Assistant',
        rank: 11,
        avgSalary: 66032,
        avgSalaryChange: 7,
      },
      {
        industryId: 1200,
        industryTitle: 'Accounting',
        roleTitle: 'Accounts Administrator',
        rank: 12,
        avgSalary: 64592,
        avgSalaryChange: 3,
      },
      {
        industryId: 1200,
        industryTitle: 'Accounting',
        roleTitle: 'Accounts Payable Administrator',
        rank: 13,
        avgSalary: 64203,
        avgSalaryChange: 5,
      },
    ],
  },
  {
    industryIdFilter: 1204,
    roles: [
      {
        industryId: 1204,
        industryTitle: 'Call Centre & Customer Service',
        roleTitle: 'Customer Service Representative',
        rank: 1,
        avgSalary: 56555,
        avgSalaryChange: 4,
      },
      {
        industryId: 1204,
        industryTitle: 'Call Centre & Customer Service',
        roleTitle: 'Customer Experience Consultant',
        rank: 2,
        avgSalary: 55973,
        avgSalaryChange: 2,
      },
    ],
  },
  {
    industryIdFilter: 1206,
    roles: [
      {
        industryId: 1206,
        industryTitle: 'Construction',
        roleTitle: 'Site Manager',
        rank: 1,
        avgSalary: 123496,
        avgSalaryChange: 0,
      },
      {
        industryId: 1206,
        industryTitle: 'Construction',
        roleTitle: 'Foreperson',
        rank: 2,
        avgSalary: 79328,
        avgSalaryChange: 14,
      },
      {
        industryId: 1206,
        industryTitle: 'Construction',
        roleTitle: 'Excavator Operator',
        rank: 3,
        avgSalary: 67764,
        avgSalaryChange: 6,
      },
      {
        industryId: 1206,
        industryTitle: 'Construction',
        roleTitle: 'Site Traffic Management Supervisor',
        rank: 4,
        avgSalary: 62189,
        avgSalaryChange: 7,
      },
    ],
  },
  {
    industryIdFilter: 1209,
    roles: [
      {
        industryId: 1209,
        industryTitle: 'Engineering',
        roleTitle: 'Project Manager',
        rank: 1,
        avgSalary: 126561,
        avgSalaryChange: 5,
      },
      {
        industryId: 1209,
        industryTitle: 'Engineering',
        roleTitle: 'Systems Engineer',
        rank: 2,
        avgSalary: 117542,
        avgSalaryChange: 10,
      },
      {
        industryId: 1209,
        industryTitle: 'Engineering',
        roleTitle: 'Project Engineer',
        rank: 3,
        avgSalary: 113884,
        avgSalaryChange: -1,
      },
      {
        industryId: 1209,
        industryTitle: 'Engineering',
        roleTitle: 'Design Engineer',
        rank: 4,
        avgSalary: 103991,
        avgSalaryChange: 4,
      },
      {
        industryId: 1209,
        industryTitle: 'Engineering',
        roleTitle: 'Designer',
        rank: 5,
        avgSalary: 98916,
        avgSalaryChange: 0,
      },
      {
        industryId: 1209,
        industryTitle: 'Engineering',
        roleTitle: 'Site Engineer',
        rank: 6,
        avgSalary: 96500,
        avgSalaryChange: 2,
      },
      {
        industryId: 1209,
        industryTitle: 'Engineering',
        roleTitle: 'Geologist',
        rank: 7,
        avgSalary: 93278,
        avgSalaryChange: 5,
      },
      {
        industryId: 1209,
        industryTitle: 'Engineering',
        roleTitle: 'Maintenance Engineer',
        rank: 8,
        avgSalary: 91052,
        avgSalaryChange: 6,
      },
      {
        industryId: 1209,
        industryTitle: 'Engineering',
        roleTitle: 'Fitter',
        rank: 9,
        avgSalary: 85300,
        avgSalaryChange: 8,
      },
    ],
  },
  {
    industryIdFilter: 1210,
    roles: [
      {
        industryId: 1210,
        industryTitle: 'Government & Defence',
        roleTitle: 'Adviser',
        rank: 1,
        avgSalary: 115561,
        avgSalaryChange: 4,
      },
    ],
  },
  {
    industryIdFilter: 1211,
    roles: [
      {
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        roleTitle: 'General Practitioner',
        rank: 1,
        avgSalary: 208181,
        avgSalaryChange: 3,
      },
      {
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        roleTitle: 'Sonographer',
        rank: 2,
        avgSalary: 141378,
        avgSalaryChange: 10,
      },
      {
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        roleTitle: 'Nurse Manager',
        rank: 3,
        avgSalary: 106037,
        avgSalaryChange: 2,
      },
      {
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        roleTitle: 'Psychologist',
        rank: 4,
        avgSalary: 105113,
        avgSalaryChange: 7,
      },
      {
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        roleTitle: 'Technologist',
        rank: 5,
        avgSalary: 99832,
        avgSalaryChange: 5,
      },
      {
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        roleTitle: 'Pharmacist',
        rank: 6,
        avgSalary: 97519,
        avgSalaryChange: 11,
      },
      {
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        roleTitle: 'Occupational Therapist',
        rank: 7,
        avgSalary: 89367,
        avgSalaryChange: 16,
      },
      {
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        roleTitle: 'Midwife',
        rank: 8,
        avgSalary: 87852,
        avgSalaryChange: 6,
      },
      {
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        roleTitle: 'Physiotherapist',
        rank: 9,
        avgSalary: 86272,
        avgSalaryChange: 13,
      },
      {
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        roleTitle: 'Therapist',
        rank: 10,
        avgSalary: 85565,
        avgSalaryChange: 13,
      },
      {
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        roleTitle: 'Nurse',
        rank: 11,
        avgSalary: 84289,
        avgSalaryChange: 8,
      },
      {
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        roleTitle: 'Social Worker',
        rank: 12,
        avgSalary: 81862,
        avgSalaryChange: 16,
      },
      {
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        roleTitle: 'Health Improvement Practitioner',
        rank: 13,
        avgSalary: 81803,
        avgSalaryChange: -1,
      },
      {
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        roleTitle: 'Clinician',
        rank: 14,
        avgSalary: 80680,
        avgSalaryChange: 9,
      },
      {
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        roleTitle: 'Practice Manager',
        rank: 15,
        avgSalary: 80586,
        avgSalaryChange: -2,
      },
      {
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        roleTitle: 'Counsellor',
        rank: 16,
        avgSalary: 77720,
        avgSalaryChange: 8,
      },
      {
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        roleTitle: 'Pharmacy Technician',
        rank: 17,
        avgSalary: 60062,
        avgSalaryChange: 6,
      },
      {
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        roleTitle: 'Support Worker',
        rank: 18,
        avgSalary: 55969,
        avgSalaryChange: 11,
      },
      {
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        roleTitle: 'Healthcare Assistant',
        rank: 19,
        avgSalary: 54940,
        avgSalaryChange: 2,
      },
      {
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        roleTitle: 'Receptionist',
        rank: 20,
        avgSalary: 54304,
        avgSalaryChange: 3,
      },
    ],
  },
  {
    industryIdFilter: 1212,
    roles: [
      {
        industryId: 1212,
        industryTitle: 'Hospitality & Tourism',
        roleTitle: 'Travel Consultant',
        rank: 1,
        avgSalary: 67198,
        avgSalaryChange: 2,
      },
      {
        industryId: 1212,
        industryTitle: 'Hospitality & Tourism',
        roleTitle: 'Cafe Manager',
        rank: 2,
        avgSalary: 61531,
        avgSalaryChange: 7,
      },
      {
        industryId: 1212,
        industryTitle: 'Hospitality & Tourism',
        roleTitle: 'Restaurant Manager',
        rank: 3,
        avgSalary: 61159,
        avgSalaryChange: -1,
      },
      {
        industryId: 1212,
        industryTitle: 'Hospitality & Tourism',
        roleTitle: 'Chef',
        rank: 4,
        avgSalary: 59594,
        avgSalaryChange: 0,
      },
      {
        industryId: 1212,
        industryTitle: 'Hospitality & Tourism',
        roleTitle: 'Chef de Partie',
        rank: 5,
        avgSalary: 58340,
        avgSalaryChange: 4,
      },
      {
        industryId: 1212,
        industryTitle: 'Hospitality & Tourism',
        roleTitle: 'Duty Manager',
        rank: 6,
        avgSalary: 57069,
        avgSalaryChange: 2,
      },
      {
        industryId: 1212,
        industryTitle: 'Hospitality & Tourism',
        roleTitle: 'Food and Beverage Supervisor',
        rank: 7,
        avgSalary: 56813,
        avgSalaryChange: 3,
      },
      {
        industryId: 1212,
        industryTitle: 'Hospitality & Tourism',
        roleTitle: 'Cook',
        rank: 8,
        avgSalary: 55289,
        avgSalaryChange: 2,
      },
      {
        industryId: 1212,
        industryTitle: 'Hospitality & Tourism',
        roleTitle: 'Waitperson',
        rank: 9,
        avgSalary: 52893,
        avgSalaryChange: 2,
      },
      {
        industryId: 1212,
        industryTitle: 'Hospitality & Tourism',
        roleTitle: 'Kitchen Hand',
        rank: 10,
        avgSalary: 51329,
        avgSalaryChange: 3,
      },
      {
        industryId: 1212,
        industryTitle: 'Hospitality & Tourism',
        roleTitle: 'Barista',
        rank: 11,
        avgSalary: 51324,
        avgSalaryChange: 1,
      },
      {
        industryId: 1212,
        industryTitle: 'Hospitality & Tourism',
        roleTitle: 'Food and Beverage Attendant',
        rank: 12,
        avgSalary: 51191,
        avgSalaryChange: 4,
      },
      {
        industryId: 1212,
        industryTitle: 'Hospitality & Tourism',
        roleTitle: 'Guest Services Agent',
        rank: 13,
        avgSalary: 51181,
        avgSalaryChange: 4,
      },
      {
        industryId: 1212,
        industryTitle: 'Hospitality & Tourism',
        roleTitle: 'Cafe Assistant',
        rank: 14,
        avgSalary: 49559,
        avgSalaryChange: 3,
      },
      {
        industryId: 1212,
        industryTitle: 'Hospitality & Tourism',
        roleTitle: 'Housekeeper',
        rank: 15,
        avgSalary: 48981,
        avgSalaryChange: -3,
      },
      {
        industryId: 1212,
        industryTitle: 'Hospitality & Tourism',
        roleTitle: 'Room Attendant',
        rank: 16,
        avgSalary: 48709,
        avgSalaryChange: 3,
      },
      {
        industryId: 1212,
        industryTitle: 'Hospitality & Tourism',
        roleTitle: 'Kitchen Assistant',
        rank: 17,
        avgSalary: 47619,
        avgSalaryChange: 3,
      },
    ],
  },
  {
    industryIdFilter: 1216,
    roles: [
      {
        industryId: 1216,
        industryTitle: 'Legal',
        roleTitle: 'Senior Associate',
        rank: 1,
        avgSalary: 153529,
        avgSalaryChange: -2,
      },
      {
        industryId: 1216,
        industryTitle: 'Legal',
        roleTitle: 'Legal Executive',
        rank: 2,
        avgSalary: 83203,
        avgSalaryChange: 7,
      },
      {
        industryId: 1216,
        industryTitle: 'Legal',
        roleTitle: 'Secretary',
        rank: 3,
        avgSalary: 70298,
        avgSalaryChange: 3,
      },
    ],
  },
  {
    industryIdFilter: 1220,
    roles: [
      {
        industryId: 1220,
        industryTitle: 'Real Estate & Property',
        roleTitle: 'Facilities Manager',
        rank: 1,
        avgSalary: 101250,
        avgSalaryChange: 3,
      },
      {
        industryId: 1220,
        industryTitle: 'Real Estate & Property',
        roleTitle: 'Salesperson',
        rank: 2,
        avgSalary: 100070,
        avgSalaryChange: 7,
      },
      {
        industryId: 1220,
        industryTitle: 'Real Estate & Property',
        roleTitle: 'Property Manager',
        rank: 3,
        avgSalary: 70353,
        avgSalaryChange: 1,
      },
    ],
  },
  {
    industryIdFilter: 1223,
    roles: [
      {
        industryId: 1223,
        industryTitle: 'Science & Technology',
        roleTitle: 'Technologist',
        rank: 1,
        avgSalary: 96065,
        avgSalaryChange: 12,
      },
      {
        industryId: 1223,
        industryTitle: 'Science & Technology',
        roleTitle: 'Laboratory Technician',
        rank: 2,
        avgSalary: 58118,
        avgSalaryChange: 1,
      },
    ],
  },
  {
    industryIdFilter: 1225,
    roles: [
      {
        industryId: 1225,
        industryTitle: 'Trades & Services',
        roleTitle: 'Electrician',
        rank: 1,
        avgSalary: 84392,
        avgSalaryChange: 6,
      },
      {
        industryId: 1225,
        industryTitle: 'Trades & Services',
        roleTitle: 'Plumber',
        rank: 2,
        avgSalary: 81572,
        avgSalaryChange: 4,
      },
      {
        industryId: 1225,
        industryTitle: 'Trades & Services',
        roleTitle: 'Fitter',
        rank: 3,
        avgSalary: 80984,
        avgSalaryChange: 7,
      },
      {
        industryId: 1225,
        industryTitle: 'Trades & Services',
        roleTitle: 'Foreperson',
        rank: 4,
        avgSalary: 80543,
        avgSalaryChange: 5,
      },
      {
        industryId: 1225,
        industryTitle: 'Trades & Services',
        roleTitle: 'Field Service Technician',
        rank: 5,
        avgSalary: 78359,
        avgSalaryChange: -2,
      },
      {
        industryId: 1225,
        industryTitle: 'Trades & Services',
        roleTitle: 'Inspector',
        rank: 6,
        avgSalary: 78303,
        avgSalaryChange: 15,
      },
      {
        industryId: 1225,
        industryTitle: 'Trades & Services',
        roleTitle: 'Drainlayer',
        rank: 7,
        avgSalary: 75809,
        avgSalaryChange: 17,
      },
      {
        industryId: 1225,
        industryTitle: 'Trades & Services',
        roleTitle: 'Security Technician',
        rank: 8,
        avgSalary: 75743,
        avgSalaryChange: 2,
      },
      {
        industryId: 1225,
        industryTitle: 'Trades & Services',
        roleTitle: 'Service Technician',
        rank: 9,
        avgSalary: 75396,
        avgSalaryChange: 4,
      },
      {
        industryId: 1225,
        industryTitle: 'Trades & Services',
        roleTitle: 'Mechanic',
        rank: 10,
        avgSalary: 76362,
        avgSalaryChange: 3,
      },
      {
        industryId: 1225,
        industryTitle: 'Trades & Services',
        roleTitle: 'Builder',
        rank: 11,
        avgSalary: 72647,
        avgSalaryChange: 2,
      },
      {
        industryId: 1225,
        industryTitle: 'Trades & Services',
        roleTitle: 'Automotive Technician',
        rank: 12,
        avgSalary: 70824,
        avgSalaryChange: 4,
      },
      {
        industryId: 1225,
        industryTitle: 'Trades & Services',
        roleTitle: 'Service Adviser',
        rank: 13,
        avgSalary: 69772,
        avgSalaryChange: 5,
      },
      {
        industryId: 1225,
        industryTitle: 'Trades & Services',
        roleTitle: 'Carpenter',
        rank: 14,
        avgSalary: 69724,
        avgSalaryChange: -3,
      },
      {
        industryId: 1225,
        industryTitle: 'Trades & Services',
        roleTitle: 'Fabricator',
        rank: 15,
        avgSalary: 68832,
        avgSalaryChange: 3,
      },
      {
        industryId: 1225,
        industryTitle: 'Trades & Services',
        roleTitle: 'Cabinet Maker',
        rank: 16,
        avgSalary: 68381,
        avgSalaryChange: -7,
      },
      {
        industryId: 1225,
        industryTitle: 'Trades & Services',
        roleTitle: 'Painter',
        rank: 17,
        avgSalary: 65226,
        avgSalaryChange: 7,
      },
      {
        industryId: 1225,
        industryTitle: 'Trades & Services',
        roleTitle: 'Arborist',
        rank: 18,
        avgSalary: 65212,
        avgSalaryChange: 5,
      },
      {
        industryId: 1225,
        industryTitle: 'Trades & Services',
        roleTitle: 'Maintenance Technician',
        rank: 19,
        avgSalary: 64280,
        avgSalaryChange: -6,
      },
      {
        industryId: 1225,
        industryTitle: 'Trades & Services',
        roleTitle: 'Installer',
        rank: 20,
        avgSalary: 64048,
        avgSalaryChange: 1,
      },
    ],
  },
  {
    industryIdFilter: 6008,
    roles: [
      {
        industryId: 6008,
        industryTitle: 'Marketing & Communications',
        roleTitle: 'Brand Manager',
        rank: 1,
        avgSalary: 101535,
        avgSalaryChange: 4,
      },
      {
        industryId: 6008,
        industryTitle: 'Marketing & Communications',
        roleTitle: 'Communications Adviser',
        rank: 2,
        avgSalary: 97849,
        avgSalaryChange: 6,
      },
      {
        industryId: 6008,
        industryTitle: 'Marketing & Communications',
        roleTitle: 'Marketing Specialist',
        rank: 3,
        avgSalary: 83251,
        avgSalaryChange: 7,
      },
      {
        industryId: 6008,
        industryTitle: 'Marketing & Communications',
        roleTitle: 'Marketing Executive',
        rank: 4,
        avgSalary: 74775,
        avgSalaryChange: 0,
      },
      {
        industryId: 6008,
        industryTitle: 'Marketing & Communications',
        roleTitle: 'Marketing Coordinator',
        rank: 5,
        avgSalary: 67426,
        avgSalaryChange: 5,
      },
    ],
  },
  {
    industryIdFilter: 6043,
    roles: [
      {
        industryId: 6043,
        industryTitle: 'Retail & Consumer Products',
        roleTitle: 'Branch Manager',
        rank: 1,
        avgSalary: 80213,
        avgSalaryChange: 4,
      },
      {
        industryId: 6043,
        industryTitle: 'Retail & Consumer Products',
        roleTitle: 'Department Manager',
        rank: 2,
        avgSalary: 67529,
        avgSalaryChange: 5,
      },
      {
        industryId: 6043,
        industryTitle: 'Retail & Consumer Products',
        roleTitle: 'Store Manager',
        rank: 3,
        avgSalary: 60040,
        avgSalaryChange: 3,
      },
      {
        industryId: 6043,
        industryTitle: 'Retail & Consumer Products',
        roleTitle: 'Duty Manager',
        rank: 4,
        avgSalary: 55118,
        avgSalaryChange: 4,
      },
      {
        industryId: 6043,
        industryTitle: 'Retail & Consumer Products',
        roleTitle: 'Assistant Manager',
        rank: 5,
        avgSalary: 54870,
        avgSalaryChange: 3,
      },
      {
        industryId: 6043,
        industryTitle: 'Retail & Consumer Products',
        roleTitle: 'Sales Consultant',
        rank: 6,
        avgSalary: 53119,
        avgSalaryChange: 2,
      },
      {
        industryId: 6043,
        industryTitle: 'Retail & Consumer Products',
        roleTitle: 'Sales Associate',
        rank: 7,
        avgSalary: 50309,
        avgSalaryChange: 2,
      },
      {
        industryId: 6043,
        industryTitle: 'Retail & Consumer Products',
        roleTitle: 'Customer Service Representative',
        rank: 8,
        avgSalary: 50094,
        avgSalaryChange: 8,
      },
      {
        industryId: 6043,
        industryTitle: 'Retail & Consumer Products',
        roleTitle: 'Sales Assistant',
        rank: 9,
        avgSalary: 48791,
        avgSalaryChange: 3,
      },
      {
        industryId: 6043,
        industryTitle: 'Retail & Consumer Products',
        roleTitle: 'Team Member',
        rank: 10,
        avgSalary: 48773,
        avgSalaryChange: -1,
      },
      {
        industryId: 6043,
        industryTitle: 'Retail & Consumer Products',
        roleTitle: 'Merchandiser',
        rank: 11,
        avgSalary: 48030,
        avgSalaryChange: 3,
      },
    ],
  },
  {
    industryIdFilter: 6092,
    roles: [
      {
        industryId: 6092,
        industryTitle: 'Manufacturing, Transport & Logistics',
        roleTitle: 'Production Manager',
        rank: 1,
        avgSalary: 108662,
        avgSalaryChange: 4,
      },
      {
        industryId: 6092,
        industryTitle: 'Manufacturing, Transport & Logistics',
        roleTitle: 'Planner',
        rank: 2,
        avgSalary: 85732,
        avgSalaryChange: 3,
      },
      {
        industryId: 6092,
        industryTitle: 'Manufacturing, Transport & Logistics',
        roleTitle: 'Production Supervisor',
        rank: 3,
        avgSalary: 78605,
        avgSalaryChange: 4,
      },
      {
        industryId: 6092,
        industryTitle: 'Manufacturing, Transport & Logistics',
        roleTitle: 'Team Leader',
        rank: 4,
        avgSalary: 70622,
        avgSalaryChange: 3,
      },
      {
        industryId: 6092,
        industryTitle: 'Manufacturing, Transport & Logistics',
        roleTitle: 'Logistics Coordinator',
        rank: 5,
        avgSalary: 66179,
        avgSalaryChange: 1,
      },
      {
        industryId: 6092,
        industryTitle: 'Manufacturing, Transport & Logistics',
        roleTitle: 'Process Operator',
        rank: 6,
        avgSalary: 65270,
        avgSalaryChange: 22,
      },
      {
        industryId: 6092,
        industryTitle: 'Manufacturing, Transport & Logistics',
        roleTitle: 'Truck Driver',
        rank: 7,
        avgSalary: 60563,
        avgSalaryChange: 2,
      },
      {
        industryId: 6092,
        industryTitle: 'Manufacturing, Transport & Logistics',
        roleTitle: 'Warehouse Operator',
        rank: 8,
        avgSalary: 56371,
        avgSalaryChange: 6,
      },
      {
        industryId: 6092,
        industryTitle: 'Manufacturing, Transport & Logistics',
        roleTitle: 'Delivery Driver',
        rank: 9,
        avgSalary: 55736,
        avgSalaryChange: 4,
      },
      {
        industryId: 6092,
        industryTitle: 'Manufacturing, Transport & Logistics',
        roleTitle: 'Machine Operator',
        rank: 10,
        avgSalary: 55625,
        avgSalaryChange: 1,
      },
      {
        industryId: 6092,
        industryTitle: 'Manufacturing, Transport & Logistics',
        roleTitle: 'Production Operator',
        rank: 11,
        avgSalary: 54459,
        avgSalaryChange: 3,
      },
      {
        industryId: 6092,
        industryTitle: 'Manufacturing, Transport & Logistics',
        roleTitle: 'Storeperson',
        rank: 12,
        avgSalary: 54152,
        avgSalaryChange: 3,
      },
      {
        industryId: 6092,
        industryTitle: 'Manufacturing, Transport & Logistics',
        roleTitle: 'Forklift Driver',
        rank: 13,
        avgSalary: 53950,
        avgSalaryChange: 1,
      },
      {
        industryId: 6092,
        industryTitle: 'Manufacturing, Transport & Logistics',
        roleTitle: 'Warehouse Officer',
        rank: 14,
        avgSalary: 53077,
        avgSalaryChange: 4,
      },
      {
        industryId: 6092,
        industryTitle: 'Manufacturing, Transport & Logistics',
        roleTitle: 'Processing Officer',
        rank: 15,
        avgSalary: 52812,
        avgSalaryChange: 7,
      },
      {
        industryId: 6092,
        industryTitle: 'Manufacturing, Transport & Logistics',
        roleTitle: 'Warehouse Assistant',
        rank: 16,
        avgSalary: 52330,
        avgSalaryChange: 1,
      },
      {
        industryId: 6092,
        industryTitle: 'Manufacturing, Transport & Logistics',
        roleTitle: 'Process Worker',
        rank: 17,
        avgSalary: 51731,
        avgSalaryChange: 6,
      },
      {
        industryId: 6092,
        industryTitle: 'Manufacturing, Transport & Logistics',
        roleTitle: 'Picker and Packer',
        rank: 18,
        avgSalary: 49358,
        avgSalaryChange: 3,
      },
    ],
  },
  {
    industryIdFilter: 6123,
    roles: [
      {
        industryId: 6123,
        industryTitle: 'Education & Training',
        roleTitle: 'Centre Manager',
        rank: 1,
        avgSalary: 91516,
        avgSalaryChange: 12,
      },
      {
        industryId: 6123,
        industryTitle: 'Education & Training',
        roleTitle: 'Teacher',
        rank: 2,
        avgSalary: 74575,
        avgSalaryChange: 12,
      },
      {
        industryId: 6123,
        industryTitle: 'Education & Training',
        roleTitle: 'Kaiako',
        rank: 3,
        avgSalary: 71256,
        avgSalaryChange: 16,
      },
      {
        industryId: 6123,
        industryTitle: 'Education & Training',
        roleTitle: 'Early Childhood Teacher',
        rank: 4,
        avgSalary: 70016,
        avgSalaryChange: 12,
      },
      {
        industryId: 6123,
        industryTitle: 'Education & Training',
        roleTitle: 'Tutor',
        rank: 5,
        avgSalary: 67188,
        avgSalaryChange: 7,
      },
      {
        industryId: 6123,
        industryTitle: 'Education & Training',
        roleTitle: 'Educator',
        rank: 6,
        avgSalary: 64808,
        avgSalaryChange: -5,
      },
      {
        industryId: 6123,
        industryTitle: 'Education & Training',
        roleTitle: 'Learning Assistant',
        rank: 7,
        avgSalary: 55703,
        avgSalaryChange: 5,
      },
      {
        industryId: 6123,
        industryTitle: 'Education & Training',
        roleTitle: 'Teachers Aide',
        rank: 8,
        avgSalary: 55117,
        avgSalaryChange: 6,
      },
    ],
  },
  {
    industryIdFilter: 6163,
    roles: [
      {
        industryId: 6163,
        industryTitle: 'Community Services & Development',
        roleTitle: 'Social Worker',
        rank: 1,
        avgSalary: 84131,
        avgSalaryChange: 7,
      },
      {
        industryId: 6163,
        industryTitle: 'Community Services & Development',
        roleTitle: 'Team Leader',
        rank: 2,
        avgSalary: 77596,
        avgSalaryChange: -1,
      },
      {
        industryId: 6163,
        industryTitle: 'Community Services & Development',
        roleTitle: 'Coordinator',
        rank: 3,
        avgSalary: 66562,
        avgSalaryChange: 4,
      },
      {
        industryId: 6163,
        industryTitle: 'Community Services & Development',
        roleTitle: 'Facilitator',
        rank: 4,
        avgSalary: 63905,
        avgSalaryChange: 11,
      },
      {
        industryId: 6163,
        industryTitle: 'Community Services & Development',
        roleTitle: 'Navigator',
        rank: 5,
        avgSalary: 60721,
        avgSalaryChange: 2,
      },
      {
        industryId: 6163,
        industryTitle: 'Community Services & Development',
        roleTitle: 'Youth Worker',
        rank: 6,
        avgSalary: 58510,
        avgSalaryChange: 5,
      },
      {
        industryId: 6163,
        industryTitle: 'Community Services & Development',
        roleTitle: 'Support Worker',
        rank: 7,
        avgSalary: 51318,
        avgSalaryChange: 2,
      },
    ],
  },
  {
    industryIdFilter: 6205,
    roles: [
      {
        industryId: 6205,
        industryTitle: 'Farming, Animals & Conservation',
        roleTitle: 'Veterinarian',
        rank: 1,
        avgSalary: 114058,
        avgSalaryChange: 9,
      },
      {
        industryId: 6205,
        industryTitle: 'Farming, Animals & Conservation',
        roleTitle: 'Nurse',
        rank: 2,
        avgSalary: 57689,
        avgSalaryChange: 3,
      },
    ],
  },
  {
    industryIdFilter: 6246,
    roles: [
      {
        industryId: 6246,
        industryTitle: 'Sport & Recreation',
        roleTitle: 'Trainer',
        rank: 1,
        avgSalary: 58880,
        avgSalaryChange: 6,
      },
      {
        industryId: 6246,
        industryTitle: 'Sport & Recreation',
        roleTitle: 'Coach',
        rank: 2,
        avgSalary: 58614,
        avgSalaryChange: 7,
      },
      {
        industryId: 6246,
        industryTitle: 'Sport & Recreation',
        roleTitle: 'Instructor',
        rank: 3,
        avgSalary: 53897,
        avgSalaryChange: 7,
      },
      {
        industryId: 6246,
        industryTitle: 'Sport & Recreation',
        roleTitle: 'Lifeguard',
        rank: 4,
        avgSalary: 52656,
        avgSalaryChange: 7,
      },
    ],
  },
  {
    industryIdFilter: 6251,
    roles: [
      {
        industryId: 6251,
        industryTitle: 'Administration & Office Support',
        roleTitle: 'Executive Assistant',
        rank: 1,
        avgSalary: 79896,
        avgSalaryChange: 1,
      },
      {
        industryId: 6251,
        industryTitle: 'Administration & Office Support',
        roleTitle: 'Office Manager',
        rank: 2,
        avgSalary: 71025,
        avgSalaryChange: 1,
      },
      {
        industryId: 6251,
        industryTitle: 'Administration & Office Support',
        roleTitle: 'Personal Assistant',
        rank: 3,
        avgSalary: 67633,
        avgSalaryChange: -4,
      },
      {
        industryId: 6251,
        industryTitle: 'Administration & Office Support',
        roleTitle: 'Operations Coordinator',
        rank: 4,
        avgSalary: 67186,
        avgSalaryChange: 1,
      },
      {
        industryId: 6251,
        industryTitle: 'Administration & Office Support',
        roleTitle: 'Service Coordinator',
        rank: 5,
        avgSalary: 65361,
        avgSalaryChange: 9,
      },
      {
        industryId: 6251,
        industryTitle: 'Administration & Office Support',
        roleTitle: 'Team Administrator',
        rank: 6,
        avgSalary: 63206,
        avgSalaryChange: 3,
      },
      {
        industryId: 6251,
        industryTitle: 'Administration & Office Support',
        roleTitle: 'Accounts Administrator',
        rank: 7,
        avgSalary: 62914,
        avgSalaryChange: 7,
      },
      {
        industryId: 6251,
        industryTitle: 'Administration & Office Support',
        roleTitle: 'Support Officer',
        rank: 8,
        avgSalary: 61768,
        avgSalaryChange: 3,
      },
      {
        industryId: 6251,
        industryTitle: 'Administration & Office Support',
        roleTitle: 'Operations Administrator',
        rank: 9,
        avgSalary: 61593,
        avgSalaryChange: 5,
      },
      {
        industryId: 6251,
        industryTitle: 'Administration & Office Support',
        roleTitle: 'Office Administrator',
        rank: 10,
        avgSalary: 59350,
        avgSalaryChange: 4,
      },
      {
        industryId: 6251,
        industryTitle: 'Administration & Office Support',
        roleTitle: 'Receptionist',
        rank: 11,
        avgSalary: 54143,
        avgSalaryChange: 4,
      },
    ],
  },
  {
    industryIdFilter: 6263,
    roles: [
      {
        industryId: 6263,
        industryTitle: 'Design & Architecture',
        roleTitle: 'Planner',
        rank: 1,
        avgSalary: 108668,
        avgSalaryChange: 12,
      },
      {
        industryId: 6263,
        industryTitle: 'Design & Architecture',
        roleTitle: 'Designer',
        rank: 2,
        avgSalary: 82035,
        avgSalaryChange: -2,
      },
    ],
  },
  {
    industryIdFilter: 6281,
    roles: [
      {
        industryId: 6281,
        industryTitle: 'Information & Communication Technology',
        roleTitle: 'Data Engineer',
        rank: 1,
        avgSalary: 127118,
        avgSalaryChange: 1,
      },
      {
        industryId: 6281,
        industryTitle: 'Information & Communication Technology',
        roleTitle: 'Product Owner',
        rank: 2,
        avgSalary: 124650,
        avgSalaryChange: -2,
      },
      {
        industryId: 6281,
        industryTitle: 'Information & Communication Technology',
        roleTitle: 'Network Engineer',
        rank: 3,
        avgSalary: 122162,
        avgSalaryChange: 17,
      },
      {
        industryId: 6281,
        industryTitle: 'Information & Communication Technology',
        roleTitle: 'DevOps Engineer',
        rank: 4,
        avgSalary: 118972,
        avgSalaryChange: -7,
      },
      {
        industryId: 6281,
        industryTitle: 'Information & Communication Technology',
        roleTitle: 'Security Analyst',
        rank: 5,
        avgSalary: 111326,
        avgSalaryChange: 12,
      },
      {
        industryId: 6281,
        industryTitle: 'Information & Communication Technology',
        roleTitle: 'Business Analyst',
        rank: 6,
        avgSalary: 110901,
        avgSalaryChange: -2,
      },
      {
        industryId: 6281,
        industryTitle: 'Information & Communication Technology',
        roleTitle: 'Systems Engineer',
        rank: 7,
        avgSalary: 96872,
        avgSalaryChange: -4,
      },
      {
        industryId: 6281,
        industryTitle: 'Information & Communication Technology',
        roleTitle: 'Systems Administrator',
        rank: 8,
        avgSalary: 85896,
        avgSalaryChange: 5,
      },
      {
        industryId: 6281,
        industryTitle: 'Information & Communication Technology',
        roleTitle: 'Support Analyst',
        rank: 9,
        avgSalary: 75076,
        avgSalaryChange: 4,
      },
      {
        industryId: 6281,
        industryTitle: 'Information & Communication Technology',
        roleTitle: 'Support Specialist',
        rank: 10,
        avgSalary: 72708,
        avgSalaryChange: -2,
      },
      {
        industryId: 6281,
        industryTitle: 'Information & Communication Technology',
        roleTitle: 'Support Engineer',
        rank: 11,
        avgSalary: 71925,
        avgSalaryChange: -8,
      },
      {
        industryId: 6281,
        industryTitle: 'Information & Communication Technology',
        roleTitle: 'Service Desk Analyst',
        rank: 12,
        avgSalary: 63342,
        avgSalaryChange: 3,
      },
    ],
  },
  {
    industryIdFilter: 6317,
    roles: [
      {
        industryId: 6317,
        industryTitle: 'Human Resources & Recruitment',
        roleTitle: 'Human Resources Business Partner',
        rank: 1,
        avgSalary: 121885,
        avgSalaryChange: 2,
      },
      {
        industryId: 6317,
        industryTitle: 'Human Resources & Recruitment',
        roleTitle: 'Health and Safety Adviser',
        rank: 2,
        avgSalary: 97441,
        avgSalaryChange: 7,
      },
      {
        industryId: 6317,
        industryTitle: 'Human Resources & Recruitment',
        roleTitle: 'Human Resources Adviser',
        rank: 3,
        avgSalary: 94738,
        avgSalaryChange: 3,
      },
      {
        industryId: 6317,
        industryTitle: 'Human Resources & Recruitment',
        roleTitle: 'People and Culture Adviser',
        rank: 4,
        avgSalary: 94168,
        avgSalaryChange: 13,
      },
      {
        industryId: 6317,
        industryTitle: 'Human Resources & Recruitment',
        roleTitle: 'Recruitment Consultant',
        rank: 5,
        avgSalary: 79227,
        avgSalaryChange: 3,
      },
      {
        industryId: 6317,
        industryTitle: 'Human Resources & Recruitment',
        roleTitle: 'Human Resources Coordinator',
        rank: 6,
        avgSalary: 66243,
        avgSalaryChange: -3,
      },
    ],
  },
  {
    industryIdFilter: 6362,
    roles: [
      {
        industryId: 6362,
        industryTitle: 'Sales',
        roleTitle: 'Business Development Manager',
        rank: 1,
        avgSalary: 101638,
        avgSalaryChange: 4,
      },
      {
        industryId: 6362,
        industryTitle: 'Sales',
        roleTitle: 'Account Manager',
        rank: 2,
        avgSalary: 90682,
        avgSalaryChange: 4,
      },
      {
        industryId: 6362,
        industryTitle: 'Sales',
        roleTitle: 'Relationship Manager',
        rank: 3,
        avgSalary: 89700,
        avgSalaryChange: -18,
      },
      {
        industryId: 6362,
        industryTitle: 'Sales',
        roleTitle: 'Territory Manager',
        rank: 4,
        avgSalary: 81703,
        avgSalaryChange: 1,
      },
      {
        industryId: 6362,
        industryTitle: 'Sales',
        roleTitle: 'Account Executive',
        rank: 5,
        avgSalary: 79287,
        avgSalaryChange: -1,
      },
      {
        industryId: 6362,
        industryTitle: 'Sales',
        roleTitle: 'Sales Executive',
        rank: 6,
        avgSalary: 77562,
        avgSalaryChange: -4,
      },
      {
        industryId: 6362,
        industryTitle: 'Sales',
        roleTitle: 'Sales Representative',
        rank: 7,
        avgSalary: 73176,
        avgSalaryChange: 1,
      },
      {
        industryId: 6362,
        industryTitle: 'Sales',
        roleTitle: 'Salesperson',
        rank: 8,
        avgSalary: 73173,
        avgSalaryChange: -9,
      },
      {
        industryId: 6362,
        industryTitle: 'Sales',
        roleTitle: 'Sales Coordinator',
        rank: 9,
        avgSalary: 61101,
        avgSalaryChange: -2,
      },
    ],
  },
];
