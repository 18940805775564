import type { PdfDataSummary } from './custom-template/summary';
import { useDownloadTalPdf } from './useDownloadTalPdf';

import { forwardRef } from 'react';

export const DownloadTalPdfButton = forwardRef<
  HTMLAnchorElement,
  { data: PdfDataSummary[] }
>(({ data }, ref) => {
  const instance = useDownloadTalPdf(data);
  if (instance.loading) return <div>Loading ...</div>;

  if (instance.error) return <div>Something went wrong: {instance.error}</div>;

  return (
    <>
      {instance.url ? (
        <a
          style={{
            position: 'absolute',
            width: '1px',
            height: '1px',
            padding: '0',
            margin: '-1px',
            overflow: 'hidden',
            clip: 'rect(0, 0, 0, 0)',
            whiteSpace: 'nowrap',
            border: '0',
          }}
          href={instance.url}
          target="_blank"
          rel="noreferrer"
          download="talent-attraction-lab-report.pdf"
          ref={ref}
        />
      ) : null}
    </>
  );
});

DownloadTalPdfButton.displayName = 'DownloadTalPdfButton';
