import type { TipsCardFragment } from '@seek/cmsu-cms-connect';
import {
  Box,
  Badge,
  Inline,
  ButtonLink,
  TextLink,
  Text,
  Stack,
  Tiles,
} from 'braid-design-system';
import { RichText } from '@seek/cmsu-rich-text';
import React from 'react';
import { renderIcon } from '../../../IconBlockGroup/renderer';
import { toUrl } from '../../../../utils';
import { tipCard, tipsCardDesc, tipTextLink } from '../../styles.css';

type Props = {
  tipsCards: ({ __typename?: 'TipsCard' | undefined } & TipsCardFragment)[];
};
// { heading, paragraph, actionGroup, image, badge }: Props
const TipsCard = ({ tipsCards }: Props) => (
  <Tiles
    space={{ mobile: 'none', tablet: 'large', desktop: 'none' }}
    columns={{ mobile: 1, tablet: 2, desktop: 1, wide: 1 }}
  >
    {tipsCards.map((tipsCard, ind) => (
      <Box height="full" paddingTop="medium" key={`tipscard-wrap-${ind}`}>
        <Box
          borderRadius="large"
          data-testid="CardTestId"
          padding="large"
          height="full"
          className={tipCard}
          key={`tipscard-${ind}`}
        >
          <Stack space="medium">
            {tipsCard.badge && (
              <Inline space="small" alignY="center">
                {tipsCard.icon && renderIcon(tipsCard.icon)}
                {tipsCard.badge && (
                  <Badge tone={tipsCard.badge?.tone}>
                    {tipsCard.badge?.text}
                  </Badge>
                )}
              </Inline>
            )}
            <Box className={tipsCardDesc}>
              <RichText content={tipsCard?.description.raw} />
            </Box>
            {tipsCard.inlineLink && (
              <Box paddingTop="xxsmall" className={tipTextLink}>
                <Text tone="formAccent">
                  {tipsCard.inlineLink.link?.to && (
                    <TextLink
                      href={toUrl(tipsCard.inlineLink.link?.to)}
                      target="_blank"
                      icon={
                        (tipsCard.inlineLink.icon &&
                          renderIcon(tipsCard.inlineLink.icon)) ||
                        undefined
                      }
                      iconPosition={
                        tipsCard.inlineLink.iconPosition || undefined
                      }
                    >
                      {tipsCard.inlineLink.text}
                    </TextLink>
                  )}
                </Text>
              </Box>
            )}
            {tipsCard.downloadButton && (
              <Inline space="none">
                <ButtonLink
                  href={toUrl(tipsCard.downloadButton.link?.to) || '#'}
                  target="_blank"
                  icon={
                    (tipsCard.downloadButton.icon &&
                      renderIcon(tipsCard.downloadButton.icon)) ||
                    undefined
                  }
                  iconPosition={
                    tipsCard.downloadButton.iconPosition || undefined
                  }
                  variant={tipsCard.downloadButton.variant || undefined}
                  download
                >
                  {tipsCard.downloadButton.text}
                </ButtonLink>
              </Inline>
            )}
          </Stack>
        </Box>
      </Box>
    ))}
  </Tiles>
);

export { TipsCard };
