import React, { useState } from 'react';
import { CandidateCard, type CandidateCardProps } from './CandidateCard';
import { useTranslations } from '@vocab/react';
import translations_tal from '../../.vocab';
import { Box, Button, Hidden, Stack, Text } from 'braid-design-system';
import { datalabHelper } from '../../datalabHelper';
import type { GetSurveyQuery } from '../../../../../../../apps/unified-apac/src/gql/tal/generated';
import translations from '../../../../.vocab';
import { Tiles } from '../../../Tiles/Tiles';

interface CandidateInformationProps {
  demographic: GetSurveyQuery['demographic'];
}

const maxCardsToDisplay = 4;

export const CandidateInformation = ({
  demographic,
}: CandidateInformationProps) => {
  const { t } = useTranslations(translations);
  const { t: t_tal } = useTranslations(translations_tal);
  const [showMore, setShowMore] = useState<boolean>(false);

  const candidateCards: CandidateCardProps[] =
    datalabHelper.mapAndSortAllSurveyDemographicItems(demographic);

  const expandShowMore = () => {
    setShowMore(true);
  };

  return (
    <Box>
      <Stack space="gutter">
        <Text size="large" weight="strong">
          {t_tal('About these candidates')}
        </Text>

        <Hidden above="mobile">
          <Stack space="small">
            {!showMore && (
              <>
                <CandidateCards
                  candidateCards={candidateCards.slice(0, maxCardsToDisplay)}
                />
                <Button onClick={expandShowMore}>{t('Show more')}</Button>
              </>
            )}
            {showMore && <CandidateCards candidateCards={candidateCards} />}
          </Stack>
        </Hidden>
        <Hidden below="tablet">
          <CandidateCards candidateCards={candidateCards} />
        </Hidden>
      </Stack>
    </Box>
  );
};

interface CandidateCardsProp {
  candidateCards: CandidateCardProps[];
}
const CandidateCards = ({ candidateCards }: CandidateCardsProp) => (
  <Tiles columns={{ mobile: 1, tablet: 2 }} space="small">
    {candidateCards.map((card, index) => (
      <CandidateCard {...card} key={index} />
    ))}
  </Tiles>
);
