import { createContext, useContext, useEffect, useRef, useState } from 'react';
import type { DatalabDriverData } from '../../datalabHelper';
import { DownloadTalPdfButton } from './DownloadPdfButton';
import { getDownloadableSummary } from './helpers';
const PdfDownloadContext = createContext<PdfDownloadContextType | null>(null);
type PdfDownloadContextType = {
  downloadPdf: () => void;
};
type PdfDownloadProviderProps = {
  drivers: DatalabDriverData | null;
  children: React.ReactNode;
};
export const PdfDownloadProvider = ({
  drivers,
  children,
}: PdfDownloadProviderProps) => {
  const linkRef = useRef<HTMLAnchorElement>(null);
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);
  const data = drivers ? getDownloadableSummary(drivers) : null;
  const downloadPdf = () => {
    linkRef.current?.click();
  };

  return (
    <PdfDownloadContext.Provider value={{ downloadPdf }}>
      {children}
      {mounted && data && <DownloadTalPdfButton data={data} ref={linkRef} />}
    </PdfDownloadContext.Provider>
  );
};

export const usePdfDownloadContext = () => {
  const context = useContext(PdfDownloadContext);
  if (!context) {
    throw new Error(
      'usePdfDownloadContext must be used within a PdfDownloadProvider',
    );
  }
  return context;
};
