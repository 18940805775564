import React from 'react';
import {
  Accordion,
  Box,
  Button,
  Column,
  Columns,
  Stack,
  Text,
} from 'braid-design-system';
import { scroller } from 'react-scroll';

import { QuestionList } from './APAC_QuestionList';
import { questions } from './questions';
import { SelectedQuestions } from './APAC_SelectedQuestions';
import { useScreenSize } from '../../hooks/useScreenSize';

export const ADD_QUESTION = 'addQuestion';

interface BuilderProps {
  activeList: SelectedQuestion[];
  setActiveList: React.Dispatch<React.SetStateAction<SelectedQuestion[]>>;
}

export interface SelectedQuestion {
  question: string;
  parent: string;
}

export const Builder = ({ activeList, setActiveList }: BuilderProps) => {
  const { isMobile } = useScreenSize();

  const [customQuestions, setCustomQuestions] = React.useState<string[]>([]);
  const [isPreview, setPreviewVisible] = React.useState<boolean>(false);

  const removeActiveItem = (item: SelectedQuestion) => {
    const newActiveList = activeList.filter(
      (q) => q.question !== item.question,
    );
    setActiveList(([] as SelectedQuestion[]).concat(newActiveList));
  };

  const addActiveItem = (item: SelectedQuestion) => {
    setActiveList(activeList.concat([item]));
  };

  const switchActiveItem = (index: number, newIndex: number) => {
    const item = activeList[index];

    activeList.splice(index, 1);
    activeList.splice(newIndex, 0, item);
    setActiveList([...activeList]);
  };

  const addCustomQuestion = (q: string) => {
    setCustomQuestions(customQuestions.concat([q]));
    addActiveItem({
      parent: ADD_QUESTION,
      question: q,
    });
  };

  return (
    <Stack space="large">
      <Columns
        space={{ mobile: 'none', tablet: 'gutter' }}
        collapseBelow="desktop"
      >
        <Column width="3/5">
          {(!isMobile || isPreview) && (
            <SelectedQuestions
              activeList={activeList}
              removeActiveItem={removeActiveItem}
              switchActiveItem={switchActiveItem}
            />
          )}
        </Column>
        <Column width="2/5">
          {(!isMobile || !isPreview) && (
            <>
              <Box marginBottom="large">
                <Text weight="strong">
                  Select questions from the categories below:
                </Text>
              </Box>
              <Accordion>
                {Object.keys(questions).map((key: string) => (
                  <QuestionList
                    key={key}
                    questionKey={key}
                    {...questions[key]}
                    activeList={activeList}
                    addActiveItem={addActiveItem}
                    removeActiveItem={removeActiveItem}
                  />
                ))}
              </Accordion>
              <QuestionList
                questionKey={ADD_QUESTION}
                name="Add your own questions"
                icon="add-question"
                questions={customQuestions}
                activeList={activeList}
                addActiveItem={addActiveItem}
                removeActiveItem={removeActiveItem}
                addCustomQuestion={addCustomQuestion}
              />
            </>
          )}
        </Column>
      </Columns>

      {isMobile && (activeList.length !== 0 || isPreview) && (
        <Button
          onClick={() => {
            setPreviewVisible(!isPreview);
            scroller.scrollTo('interviewBuilder', {
              duration: 200,
              smooth: true,
              offset: -50,
            });
          }}
        >
          {isPreview ? 'Add more questions' : 'Preview'}
        </Button>
      )}
    </Stack>
  );
};
