import { useEffect } from 'react';

const setTarget = (link: HTMLAnchorElement) => {
  const href = link.href.startsWith('http') ? link.href : `http:${link.href}`;
  const url = new URL(href);

  if (url.hostname !== window.location.hostname) {
    link.setAttribute('target', '_blank');
    link.setAttribute(
      'aria-label',
      `${link.textContent} - link opens in a new tab`,
    );
  }
};

export function useTargetBlank() {
  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    const links = document.querySelectorAll('a');
    links.forEach((link) => {
      if (
        (link.href && link.href.startsWith('http:')) ||
        link.href.startsWith('https:') ||
        link.href.startsWith('//')
      ) {
        setTarget(link);
      }
    });
  }, []);
}
