import React from 'react';
import working_on_it from './images/working-on-it.png';
import { Box, Heading, Stack, Text } from 'braid-design-system';
import { useTranslations } from '@vocab/react';
import translations from './.vocab';
import { vars } from 'braid-design-system/css';

export const NoDataState = () => {
  const { t } = useTranslations(translations);
  return (
    <Box textAlign="center">
      <Stack space="large">
        <img
          src={working_on_it}
          alt={t("We're working on it.")}
          height="128"
          width="128"
          style={{
            paddingBottom: vars.space.medium,
          }}
        />

        <Heading level="3">{t("We're working on it.")}</Heading>

        <Stack space="small">
          <Text tone="secondary">
            {t(
              "We can't show this data right now. We're doing our best to fix this.",
            )}
          </Text>
          <Text tone="secondary">
            {t('Try refreshing the page or check back later.')}
          </Text>
        </Stack>
      </Stack>
    </Box>
  );
};
