import type { TextWeight } from '@seek/cmsu-cms-connect';
import type { Text } from 'braid-design-system';

type BraidTextProps = React.ComponentProps<typeof Text>;

export const toBraidTextWeight = (
  weight?: TextWeight | null,
  fallback: BraidTextProps['weight'] = 'regular',
): BraidTextProps['weight'] => {
  const values = ['medium', 'regular', 'strong'] as const;
  return values.find((value) => value === weight) || fallback;
};
