import "../../libs/components/src/modules/Banner/styles.css.ts.vanilla.css!=!../../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.14_@types+node@20.14.9_babel-plugin-macros@3.1.0_webpack@_opjo4tf3zoifprrod44zuxxh6u/node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../libs/components/src/modules/Banner/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61Sy07DMBC89ytWQkj04Crpk4QLJ34DufE2XUjWkeO0AcS/o7hOcUNLOeBL7Hg8M7uzk6xcxas6ho8RgMXWCllQzilkyBbNwwhgi5RvbQpxFN1251K2Yk/Kbk9/9bB5FFXtw+hzNDlQT/+Pejagnv1CvZbZa250w0oYrFDaFFj77eC+pndMIdNsJXF3d706gHdBrLBNIUkCR3PnKODOdKFNCjdPbmGIXVzA7qS5E+I5TmjHXE6bsbOrjUIjjFTU1ENMNg5oV45WUV0V8i2FTYHO70tTW9q8ia5MZPtbCpVUijgXVldDpdk4BKy1tbo8hzm6uT/vpvsKRQYzS7rLTRdN6XrvghRksaxDj2d7Y0Ol5NDOQ58K3HQFVS3UuiB15p33kBu9TyEOUz8ZMelYL6p3w2Fy4h+pOWePJSqSUGcGkUGygruSuJ/w1Tyq2rHj92KROwB0oD6UZT9wn9+4ucf5HIbiC2fsyswoD/JmlofoA5HF30SG769VnSTTQdV9NR6wuOjkImDpAccxY83o7XwBJyhykOEEAAA=\"}!../../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.14_@types+node@20.14.9_babel-plugin-macros@3.1.0_webpack@_opjo4tf3zoifprrod44zuxxh6u/node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var BannerImageColumnStyle = 'cm717s6';
export var BannerTextColumnBoxStyle = 'cm717s4';
export var ShopfrontBannerTextColumnBoxStyle = 'cm717s5';
export var VerticalDivider = 'cm717s8';
export var VerticalDividerText = 'cm717sa';
export var VerticalDividerWrapper = 'cm717s7';
export var VerticalLine = 'cm717s9';
export var bannerContainer = 'cm717s0';
export var headerImageStyle = 'cm717s2';
export var headerImageStyleEnlarged = 'cm717s1';
export var tertiaryImageStyle = 'cm717s3';