import React from 'react';
import {
  percentageWrapper,
  TableBuilder,
  textElementWrapper,
} from '@seek/cmsu-rich-text/src/components/Table/TableBuilder';
import moment from 'moment';
import { NoDataState } from '../../NoDataState/NoDataState';

export type Dividend = {
  DividendType: string;
  DividendAmount: number;
  FrankingCredit: number;
  PaymentDate: string;
};

type Props = {
  dividends: Dividend[];
};

export const Dividend = ({ dividends }: Props) => {
  if (!dividends || dividends.length === 0) return <NoDataState />;

  const tableData: Map<string, JSX.Element>[] | undefined = dividends?.map(
    (dividend) => {
      const date = moment(dividend.PaymentDate, 'YYYYMMDD');
      return new Map<string, JSX.Element>([
        ['Year', textElementWrapper(date.year())],
        ['Type', textElementWrapper(getDividendType(dividend.DividendType))],
        ['Rate', textElementWrapper(dividend.DividendAmount)],
        ['Franking Credit', percentageWrapper(dividend.FrankingCredit)],
        ['Payment Date', textElementWrapper(date.format('DD-MMM-YYYY'))],
      ]);
    },
  );

  return <TableBuilder tableData={tableData} showHeader={true} />;
};

const getDividendType = (type: string) => {
  switch (type) {
    case 'I':
      return 'Interim Dividend';
    case 'F':
      return 'Final Dividend';
    case 'D':
      return 'Dividend';
    default:
      return 'Unknown';
  }
};
