import { Box, Stack, Text } from 'braid-design-system';
import ErrorSvg from '../../../public/images/error-icon.svg';
import translations from '../../.vocab';
import { useTranslations } from '@vocab/react';

type Props = {
  searchedTerm: string;
};

export const NoSearchResult = ({ searchedTerm }: Props) => {
  const { t } = useTranslations(translations);

  return (
    <Stack space="large" align="center">
      <Box
        marginTop="xlarge"
        style={{
          width: '110px',
          height: '95px',
          background: `url(data:image/svg+xml;utf8,${encodeURIComponent(
            ErrorSvg,
          )})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
        }}
      />
      <Box textAlign="center" margin="large">
        <Stack space="large">
          <Text weight="strong" size="large">
            {t('No results found')}
            {`’${searchedTerm}’`}
          </Text>
          <Text tone="secondary">{t('Try other ways to search')}</Text>
        </Stack>
      </Box>
    </Stack>
  );
};
