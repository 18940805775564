import { Icon } from '@seek/cmsu-cms-connect';
import { useMelwaysLink } from '@seek/melways-react';
import type { Language } from '@seek/melways-sites';
import { Box, Pagination, Stack } from 'braid-design-system';
import moment from 'moment';
import { useMemo } from 'react';
import { DocumentList } from '../../DocumentList/DocumentList';
import { NoDataState } from '../../NoDataState/NoDataState';

// Types
type Headline = {
  date: string;
  time: string;
  articleID: string;
  pdfLink: string;
  HeadlineText: string;
  Symbol: string;
  pages: number;
};

export type AsxAnnouncement = {
  totalHeadlines: number;
  totalPages: number;
  category: string;
  pageNumber: number;
  oldestYear: number;
  latestYear: number;
  headlines: Headline[];
};

type AsxAnnouncementsContentProps = {
  headlines: Headline[];
};

type AsxAnnouncementsProps = {
  asxAnnouncements: AsxAnnouncement;
  pathname: string;
  // pageNumber: number;
  language: Language;
  limit?: number;
};

// Constants
const PAGINATION_THRESHOLD = 20;
const DATE_FORMAT = 'YYYYMMDD';
const DISPLAY_DATE_FORMAT = 'DD MMM YYYY';

// Utility functions
const formatDate = (dateString: string): string =>
  moment(dateString, DATE_FORMAT).format(DISPLAY_DATE_FORMAT);

// Content component
const AsxAnnouncementsContent: React.FC<AsxAnnouncementsContentProps> = ({
  headlines,
}) => {
  const items = useMemo(
    () =>
      headlines.map((headline) => ({
        id: headline.articleID,
        title: headline.HeadlineText,
        date: formatDate(headline.date),
        externalUrl: {
          externalUrl: headline.pdfLink,
        },
      })),
    [headlines],
  );

  return (
    <DocumentList
      icon={Icon.Document}
      usePagination={false}
      documents={items}
    />
  );
};
// AsxAnnouncements component: Displays ASX announcements with pagination
export const AsxAnnouncements: React.FC<AsxAnnouncementsProps> = ({
  asxAnnouncements,
  limit,
  pathname,
  language,
}) => {
  const { totalPages, pageNumber, headlines } = asxAnnouncements;
  const urlResolver = useMelwaysLink();

  // Determine if pagination is needed
  const hasMultiplePages = totalPages > 1;

  // Apply limit to headlines if specified
  const displayedHeadlines = useMemo(
    () => (limit ? headlines.slice(0, limit) : headlines),
    [headlines, limit],
  );

  const handlePaginationClick = (page: number) => {
    const nextPaginationUrl = urlResolver({
      language,
      path: `${pathname}/${page}`,
    });

    window.location.href = nextPaginationUrl;
  };

  if (!displayedHeadlines || displayedHeadlines.length === 0) {
    return <NoDataState />;
  }

  return (
    <Stack space={'large'}>
      <AsxAnnouncementsContent headlines={displayedHeadlines} />
      {displayedHeadlines.length >= PAGINATION_THRESHOLD &&
        hasMultiplePages && (
          <Box>
            <Pagination
              page={pageNumber}
              total={totalPages}
              label="pagination"
              linkProps={({ page: pageInPagination }) => ({
                href: '#',
                onClick: (e) => {
                  e.preventDefault();
                  handlePaginationClick(pageInPagination);
                },
              })}
            />
          </Box>
        )}
    </Stack>
  );
};
