import { renderers, type RichTextProps } from '@seek/cmsu-rich-text';
import type {
  AccordionFragment,
  EmbedAccordionGroupFragment,
  EmbedCustomContentFragment,
  RichTextAccordionFragment,
} from '@seek/cmsu-cms-connect';
import { AccordionGroup } from '@seek/cmsu-components';
import { Box, ContentBlock } from 'braid-design-system';
import React from 'react';
import { CustomContent } from '../../CustomContent/CustomContent';

export const toAccordions = (
  accordions: RichTextAccordionFragment[],
): AccordionFragment[] =>
  accordions.map((accordion) => ({
    id: accordion.id,
    label: accordion.label,
    content: accordion.content,
    locale: accordion.locale,
  }));

export const richTextRenderers: RichTextProps['renderers'] = {
  ...renderers,
  embed: {
    EmbedAccordionGroup: ({ accordion }: EmbedAccordionGroupFragment) => (
      <Box background="surface" paddingX="gutter" paddingY="gutter">
        <ContentBlock>
          <AccordionGroup accordions={toAccordions(accordion)} />
        </ContentBlock>
      </Box>
    ),
    EmbedCustomContent: ({ title }: EmbedCustomContentFragment) => (
      <CustomContent title={title} />
    ),
  },
};
