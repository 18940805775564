import { Box, Inline, Text, TextLink } from 'braid-design-system';
import React from 'react';
import { prefixUrlWithLanguage } from '../../helpers/prefixUrlWithLanguage';

type Props = {
  name: string;
  slug: string;
  basePath: string;
  locale?: string;
};

const CategoryLink = ({ name, slug, basePath, locale }: Props) => {
  const categoryLinkWithLocale = prefixUrlWithLanguage({
    url: `/${basePath}/category/${slug}`,
    locale,
  });

  return (
    <Inline space="xsmall" alignY="center">
      <Box
        background="neutralLight"
        paddingY="xsmall"
        paddingX="small"
        borderRadius="full"
      >
        <Text size="small" weight="strong">
          <TextLink href={categoryLinkWithLocale}>{name}</TextLink>
        </Text>
      </Box>
    </Inline>
  );
};

export { CategoryLink };
