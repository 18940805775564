import { useTranslations } from '@vocab/react';
import { Box, Dialog, List, Text, TextLink } from 'braid-design-system';
import { useId, useState } from 'react';
import translations_tal from '../../.vocab';
export const HowWeDefineImportance = () => {
  const { t: t_tal } = useTranslations(translations_tal);
  const [isOpen, setIsOpen] = useState(false);
  const id = useId();
  return (
    <Box display="flex" flexDirection="column" style={{ gap: '12px' }}>
      <Text>
        {t_tal(
          "When writing job ads, focus on delighted-if and must-have drivers that differentiate you from your competitors. Don't waste space on not-bothered drivers, and highlight put-offs early. ",
        )}
      </Text>
      <Text>
        <TextLink
          href="#"
          hitArea="large"
          onClick={(e) => {
            e.preventDefault();
            setIsOpen(true);
          }}
        >
          {t_tal('How we define importance')}
        </TextLink>
      </Text>
      {isOpen && (
        <Dialog
          id={id}
          title={t_tal('How we define importance')}
          open={isOpen}
          onClose={() => setIsOpen(false)}
          closeLabel="Close Dialog"
        >
          <Text>
            {t_tal(
              'Candidates have ranked each driver according to 4 levels of importance:',
            )}
          </Text>
          <List>
            <Text>
              {t_tal(
                'Must-have: Essentials that candidates expect a job to have.',
              )}
            </Text>
            <Text>
              {t_tal(
                'Delighted-if: Extras that differentiate you from other companies.',
              )}
            </Text>
            <Text>
              {t_tal(
                "Not-bothered: Factors that don't really matter to candidates.",
              )}
            </Text>
            <Text>
              {t_tal(
                "Put-off: Dealbreakers that candidates don't want in a role.",
              )}
            </Text>
          </List>
        </Dialog>
      )}
    </Box>
  );
};
