import React from 'react';
import { Box } from 'braid-design-system';

export interface ImageUIProps {
  alt: string;
  imageUrl?: string | null;
  isAbsolute?: boolean;
}

export const ImageUI = ({ alt, imageUrl, isAbsolute }: ImageUIProps) => (
  <Box
    component="img"
    src={imageUrl != null ? imageUrl : ''}
    alt={alt}
    width="full"
    height="full"
    style={{
      position: isAbsolute ? 'absolute' : 'inherit',
      objectFit: 'cover',
      objectPosition: 'center',
    }}
  />
);
