
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93IG1vcmUiOiJTaG93IG1vcmUiLCJTaG93IGxlc3MiOiJTaG93IGxlc3MiLCJGaWx0ZXIgYnkgeWVhciI6IkZpbHRlciBieSB5ZWFyIiwiTWVudSI6Ik1lbnUiLCJGaWx0ZXJzIjoiRmlsdGVycyIsIlNlbGVjdCBhIGZpbHRlciI6IlNlbGVjdCBhIGZpbHRlciIsIlNlbGVjdCB1cCB0byB0d28gb3B0aW9ucyI6IlNlbGVjdCB1cCB0byB0d28gb3B0aW9ucyIsIlNlbGVjdCBvcHRpb25zIjoiU2VsZWN0IG9wdGlvbnMiLCJBcHBseSBmaWx0ZXJzIjoiQXBwbHkgZmlsdGVycyIsIkNsZWFyIGFsbCI6IkNsZWFyIGFsbCIsIkZpbHRlciBieSI6IkZpbHRlciBieSIsIlNlbGVjdCBhbiBvcHRpb24iOiJTZWxlY3QgYW4gb3B0aW9uIiwiU29ydCBieSI6IlNvcnQgYnkiLCJTaG93IGRldGFpbHMiOiJTaG93IGRldGFpbHMiLCJIaWRlIGRldGFpbHMiOiJIaWRlIGRldGFpbHMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93IG1vcmUiOiJTaG93IG1vcmUiLCJTaG93IGxlc3MiOiJTaG93IGxlc3MiLCJGaWx0ZXIgYnkgeWVhciI6IkZpbHRlciBieSB5ZWFyIiwiTWVudSI6Ik1lbnUiLCJGaWx0ZXJzIjoiRmlsdGVycyIsIlNlbGVjdCBhIGZpbHRlciI6IlNlbGVjdCBhIGZpbHRlciIsIlNlbGVjdCB1cCB0byB0d28gb3B0aW9ucyI6IlNlbGVjdCB1cCB0byB0d28gb3B0aW9ucyIsIlNlbGVjdCBvcHRpb25zIjoiU2VsZWN0IG9wdGlvbnMiLCJBcHBseSBmaWx0ZXJzIjoiQXBwbHkgZmlsdGVycyIsIkNsZWFyIGFsbCI6IkNsZWFyIGFsbCIsIkZpbHRlciBieSI6IkZpbHRlciBieSIsIlNlbGVjdCBhbiBvcHRpb24iOiJTZWxlY3QgYW4gb3B0aW9uIiwiU29ydCBieSI6IlNvcnQgYnkiLCJTaG93IGRldGFpbHMiOiJTaG93IGRldGFpbHMiLCJIaWRlIGRldGFpbHMiOiJIaWRlIGRldGFpbHMifQ%3D%3D!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93IG1vcmUiOiJTaG93IG1vcmUiLCJTaG93IGxlc3MiOiJTaG93IGxlc3MiLCJGaWx0ZXIgYnkgeWVhciI6IuC4leC4seC4p%2BC4geC4o%2BC4reC4h%2BC4leC4suC4oeC4m%2BC4tSIsIk1lbnUiOiLguYDguKHguJnguLkiLCJGaWx0ZXJzIjoi4LiE4Lix4LiU4LiB4Lij4Lit4LiHIiwiU2VsZWN0IGEgZmlsdGVyIjoi4LmA4Lil4Li34Lit4LiB4LiV4Lix4Lin4LiB4Lij4Lit4LiHIiwiU2VsZWN0IHVwIHRvIHR3byBvcHRpb25zIjoi4LmA4Lil4Li34Lit4LiB4LmE4LiU4LmJ4Liq4Li54LiH4Liq4Li44LiUIDIg4LiV4Lix4Lin4LmA4Lil4Li34Lit4LiBIiwiU2VsZWN0IG9wdGlvbnMiOiLguYDguKXguLfguK3guIHguJXguLHguKfguYDguKXguLfguK3guIEiLCJBcHBseSBmaWx0ZXJzIjoi4LmD4LiK4LmJ4LiV4Lix4Lin4LiB4Lij4Lit4LiHIiwiQ2xlYXIgYWxsIjoi4Lil4LmJ4Liy4LiH4LiV4Lix4Lin4LiB4Lij4Lit4LiHIiwiRmlsdGVyIGJ5Ijoi4LiE4Lix4LiU4LiB4Lij4Lit4LiH4LiV4Liy4LihIiwiU2VsZWN0IGFuIG9wdGlvbiI6IuC5gOC4peC4t%2BC4reC4geC4q%2BC4meC4tuC5iOC4h%2BC4leC4seC4p%2BC5gOC4peC4t%2BC4reC4gSIsIlNvcnQgYnkiOiLguYDguKPguLXguKLguIfguJXguLLguKEiLCJTaG93IGRldGFpbHMiOiLguYHguKrguJTguIfguYDguJnguLfguYnguK3guKvguLIiLCJIaWRlIGRldGFpbHMiOiLguIvguYjguK3guJnguYDguJnguLfguYnguK3guKvguLIifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93IG1vcmUiOiJTaG93IG1vcmUiLCJTaG93IGxlc3MiOiJTaG93IGxlc3MiLCJGaWx0ZXIgYnkgeWVhciI6IuC4leC4seC4p%2BC4geC4o%2BC4reC4h%2BC4leC4suC4oeC4m%2BC4tSIsIk1lbnUiOiLguYDguKHguJnguLkiLCJGaWx0ZXJzIjoi4LiE4Lix4LiU4LiB4Lij4Lit4LiHIiwiU2VsZWN0IGEgZmlsdGVyIjoi4LmA4Lil4Li34Lit4LiB4LiV4Lix4Lin4LiB4Lij4Lit4LiHIiwiU2VsZWN0IHVwIHRvIHR3byBvcHRpb25zIjoi4LmA4Lil4Li34Lit4LiB4LmE4LiU4LmJ4Liq4Li54LiH4Liq4Li44LiUIDIg4LiV4Lix4Lin4LmA4Lil4Li34Lit4LiBIiwiU2VsZWN0IG9wdGlvbnMiOiLguYDguKXguLfguK3guIHguJXguLHguKfguYDguKXguLfguK3guIEiLCJBcHBseSBmaWx0ZXJzIjoi4LmD4LiK4LmJ4LiV4Lix4Lin4LiB4Lij4Lit4LiHIiwiQ2xlYXIgYWxsIjoi4Lil4LmJ4Liy4LiH4LiV4Lix4Lin4LiB4Lij4Lit4LiHIiwiRmlsdGVyIGJ5Ijoi4LiE4Lix4LiU4LiB4Lij4Lit4LiH4LiV4Liy4LihIiwiU2VsZWN0IGFuIG9wdGlvbiI6IuC5gOC4peC4t%2BC4reC4geC4q%2BC4meC4tuC5iOC4h%2BC4leC4seC4p%2BC5gOC4peC4t%2BC4reC4gSIsIlNvcnQgYnkiOiLguYDguKPguLXguKLguIfguJXguLLguKEiLCJTaG93IGRldGFpbHMiOiLguYHguKrguJTguIfguYDguJnguLfguYnguK3guKvguLIiLCJIaWRlIGRldGFpbHMiOiLguIvguYjguK3guJnguYDguJnguLfguYnguK3guKvguLIifQ%3D%3D!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93IG1vcmUiOiJTaG93IG1vcmUiLCJTaG93IGxlc3MiOiJTaG93IGxlc3MiLCJGaWx0ZXIgYnkgeWVhciI6IkZpbHRlciBiZXJkYXNhcmthbiB0YWh1biIsIk1lbnUiOiJNZW51IiwiRmlsdGVycyI6IkZpbHRlciIsIlNlbGVjdCBhIGZpbHRlciI6IlBpbGloIGZpbHRlciIsIlNlbGVjdCB1cCB0byB0d28gb3B0aW9ucyI6IlBpbGloIGhpbmdnYSBkdWEgb3BzaSIsIlNlbGVjdCBvcHRpb25zIjoiUGlsaWggb3BzaSIsIkFwcGx5IGZpbHRlcnMiOiJUZXJhcGthbiBmaWx0ZXIiLCJDbGVhciBhbGwiOiJIYXB1cyBzZW11YSIsIkZpbHRlciBieSI6IkZpbHRlciBiZXJkYXNhcmthbiIsIlNlbGVjdCBhbiBvcHRpb24iOiJQaWxpaCBzYXR1IG9wc2kiLCJTb3J0IGJ5IjoiVXJ1dGthbiBiZXJkYXNhcmthbiIsIlNob3cgZGV0YWlscyI6IlRhbXBpbGthbiBkZXRhaWwiLCJIaWRlIGRldGFpbHMiOiJTZW1idW55aWthbiBkZXRhaWwifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93IG1vcmUiOiJTaG93IG1vcmUiLCJTaG93IGxlc3MiOiJTaG93IGxlc3MiLCJGaWx0ZXIgYnkgeWVhciI6IkZpbHRlciBiZXJkYXNhcmthbiB0YWh1biIsIk1lbnUiOiJNZW51IiwiRmlsdGVycyI6IkZpbHRlciIsIlNlbGVjdCBhIGZpbHRlciI6IlBpbGloIGZpbHRlciIsIlNlbGVjdCB1cCB0byB0d28gb3B0aW9ucyI6IlBpbGloIGhpbmdnYSBkdWEgb3BzaSIsIlNlbGVjdCBvcHRpb25zIjoiUGlsaWggb3BzaSIsIkFwcGx5IGZpbHRlcnMiOiJUZXJhcGthbiBmaWx0ZXIiLCJDbGVhciBhbGwiOiJIYXB1cyBzZW11YSIsIkZpbHRlciBieSI6IkZpbHRlciBiZXJkYXNhcmthbiIsIlNlbGVjdCBhbiBvcHRpb24iOiJQaWxpaCBzYXR1IG9wc2kiLCJTb3J0IGJ5IjoiVXJ1dGthbiBiZXJkYXNhcmthbiIsIlNob3cgZGV0YWlscyI6IlRhbXBpbGthbiBkZXRhaWwiLCJIaWRlIGRldGFpbHMiOiJTZW1idW55aWthbiBkZXRhaWwifQ%3D%3D!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93IG1vcmUiOiJTaG93IG1vcmUiLCJTaG93IGxlc3MiOiJTaG93IGxlc3MiLCJGaWx0ZXIgYnkgeWVhciI6IkZpbHRlciBieSB5ZWFyIiwiTWVudSI6Ik1lbnUiLCJGaWx0ZXJzIjoiRmlsdGVycyIsIlNlbGVjdCBhIGZpbHRlciI6IlNlbGVjdCBhIGZpbHRlciIsIlNlbGVjdCB1cCB0byB0d28gb3B0aW9ucyI6IlNlbGVjdCB1cCB0byB0d28gb3B0aW9ucyIsIlNlbGVjdCBvcHRpb25zIjoiU2VsZWN0IG9wdGlvbnMiLCJBcHBseSBmaWx0ZXJzIjoiQXBwbHkgZmlsdGVycyIsIkNsZWFyIGFsbCI6IkNsZWFyIGFsbCIsIkZpbHRlciBieSI6IkZpbHRlciBieSIsIlNlbGVjdCBhbiBvcHRpb24iOiJTZWxlY3QgYW4gb3B0aW9uIiwiU29ydCBieSI6IlNvcnQgYnkiLCJTaG93IGRldGFpbHMiOiJTaG93IGRldGFpbHMiLCJIaWRlIGRldGFpbHMiOiJIaWRlIGRldGFpbHMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93IG1vcmUiOiJTaG93IG1vcmUiLCJTaG93IGxlc3MiOiJTaG93IGxlc3MiLCJGaWx0ZXIgYnkgeWVhciI6IkZpbHRlciBieSB5ZWFyIiwiTWVudSI6Ik1lbnUiLCJGaWx0ZXJzIjoiRmlsdGVycyIsIlNlbGVjdCBhIGZpbHRlciI6IlNlbGVjdCBhIGZpbHRlciIsIlNlbGVjdCB1cCB0byB0d28gb3B0aW9ucyI6IlNlbGVjdCB1cCB0byB0d28gb3B0aW9ucyIsIlNlbGVjdCBvcHRpb25zIjoiU2VsZWN0IG9wdGlvbnMiLCJBcHBseSBmaWx0ZXJzIjoiQXBwbHkgZmlsdGVycyIsIkNsZWFyIGFsbCI6IkNsZWFyIGFsbCIsIkZpbHRlciBieSI6IkZpbHRlciBieSIsIlNlbGVjdCBhbiBvcHRpb24iOiJTZWxlY3QgYW4gb3B0aW9uIiwiU29ydCBieSI6IlNvcnQgYnkiLCJTaG93IGRldGFpbHMiOiJTaG93IGRldGFpbHMiLCJIaWRlIGRldGFpbHMiOiJIaWRlIGRldGFpbHMifQ%3D%3D!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93IG1vcmUiOiJTaG93IG1vcmUiLCJTaG93IGxlc3MiOiJTaG93IGxlc3MiLCJGaWx0ZXIgYnkgeWVhciI6IkZpbHRlciBieSB5ZWFyIiwiTWVudSI6Ik1lbnUiLCJGaWx0ZXJzIjoiRmlsdGVycyIsIlNlbGVjdCBhIGZpbHRlciI6IlNlbGVjdCBhIGZpbHRlciIsIlNlbGVjdCB1cCB0byB0d28gb3B0aW9ucyI6IlNlbGVjdCB1cCB0byB0d28gb3B0aW9ucyIsIlNlbGVjdCBvcHRpb25zIjoiU2VsZWN0IG9wdGlvbnMiLCJBcHBseSBmaWx0ZXJzIjoiQXBwbHkgZmlsdGVycyIsIkNsZWFyIGFsbCI6IkNsZWFyIGFsbCIsIkZpbHRlciBieSI6IkZpbHRlciBieSIsIlNlbGVjdCBhbiBvcHRpb24iOiJTZWxlY3QgYW4gb3B0aW9uIiwiU29ydCBieSI6IlNvcnQgYnkiLCJTaG93IGRldGFpbHMiOiJTaG93IGRldGFpbHMiLCJIaWRlIGRldGFpbHMiOiJIaWRlIGRldGFpbHMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93IG1vcmUiOiJTaG93IG1vcmUiLCJTaG93IGxlc3MiOiJTaG93IGxlc3MiLCJGaWx0ZXIgYnkgeWVhciI6IkZpbHRlciBieSB5ZWFyIiwiTWVudSI6Ik1lbnUiLCJGaWx0ZXJzIjoiRmlsdGVycyIsIlNlbGVjdCBhIGZpbHRlciI6IlNlbGVjdCBhIGZpbHRlciIsIlNlbGVjdCB1cCB0byB0d28gb3B0aW9ucyI6IlNlbGVjdCB1cCB0byB0d28gb3B0aW9ucyIsIlNlbGVjdCBvcHRpb25zIjoiU2VsZWN0IG9wdGlvbnMiLCJBcHBseSBmaWx0ZXJzIjoiQXBwbHkgZmlsdGVycyIsIkNsZWFyIGFsbCI6IkNsZWFyIGFsbCIsIkZpbHRlciBieSI6IkZpbHRlciBieSIsIlNlbGVjdCBhbiBvcHRpb24iOiJTZWxlY3QgYW4gb3B0aW9uIiwiU29ydCBieSI6IlNvcnQgYnkiLCJTaG93IGRldGFpbHMiOiJTaG93IGRldGFpbHMiLCJIaWRlIGRldGFpbHMiOiJIaWRlIGRldGFpbHMifQ%3D%3D!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93IG1vcmUiOiJTaG93IG1vcmUiLCJTaG93IGxlc3MiOiJTaG93IGxlc3MiLCJGaWx0ZXIgYnkgeWVhciI6IkZpbHRlciBieSB5ZWFyIiwiTWVudSI6Ik1lbnUiLCJGaWx0ZXJzIjoiRmlsdGVycyIsIlNlbGVjdCBhIGZpbHRlciI6IlNlbGVjdCBhIGZpbHRlciIsIlNlbGVjdCB1cCB0byB0d28gb3B0aW9ucyI6IlNlbGVjdCB1cCB0byB0d28gb3B0aW9ucyIsIlNlbGVjdCBvcHRpb25zIjoiU2VsZWN0IG9wdGlvbnMiLCJBcHBseSBmaWx0ZXJzIjoiQXBwbHkgZmlsdGVycyIsIkNsZWFyIGFsbCI6IkNsZWFyIGFsbCIsIkZpbHRlciBieSI6IkZpbHRlciBieSIsIlNlbGVjdCBhbiBvcHRpb24iOiJTZWxlY3QgYW4gb3B0aW9uIiwiU29ydCBieSI6IlNvcnQgYnkiLCJTaG93IGRldGFpbHMiOiJTaG93IGRldGFpbHMiLCJIaWRlIGRldGFpbHMiOiJIaWRlIGRldGFpbHMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93IG1vcmUiOiJTaG93IG1vcmUiLCJTaG93IGxlc3MiOiJTaG93IGxlc3MiLCJGaWx0ZXIgYnkgeWVhciI6IkZpbHRlciBieSB5ZWFyIiwiTWVudSI6Ik1lbnUiLCJGaWx0ZXJzIjoiRmlsdGVycyIsIlNlbGVjdCBhIGZpbHRlciI6IlNlbGVjdCBhIGZpbHRlciIsIlNlbGVjdCB1cCB0byB0d28gb3B0aW9ucyI6IlNlbGVjdCB1cCB0byB0d28gb3B0aW9ucyIsIlNlbGVjdCBvcHRpb25zIjoiU2VsZWN0IG9wdGlvbnMiLCJBcHBseSBmaWx0ZXJzIjoiQXBwbHkgZmlsdGVycyIsIkNsZWFyIGFsbCI6IkNsZWFyIGFsbCIsIkZpbHRlciBieSI6IkZpbHRlciBieSIsIlNlbGVjdCBhbiBvcHRpb24iOiJTZWxlY3QgYW4gb3B0aW9uIiwiU29ydCBieSI6IlNvcnQgYnkiLCJTaG93IGRldGFpbHMiOiJTaG93IGRldGFpbHMiLCJIaWRlIGRldGFpbHMiOiJIaWRlIGRldGFpbHMifQ%3D%3D!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93IG1vcmUiOiJTaG93IG1vcmUiLCJTaG93IGxlc3MiOiJTaG93IGxlc3MiLCJGaWx0ZXIgYnkgeWVhciI6IkZpbHRlciBieSB5ZWFyIiwiTWVudSI6Ik1lbnUiLCJGaWx0ZXJzIjoiRmlsdGVycyIsIlNlbGVjdCBhIGZpbHRlciI6IlNlbGVjdCBhIGZpbHRlciIsIlNlbGVjdCB1cCB0byB0d28gb3B0aW9ucyI6IlNlbGVjdCB1cCB0byB0d28gb3B0aW9ucyIsIlNlbGVjdCBvcHRpb25zIjoiU2VsZWN0IG9wdGlvbnMiLCJBcHBseSBmaWx0ZXJzIjoiQXBwbHkgZmlsdGVycyIsIkNsZWFyIGFsbCI6IkNsZWFyIGFsbCIsIkZpbHRlciBieSI6IkZpbHRlciBieSIsIlNlbGVjdCBhbiBvcHRpb24iOiJTZWxlY3QgYW4gb3B0aW9uIiwiU29ydCBieSI6IlNvcnQgYnkiLCJTaG93IGRldGFpbHMiOiJTaG93IGRldGFpbHMiLCJIaWRlIGRldGFpbHMiOiJIaWRlIGRldGFpbHMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93IG1vcmUiOiJTaG93IG1vcmUiLCJTaG93IGxlc3MiOiJTaG93IGxlc3MiLCJGaWx0ZXIgYnkgeWVhciI6IkZpbHRlciBieSB5ZWFyIiwiTWVudSI6Ik1lbnUiLCJGaWx0ZXJzIjoiRmlsdGVycyIsIlNlbGVjdCBhIGZpbHRlciI6IlNlbGVjdCBhIGZpbHRlciIsIlNlbGVjdCB1cCB0byB0d28gb3B0aW9ucyI6IlNlbGVjdCB1cCB0byB0d28gb3B0aW9ucyIsIlNlbGVjdCBvcHRpb25zIjoiU2VsZWN0IG9wdGlvbnMiLCJBcHBseSBmaWx0ZXJzIjoiQXBwbHkgZmlsdGVycyIsIkNsZWFyIGFsbCI6IkNsZWFyIGFsbCIsIkZpbHRlciBieSI6IkZpbHRlciBieSIsIlNlbGVjdCBhbiBvcHRpb24iOiJTZWxlY3QgYW4gb3B0aW9uIiwiU29ydCBieSI6IlNvcnQgYnkiLCJTaG93IGRldGFpbHMiOiJTaG93IGRldGFpbHMiLCJIaWRlIGRldGFpbHMiOiJIaWRlIGRldGFpbHMifQ%3D%3D!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93IG1vcmUiOiJTaG93IG1vcmUiLCJTaG93IGxlc3MiOiJTaG93IGxlc3MiLCJGaWx0ZXIgYnkgeWVhciI6IkZpbHRlciBieSB5ZWFyIiwiTWVudSI6Ik1lbnUiLCJGaWx0ZXJzIjoiRmlsdGVycyIsIlNlbGVjdCBhIGZpbHRlciI6IlNlbGVjdCBhIGZpbHRlciIsIlNlbGVjdCB1cCB0byB0d28gb3B0aW9ucyI6IlNlbGVjdCB1cCB0byB0d28gb3B0aW9ucyIsIlNlbGVjdCBvcHRpb25zIjoiU2VsZWN0IG9wdGlvbnMiLCJBcHBseSBmaWx0ZXJzIjoiQXBwbHkgZmlsdGVycyIsIkNsZWFyIGFsbCI6IkNsZWFyIGFsbCIsIkZpbHRlciBieSI6IkZpbHRlciBieSIsIlNlbGVjdCBhbiBvcHRpb24iOiJTZWxlY3QgYW4gb3B0aW9uIiwiU29ydCBieSI6IlNvcnQgYnkiLCJTaG93IGRldGFpbHMiOiJTaG93IGRldGFpbHMiLCJIaWRlIGRldGFpbHMiOiJIaWRlIGRldGFpbHMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93IG1vcmUiOiJTaG93IG1vcmUiLCJTaG93IGxlc3MiOiJTaG93IGxlc3MiLCJGaWx0ZXIgYnkgeWVhciI6IkZpbHRlciBieSB5ZWFyIiwiTWVudSI6Ik1lbnUiLCJGaWx0ZXJzIjoiRmlsdGVycyIsIlNlbGVjdCBhIGZpbHRlciI6IlNlbGVjdCBhIGZpbHRlciIsIlNlbGVjdCB1cCB0byB0d28gb3B0aW9ucyI6IlNlbGVjdCB1cCB0byB0d28gb3B0aW9ucyIsIlNlbGVjdCBvcHRpb25zIjoiU2VsZWN0IG9wdGlvbnMiLCJBcHBseSBmaWx0ZXJzIjoiQXBwbHkgZmlsdGVycyIsIkNsZWFyIGFsbCI6IkNsZWFyIGFsbCIsIkZpbHRlciBieSI6IkZpbHRlciBieSIsIlNlbGVjdCBhbiBvcHRpb24iOiJTZWxlY3QgYW4gb3B0aW9uIiwiU29ydCBieSI6IlNvcnQgYnkiLCJTaG93IGRldGFpbHMiOiJTaG93IGRldGFpbHMiLCJIaWRlIGRldGFpbHMiOiJIaWRlIGRldGFpbHMifQ%3D%3D!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93IG1vcmUiOiJTaG93IG1vcmUiLCJTaG93IGxlc3MiOiJTaG93IGxlc3MiLCJGaWx0ZXIgYnkgeWVhciI6IkZpbHRlciBieSB5ZWFyIiwiTWVudSI6Ik1lbnUiLCJGaWx0ZXJzIjoiRmlsdGVycyIsIlNlbGVjdCBhIGZpbHRlciI6IlNlbGVjdCBhIGZpbHRlciIsIlNlbGVjdCB1cCB0byB0d28gb3B0aW9ucyI6IlNlbGVjdCB1cCB0byB0d28gb3B0aW9ucyIsIlNlbGVjdCBvcHRpb25zIjoiU2VsZWN0IG9wdGlvbnMiLCJBcHBseSBmaWx0ZXJzIjoiQXBwbHkgZmlsdGVycyIsIkNsZWFyIGFsbCI6IkNsZWFyIGFsbCIsIkZpbHRlciBieSI6IkZpbHRlciBieSIsIlNlbGVjdCBhbiBvcHRpb24iOiJTZWxlY3QgYW4gb3B0aW9uIiwiU29ydCBieSI6IlNvcnQgYnkiLCJTaG93IGRldGFpbHMiOiJTaG93IGRldGFpbHMiLCJIaWRlIGRldGFpbHMiOiJIaWRlIGRldGFpbHMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93IG1vcmUiOiJTaG93IG1vcmUiLCJTaG93IGxlc3MiOiJTaG93IGxlc3MiLCJGaWx0ZXIgYnkgeWVhciI6IkZpbHRlciBieSB5ZWFyIiwiTWVudSI6Ik1lbnUiLCJGaWx0ZXJzIjoiRmlsdGVycyIsIlNlbGVjdCBhIGZpbHRlciI6IlNlbGVjdCBhIGZpbHRlciIsIlNlbGVjdCB1cCB0byB0d28gb3B0aW9ucyI6IlNlbGVjdCB1cCB0byB0d28gb3B0aW9ucyIsIlNlbGVjdCBvcHRpb25zIjoiU2VsZWN0IG9wdGlvbnMiLCJBcHBseSBmaWx0ZXJzIjoiQXBwbHkgZmlsdGVycyIsIkNsZWFyIGFsbCI6IkNsZWFyIGFsbCIsIkZpbHRlciBieSI6IkZpbHRlciBieSIsIlNlbGVjdCBhbiBvcHRpb24iOiJTZWxlY3QgYW4gb3B0aW9uIiwiU29ydCBieSI6IlNvcnQgYnkiLCJTaG93IGRldGFpbHMiOiJTaG93IGRldGFpbHMiLCJIaWRlIGRldGFpbHMiOiJIaWRlIGRldGFpbHMifQ%3D%3D!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93IG1vcmUiOiJTaG93IG1vcmUiLCJTaG93IGxlc3MiOiJTaG93IGxlc3MiLCJGaWx0ZXIgYnkgeWVhciI6IkZpbHRlciBieSB5ZWFyIiwiTWVudSI6Ik1lbnUiLCJGaWx0ZXJzIjoiRmlsdGVycyIsIlNlbGVjdCBhIGZpbHRlciI6IlNlbGVjdCBhIGZpbHRlciIsIlNlbGVjdCB1cCB0byB0d28gb3B0aW9ucyI6IlNlbGVjdCB1cCB0byB0d28gb3B0aW9ucyIsIlNlbGVjdCBvcHRpb25zIjoiU2VsZWN0IG9wdGlvbnMiLCJBcHBseSBmaWx0ZXJzIjoiQXBwbHkgZmlsdGVycyIsIkNsZWFyIGFsbCI6IkNsZWFyIGFsbCIsIkZpbHRlciBieSI6IkZpbHRlciBieSIsIlNlbGVjdCBhbiBvcHRpb24iOiJTZWxlY3QgYW4gb3B0aW9uIiwiU29ydCBieSI6IlNvcnQgYnkiLCJTaG93IGRldGFpbHMiOiJTaG93IGRldGFpbHMiLCJIaWRlIGRldGFpbHMiOiJIaWRlIGRldGFpbHMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93IG1vcmUiOiJTaG93IG1vcmUiLCJTaG93IGxlc3MiOiJTaG93IGxlc3MiLCJGaWx0ZXIgYnkgeWVhciI6IkZpbHRlciBieSB5ZWFyIiwiTWVudSI6Ik1lbnUiLCJGaWx0ZXJzIjoiRmlsdGVycyIsIlNlbGVjdCBhIGZpbHRlciI6IlNlbGVjdCBhIGZpbHRlciIsIlNlbGVjdCB1cCB0byB0d28gb3B0aW9ucyI6IlNlbGVjdCB1cCB0byB0d28gb3B0aW9ucyIsIlNlbGVjdCBvcHRpb25zIjoiU2VsZWN0IG9wdGlvbnMiLCJBcHBseSBmaWx0ZXJzIjoiQXBwbHkgZmlsdGVycyIsIkNsZWFyIGFsbCI6IkNsZWFyIGFsbCIsIkZpbHRlciBieSI6IkZpbHRlciBieSIsIlNlbGVjdCBhbiBvcHRpb24iOiJTZWxlY3QgYW4gb3B0aW9uIiwiU29ydCBieSI6IlNvcnQgYnkiLCJTaG93IGRldGFpbHMiOiJTaG93IGRldGFpbHMiLCJIaWRlIGRldGFpbHMiOiJIaWRlIGRldGFpbHMifQ%3D%3D!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93IG1vcmUiOiJTaG93IG1vcmUiLCJTaG93IGxlc3MiOiJTaG93IGxlc3MiLCJGaWx0ZXIgYnkgeWVhciI6IkZpbHRlciBieSB5ZWFyIiwiTWVudSI6Ik1lbnUiLCJGaWx0ZXJzIjoiRmlsdGVycyIsIlNlbGVjdCBhIGZpbHRlciI6IlNlbGVjdCBhIGZpbHRlciIsIlNlbGVjdCB1cCB0byB0d28gb3B0aW9ucyI6IlNlbGVjdCB1cCB0byB0d28gb3B0aW9ucyIsIlNlbGVjdCBvcHRpb25zIjoiU2VsZWN0IG9wdGlvbnMiLCJBcHBseSBmaWx0ZXJzIjoiQXBwbHkgZmlsdGVycyIsIkNsZWFyIGFsbCI6IkNsZWFyIGFsbCIsIkZpbHRlciBieSI6IkZpbHRlciBieSIsIlNlbGVjdCBhbiBvcHRpb24iOiJTZWxlY3QgYW4gb3B0aW9uIiwiU29ydCBieSI6IlNvcnQgYnkiLCJTaG93IGRldGFpbHMiOiJTaG93IGRldGFpbHMiLCJIaWRlIGRldGFpbHMiOiJIaWRlIGRldGFpbHMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93IG1vcmUiOiJTaG93IG1vcmUiLCJTaG93IGxlc3MiOiJTaG93IGxlc3MiLCJGaWx0ZXIgYnkgeWVhciI6IkZpbHRlciBieSB5ZWFyIiwiTWVudSI6Ik1lbnUiLCJGaWx0ZXJzIjoiRmlsdGVycyIsIlNlbGVjdCBhIGZpbHRlciI6IlNlbGVjdCBhIGZpbHRlciIsIlNlbGVjdCB1cCB0byB0d28gb3B0aW9ucyI6IlNlbGVjdCB1cCB0byB0d28gb3B0aW9ucyIsIlNlbGVjdCBvcHRpb25zIjoiU2VsZWN0IG9wdGlvbnMiLCJBcHBseSBmaWx0ZXJzIjoiQXBwbHkgZmlsdGVycyIsIkNsZWFyIGFsbCI6IkNsZWFyIGFsbCIsIkZpbHRlciBieSI6IkZpbHRlciBieSIsIlNlbGVjdCBhbiBvcHRpb24iOiJTZWxlY3QgYW4gb3B0aW9uIiwiU29ydCBieSI6IlNvcnQgYnkiLCJTaG93IGRldGFpbHMiOiJTaG93IGRldGFpbHMiLCJIaWRlIGRldGFpbHMiOiJIaWRlIGRldGFpbHMifQ%3D%3D!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93IG1vcmUiOiJTaG93IG1vcmUiLCJTaG93IGxlc3MiOiJTaG93IGxlc3MiLCJGaWx0ZXIgYnkgeWVhciI6IuC4leC4seC4p%2BC4geC4o%2BC4reC4h%2BC4leC4suC4oeC4m%2BC4tSIsIk1lbnUiOiLguYDguKHguJnguLkiLCJGaWx0ZXJzIjoi4LiE4Lix4LiU4LiB4Lij4Lit4LiHIiwiU2VsZWN0IGEgZmlsdGVyIjoi4LmA4Lil4Li34Lit4LiB4LiV4Lix4Lin4LiB4Lij4Lit4LiHIiwiU2VsZWN0IHVwIHRvIHR3byBvcHRpb25zIjoi4LmA4Lil4Li34Lit4LiB4LmE4LiU4LmJ4Liq4Li54LiH4Liq4Li44LiUIDIg4LiV4Lix4Lin4LmA4Lil4Li34Lit4LiBIiwiU2VsZWN0IG9wdGlvbnMiOiLguYDguKXguLfguK3guIHguJXguLHguKfguYDguKXguLfguK3guIEiLCJBcHBseSBmaWx0ZXJzIjoi4LmD4LiK4LmJ4LiV4Lix4Lin4LiB4Lij4Lit4LiHIiwiQ2xlYXIgYWxsIjoi4Lil4LmJ4Liy4LiH4LiV4Lix4Lin4LiB4Lij4Lit4LiHIiwiRmlsdGVyIGJ5Ijoi4LiE4Lix4LiU4LiB4Lij4Lit4LiH4LiV4Liy4LihIiwiU2VsZWN0IGFuIG9wdGlvbiI6IuC5gOC4peC4t%2BC4reC4geC4q%2BC4meC4tuC5iOC4h%2BC4leC4seC4p%2BC5gOC4peC4t%2BC4reC4gSIsIlNvcnQgYnkiOiLguYDguKPguLXguKLguIfguJXguLLguKEiLCJTaG93IGRldGFpbHMiOiLguYHguKrguJTguIfguYDguJnguLfguYnguK3guKvguLIiLCJIaWRlIGRldGFpbHMiOiLguIvguYjguK3guJnguYDguJnguLfguYnguK3guKvguLIifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93IG1vcmUiOiJTaG93IG1vcmUiLCJTaG93IGxlc3MiOiJTaG93IGxlc3MiLCJGaWx0ZXIgYnkgeWVhciI6IuC4leC4seC4p%2BC4geC4o%2BC4reC4h%2BC4leC4suC4oeC4m%2BC4tSIsIk1lbnUiOiLguYDguKHguJnguLkiLCJGaWx0ZXJzIjoi4LiE4Lix4LiU4LiB4Lij4Lit4LiHIiwiU2VsZWN0IGEgZmlsdGVyIjoi4LmA4Lil4Li34Lit4LiB4LiV4Lix4Lin4LiB4Lij4Lit4LiHIiwiU2VsZWN0IHVwIHRvIHR3byBvcHRpb25zIjoi4LmA4Lil4Li34Lit4LiB4LmE4LiU4LmJ4Liq4Li54LiH4Liq4Li44LiUIDIg4LiV4Lix4Lin4LmA4Lil4Li34Lit4LiBIiwiU2VsZWN0IG9wdGlvbnMiOiLguYDguKXguLfguK3guIHguJXguLHguKfguYDguKXguLfguK3guIEiLCJBcHBseSBmaWx0ZXJzIjoi4LmD4LiK4LmJ4LiV4Lix4Lin4LiB4Lij4Lit4LiHIiwiQ2xlYXIgYWxsIjoi4Lil4LmJ4Liy4LiH4LiV4Lix4Lin4LiB4Lij4Lit4LiHIiwiRmlsdGVyIGJ5Ijoi4LiE4Lix4LiU4LiB4Lij4Lit4LiH4LiV4Liy4LihIiwiU2VsZWN0IGFuIG9wdGlvbiI6IuC5gOC4peC4t%2BC4reC4geC4q%2BC4meC4tuC5iOC4h%2BC4leC4seC4p%2BC5gOC4peC4t%2BC4reC4gSIsIlNvcnQgYnkiOiLguYDguKPguLXguKLguIfguJXguLLguKEiLCJTaG93IGRldGFpbHMiOiLguYHguKrguJTguIfguYDguJnguLfguYnguK3guKvguLIiLCJIaWRlIGRldGFpbHMiOiLguIvguYjguK3guJnguYDguJnguLfguYnguK3guKvguLIifQ%3D%3D!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93IG1vcmUiOiJTaG93IG1vcmUiLCJTaG93IGxlc3MiOiJTaG93IGxlc3MiLCJGaWx0ZXIgYnkgeWVhciI6IkZpbHRlciBiZXJkYXNhcmthbiB0YWh1biIsIk1lbnUiOiJNZW51IiwiRmlsdGVycyI6IkZpbHRlciIsIlNlbGVjdCBhIGZpbHRlciI6IlBpbGloIGZpbHRlciIsIlNlbGVjdCB1cCB0byB0d28gb3B0aW9ucyI6IlBpbGloIGhpbmdnYSBkdWEgb3BzaSIsIlNlbGVjdCBvcHRpb25zIjoiUGlsaWggb3BzaSIsIkFwcGx5IGZpbHRlcnMiOiJUZXJhcGthbiBmaWx0ZXIiLCJDbGVhciBhbGwiOiJIYXB1cyBzZW11YSIsIkZpbHRlciBieSI6IkZpbHRlciBiZXJkYXNhcmthbiIsIlNlbGVjdCBhbiBvcHRpb24iOiJQaWxpaCBzYXR1IG9wc2kiLCJTb3J0IGJ5IjoiVXJ1dGthbiBiZXJkYXNhcmthbiIsIlNob3cgZGV0YWlscyI6IlRhbXBpbGthbiBkZXRhaWwiLCJIaWRlIGRldGFpbHMiOiJTZW1idW55aWthbiBkZXRhaWwifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93IG1vcmUiOiJTaG93IG1vcmUiLCJTaG93IGxlc3MiOiJTaG93IGxlc3MiLCJGaWx0ZXIgYnkgeWVhciI6IkZpbHRlciBiZXJkYXNhcmthbiB0YWh1biIsIk1lbnUiOiJNZW51IiwiRmlsdGVycyI6IkZpbHRlciIsIlNlbGVjdCBhIGZpbHRlciI6IlBpbGloIGZpbHRlciIsIlNlbGVjdCB1cCB0byB0d28gb3B0aW9ucyI6IlBpbGloIGhpbmdnYSBkdWEgb3BzaSIsIlNlbGVjdCBvcHRpb25zIjoiUGlsaWggb3BzaSIsIkFwcGx5IGZpbHRlcnMiOiJUZXJhcGthbiBmaWx0ZXIiLCJDbGVhciBhbGwiOiJIYXB1cyBzZW11YSIsIkZpbHRlciBieSI6IkZpbHRlciBiZXJkYXNhcmthbiIsIlNlbGVjdCBhbiBvcHRpb24iOiJQaWxpaCBzYXR1IG9wc2kiLCJTb3J0IGJ5IjoiVXJ1dGthbiBiZXJkYXNhcmthbiIsIlNob3cgZGV0YWlscyI6IlRhbXBpbGthbiBkZXRhaWwiLCJIaWRlIGRldGFpbHMiOiJTZW1idW55aWthbiBkZXRhaWwifQ%3D%3D!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93IG1vcmUiOiJbxaDhuKnDtsO2w7bFtSBtzILDtsO2w7bFmeG6veG6veG6vV0iLCJTaG93IGxlc3MiOiJbxaDhuKnDtsO2w7bFtSDGmuG6veG6veG6vcWhxaFdIiwiRmlsdGVyIGJ5IHllYXIiOiJbxpHDrMOsxprhua%2Fhur3hur3FmSDDn8O9w70gw73DveG6veG6vcSDxIPFmV0iLCJNZW51IjoiW%2BG5guG6veG6veC4geC4teC5iceax5pdIiwiRmlsdGVycyI6IlvGkcOsw6zDrMaa4bmv4bq94bq94bq9xZnFoV0iLCJTZWxlY3QgYSBmaWx0ZXIiOiJbxaDhur3hur3hur3GmuG6veG6veG6vcOn4bmvIMSDxIPEgyDGksOsw6zDrMaa4bmv4bq94bq94bq9xZldIiwiU2VsZWN0IHVwIHRvIHR3byBvcHRpb25zIjoiW8Wg4bq94bq94bq9xprhur3hur3hur3Dp%2BG5ryDHmseax5rGpSDhua%2FDtsO2w7Yg4bmvxbXDtsO2w7Ygw7bDtsO2xqXhua%2FDrMOsw6zDtsO2w7bguIHguLXguYnFoV0iLCJTZWxlY3Qgb3B0aW9ucyI6IlvFoOG6veG6veG6vcaa4bq94bq94bq9w6fhua8gw7bDtsO2xqXhua%2FDrMOsw6zDtsO2w7bguIHguLXguYnFoV0iLCJBcHBseSBmaWx0ZXJzIjoiW%2BG6rMalxqXGmsO9w73DvSDGksOsw6zDrMaa4bmv4bq94bq94bq9xZnFoV0iLCJDbGVhciBhbGwiOiJbw4fGmuG6veG6veG6vcSDxIPEg8WZIMSDxIPEg8aaxppdIiwiRmlsdGVyIGJ5IjoiW8aRw6zDrMOsxprhua%2Fhur3hur3hur3FmSDDn8O9w73DvV0iLCJTZWxlY3QgYW4gb3B0aW9uIjoiW8Wg4bq94bq94bq9xprhur3hur3hur3Dp%2BG5ryDEg8SDxIPguIHguLXguYkgw7bDtsO2xqXhua%2FDrMOsw6zDtsO2w7bguIHguLXguYldIiwiU29ydCBieSI6IlvFoMO2w7bDtsWZ4bmvIMOfw73DvcO9XSIsIlNob3cgZGV0YWlscyI6IlvFoOG4qcO2w7bDtsW1IMaM4bq94bq94bq94bmvxIPEg8SDw6zDrMOsxprFoV0iLCJIaWRlIGRldGFpbHMiOiJb4biow6zDrMaM4bq94bq9IMaM4bq94bq94bmvxIPEg8Osw6zGmsWhXSJ9!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaG93IG1vcmUiOiJbxaDhuKnDtsO2w7bFtSBtzILDtsO2w7bFmeG6veG6veG6vV0iLCJTaG93IGxlc3MiOiJbxaDhuKnDtsO2w7bFtSDGmuG6veG6veG6vcWhxaFdIiwiRmlsdGVyIGJ5IHllYXIiOiJbxpHDrMOsxprhua%2Fhur3hur3FmSDDn8O9w70gw73DveG6veG6vcSDxIPFmV0iLCJNZW51IjoiW%2BG5guG6veG6veC4geC4teC5iceax5pdIiwiRmlsdGVycyI6IlvGkcOsw6zDrMaa4bmv4bq94bq94bq9xZnFoV0iLCJTZWxlY3QgYSBmaWx0ZXIiOiJbxaDhur3hur3hur3GmuG6veG6veG6vcOn4bmvIMSDxIPEgyDGksOsw6zDrMaa4bmv4bq94bq94bq9xZldIiwiU2VsZWN0IHVwIHRvIHR3byBvcHRpb25zIjoiW8Wg4bq94bq94bq9xprhur3hur3hur3Dp%2BG5ryDHmseax5rGpSDhua%2FDtsO2w7Yg4bmvxbXDtsO2w7Ygw7bDtsO2xqXhua%2FDrMOsw6zDtsO2w7bguIHguLXguYnFoV0iLCJTZWxlY3Qgb3B0aW9ucyI6IlvFoOG6veG6veG6vcaa4bq94bq94bq9w6fhua8gw7bDtsO2xqXhua%2FDrMOsw6zDtsO2w7bguIHguLXguYnFoV0iLCJBcHBseSBmaWx0ZXJzIjoiW%2BG6rMalxqXGmsO9w73DvSDGksOsw6zDrMaa4bmv4bq94bq94bq9xZnFoV0iLCJDbGVhciBhbGwiOiJbw4fGmuG6veG6veG6vcSDxIPEg8WZIMSDxIPEg8aaxppdIiwiRmlsdGVyIGJ5IjoiW8aRw6zDrMOsxprhua%2Fhur3hur3hur3FmSDDn8O9w73DvV0iLCJTZWxlY3QgYW4gb3B0aW9uIjoiW8Wg4bq94bq94bq9xprhur3hur3hur3Dp%2BG5ryDEg8SDxIPguIHguLXguYkgw7bDtsO2xqXhua%2FDrMOsw6zDtsO2w7bguIHguLXguYldIiwiU29ydCBieSI6IlvFoMO2w7bDtsWZ4bmvIMOfw73DvcO9XSIsIlNob3cgZGV0YWlscyI6IlvFoOG4qcO2w7bDtsW1IMaM4bq94bq94bq94bmvxIPEg8SDw6zDrMOsxprFoV0iLCJIaWRlIGRldGFpbHMiOiJb4biow6zDrMaM4bq94bq9IMaM4bq94bq94bmvxIPEg8Osw6zGmsWhXSJ9!"
        )
      )
      });
  
      export { translations as default };
    