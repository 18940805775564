export const CMS_PAGE_DISPLAYED_EVENT = 'cms_page_displayed';
export const CONTENT_ELEMENT_PRESSED_EVENT = 'content_element_pressed';
export const SEARCH_RESULTS = 'search results';
export const SOCIAL_LINK_IDS = [
  'social-facebook-tag',
  'social-twitter-tag',
  'social-linkedin-tag',
  'print-tag',
  'mail-tag',
];
export const PROMOTIONAL_BANNER_DISPLAYED = 'promotional_banner_displayed';
export const CONTENT_ELEMENT_DISPLAYED = 'content_element_displayed';
