export interface TopicQuestion {
  name: string;
  icon: string;
  description?: string;
  questions: string[];
}

export const questions: Record<string, TopicQuestion> = {
  motivation: {
    name: 'Motivation',
    icon: 'motivation',
    description:
      'Motivation questions seek to uncover the reasons, drive and enthusiasm behind a job application. These questions are asked to find out whether the values of the individual and organisation align.',
    questions: [
      'Why are you interested in working at this organisation?',
      "What's your understanding of the role and why are you interested?",
      'What parts of your current role do you really love?',
      'What parts of your current role frustrate you?',
      'If you were to be successful in getting this role, what would success look like to you?',
      'Why are you looking to leave your current role? What action have you taken to try and make it work?',
      "How would you describe the work environment or culture you're most productive and happy in?",
      'What actions, behaviours or events would limit or impact your workplace motivation?',
      'Take us through a perfect day at work, how would you be spending your time, who would you be working with?',
      'How would your colleagues describe you in three words?',
      "Tell us about a team you've worked in that enabled you to do your best work? What was it about the team that made you do your best work?",
      'What work achievement you are most proud of?',
    ],
  },
  behavioural: {
    name: 'Behavioural',
    icon: 'behavioural',
    description:
      'Behavioural questions aim to discover how previous behaviour may influence future decisions and performance. The best responses provide concrete examples of how situations were handled or resolved.',
    questions: [
      'At times you will be asked to do many things at once. How do you prioritise your tasks?',
      'Have you ever worked in a role where priorities and goals have changed frequently, what were they and how did you ensure you were successful in that environment?',
      'Tell me about a project or idea that was implemented or executed upon successfully because of your efforts?',
      "Tell me about a time when you've thought about the bigger picture and recommended or implemented a change that's had long term benefits?",
      "Tell me about the most difficult stakeholder you've had. Why was this the case and how did you make the relationship productive?",
      "Can you tell me about a recommendation you've made which has had significant pushback from a stakeholder? How did you go about influencing them?",
      "Give me an example of a time when you made a mistake or didn't deliver on expectations, what happened? What did you learn?",
      "What's been the most rewarding team you've been a part of and why?",
      'Give me an example of a new idea or initiative you have suggested to your manager. How did you implement this idea?',
      "Can you tell me about a time when you've had to work with multiple stakeholders to deliver on an outcome? What worked well? What were the challenges?",
      "Can you share with me constructive feedback you've received and how you've used that feedback to change the way you work?",
      "Tell me about a time where you've given a team member feedback? How did you approach it and what was the outcome?",
    ],
  },
  situational: {
    name: 'Situational',
    icon: 'situational',
    description:
      'Situational questions aim to unpack specific issues and challenges that may occur at work. Answers should highlight specific steps that the individual would take to manage a particular situation.',
    questions: [
      'If you had two important deadlines coming up, how would you prioritise your tasks?',
      'What steps would you take to make an important decision on the job?',
      'You realise that an early mistake in a project is going to put you behind deadline. What do you do?',
      'How would you handle working closely with a colleague who has a different working style to you?',
      'What would you do if you made a strong recommendation in a meeting, but your colleagues decided against it?',
      'You disagree with the way your manager and supervisor says to handle a problem. What would you do?',
      'How would you deal with a colleague at work with whom you seem to be unable to build a successful working relationship?',
    ],
  },
  skillsBased: {
    name: 'Skills-based',
    icon: 'skills-based',
    description:
      'Skills-based questions aim to uncover whether an individual’s skills match what the organisation needs. These questions require the interviewee to discuss their existing skills and those they would like to develop.',
    questions: [
      'Talk us through your professional and technical skill-set.',
      'What is the one professional and/or technical skill you would most like to develop?',
      'Based on your understanding of this role, which of your skills do you believe will be most valuable to our organisation?',
      'Tell us about your most recent project and how you drew on your professional skills to deliver an outcome.',
      'Tell me about a time that you used your professional skills and/or technical experience to improve a process in your workplace.',
      "Describe a time when you've drawn on your professional skills to challenge the status quo and deliver a better outcome?",
      "What's the one achievement that you're proudest of in your career, what was your involvement and how did you utilise your skills to garner the best outcome?",
    ],
  },
};
