import React from 'react';
import { useTargetBlank } from '../shared/hooks/useTargetBlank';
import {
  Box,
  Column,
  Columns,
  ContentBlock,
  Heading,
  Hidden,
  Pagination,
  Stack,
  Text,
} from 'braid-design-system';
import { useConfig } from '../shared/hooks';
import { useLoaderData, useParams } from 'react-router';
import type { AuthorWithArticles } from '../../helpers/loadersHelper';
import { PageLayout } from '../components/PageLayout/PageLayout';
import type { LayoutProps } from '../shared/types';
import { CMSUProvider } from '@seek/cmsu-components/src/hooks/useCMSUContext';
import { AuthorProfile } from '../components/AuthorProfile/AuthorProfile';
import type { Article } from '@seek/cmsu-cms-connect';
import { useTranslations } from '@vocab/react';
import translations from '../.vocab';
import { useMelwaysLink } from '@seek/melways-react';
import type { Language } from '@seek/melways-sites';
import NoArticles from './images/no-content.png';
import { getPageNumberFrom, getTotalPages } from '../shared/utils/Pagination';
import type { LocalesInfo } from '../shared/utils/getLocales';
import { useScreenSize } from '../hooks/useScreenSize';
import { ArticleItem } from '@seek/cmsu-components/src/modules/ArticleItem/ArticleItem';
import { ITEM_DISPLAY_TYPE } from '@seek/cmsu-components/src/helpers/articleHelper';

const layoutProps: LayoutProps = {
  title: 'Loading...',
};

export const Author = () => {
  useTargetBlank();
  // Get the params from context.
  const {
    // melwaysLocale, fullURL, sourceName, privacyUrl, country,
    language,
  } = useConfig();
  const { pageNumber } = useParams();
  // Get the article content by using the loader.
  const data = useLoaderData() as AuthorWithArticles & LocalesInfo;

  const { name } = data;
  const currentPageNumber = getPageNumberFrom(pageNumber);

  return (
    <PageLayout
      layoutProps={{
        ...layoutProps,
        title: `${name}`, // ToDo: Page Title?
      }}
    >
      <CMSUProvider config={{ language }}>
        <Box
          background="surface"
          paddingTop={{ mobile: 'large', desktop: 'xlarge' }}
          paddingBottom="medium"
        >
          <ContentBlock width="medium">
            <Hidden below="desktop">
              <Columns space="xlarge">
                <Column width="2/3">
                  <ArticlesByAuthor
                    data={data}
                    language={language}
                    pageNumber={currentPageNumber}
                  />
                </Column>
                <Column width="1/3">
                  <AuthorProfile author={data} />
                </Column>
              </Columns>
            </Hidden>
            <Hidden above="tablet">
              <Box paddingX="medium">
                <Stack space="xlarge">
                  <AuthorProfile author={data} />

                  <ArticlesByAuthor
                    data={data}
                    language={language}
                    pageNumber={currentPageNumber}
                  />
                </Stack>
              </Box>
            </Hidden>
          </ContentBlock>
        </Box>
      </CMSUProvider>
    </PageLayout>
  );
};

const ArticlesByAuthor = ({
  data,
  language,
  pageNumber,
}: {
  data: AuthorWithArticles;
  language: Language;
  pageNumber: number;
}) => {
  const { t } = useTranslations(translations);
  const urlResolver = useMelwaysLink();
  const screenSize = useScreenSize();

  const { slug } = data;

  const totalArticles = data.articles.aggregate.count;
  // ToDo: to be decided where to be displayed
  // const totalArticlesDisplay = totalArticles ? `(${totalArticles})` : '';

  const articles = data.articles.edges.map((item) => item.node as Article);
  const totalPages = getTotalPages(totalArticles);

  return (
    <Stack space="large">
      <Box>
        <Heading level="1">
          {t('Articles by')} {data.name}
        </Heading>
      </Box>

      {totalArticles <= 0 && (
        <Box width="full" textAlign="center" padding="xlarge">
          <Stack space="xlarge">
            <img src={NoArticles} />
            <Text tone="secondary">{`There aren't any articles by this author right now.`}</Text>
          </Stack>
        </Box>
      )}

      {totalArticles > 0 && (
        <Stack space="gutter">
          {articles.map((article, index) => (
            <ArticleItem
              displayType={
                screenSize.isMobile
                  ? ITEM_DISPLAY_TYPE.CARD
                  : ITEM_DISPLAY_TYPE.LIST_ITEM
              }
              article={article}
              language={language}
              key={article.id}
              isLastItem={index === articles.length - 1}
            />
          ))}
        </Stack>
      )}

      {totalPages > 1 && (
        <Box paddingTop={{ desktop: 'small' }}>
          <Pagination
            page={pageNumber}
            total={totalPages}
            label="pagination"
            linkProps={({ page: pageInPagination }) => ({
              href: `#`,
              onClick: (e) => {
                e.preventDefault();

                window.location.href = urlResolver({
                  language,
                  path: `/author/${slug}/${pageInPagination}`,
                });
              },
            })}
          />
        </Box>
      )}
    </Stack>
  );
};
