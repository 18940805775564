
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXF1aXJlZCBmaWVsZCI6IlJlcXVpcmVkIGZpZWxkIiwiTWluIGxlbmd0aCI6Int2YWx1ZX0gY2hhcmFjdGVycyBvciBtb3JlIiwiTWF4IGxlbmd0aCI6Int2YWx1ZX0gY2hhcmFjdGVycyBvciBsZXNzIiwiTWluIG51bWJlciI6Ik11c3QgYmUge3ZhbHVlfSBvciBtb3JlIiwiTWF4IG51bWJlciI6Ik11c3QgYmUge3ZhbHVlfSBvciBsZXNzIiwiSW52YWxpZCBlbWFpbCI6IkludmFsaWQgZW1haWwgYWRkcmVzcywgdXNlIGZvcm1hdCBleGFtcGxlQGRvbWFpbi5jb20iLCJNaW4gY2hlY2tlZCBpdGVtIjoiTXVzdCBzZWxlY3Qge3ZhbHVlfSBvciBtb3JlIiwiTWF4IGNoZWNrZWQgaXRlbSI6Ik11c3Qgc2VsZWN0IHt2YWx1ZX0gb3IgbGVzcyIsIlN1Ym1pdCI6IlN1Ym1pdCJ9!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXF1aXJlZCBmaWVsZCI6IlJlcXVpcmVkIGZpZWxkIiwiTWluIGxlbmd0aCI6Int2YWx1ZX0gY2hhcmFjdGVycyBvciBtb3JlIiwiTWF4IGxlbmd0aCI6Int2YWx1ZX0gY2hhcmFjdGVycyBvciBsZXNzIiwiTWluIG51bWJlciI6Ik11c3QgYmUge3ZhbHVlfSBvciBtb3JlIiwiTWF4IG51bWJlciI6Ik11c3QgYmUge3ZhbHVlfSBvciBsZXNzIiwiSW52YWxpZCBlbWFpbCI6IkludmFsaWQgZW1haWwgYWRkcmVzcywgdXNlIGZvcm1hdCBleGFtcGxlQGRvbWFpbi5jb20iLCJNaW4gY2hlY2tlZCBpdGVtIjoiTXVzdCBzZWxlY3Qge3ZhbHVlfSBvciBtb3JlIiwiTWF4IGNoZWNrZWQgaXRlbSI6Ik11c3Qgc2VsZWN0IHt2YWx1ZX0gb3IgbGVzcyIsIlN1Ym1pdCI6IlN1Ym1pdCJ9!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXF1aXJlZCBmaWVsZCI6IuC4iOC4s%2BC5gOC4m%2BC5h%2BC4meC4leC5ieC4reC4h%2BC4o%2BC4sOC4muC4uCIsIk1pbiBsZW5ndGgiOiJ7dmFsdWV9IOC4leC4seC4p%2BC4reC4seC4geC4qeC4o%2BC4q%2BC4o%2BC4t%2BC4reC4oeC4suC4geC4geC4p%2BC5iOC4siIsIk1heCBsZW5ndGgiOiJ7dmFsdWV9IOC4leC4seC4p%2BC4reC4seC4geC4qeC4o%2BC4q%2BC4o%2BC4t%2BC4reC4meC5ieC4reC4ouC4geC4p%2BC5iOC4siIsIk1pbiBudW1iZXIiOiLguJXguYnguK3guIfguYDguJfguYjguLLguIHguLHguJoge3ZhbHVlfSDguKvguKPguLfguK3guKHguLLguIHguIHguKfguYjguLIiLCJNYXggbnVtYmVyIjoi4LiV4LmJ4Lit4LiH4LmA4LiX4LmI4Liy4LiB4Lix4LiaIHt2YWx1ZX0g4Lir4Lij4Li34Lit4LiZ4LmJ4Lit4Lii4LiB4Lin4LmI4LiyIiwiSW52YWxpZCBlbWFpbCI6IuC4reC4teC5gOC4oeC4peC5hOC4oeC5iOC4luC4ueC4geC4leC5ieC4reC4hyDguIHguKPguLjguJPguLLguKPguLDguJrguLjguK3guLXguYDguKHguKXguYPguJnguKPguLnguJvguYHguJrguJogZXhhbXBsZUBkb21haW4uY29tIiwiTWluIGNoZWNrZWQgaXRlbSI6IuC4leC5ieC4reC4h%2BC5gOC4peC4t%2BC4reC4gSB7dmFsdWV9IOC4q%2BC4o%2BC4t%2BC4reC4oeC4suC4geC4geC4p%2BC5iOC4siIsIk1heCBjaGVja2VkIGl0ZW0iOiLguJXguYnguK3guIfguYDguKXguLfguK3guIEge3ZhbHVlfSDguKvguKPguLfguK3guJnguYnguK3guKLguIHguKfguYjguLIiLCJTdWJtaXQiOiLguKrguYjguIcifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXF1aXJlZCBmaWVsZCI6IuC4iOC4s%2BC5gOC4m%2BC5h%2BC4meC4leC5ieC4reC4h%2BC4o%2BC4sOC4muC4uCIsIk1pbiBsZW5ndGgiOiJ7dmFsdWV9IOC4leC4seC4p%2BC4reC4seC4geC4qeC4o%2BC4q%2BC4o%2BC4t%2BC4reC4oeC4suC4geC4geC4p%2BC5iOC4siIsIk1heCBsZW5ndGgiOiJ7dmFsdWV9IOC4leC4seC4p%2BC4reC4seC4geC4qeC4o%2BC4q%2BC4o%2BC4t%2BC4reC4meC5ieC4reC4ouC4geC4p%2BC5iOC4siIsIk1pbiBudW1iZXIiOiLguJXguYnguK3guIfguYDguJfguYjguLLguIHguLHguJoge3ZhbHVlfSDguKvguKPguLfguK3guKHguLLguIHguIHguKfguYjguLIiLCJNYXggbnVtYmVyIjoi4LiV4LmJ4Lit4LiH4LmA4LiX4LmI4Liy4LiB4Lix4LiaIHt2YWx1ZX0g4Lir4Lij4Li34Lit4LiZ4LmJ4Lit4Lii4LiB4Lin4LmI4LiyIiwiSW52YWxpZCBlbWFpbCI6IuC4reC4teC5gOC4oeC4peC5hOC4oeC5iOC4luC4ueC4geC4leC5ieC4reC4hyDguIHguKPguLjguJPguLLguKPguLDguJrguLjguK3guLXguYDguKHguKXguYPguJnguKPguLnguJvguYHguJrguJogZXhhbXBsZUBkb21haW4uY29tIiwiTWluIGNoZWNrZWQgaXRlbSI6IuC4leC5ieC4reC4h%2BC5gOC4peC4t%2BC4reC4gSB7dmFsdWV9IOC4q%2BC4o%2BC4t%2BC4reC4oeC4suC4geC4geC4p%2BC5iOC4siIsIk1heCBjaGVja2VkIGl0ZW0iOiLguJXguYnguK3guIfguYDguKXguLfguK3guIEge3ZhbHVlfSDguKvguKPguLfguK3guJnguYnguK3guKLguIHguKfguYjguLIiLCJTdWJtaXQiOiLguKrguYjguIcifQ%3D%3D!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXF1aXJlZCBmaWVsZCI6IldhamliIGRpaXNpIiwiTWluIGxlbmd0aCI6Int2YWx1ZX0ga2FyYWt0ZXIgYXRhdSBsZWJpaCIsIk1heCBsZW5ndGgiOiJ7dmFsdWV9IGthcmFrdGVyIGF0YXUga3VyYW5nIiwiTWluIG51bWJlciI6IkhhcnVzIHt2YWx1ZX0gYXRhdSBsZWJpaCIsIk1heCBudW1iZXIiOiJIYXJ1cyB7dmFsdWV9IGF0YXUga3VyYW5nIiwiSW52YWxpZCBlbWFpbCI6IkFsYW1hdCBlbWFpbCB0aWRhayBzYWgsIGd1bmFrYW4gZm9ybWF0IGV4YW1wbGVAZG9tYWluLmNvbSIsIk1pbiBjaGVja2VkIGl0ZW0iOiJIYXJ1cyBtZW1pbGloIHt2YWx1ZX0gYXRhdSBsZWJpaCIsIk1heCBjaGVja2VkIGl0ZW0iOiJIYXJ1cyBtZW1pbGloIHt2YWx1ZX0gYXRhdSBrdXJhbmciLCJTdWJtaXQiOiJLaXJpbSJ9!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXF1aXJlZCBmaWVsZCI6IldhamliIGRpaXNpIiwiTWluIGxlbmd0aCI6Int2YWx1ZX0ga2FyYWt0ZXIgYXRhdSBsZWJpaCIsIk1heCBsZW5ndGgiOiJ7dmFsdWV9IGthcmFrdGVyIGF0YXUga3VyYW5nIiwiTWluIG51bWJlciI6IkhhcnVzIHt2YWx1ZX0gYXRhdSBsZWJpaCIsIk1heCBudW1iZXIiOiJIYXJ1cyB7dmFsdWV9IGF0YXUga3VyYW5nIiwiSW52YWxpZCBlbWFpbCI6IkFsYW1hdCBlbWFpbCB0aWRhayBzYWgsIGd1bmFrYW4gZm9ybWF0IGV4YW1wbGVAZG9tYWluLmNvbSIsIk1pbiBjaGVja2VkIGl0ZW0iOiJIYXJ1cyBtZW1pbGloIHt2YWx1ZX0gYXRhdSBsZWJpaCIsIk1heCBjaGVja2VkIGl0ZW0iOiJIYXJ1cyBtZW1pbGloIHt2YWx1ZX0gYXRhdSBrdXJhbmciLCJTdWJtaXQiOiJLaXJpbSJ9!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXF1aXJlZCBmaWVsZCI6IlJlcXVpcmVkIGZpZWxkIiwiTWluIGxlbmd0aCI6Int2YWx1ZX0gY2hhcmFjdGVycyBvciBtb3JlIiwiTWF4IGxlbmd0aCI6Int2YWx1ZX0gY2hhcmFjdGVycyBvciBsZXNzIiwiTWluIG51bWJlciI6Ik11c3QgYmUge3ZhbHVlfSBvciBtb3JlIiwiTWF4IG51bWJlciI6Ik11c3QgYmUge3ZhbHVlfSBvciBsZXNzIiwiSW52YWxpZCBlbWFpbCI6IkludmFsaWQgZW1haWwgYWRkcmVzcywgdXNlIGZvcm1hdCBleGFtcGxlQGRvbWFpbi5jb20iLCJNaW4gY2hlY2tlZCBpdGVtIjoiTXVzdCBzZWxlY3Qge3ZhbHVlfSBvciBtb3JlIiwiTWF4IGNoZWNrZWQgaXRlbSI6Ik11c3Qgc2VsZWN0IHt2YWx1ZX0gb3IgbGVzcyIsIlN1Ym1pdCI6IlN1Ym1pdCJ9!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXF1aXJlZCBmaWVsZCI6IlJlcXVpcmVkIGZpZWxkIiwiTWluIGxlbmd0aCI6Int2YWx1ZX0gY2hhcmFjdGVycyBvciBtb3JlIiwiTWF4IGxlbmd0aCI6Int2YWx1ZX0gY2hhcmFjdGVycyBvciBsZXNzIiwiTWluIG51bWJlciI6Ik11c3QgYmUge3ZhbHVlfSBvciBtb3JlIiwiTWF4IG51bWJlciI6Ik11c3QgYmUge3ZhbHVlfSBvciBsZXNzIiwiSW52YWxpZCBlbWFpbCI6IkludmFsaWQgZW1haWwgYWRkcmVzcywgdXNlIGZvcm1hdCBleGFtcGxlQGRvbWFpbi5jb20iLCJNaW4gY2hlY2tlZCBpdGVtIjoiTXVzdCBzZWxlY3Qge3ZhbHVlfSBvciBtb3JlIiwiTWF4IGNoZWNrZWQgaXRlbSI6Ik11c3Qgc2VsZWN0IHt2YWx1ZX0gb3IgbGVzcyIsIlN1Ym1pdCI6IlN1Ym1pdCJ9!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXF1aXJlZCBmaWVsZCI6IlJlcXVpcmVkIGZpZWxkIiwiTWluIGxlbmd0aCI6Int2YWx1ZX0gY2hhcmFjdGVycyBvciBtb3JlIiwiTWF4IGxlbmd0aCI6Int2YWx1ZX0gY2hhcmFjdGVycyBvciBsZXNzIiwiTWluIG51bWJlciI6Ik11c3QgYmUge3ZhbHVlfSBvciBtb3JlIiwiTWF4IG51bWJlciI6Ik11c3QgYmUge3ZhbHVlfSBvciBsZXNzIiwiSW52YWxpZCBlbWFpbCI6IkludmFsaWQgZW1haWwgYWRkcmVzcywgdXNlIGZvcm1hdCBleGFtcGxlQGRvbWFpbi5jb20iLCJNaW4gY2hlY2tlZCBpdGVtIjoiTXVzdCBzZWxlY3Qge3ZhbHVlfSBvciBtb3JlIiwiTWF4IGNoZWNrZWQgaXRlbSI6Ik11c3Qgc2VsZWN0IHt2YWx1ZX0gb3IgbGVzcyIsIlN1Ym1pdCI6IlN1Ym1pdCJ9!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXF1aXJlZCBmaWVsZCI6IlJlcXVpcmVkIGZpZWxkIiwiTWluIGxlbmd0aCI6Int2YWx1ZX0gY2hhcmFjdGVycyBvciBtb3JlIiwiTWF4IGxlbmd0aCI6Int2YWx1ZX0gY2hhcmFjdGVycyBvciBsZXNzIiwiTWluIG51bWJlciI6Ik11c3QgYmUge3ZhbHVlfSBvciBtb3JlIiwiTWF4IG51bWJlciI6Ik11c3QgYmUge3ZhbHVlfSBvciBsZXNzIiwiSW52YWxpZCBlbWFpbCI6IkludmFsaWQgZW1haWwgYWRkcmVzcywgdXNlIGZvcm1hdCBleGFtcGxlQGRvbWFpbi5jb20iLCJNaW4gY2hlY2tlZCBpdGVtIjoiTXVzdCBzZWxlY3Qge3ZhbHVlfSBvciBtb3JlIiwiTWF4IGNoZWNrZWQgaXRlbSI6Ik11c3Qgc2VsZWN0IHt2YWx1ZX0gb3IgbGVzcyIsIlN1Ym1pdCI6IlN1Ym1pdCJ9!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXF1aXJlZCBmaWVsZCI6IlJlcXVpcmVkIGZpZWxkIiwiTWluIGxlbmd0aCI6Int2YWx1ZX0gY2hhcmFjdGVycyBvciBtb3JlIiwiTWF4IGxlbmd0aCI6Int2YWx1ZX0gY2hhcmFjdGVycyBvciBsZXNzIiwiTWluIG51bWJlciI6Ik11c3QgYmUge3ZhbHVlfSBvciBtb3JlIiwiTWF4IG51bWJlciI6Ik11c3QgYmUge3ZhbHVlfSBvciBsZXNzIiwiSW52YWxpZCBlbWFpbCI6IkludmFsaWQgZW1haWwgYWRkcmVzcywgdXNlIGZvcm1hdCBleGFtcGxlQGRvbWFpbi5jb20iLCJNaW4gY2hlY2tlZCBpdGVtIjoiTXVzdCBzZWxlY3Qge3ZhbHVlfSBvciBtb3JlIiwiTWF4IGNoZWNrZWQgaXRlbSI6Ik11c3Qgc2VsZWN0IHt2YWx1ZX0gb3IgbGVzcyIsIlN1Ym1pdCI6IlN1Ym1pdCJ9!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXF1aXJlZCBmaWVsZCI6IlJlcXVpcmVkIGZpZWxkIiwiTWluIGxlbmd0aCI6Int2YWx1ZX0gY2hhcmFjdGVycyBvciBtb3JlIiwiTWF4IGxlbmd0aCI6Int2YWx1ZX0gY2hhcmFjdGVycyBvciBsZXNzIiwiTWluIG51bWJlciI6Ik11c3QgYmUge3ZhbHVlfSBvciBtb3JlIiwiTWF4IG51bWJlciI6Ik11c3QgYmUge3ZhbHVlfSBvciBsZXNzIiwiSW52YWxpZCBlbWFpbCI6IkludmFsaWQgZW1haWwgYWRkcmVzcywgdXNlIGZvcm1hdCBleGFtcGxlQGRvbWFpbi5jb20iLCJNaW4gY2hlY2tlZCBpdGVtIjoiTXVzdCBzZWxlY3Qge3ZhbHVlfSBvciBtb3JlIiwiTWF4IGNoZWNrZWQgaXRlbSI6Ik11c3Qgc2VsZWN0IHt2YWx1ZX0gb3IgbGVzcyIsIlN1Ym1pdCI6IlN1Ym1pdCJ9!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXF1aXJlZCBmaWVsZCI6IlJlcXVpcmVkIGZpZWxkIiwiTWluIGxlbmd0aCI6Int2YWx1ZX0gY2hhcmFjdGVycyBvciBtb3JlIiwiTWF4IGxlbmd0aCI6Int2YWx1ZX0gY2hhcmFjdGVycyBvciBsZXNzIiwiTWluIG51bWJlciI6Ik11c3QgYmUge3ZhbHVlfSBvciBtb3JlIiwiTWF4IG51bWJlciI6Ik11c3QgYmUge3ZhbHVlfSBvciBsZXNzIiwiSW52YWxpZCBlbWFpbCI6IkludmFsaWQgZW1haWwgYWRkcmVzcywgdXNlIGZvcm1hdCBleGFtcGxlQGRvbWFpbi5jb20iLCJNaW4gY2hlY2tlZCBpdGVtIjoiTXVzdCBzZWxlY3Qge3ZhbHVlfSBvciBtb3JlIiwiTWF4IGNoZWNrZWQgaXRlbSI6Ik11c3Qgc2VsZWN0IHt2YWx1ZX0gb3IgbGVzcyIsIlN1Ym1pdCI6IlN1Ym1pdCJ9!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXF1aXJlZCBmaWVsZCI6IlJlcXVpcmVkIGZpZWxkIiwiTWluIGxlbmd0aCI6Int2YWx1ZX0gY2hhcmFjdGVycyBvciBtb3JlIiwiTWF4IGxlbmd0aCI6Int2YWx1ZX0gY2hhcmFjdGVycyBvciBsZXNzIiwiTWluIG51bWJlciI6Ik11c3QgYmUge3ZhbHVlfSBvciBtb3JlIiwiTWF4IG51bWJlciI6Ik11c3QgYmUge3ZhbHVlfSBvciBsZXNzIiwiSW52YWxpZCBlbWFpbCI6IkludmFsaWQgZW1haWwgYWRkcmVzcywgdXNlIGZvcm1hdCBleGFtcGxlQGRvbWFpbi5jb20iLCJNaW4gY2hlY2tlZCBpdGVtIjoiTXVzdCBzZWxlY3Qge3ZhbHVlfSBvciBtb3JlIiwiTWF4IGNoZWNrZWQgaXRlbSI6Ik11c3Qgc2VsZWN0IHt2YWx1ZX0gb3IgbGVzcyIsIlN1Ym1pdCI6IlN1Ym1pdCJ9!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXF1aXJlZCBmaWVsZCI6IlJlcXVpcmVkIGZpZWxkIiwiTWluIGxlbmd0aCI6Int2YWx1ZX0gY2hhcmFjdGVycyBvciBtb3JlIiwiTWF4IGxlbmd0aCI6Int2YWx1ZX0gY2hhcmFjdGVycyBvciBsZXNzIiwiTWluIG51bWJlciI6Ik11c3QgYmUge3ZhbHVlfSBvciBtb3JlIiwiTWF4IG51bWJlciI6Ik11c3QgYmUge3ZhbHVlfSBvciBsZXNzIiwiSW52YWxpZCBlbWFpbCI6IkludmFsaWQgZW1haWwgYWRkcmVzcywgdXNlIGZvcm1hdCBleGFtcGxlQGRvbWFpbi5jb20iLCJNaW4gY2hlY2tlZCBpdGVtIjoiTXVzdCBzZWxlY3Qge3ZhbHVlfSBvciBtb3JlIiwiTWF4IGNoZWNrZWQgaXRlbSI6Ik11c3Qgc2VsZWN0IHt2YWx1ZX0gb3IgbGVzcyIsIlN1Ym1pdCI6IlN1Ym1pdCJ9!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXF1aXJlZCBmaWVsZCI6IlJlcXVpcmVkIGZpZWxkIiwiTWluIGxlbmd0aCI6Int2YWx1ZX0gY2hhcmFjdGVycyBvciBtb3JlIiwiTWF4IGxlbmd0aCI6Int2YWx1ZX0gY2hhcmFjdGVycyBvciBsZXNzIiwiTWluIG51bWJlciI6Ik11c3QgYmUge3ZhbHVlfSBvciBtb3JlIiwiTWF4IG51bWJlciI6Ik11c3QgYmUge3ZhbHVlfSBvciBsZXNzIiwiSW52YWxpZCBlbWFpbCI6IkludmFsaWQgZW1haWwgYWRkcmVzcywgdXNlIGZvcm1hdCBleGFtcGxlQGRvbWFpbi5jb20iLCJNaW4gY2hlY2tlZCBpdGVtIjoiTXVzdCBzZWxlY3Qge3ZhbHVlfSBvciBtb3JlIiwiTWF4IGNoZWNrZWQgaXRlbSI6Ik11c3Qgc2VsZWN0IHt2YWx1ZX0gb3IgbGVzcyIsIlN1Ym1pdCI6IlN1Ym1pdCJ9!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXF1aXJlZCBmaWVsZCI6IlJlcXVpcmVkIGZpZWxkIiwiTWluIGxlbmd0aCI6Int2YWx1ZX0gY2hhcmFjdGVycyBvciBtb3JlIiwiTWF4IGxlbmd0aCI6Int2YWx1ZX0gY2hhcmFjdGVycyBvciBsZXNzIiwiTWluIG51bWJlciI6Ik11c3QgYmUge3ZhbHVlfSBvciBtb3JlIiwiTWF4IG51bWJlciI6Ik11c3QgYmUge3ZhbHVlfSBvciBsZXNzIiwiSW52YWxpZCBlbWFpbCI6IkludmFsaWQgZW1haWwgYWRkcmVzcywgdXNlIGZvcm1hdCBleGFtcGxlQGRvbWFpbi5jb20iLCJNaW4gY2hlY2tlZCBpdGVtIjoiTXVzdCBzZWxlY3Qge3ZhbHVlfSBvciBtb3JlIiwiTWF4IGNoZWNrZWQgaXRlbSI6Ik11c3Qgc2VsZWN0IHt2YWx1ZX0gb3IgbGVzcyIsIlN1Ym1pdCI6IlN1Ym1pdCJ9!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXF1aXJlZCBmaWVsZCI6IlJlcXVpcmVkIGZpZWxkIiwiTWluIGxlbmd0aCI6Int2YWx1ZX0gY2hhcmFjdGVycyBvciBtb3JlIiwiTWF4IGxlbmd0aCI6Int2YWx1ZX0gY2hhcmFjdGVycyBvciBsZXNzIiwiTWluIG51bWJlciI6Ik11c3QgYmUge3ZhbHVlfSBvciBtb3JlIiwiTWF4IG51bWJlciI6Ik11c3QgYmUge3ZhbHVlfSBvciBsZXNzIiwiSW52YWxpZCBlbWFpbCI6IkludmFsaWQgZW1haWwgYWRkcmVzcywgdXNlIGZvcm1hdCBleGFtcGxlQGRvbWFpbi5jb20iLCJNaW4gY2hlY2tlZCBpdGVtIjoiTXVzdCBzZWxlY3Qge3ZhbHVlfSBvciBtb3JlIiwiTWF4IGNoZWNrZWQgaXRlbSI6Ik11c3Qgc2VsZWN0IHt2YWx1ZX0gb3IgbGVzcyIsIlN1Ym1pdCI6IlN1Ym1pdCJ9!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXF1aXJlZCBmaWVsZCI6IlJlcXVpcmVkIGZpZWxkIiwiTWluIGxlbmd0aCI6Int2YWx1ZX0gY2hhcmFjdGVycyBvciBtb3JlIiwiTWF4IGxlbmd0aCI6Int2YWx1ZX0gY2hhcmFjdGVycyBvciBsZXNzIiwiTWluIG51bWJlciI6Ik11c3QgYmUge3ZhbHVlfSBvciBtb3JlIiwiTWF4IG51bWJlciI6Ik11c3QgYmUge3ZhbHVlfSBvciBsZXNzIiwiSW52YWxpZCBlbWFpbCI6IkludmFsaWQgZW1haWwgYWRkcmVzcywgdXNlIGZvcm1hdCBleGFtcGxlQGRvbWFpbi5jb20iLCJNaW4gY2hlY2tlZCBpdGVtIjoiTXVzdCBzZWxlY3Qge3ZhbHVlfSBvciBtb3JlIiwiTWF4IGNoZWNrZWQgaXRlbSI6Ik11c3Qgc2VsZWN0IHt2YWx1ZX0gb3IgbGVzcyIsIlN1Ym1pdCI6IlN1Ym1pdCJ9!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXF1aXJlZCBmaWVsZCI6IlJlcXVpcmVkIGZpZWxkIiwiTWluIGxlbmd0aCI6Int2YWx1ZX0gY2hhcmFjdGVycyBvciBtb3JlIiwiTWF4IGxlbmd0aCI6Int2YWx1ZX0gY2hhcmFjdGVycyBvciBsZXNzIiwiTWluIG51bWJlciI6Ik11c3QgYmUge3ZhbHVlfSBvciBtb3JlIiwiTWF4IG51bWJlciI6Ik11c3QgYmUge3ZhbHVlfSBvciBsZXNzIiwiSW52YWxpZCBlbWFpbCI6IkludmFsaWQgZW1haWwgYWRkcmVzcywgdXNlIGZvcm1hdCBleGFtcGxlQGRvbWFpbi5jb20iLCJNaW4gY2hlY2tlZCBpdGVtIjoiTXVzdCBzZWxlY3Qge3ZhbHVlfSBvciBtb3JlIiwiTWF4IGNoZWNrZWQgaXRlbSI6Ik11c3Qgc2VsZWN0IHt2YWx1ZX0gb3IgbGVzcyIsIlN1Ym1pdCI6IlN1Ym1pdCJ9!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXF1aXJlZCBmaWVsZCI6IlJlcXVpcmVkIGZpZWxkIiwiTWluIGxlbmd0aCI6Int2YWx1ZX0gY2hhcmFjdGVycyBvciBtb3JlIiwiTWF4IGxlbmd0aCI6Int2YWx1ZX0gY2hhcmFjdGVycyBvciBsZXNzIiwiTWluIG51bWJlciI6Ik11c3QgYmUge3ZhbHVlfSBvciBtb3JlIiwiTWF4IG51bWJlciI6Ik11c3QgYmUge3ZhbHVlfSBvciBsZXNzIiwiSW52YWxpZCBlbWFpbCI6IkludmFsaWQgZW1haWwgYWRkcmVzcywgdXNlIGZvcm1hdCBleGFtcGxlQGRvbWFpbi5jb20iLCJNaW4gY2hlY2tlZCBpdGVtIjoiTXVzdCBzZWxlY3Qge3ZhbHVlfSBvciBtb3JlIiwiTWF4IGNoZWNrZWQgaXRlbSI6Ik11c3Qgc2VsZWN0IHt2YWx1ZX0gb3IgbGVzcyIsIlN1Ym1pdCI6IlN1Ym1pdCJ9!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXF1aXJlZCBmaWVsZCI6IlJlcXVpcmVkIGZpZWxkIiwiTWluIGxlbmd0aCI6Int2YWx1ZX0gY2hhcmFjdGVycyBvciBtb3JlIiwiTWF4IGxlbmd0aCI6Int2YWx1ZX0gY2hhcmFjdGVycyBvciBsZXNzIiwiTWluIG51bWJlciI6Ik11c3QgYmUge3ZhbHVlfSBvciBtb3JlIiwiTWF4IG51bWJlciI6Ik11c3QgYmUge3ZhbHVlfSBvciBsZXNzIiwiSW52YWxpZCBlbWFpbCI6IkludmFsaWQgZW1haWwgYWRkcmVzcywgdXNlIGZvcm1hdCBleGFtcGxlQGRvbWFpbi5jb20iLCJNaW4gY2hlY2tlZCBpdGVtIjoiTXVzdCBzZWxlY3Qge3ZhbHVlfSBvciBtb3JlIiwiTWF4IGNoZWNrZWQgaXRlbSI6Ik11c3Qgc2VsZWN0IHt2YWx1ZX0gb3IgbGVzcyIsIlN1Ym1pdCI6IlN1Ym1pdCJ9!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXF1aXJlZCBmaWVsZCI6IuC4iOC4s%2BC5gOC4m%2BC5h%2BC4meC4leC5ieC4reC4h%2BC4o%2BC4sOC4muC4uCIsIk1pbiBsZW5ndGgiOiJ7dmFsdWV9IOC4leC4seC4p%2BC4reC4seC4geC4qeC4o%2BC4q%2BC4o%2BC4t%2BC4reC4oeC4suC4geC4geC4p%2BC5iOC4siIsIk1heCBsZW5ndGgiOiJ7dmFsdWV9IOC4leC4seC4p%2BC4reC4seC4geC4qeC4o%2BC4q%2BC4o%2BC4t%2BC4reC4meC5ieC4reC4ouC4geC4p%2BC5iOC4siIsIk1pbiBudW1iZXIiOiLguJXguYnguK3guIfguYDguJfguYjguLLguIHguLHguJoge3ZhbHVlfSDguKvguKPguLfguK3guKHguLLguIHguIHguKfguYjguLIiLCJNYXggbnVtYmVyIjoi4LiV4LmJ4Lit4LiH4LmA4LiX4LmI4Liy4LiB4Lix4LiaIHt2YWx1ZX0g4Lir4Lij4Li34Lit4LiZ4LmJ4Lit4Lii4LiB4Lin4LmI4LiyIiwiSW52YWxpZCBlbWFpbCI6IuC4reC4teC5gOC4oeC4peC5hOC4oeC5iOC4luC4ueC4geC4leC5ieC4reC4hyDguIHguKPguLjguJPguLLguKPguLDguJrguLjguK3guLXguYDguKHguKXguYPguJnguKPguLnguJvguYHguJrguJogZXhhbXBsZUBkb21haW4uY29tIiwiTWluIGNoZWNrZWQgaXRlbSI6IuC4leC5ieC4reC4h%2BC5gOC4peC4t%2BC4reC4gSB7dmFsdWV9IOC4q%2BC4o%2BC4t%2BC4reC4oeC4suC4geC4geC4p%2BC5iOC4siIsIk1heCBjaGVja2VkIGl0ZW0iOiLguJXguYnguK3guIfguYDguKXguLfguK3guIEge3ZhbHVlfSDguKvguKPguLfguK3guJnguYnguK3guKLguIHguKfguYjguLIiLCJTdWJtaXQiOiLguKrguYjguIcifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXF1aXJlZCBmaWVsZCI6IuC4iOC4s%2BC5gOC4m%2BC5h%2BC4meC4leC5ieC4reC4h%2BC4o%2BC4sOC4muC4uCIsIk1pbiBsZW5ndGgiOiJ7dmFsdWV9IOC4leC4seC4p%2BC4reC4seC4geC4qeC4o%2BC4q%2BC4o%2BC4t%2BC4reC4oeC4suC4geC4geC4p%2BC5iOC4siIsIk1heCBsZW5ndGgiOiJ7dmFsdWV9IOC4leC4seC4p%2BC4reC4seC4geC4qeC4o%2BC4q%2BC4o%2BC4t%2BC4reC4meC5ieC4reC4ouC4geC4p%2BC5iOC4siIsIk1pbiBudW1iZXIiOiLguJXguYnguK3guIfguYDguJfguYjguLLguIHguLHguJoge3ZhbHVlfSDguKvguKPguLfguK3guKHguLLguIHguIHguKfguYjguLIiLCJNYXggbnVtYmVyIjoi4LiV4LmJ4Lit4LiH4LmA4LiX4LmI4Liy4LiB4Lix4LiaIHt2YWx1ZX0g4Lir4Lij4Li34Lit4LiZ4LmJ4Lit4Lii4LiB4Lin4LmI4LiyIiwiSW52YWxpZCBlbWFpbCI6IuC4reC4teC5gOC4oeC4peC5hOC4oeC5iOC4luC4ueC4geC4leC5ieC4reC4hyDguIHguKPguLjguJPguLLguKPguLDguJrguLjguK3guLXguYDguKHguKXguYPguJnguKPguLnguJvguYHguJrguJogZXhhbXBsZUBkb21haW4uY29tIiwiTWluIGNoZWNrZWQgaXRlbSI6IuC4leC5ieC4reC4h%2BC5gOC4peC4t%2BC4reC4gSB7dmFsdWV9IOC4q%2BC4o%2BC4t%2BC4reC4oeC4suC4geC4geC4p%2BC5iOC4siIsIk1heCBjaGVja2VkIGl0ZW0iOiLguJXguYnguK3guIfguYDguKXguLfguK3guIEge3ZhbHVlfSDguKvguKPguLfguK3guJnguYnguK3guKLguIHguKfguYjguLIiLCJTdWJtaXQiOiLguKrguYjguIcifQ%3D%3D!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXF1aXJlZCBmaWVsZCI6IldhamliIGRpaXNpIiwiTWluIGxlbmd0aCI6Int2YWx1ZX0ga2FyYWt0ZXIgYXRhdSBsZWJpaCIsIk1heCBsZW5ndGgiOiJ7dmFsdWV9IGthcmFrdGVyIGF0YXUga3VyYW5nIiwiTWluIG51bWJlciI6IkhhcnVzIHt2YWx1ZX0gYXRhdSBsZWJpaCIsIk1heCBudW1iZXIiOiJIYXJ1cyB7dmFsdWV9IGF0YXUga3VyYW5nIiwiSW52YWxpZCBlbWFpbCI6IkFsYW1hdCBlbWFpbCB0aWRhayBzYWgsIGd1bmFrYW4gZm9ybWF0IGV4YW1wbGVAZG9tYWluLmNvbSIsIk1pbiBjaGVja2VkIGl0ZW0iOiJIYXJ1cyBtZW1pbGloIHt2YWx1ZX0gYXRhdSBsZWJpaCIsIk1heCBjaGVja2VkIGl0ZW0iOiJIYXJ1cyBtZW1pbGloIHt2YWx1ZX0gYXRhdSBrdXJhbmciLCJTdWJtaXQiOiJLaXJpbSJ9!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXF1aXJlZCBmaWVsZCI6IldhamliIGRpaXNpIiwiTWluIGxlbmd0aCI6Int2YWx1ZX0ga2FyYWt0ZXIgYXRhdSBsZWJpaCIsIk1heCBsZW5ndGgiOiJ7dmFsdWV9IGthcmFrdGVyIGF0YXUga3VyYW5nIiwiTWluIG51bWJlciI6IkhhcnVzIHt2YWx1ZX0gYXRhdSBsZWJpaCIsIk1heCBudW1iZXIiOiJIYXJ1cyB7dmFsdWV9IGF0YXUga3VyYW5nIiwiSW52YWxpZCBlbWFpbCI6IkFsYW1hdCBlbWFpbCB0aWRhayBzYWgsIGd1bmFrYW4gZm9ybWF0IGV4YW1wbGVAZG9tYWluLmNvbSIsIk1pbiBjaGVja2VkIGl0ZW0iOiJIYXJ1cyBtZW1pbGloIHt2YWx1ZX0gYXRhdSBsZWJpaCIsIk1heCBjaGVja2VkIGl0ZW0iOiJIYXJ1cyBtZW1pbGloIHt2YWx1ZX0gYXRhdSBrdXJhbmciLCJTdWJtaXQiOiJLaXJpbSJ9!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXF1aXJlZCBmaWVsZCI6IlvFmOG6veG6vXHMh8eax5rDrMOsxZnhur3hur3GjCDGksOsw6zhur3hur3GmsaMXSIsIk1pbiBsZW5ndGgiOiJbe3ZhbHVlfSDDp%2BG4qcSDxIPEg8WZxIPEg8SDw6fhua%2Fhur3hur3hur3FmcWhIMO2w7bDtsWZIG3MgsO2w7bDtsWZ4bq94bq94bq9XSIsIk1heCBsZW5ndGgiOiJbe3ZhbHVlfSDDp%2BG4qcSDxIPEg8WZxIPEg8SDw6fhua%2Fhur3hur3hur3FmcWhIMO2w7bDtsWZIMaa4bq94bq94bq9xaHFoV0iLCJNaW4gbnVtYmVyIjoiW%2BG5gseax5rHmsWh4bmvIMOf4bq94bq94bq9IHt2YWx1ZX0gw7bDtsO2xZkgbcyCw7bDtsO2xZnhur3hur3hur1dIiwiTWF4IG51bWJlciI6IlvhuYLHmseax5rFoeG5ryDDn%2BG6veG6veG6vSB7dmFsdWV9IMO2w7bDtsWZIMaa4bq94bq94bq9xaHFoV0iLCJJbnZhbGlkIGVtYWlsIjoiW8OP4LiB4Li14LmJ4bm9xIPEg8SDxprDrMOsw6zGjCDhur3hur3hur1tzILEg8SDxIPDrMOsw6zGmiDEg8SDxIPGjMaMxZnhur3hur3hur3FocWhLCDHmseax5rFoeG6veG6veG6vSDGksO2w7bDtsWZbcyCxIPEg8SD4bmvIOG6veG6veG6veqtlcSDxIPEg23Mgsalxprhur3hur3hur1AxozDtsO2w7ZtzILEg8SDxIPDrMOsw6zguIHguLXguYkuw6fDtsO2w7ZtzIJdIiwiTWluIGNoZWNrZWQgaXRlbSI6IlvhuYLHmseax5rFoeG5ryDFoeG6veG6veG6vcaa4bq94bq94bq9w6fhua8ge3ZhbHVlfSDDtsO2w7bFmSBtzILDtsO2w7bFmeG6veG6veG6vV0iLCJNYXggY2hlY2tlZCBpdGVtIjoiW%2BG5gseax5rHmsWh4bmvIMWh4bq94bq94bq9xprhur3hur3hur3Dp%2BG5ryB7dmFsdWV9IMO2w7bDtsWZIMaa4bq94bq94bq9xaHFoV0iLCJTdWJtaXQiOiJbxaDHmseax5rDn23MgsOsw6zDrOG5r10ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXF1aXJlZCBmaWVsZCI6IlvFmOG6veG6vXHMh8eax5rDrMOsxZnhur3hur3GjCDGksOsw6zhur3hur3GmsaMXSIsIk1pbiBsZW5ndGgiOiJbe3ZhbHVlfSDDp%2BG4qcSDxIPEg8WZxIPEg8SDw6fhua%2Fhur3hur3hur3FmcWhIMO2w7bDtsWZIG3MgsO2w7bDtsWZ4bq94bq94bq9XSIsIk1heCBsZW5ndGgiOiJbe3ZhbHVlfSDDp%2BG4qcSDxIPEg8WZxIPEg8SDw6fhua%2Fhur3hur3hur3FmcWhIMO2w7bDtsWZIMaa4bq94bq94bq9xaHFoV0iLCJNaW4gbnVtYmVyIjoiW%2BG5gseax5rHmsWh4bmvIMOf4bq94bq94bq9IHt2YWx1ZX0gw7bDtsO2xZkgbcyCw7bDtsO2xZnhur3hur3hur1dIiwiTWF4IG51bWJlciI6IlvhuYLHmseax5rFoeG5ryDDn%2BG6veG6veG6vSB7dmFsdWV9IMO2w7bDtsWZIMaa4bq94bq94bq9xaHFoV0iLCJJbnZhbGlkIGVtYWlsIjoiW8OP4LiB4Li14LmJ4bm9xIPEg8SDxprDrMOsw6zGjCDhur3hur3hur1tzILEg8SDxIPDrMOsw6zGmiDEg8SDxIPGjMaMxZnhur3hur3hur3FocWhLCDHmseax5rFoeG6veG6veG6vSDGksO2w7bDtsWZbcyCxIPEg8SD4bmvIOG6veG6veG6veqtlcSDxIPEg23Mgsalxprhur3hur3hur1AxozDtsO2w7ZtzILEg8SDxIPDrMOsw6zguIHguLXguYkuw6fDtsO2w7ZtzIJdIiwiTWluIGNoZWNrZWQgaXRlbSI6IlvhuYLHmseax5rFoeG5ryDFoeG6veG6veG6vcaa4bq94bq94bq9w6fhua8ge3ZhbHVlfSDDtsO2w7bFmSBtzILDtsO2w7bFmeG6veG6veG6vV0iLCJNYXggY2hlY2tlZCBpdGVtIjoiW%2BG5gseax5rHmsWh4bmvIMWh4bq94bq94bq9xprhur3hur3hur3Dp%2BG5ryB7dmFsdWV9IMO2w7bDtsWZIMaa4bq94bq94bq9xaHFoV0iLCJTdWJtaXQiOiJbxaDHmseax5rDn23MgsOsw6zDrOG5r10ifQ%3D%3D!"
        )
      )
      });
  
      export { translations as default };
    