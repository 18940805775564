import React from 'react';
import { PageLayout } from '../../components/PageLayout/PageLayout';
import {
  Box,
  Link,
  Badge,
  Heading,
  Notice,
  Columns,
  Column,
  List,
  Stack,
  Text,
  Divider,
  Spread,
} from 'braid-design-system';
import { useLoaderData } from 'react-router';
import type { PreviewData } from '../../../helpers/loadersHelper';
import { Helmet } from 'react-helmet';

type Props = {
  isDraft?: boolean;
};

/**
 * This is used to display the view and preview pages. If it is Preview the content should be Published otherwise this will be a Draft document.
 * @param isDraft
 * @constructor
 */
const PreviewPage = ({ isDraft }: Props) => {
  const previewData: PreviewData = useLoaderData() as PreviewData;
  const isPreviewAvailable = previewData?.previewDocuments?.length > 0;
  const previewType = previewData?.isArticle ? 'Article' : 'Page';

  // If it is Published document, the title will be View
  const title = isDraft ? 'Preview' : 'View';
  const stage = isDraft ? 'Draft' : 'Published';

  return (
    <PageLayout layoutProps={{ title }}>
      <Helmet meta={[{ name: 'robots', content: 'noindex nofollow' }]} />
      {/* If there is no locales to be displayed */}
      {!isPreviewAvailable ? (
        // Start: Preview Not Available
        <Box
          background="body"
          padding="large"
          style={{ width: '50%', margin: 'auto' }}
        >
          <Stack
            space={{
              mobile: 'medium',
              tablet: 'medium',
              desktop: 'large',
              wide: 'large',
            }}
            align="center"
          >
            <Notice tone="critical">
              <Text>Preview is not available for this entry.</Text>
            </Notice>

            <Heading level="3">Why is there no preview available?</Heading>

            <List type="number">
              <Text>
                Entry id is not valid. Double check the provided entry id.
              </Text>
              <Text>There will be no Published page or article available.</Text>
              <Text>
                If this is an article, there will be no section or category
                selected.
              </Text>
              <Text>This would be a Candidate Article or a SARA page.</Text>
            </List>
          </Stack>
        </Box>
      ) : (
        // End: Preview Not Available

        // Start: Preview Documents Exist
        <Box
          background="body"
          paddingTop="large"
          padding="small"
          paddingBottom="large"
          style={{ width: '100%', maxWidth: '700px', margin: 'auto' }}
        >
          <Stack space="large">
            <Heading level="3" align="center">
              {stage} {title}(s) are available for this {previewType}.
            </Heading>

            <Stack space="medium">
              <Columns space="large">
                <Column width="1/5">
                  <Heading level="4" align="left">
                    Locale
                  </Heading>
                </Column>

                <Column width="2/5">
                  <Heading level="4" align="left">
                    Site
                  </Heading>
                </Column>

                <Column width="content">
                  <Heading level="4" align="left">
                    Slug
                  </Heading>
                </Column>
              </Columns>

              {previewData?.previewDocuments?.map((previewDoc, index) => (
                <Box key={index}>
                  <Spread space="small" direction="vertical">
                    <Divider />
                    <Link
                      href={`${previewDoc.link}${
                        isDraft && previewDoc.sectionName !== 'career-advice'
                          ? `?draft=${previewDoc.id}`
                          : ''
                      }`}
                      target="_blank"
                    >
                      <Columns space="large">
                        <Column width="1/5">
                          <Badge
                            weight="strong"
                            tone={
                              previewDoc.locale === 'default'
                                ? 'positive'
                                : 'info'
                            }
                          >
                            {previewDoc.locale}
                          </Badge>
                        </Column>

                        <Column width="2/5">
                          <Text>{previewDoc.site}</Text>
                        </Column>

                        <Column width="content">
                          <Text>{previewDoc.slug}</Text>
                        </Column>
                      </Columns>
                    </Link>
                  </Spread>
                </Box>
              ))}
            </Stack>
          </Stack>
        </Box>

        // End: Preview Documents Exist
      )}
    </PageLayout>
  );
};
export { PreviewPage };
