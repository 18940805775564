import React, { useEffect, useState } from 'react';
import { useConfig } from '../../../shared/hooks';
import {
  ALL_INDUSTRIES_HIGHEST_PAID,
  type HighestPayingJobsIndustriesInsights,
  getHighestPayingJobs,
  industriesByHighestPayingJobs,
} from './Data/highestPayingJobs';
import {
  percentageWrapper,
  TableBuilder,
  textElementWrapper,
} from '@seek/cmsu-rich-text/src/components/Table/TableBuilder';
import { Dropdown, Stack } from 'braid-design-system';
import * as styles from './styles.css';
import { formatCurrency } from '@seek/cmsu-components/src/helpers/helper';

export const HighestPayingJobs = () => {
  const { country } = useConfig();
  const [data, setData] = useState<
    HighestPayingJobsIndustriesInsights | undefined
  >(undefined);
  const [selectedIndustry, setSelectedIndustry] = useState(-1);

  const highestPayingJobs = getHighestPayingJobs(country);

  useEffect(() => {
    const result = highestPayingJobs?.find(
      (item) => item.industryIdFilter === selectedIndustry,
    );
    setData(result);
  }, [highestPayingJobs, country, selectedIndustry]);

  if (!highestPayingJobs) return;

  const industries = industriesByHighestPayingJobs(highestPayingJobs);

  // Convert to Table Data with ROWS and COLUMNS
  // Map<string, JSX.Element> is a map of ROWS with column name and column values
  const tableData: Map<string, JSX.Element>[] | undefined = data?.roles.map(
    (item, index) => {
      const percentageIcon = (
        <div
          className={
            item.avgSalaryChange > 0 ? styles.arrow_up : styles.arrow_down
          }
        />
      );
      return new Map<string, JSX.Element>([
        ['Rank', textElementWrapper(`${index + 1}`)],
        ['Industry', textElementWrapper(item.industryTitle)],
        ['Role Type', textElementWrapper(item.roleTitle)],
        [
          'Average salary',
          textElementWrapper(formatCurrency(item.avgSalary, country)),
        ],
        [
          'Salary Growth',
          percentageWrapper(item.avgSalaryChange, percentageIcon),
        ],
      ]);
    },
  );

  return (
    <Stack space="gutter">
      <Dropdown
        id="INDUSTRY_SELECTION"
        label="Select an industry"
        onChange={(event) => {
          setSelectedIndustry(Number(event.currentTarget.value));
        }}
        value={selectedIndustry}
        placeholder="Select an industry"
      >
        <>
          <option value="-1">{ALL_INDUSTRIES_HIGHEST_PAID}</option>
          {industries.map((industry) => (
            <option key={industry.id} value={industry.id}>
              {industry.description}
            </option>
          ))}
        </>
      </Dropdown>
      {tableData && <TableBuilder tableData={tableData} showHeader={true} />}
    </Stack>
  );
};
