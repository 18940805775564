export const HIGHEST_PAYING_SALARIES_AU = [
  {
    industryIdFilter: -1,
    roles: [
      {
        rank: 1,
        roleTitle: 'Psychiatrist',
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        avgSalary: 236055,
        avgSalaryChange: 7,
      },
      {
        rank: 2,
        roleTitle: 'Project Director',
        industryId: 6251,
        industryTitle: 'Construction',
        avgSalary: 232682,
        avgSalaryChange: 4,
      },
      {
        rank: 3,
        roleTitle: 'General Practitioner',
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        avgSalary: 232450,
        avgSalaryChange: 0,
      },
      {
        rank: 4,
        roleTitle: 'Staff Specialist',
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        avgSalary: 222503,
        avgSalaryChange: 8,
      },
      {
        rank: 5,
        roleTitle: 'Engineering Manager',
        industryId: 6281,
        industryTitle: 'Information & Communication Technology',
        avgSalary: 181783,
        avgSalaryChange: 0,
      },
      {
        rank: 6,
        roleTitle: 'Engineering Lead',
        industryId: 6281,
        industryTitle: 'Information & Communication Technology',
        avgSalary: 171441,
        avgSalaryChange: 6,
      },
      {
        rank: 7,
        roleTitle: 'Financial Planning and Analysis Manager',
        industryId: 1200,
        industryTitle: 'Accounting',
        avgSalary: 167445,
        avgSalaryChange: 1,
      },
      {
        rank: 8,
        roleTitle: 'Electrical Supervisor',
        industryId: 6058,
        industryTitle: 'Mining, Resources & Energy',
        avgSalary: 164545,
        avgSalaryChange: 2,
      },
      {
        rank: 9,
        roleTitle: 'Reliability Engineer',
        industryId: 6058,
        industryTitle: 'Mining, Resources & Energy',
        avgSalary: 158347,
        avgSalaryChange: 5,
      },
      {
        rank: 10,
        roleTitle: 'Functional Consultant',
        industryId: 6281,
        industryTitle: 'Information & Communication Technology',
        avgSalary: 155266,
        avgSalaryChange: 0,
      },
      {
        rank: 11,
        roleTitle: 'Delivery Lead',
        industryId: 6281,
        industryTitle: 'Information & Communication Technology',
        avgSalary: 154881,
        avgSalaryChange: 3,
      },
      {
        rank: 12,
        roleTitle: 'Civil Supervisor',
        industryId: 6251,
        industryTitle: 'Construction',
        avgSalary: 151133,
        avgSalaryChange: 2,
      },
      {
        rank: 13,
        roleTitle: 'Director of Nursing',
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        avgSalary: 149873,
        avgSalaryChange: 9,
      },
      {
        rank: 14,
        roleTitle: 'Facilities Manager',
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        avgSalary: 146867,
        avgSalaryChange: 8,
      },
      {
        rank: 16,
        roleTitle: 'Site Reliability Engineer',
        industryId: 6281,
        industryTitle: 'Information & Communication Technology',
        avgSalary: 146532,
        avgSalaryChange: -3,
      },
      {
        rank: 15,
        roleTitle: 'Electrician',
        industryId: 6058,
        industryTitle: 'Mining, Resources & Energy',
        avgSalary: 146800,
        avgSalaryChange: 3,
      },
      {
        rank: 17,
        roleTitle: 'Acquisition Manager',
        industryId: 6317,
        industryTitle: 'Human Resources & Recruitment',
        avgSalary: 145901,
        avgSalaryChange: 12,
      },
      {
        rank: 18,
        roleTitle: 'Registrar',
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        avgSalary: 145176,
        avgSalaryChange: 12,
      },
      {
        rank: 19,
        roleTitle: 'Banking Manager',
        industryId: 1203,
        industryTitle: 'Banking & Financial Services',
        avgSalary: 143932,
        avgSalaryChange: 12,
      },
      {
        rank: 20,
        roleTitle: 'Site Supervisor',
        industryId: 6058,
        industryTitle: 'Mining, Resources & Energy',
        avgSalary: 143818,
        avgSalaryChange: 4,
      },
    ],
  },
  {
    industryIdFilter: 1200,
    roles: [
      {
        industryId: 1200,
        industryTitle: 'Accounting',
        roleTitle: 'Financial Planning and Analysis Manager',
        rank: 1,
        avgSalary: 167445,
        avgSalaryChange: 1,
      },
      {
        industryId: 1200,
        industryTitle: 'Accounting',
        roleTitle: 'Business Services Manager',
        rank: 2,
        avgSalary: 132400,
        avgSalaryChange: 4,
      },
      {
        industryId: 1200,
        industryTitle: 'Accounting',
        roleTitle: 'Audit Manager',
        rank: 3,
        avgSalary: 130363,
        avgSalaryChange: -7,
      },
      {
        industryId: 1200,
        industryTitle: 'Accounting',
        roleTitle: 'Service Manager',
        rank: 4,
        avgSalary: 130217,
        avgSalaryChange: 4,
      },
      {
        industryId: 1200,
        industryTitle: 'Accounting',
        roleTitle: 'Commercial Analyst',
        rank: 5,
        avgSalary: 122712,
        avgSalaryChange: 4,
      },
      {
        industryId: 1200,
        industryTitle: 'Accounting',
        roleTitle: 'Financial Planning and Analysis Analyst',
        rank: 6,
        avgSalary: 122570,
        avgSalaryChange: -2,
      },
      {
        industryId: 1200,
        industryTitle: 'Accounting',
        roleTitle: 'Business Services Supervisor',
        rank: 7,
        avgSalary: 113842,
        avgSalaryChange: 7,
      },
      {
        industryId: 1200,
        industryTitle: 'Accounting',
        roleTitle: 'Assistant Manager',
        rank: 8,
        avgSalary: 104571,
        avgSalaryChange: -4,
      },
      {
        industryId: 1200,
        industryTitle: 'Accounting',
        roleTitle: 'Payroll Specialist',
        rank: 9,
        avgSalary: 94974,
        avgSalaryChange: -3,
      },
      {
        industryId: 1200,
        industryTitle: 'Accounting',
        roleTitle: 'Auditor',
        rank: 10,
        avgSalary: 87510,
        avgSalaryChange: -6,
      },
      {
        industryId: 1200,
        industryTitle: 'Accounting',
        roleTitle: 'Payroll Officer',
        rank: 11,
        avgSalary: 85087,
        avgSalaryChange: 5,
      },
      {
        industryId: 1200,
        industryTitle: 'Accounting',
        roleTitle: 'Accounts Manager',
        rank: 12,
        avgSalary: 80551,
        avgSalaryChange: -4,
      },
      {
        industryId: 1200,
        industryTitle: 'Accounting',
        roleTitle: 'Credit Controller',
        rank: 13,
        avgSalary: 78477,
        avgSalaryChange: 4,
      },
      {
        industryId: 1200,
        industryTitle: 'Accounting',
        roleTitle: 'Financial Administrator',
        rank: 14,
        avgSalary: 77970,
        avgSalaryChange: 11,
      },
      {
        industryId: 1200,
        industryTitle: 'Accounting',
        roleTitle: 'Financial Officer',
        rank: 15,
        avgSalary: 76073,
        avgSalaryChange: 3,
      },
      {
        industryId: 1200,
        industryTitle: 'Accounting',
        roleTitle: 'Bookkeeper',
        rank: 16,
        avgSalary: 75720,
        avgSalaryChange: 5,
      },
      {
        industryId: 1200,
        industryTitle: 'Accounting',
        roleTitle: 'Payroll and Accounts Officer',
        rank: 17,
        avgSalary: 74667,
        avgSalaryChange: 5,
      },
      {
        industryId: 1200,
        industryTitle: 'Accounting',
        roleTitle: 'Credit Officer',
        rank: 18,
        avgSalary: 74204,
        avgSalaryChange: 5,
      },
      {
        industryId: 1200,
        industryTitle: 'Accounting',
        roleTitle: 'Accounts Payable Officer',
        rank: 19,
        avgSalary: 73087,
        avgSalaryChange: 5,
      },
      {
        industryId: 1200,
        industryTitle: 'Accounting',
        roleTitle: 'Accounts Receivable Officer',
        rank: 20,
        avgSalary: 72635,
        avgSalaryChange: 4,
      },
    ],
  },
  {
    industryIdFilter: 1203,
    roles: [
      {
        industryId: 1203,
        industryTitle: 'Banking & Financial Services',
        roleTitle: 'Banking Manager',
        rank: 1,
        avgSalary: 143932,
        avgSalaryChange: 12,
      },
      {
        industryId: 1203,
        industryTitle: 'Banking & Financial Services',
        roleTitle: 'Risk Analyst',
        rank: 2,
        avgSalary: 112988,
        avgSalaryChange: 4,
      },
      {
        industryId: 1203,
        industryTitle: 'Banking & Financial Services',
        roleTitle: 'Financial Analyst',
        rank: 3,
        avgSalary: 111592,
        avgSalaryChange: 8,
      },
      {
        industryId: 1203,
        industryTitle: 'Banking & Financial Services',
        roleTitle: 'Branch Manager',
        rank: 4,
        avgSalary: 101007,
        avgSalaryChange: 2,
      },
      {
        industryId: 1203,
        industryTitle: 'Banking & Financial Services',
        roleTitle: 'Adviser',
        rank: 5,
        avgSalary: 95480,
        avgSalaryChange: 1,
      },
      {
        industryId: 1203,
        industryTitle: 'Banking & Financial Services',
        roleTitle: 'Compliance Officer',
        rank: 6,
        avgSalary: 94936,
        avgSalaryChange: 4,
      },
      {
        industryId: 1203,
        industryTitle: 'Banking & Financial Services',
        roleTitle: 'Paraplanner',
        rank: 7,
        avgSalary: 89004,
        avgSalaryChange: 3,
      },
      {
        industryId: 1203,
        industryTitle: 'Banking & Financial Services',
        roleTitle: 'Service Manager',
        rank: 8,
        avgSalary: 86026,
        avgSalaryChange: 8,
      },
      {
        industryId: 1203,
        industryTitle: 'Banking & Financial Services',
        roleTitle: 'Lending Specialist',
        rank: 9,
        avgSalary: 83659,
        avgSalaryChange: 2,
      },
      {
        industryId: 1203,
        industryTitle: 'Banking & Financial Services',
        roleTitle: 'Support Officer',
        rank: 10,
        avgSalary: 72656,
        avgSalaryChange: 7,
      },
      {
        industryId: 1203,
        industryTitle: 'Banking & Financial Services',
        roleTitle: 'Services Officer',
        rank: 11,
        avgSalary: 70888,
        avgSalaryChange: 3,
      },
      {
        industryId: 1203,
        industryTitle: 'Banking & Financial Services',
        roleTitle: 'Settlements Officer',
        rank: 12,
        avgSalary: 70544,
        avgSalaryChange: 0,
      },
      {
        industryId: 1203,
        industryTitle: 'Banking & Financial Services',
        roleTitle: 'Banking Adviser',
        rank: 13,
        avgSalary: 65195,
        avgSalaryChange: -15,
      },
      {
        industryId: 1203,
        industryTitle: 'Banking & Financial Services',
        roleTitle: 'Customer Service Representative',
        rank: 14,
        avgSalary: 62762,
        avgSalaryChange: 5,
      },
      {
        industryId: 1203,
        industryTitle: 'Banking & Financial Services',
        roleTitle: 'Customer Adviser',
        rank: 15,
        avgSalary: 62135,
        avgSalaryChange: -1,
      },
      {
        industryId: 1203,
        industryTitle: 'Banking & Financial Services',
        roleTitle: 'Banking Consultant',
        rank: 16,
        avgSalary: 62054,
        avgSalaryChange: 7,
      },
    ],
  },
  {
    industryIdFilter: 1204,
    roles: [
      {
        industryId: 1204,
        industryTitle: 'Call Centre & Customer Service',
        roleTitle: 'Customer Service Manager',
        rank: 1,
        avgSalary: 89547,
        avgSalaryChange: -1,
      },
      {
        industryId: 1204,
        industryTitle: 'Call Centre & Customer Service',
        roleTitle: 'Customer Service Team Leader',
        rank: 2,
        avgSalary: 84943,
        avgSalaryChange: 7,
      },
      {
        industryId: 1204,
        industryTitle: 'Call Centre & Customer Service',
        roleTitle: 'Customer Service Coordinator',
        rank: 3,
        avgSalary: 69007,
        avgSalaryChange: 7,
      },
      {
        industryId: 1204,
        industryTitle: 'Call Centre & Customer Service',
        roleTitle: 'Collections Officer',
        rank: 4,
        avgSalary: 68458,
        avgSalaryChange: 6,
      },
      {
        industryId: 1204,
        industryTitle: 'Call Centre & Customer Service',
        roleTitle: 'Relationship Officer',
        rank: 5,
        avgSalary: 68431,
        avgSalaryChange: 9,
      },
      {
        industryId: 1204,
        industryTitle: 'Call Centre & Customer Service',
        roleTitle: 'Service Adviser',
        rank: 6,
        avgSalary: 68089,
        avgSalaryChange: 1,
      },
      {
        industryId: 1204,
        industryTitle: 'Call Centre & Customer Service',
        roleTitle: 'Sales Representative',
        rank: 7,
        avgSalary: 67195,
        avgSalaryChange: 8,
      },
      {
        industryId: 1204,
        industryTitle: 'Call Centre & Customer Service',
        roleTitle: 'Support Specialist',
        rank: 8,
        avgSalary: 66558,
        avgSalaryChange: 7,
      },
      {
        industryId: 1204,
        industryTitle: 'Call Centre & Customer Service',
        roleTitle: 'Services Officer',
        rank: 9,
        avgSalary: 66441,
        avgSalaryChange: 1,
      },
      {
        industryId: 1204,
        industryTitle: 'Call Centre & Customer Service',
        roleTitle: 'Support Officer',
        rank: 10,
        avgSalary: 65015,
        avgSalaryChange: 3,
      },
      {
        industryId: 1204,
        industryTitle: 'Call Centre & Customer Service',
        roleTitle: 'Sales Consultant',
        rank: 11,
        avgSalary: 64832,
        avgSalaryChange: -1,
      },
      {
        industryId: 1204,
        industryTitle: 'Call Centre & Customer Service',
        roleTitle: 'Appointment Setter',
        rank: 12,
        avgSalary: 64242,
        avgSalaryChange: 0,
      },
      {
        industryId: 1204,
        industryTitle: 'Call Centre & Customer Service',
        roleTitle: 'Call Centre Operator',
        rank: 13,
        avgSalary: 63512,
        avgSalaryChange: 7,
      },
      {
        industryId: 1204,
        industryTitle: 'Call Centre & Customer Service',
        roleTitle: 'Customer Experience Consultant',
        rank: 14,
        avgSalary: 63461,
        avgSalaryChange: 10,
      },
      {
        industryId: 1204,
        industryTitle: 'Call Centre & Customer Service',
        roleTitle: 'Customer Service Adviser',
        rank: 15,
        avgSalary: 63244,
        avgSalaryChange: 2,
      },
      {
        industryId: 1204,
        industryTitle: 'Call Centre & Customer Service',
        roleTitle: 'Customer Service and Sales Consultant',
        rank: 16,
        avgSalary: 62963,
        avgSalaryChange: 4,
      },
      {
        industryId: 1204,
        industryTitle: 'Call Centre & Customer Service',
        roleTitle: 'Care Consultant',
        rank: 17,
        avgSalary: 59608,
        avgSalaryChange: 1,
      },
    ],
  },
  {
    industryIdFilter: 1206,
    roles: [
      {
        industryId: 1206,
        industryTitle: 'Construction',
        roleTitle: 'Project Director',
        rank: 1,
        avgSalary: 232682,
        avgSalaryChange: 4,
      },
      {
        industryId: 1206,
        industryTitle: 'Construction',
        roleTitle: 'Civil Supervisor',
        rank: 2,
        avgSalary: 151133,
        avgSalaryChange: 2,
      },
      {
        industryId: 1206,
        industryTitle: 'Construction',
        roleTitle: 'Environmental Adviser',
        rank: 3,
        avgSalary: 130977,
        avgSalaryChange: 1,
      },
      {
        industryId: 1206,
        industryTitle: 'Construction',
        roleTitle: 'Safety Coordinator',
        rank: 4,
        avgSalary: 121678,
        avgSalaryChange: 20,
      },
      {
        industryId: 1206,
        industryTitle: 'Construction',
        roleTitle: 'Site Supervisor',
        rank: 5,
        avgSalary: 117478,
        avgSalaryChange: 3,
      },
      {
        industryId: 1206,
        industryTitle: 'Construction',
        roleTitle: 'Site Engineer',
        rank: 6,
        avgSalary: 112347,
        avgSalaryChange: 3,
      },
      {
        industryId: 1206,
        industryTitle: 'Construction',
        roleTitle: 'Health, Safety, Environment and Quality Coordinator',
        rank: 7,
        avgSalary: 105490,
        avgSalaryChange: 9,
      },
      {
        industryId: 1206,
        industryTitle: 'Construction',
        roleTitle: 'Building Supervisor',
        rank: 8,
        avgSalary: 104968,
        avgSalaryChange: 4,
      },
      {
        industryId: 1206,
        industryTitle: 'Construction',
        roleTitle: 'Safety Officer',
        rank: 9,
        avgSalary: 102151,
        avgSalaryChange: 6,
      },
      {
        industryId: 1206,
        industryTitle: 'Construction',
        roleTitle: 'Estimator Supervisor',
        rank: 10,
        avgSalary: 101357,
        avgSalaryChange: 1,
      },
      {
        industryId: 1206,
        industryTitle: 'Construction',
        roleTitle: 'Pump Operator',
        rank: 11,
        avgSalary: 100489,
        avgSalaryChange: 8,
      },
      {
        industryId: 1206,
        industryTitle: 'Construction',
        roleTitle: 'Crane Operator',
        rank: 12,
        avgSalary: 96609,
        avgSalaryChange: 7,
      },
      {
        industryId: 1206,
        industryTitle: 'Construction',
        roleTitle: 'Carpenter',
        rank: 13,
        avgSalary: 95239,
        avgSalaryChange: 1,
      },
      {
        industryId: 1206,
        industryTitle: 'Construction',
        roleTitle: 'Project Coordinator',
        rank: 14,
        avgSalary: 92346,
        avgSalaryChange: 6,
      },
      {
        industryId: 1206,
        industryTitle: 'Construction',
        roleTitle: 'Driller',
        rank: 15,
        avgSalary: 92328,
        avgSalaryChange: 1,
      },
      {
        industryId: 1206,
        industryTitle: 'Construction',
        roleTitle: 'Leading Hand',
        rank: 16,
        avgSalary: 92069,
        avgSalaryChange: 1,
      },
      {
        industryId: 1206,
        industryTitle: 'Construction',
        roleTitle: 'Civil Operator',
        rank: 17,
        avgSalary: 87277,
        avgSalaryChange: 5,
      },
      {
        industryId: 1206,
        industryTitle: 'Construction',
        roleTitle: 'Excavator Operator',
        rank: 18,
        avgSalary: 84666,
        avgSalaryChange: 4,
      },
      {
        industryId: 1206,
        industryTitle: 'Construction',
        roleTitle: 'Water Cart Operator',
        rank: 19,
        avgSalary: 81364,
        avgSalaryChange: 1,
      },
      {
        industryId: 1206,
        industryTitle: 'Construction',
        roleTitle: 'Machine Operator',
        rank: 20,
        avgSalary: 80700,
        avgSalaryChange: 6,
      },
    ],
  },
  {
    industryIdFilter: 1209,
    roles: [
      {
        industryId: 1209,
        industryTitle: 'Engineering',
        roleTitle: 'Licenced Aircraft Maintenance Engineer',
        rank: 1,
        avgSalary: 126816,
        avgSalaryChange: 3,
      },
      {
        industryId: 1209,
        industryTitle: 'Engineering',
        roleTitle: 'Automation Engineer',
        rank: 2,
        avgSalary: 124250,
        avgSalaryChange: 4,
      },
      {
        industryId: 1209,
        industryTitle: 'Engineering',
        roleTitle: 'Site Engineer',
        rank: 3,
        avgSalary: 107566,
        avgSalaryChange: 2,
      },
      {
        industryId: 1209,
        industryTitle: 'Engineering',
        roleTitle: 'Application Engineer',
        rank: 4,
        avgSalary: 105186,
        avgSalaryChange: 5,
      },
      {
        industryId: 1209,
        industryTitle: 'Engineering',
        roleTitle: 'Modeller',
        rank: 5,
        avgSalary: 104817,
        avgSalaryChange: -5,
      },
      {
        industryId: 1209,
        industryTitle: 'Engineering',
        roleTitle: 'Field Service Engineer',
        rank: 6,
        avgSalary: 100693,
        avgSalaryChange: 1,
      },
      {
        industryId: 1209,
        industryTitle: 'Engineering',
        roleTitle: 'Sales Engineer',
        rank: 7,
        avgSalary: 99552,
        avgSalaryChange: 2,
      },
      {
        industryId: 1209,
        industryTitle: 'Engineering',
        roleTitle: 'Development Engineer',
        rank: 8,
        avgSalary: 97746,
        avgSalaryChange: -3,
      },
      {
        industryId: 1209,
        industryTitle: 'Engineering',
        roleTitle: 'Project Coordinator',
        rank: 9,
        avgSalary: 96588,
        avgSalaryChange: -3,
      },
      {
        industryId: 1209,
        industryTitle: 'Engineering',
        roleTitle: 'Fitter',
        rank: 10,
        avgSalary: 96452,
        avgSalaryChange: 6,
      },
      {
        industryId: 1209,
        industryTitle: 'Engineering',
        roleTitle: 'Support Engineer',
        rank: 11,
        avgSalary: 95992,
        avgSalaryChange: -5,
      },
      {
        industryId: 1209,
        industryTitle: 'Engineering',
        roleTitle: 'Mechatronics Engineer',
        rank: 12,
        avgSalary: 93429,
        avgSalaryChange: -1,
      },
      {
        industryId: 1209,
        industryTitle: 'Engineering',
        roleTitle: 'Draftsperson',
        rank: 13,
        avgSalary: 92263,
        avgSalaryChange: 3,
      },
      {
        industryId: 1209,
        industryTitle: 'Engineering',
        roleTitle: 'Aircraft Maintenance Engineer',
        rank: 14,
        avgSalary: 91847,
        avgSalaryChange: 6,
      },
      {
        industryId: 1209,
        industryTitle: 'Engineering',
        roleTitle: 'Technical Officer',
        rank: 15,
        avgSalary: 89978,
        avgSalaryChange: 6,
      },
      {
        industryId: 1209,
        industryTitle: 'Engineering',
        roleTitle: 'Engineering Technician',
        rank: 16,
        avgSalary: 87425,
        avgSalaryChange: -7,
      },
    ],
  },
  {
    industryIdFilter: 1210,
    roles: [
      {
        industryId: 1210,
        industryTitle: 'Government & Defence',
        roleTitle: 'Assistant Director',
        rank: 1,
        avgSalary: 122988,
        avgSalaryChange: 3,
      },
      {
        industryId: 1210,
        industryTitle: 'Government & Defence',
        roleTitle: 'Adviser',
        rank: 2,
        avgSalary: 119149,
        avgSalaryChange: 14,
      },
      {
        industryId: 1210,
        industryTitle: 'Government & Defence',
        roleTitle: 'Coordinator',
        rank: 3,
        avgSalary: 110913,
        avgSalaryChange: 6,
      },
      {
        industryId: 1210,
        industryTitle: 'Government & Defence',
        roleTitle: 'Policy Officer',
        rank: 4,
        avgSalary: 108909,
        avgSalaryChange: 12,
      },
      {
        industryId: 1210,
        industryTitle: 'Government & Defence',
        roleTitle: 'Project Officer',
        rank: 5,
        avgSalary: 103822,
        avgSalaryChange: 4,
      },
      {
        industryId: 1210,
        industryTitle: 'Government & Defence',
        roleTitle: 'Procurement Officer',
        rank: 6,
        avgSalary: 103302,
        avgSalaryChange: 6,
      },
      {
        industryId: 1210,
        industryTitle: 'Government & Defence',
        roleTitle: 'Investigator',
        rank: 7,
        avgSalary: 101084,
        avgSalaryChange: -3,
      },
      {
        industryId: 1210,
        industryTitle: 'Government & Defence',
        roleTitle: 'Planner',
        rank: 8,
        avgSalary: 100464,
        avgSalaryChange: -1,
      },
      {
        industryId: 1210,
        industryTitle: 'Government & Defence',
        roleTitle: 'Inspector',
        rank: 9,
        avgSalary: 99132,
        avgSalaryChange: -3,
      },
      {
        industryId: 1210,
        industryTitle: 'Government & Defence',
        roleTitle: 'Engagement Officer',
        rank: 10,
        avgSalary: 98464,
        avgSalaryChange: 11,
      },
      {
        industryId: 1210,
        industryTitle: 'Government & Defence',
        roleTitle: 'Governance Officer',
        rank: 11,
        avgSalary: 98444,
        avgSalaryChange: 9,
      },
      {
        industryId: 1210,
        industryTitle: 'Government & Defence',
        roleTitle: 'Program Officer',
        rank: 12,
        avgSalary: 97666,
        avgSalaryChange: 8,
      },
      {
        industryId: 1210,
        industryTitle: 'Government & Defence',
        roleTitle: 'Compliance Officer',
        rank: 13,
        avgSalary: 96712,
        avgSalaryChange: 11,
      },
      {
        industryId: 1210,
        industryTitle: 'Government & Defence',
        roleTitle: 'Management Officer',
        rank: 14,
        avgSalary: 93539,
        avgSalaryChange: 8,
      },
      {
        industryId: 1210,
        industryTitle: 'Government & Defence',
        roleTitle: 'Health Officer',
        rank: 15,
        avgSalary: 91032,
        avgSalaryChange: -1,
      },
      {
        industryId: 1210,
        industryTitle: 'Government & Defence',
        roleTitle: 'Support Officer',
        rank: 16,
        avgSalary: 85724,
        avgSalaryChange: 6,
      },
      {
        industryId: 1210,
        industryTitle: 'Government & Defence',
        roleTitle: 'Technical Officer',
        rank: 17,
        avgSalary: 83935,
        avgSalaryChange: 2,
      },
      {
        industryId: 1210,
        industryTitle: 'Government & Defence',
        roleTitle: 'Executive Assistant',
        rank: 18,
        avgSalary: 83802,
        avgSalaryChange: 8,
      },
      {
        industryId: 1210,
        industryTitle: 'Government & Defence',
        roleTitle: 'Administration Officer',
        rank: 19,
        avgSalary: 81177,
        avgSalaryChange: 11,
      },
      {
        industryId: 1210,
        industryTitle: 'Government & Defence',
        roleTitle: 'Electorate Officer',
        rank: 20,
        avgSalary: 80649,
        avgSalaryChange: 6,
      },
    ],
  },
  {
    industryIdFilter: 1211,
    roles: [
      {
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        roleTitle: 'Psychiatrist',
        rank: 1,
        avgSalary: 236055,
        avgSalaryChange: 7,
      },
      {
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        roleTitle: 'General Practitioner',
        rank: 2,
        avgSalary: 232450,
        avgSalaryChange: 0,
      },
      {
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        roleTitle: 'Staff Specialist',
        rank: 3,
        avgSalary: 222503,
        avgSalaryChange: 8,
      },
      {
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        roleTitle: 'Director of Nursing',
        rank: 4,
        avgSalary: 149873,
        avgSalaryChange: 9,
      },
      {
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        roleTitle: 'Facilities Manager',
        rank: 5,
        avgSalary: 146867,
        avgSalaryChange: 8,
      },
      {
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        roleTitle: 'Registrar',
        rank: 6,
        avgSalary: 145176,
        avgSalaryChange: 12,
      },
      {
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        roleTitle: 'Nurse Practitioner',
        rank: 7,
        avgSalary: 141376,
        avgSalaryChange: 4,
      },
      {
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        roleTitle: 'Sonographer',
        rank: 8,
        avgSalary: 130409,
        avgSalaryChange: 2,
      },
      {
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        roleTitle: 'Nurse Consultant',
        rank: 9,
        avgSalary: 128290,
        avgSalaryChange: 7,
      },
      {
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        roleTitle: 'Unit Manager',
        rank: 10,
        avgSalary: 124152,
        avgSalaryChange: 3,
      },
      {
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        roleTitle: 'Nurse Manager',
        rank: 11,
        avgSalary: 124131,
        avgSalaryChange: 6,
      },
      {
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        roleTitle: 'Program Manager',
        rank: 12,
        avgSalary: 120988,
        avgSalaryChange: 0,
      },
      {
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        roleTitle: 'Pharmacist Manager',
        rank: 13,
        avgSalary: 120088,
        avgSalaryChange: 9,
      },
      {
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        roleTitle: 'Project Manager',
        rank: 14,
        avgSalary: 119958,
        avgSalaryChange: 4,
      },
      {
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        roleTitle: 'Clinical Manager',
        rank: 15,
        avgSalary: 119660,
        avgSalaryChange: 6,
      },
      {
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        roleTitle: 'Care Manager',
        rank: 16,
        avgSalary: 118657,
        avgSalaryChange: 9,
      },
      {
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        roleTitle: 'Health Manager',
        rank: 17,
        avgSalary: 116957,
        avgSalaryChange: -2,
      },
      {
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        roleTitle: 'Hygienist',
        rank: 18,
        avgSalary: 115578,
        avgSalaryChange: 5,
      },
      {
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        roleTitle: 'Clinical Lead',
        rank: 19,
        avgSalary: 113331,
        avgSalaryChange: 4,
      },
      {
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        roleTitle: 'Speech Pathology Team Leader',
        rank: 20,
        avgSalary: 113121,
        avgSalaryChange: 8,
      },
    ],
  },
  {
    industryIdFilter: 1212,
    roles: [
      {
        industryId: 1212,
        industryTitle: 'Hospitality & Tourism',
        roleTitle: 'Village Manager',
        rank: 1,
        avgSalary: 106775,
        avgSalaryChange: 2,
      },
      {
        industryId: 1212,
        industryTitle: 'Hospitality & Tourism',
        roleTitle: 'Operations Manager',
        rank: 2,
        avgSalary: 92262,
        avgSalaryChange: 0,
      },
      {
        industryId: 1212,
        industryTitle: 'Hospitality & Tourism',
        roleTitle: 'Hotel Manager',
        rank: 3,
        avgSalary: 92087,
        avgSalaryChange: 5,
      },
      {
        industryId: 1212,
        industryTitle: 'Hospitality & Tourism',
        roleTitle: 'Sales Manager',
        rank: 4,
        avgSalary: 84154,
        avgSalaryChange: 8,
      },
      {
        industryId: 1212,
        industryTitle: 'Hospitality & Tourism',
        roleTitle: 'Service Manager',
        rank: 5,
        avgSalary: 83071,
        avgSalaryChange: 4,
      },
      {
        industryId: 1212,
        industryTitle: 'Hospitality & Tourism',
        roleTitle: 'Food and Beverage Manager',
        rank: 6,
        avgSalary: 82859,
        avgSalaryChange: 2,
      },
      {
        industryId: 1212,
        industryTitle: 'Hospitality & Tourism',
        roleTitle: 'Venue Manager',
        rank: 7,
        avgSalary: 81803,
        avgSalaryChange: 3,
      },
      {
        industryId: 1212,
        industryTitle: 'Hospitality & Tourism',
        roleTitle: 'Sommelier',
        rank: 8,
        avgSalary: 79775,
        avgSalaryChange: 7,
      },
      {
        industryId: 1212,
        industryTitle: 'Hospitality & Tourism',
        roleTitle: 'Park Manager',
        rank: 9,
        avgSalary: 77933,
        avgSalaryChange: 2,
      },
      {
        industryId: 1212,
        industryTitle: 'Hospitality & Tourism',
        roleTitle: 'Office Manager',
        rank: 10,
        avgSalary: 77859,
        avgSalaryChange: 10,
      },
      {
        industryId: 1212,
        industryTitle: 'Hospitality & Tourism',
        roleTitle: 'Bar Manager',
        rank: 11,
        avgSalary: 75017,
        avgSalaryChange: 3,
      },
      {
        industryId: 1212,
        industryTitle: 'Hospitality & Tourism',
        roleTitle: 'Chef Manager',
        rank: 12,
        avgSalary: 75004,
        avgSalaryChange: 5,
      },
      {
        industryId: 1212,
        industryTitle: 'Hospitality & Tourism',
        roleTitle: 'Front of House Manager',
        rank: 13,
        avgSalary: 74675,
        avgSalaryChange: 9,
      },
      {
        industryId: 1212,
        industryTitle: 'Hospitality & Tourism',
        roleTitle: 'Housekeeping Manager',
        rank: 14,
        avgSalary: 74207,
        avgSalaryChange: 6,
      },
      {
        industryId: 1212,
        industryTitle: 'Hospitality & Tourism',
        roleTitle: 'Restaurant Manager',
        rank: 15,
        avgSalary: 74138,
        avgSalaryChange: 2,
      },
      {
        industryId: 1212,
        industryTitle: 'Hospitality & Tourism',
        roleTitle: 'Chef',
        rank: 16,
        avgSalary: 73621,
        avgSalaryChange: 4,
      },
      {
        industryId: 1212,
        industryTitle: 'Hospitality & Tourism',
        roleTitle: 'Kitchen Manager',
        rank: 17,
        avgSalary: 73439,
        avgSalaryChange: 3,
      },
      {
        industryId: 1212,
        industryTitle: 'Hospitality & Tourism',
        roleTitle: 'Gaming Manager',
        rank: 18,
        avgSalary: 72376,
        avgSalaryChange: 5,
      },
      {
        industryId: 1212,
        industryTitle: 'Hospitality & Tourism',
        roleTitle: 'Duty Manager',
        rank: 19,
        avgSalary: 71575,
        avgSalaryChange: 6,
      },
      {
        industryId: 1212,
        industryTitle: 'Hospitality & Tourism',
        roleTitle: 'Night Manager',
        rank: 20,
        avgSalary: 71322,
        avgSalaryChange: 6,
      },
    ],
  },
  {
    industryIdFilter: 1214,
    roles: [
      {
        industryId: 1214,
        industryTitle: 'Insurance & Superannuation',
        roleTitle: 'Account Manager',
        rank: 1,
        avgSalary: 113832,
        avgSalaryChange: 1,
      },
      {
        industryId: 1214,
        industryTitle: 'Insurance & Superannuation',
        roleTitle: 'Assessor',
        rank: 2,
        avgSalary: 99112,
        avgSalaryChange: 1,
      },
      {
        industryId: 1214,
        industryTitle: 'Insurance & Superannuation',
        roleTitle: 'Account Executive',
        rank: 3,
        avgSalary: 93904,
        avgSalaryChange: 9,
      },
      {
        industryId: 1214,
        industryTitle: 'Insurance & Superannuation',
        roleTitle: 'Broker',
        rank: 4,
        avgSalary: 89140,
        avgSalaryChange: 15,
      },
      {
        industryId: 1214,
        industryTitle: 'Insurance & Superannuation',
        roleTitle: 'Client Executive',
        rank: 5,
        avgSalary: 88106,
        avgSalaryChange: 0,
      },
      {
        industryId: 1214,
        industryTitle: 'Insurance & Superannuation',
        roleTitle: 'Case Manager',
        rank: 6,
        avgSalary: 87159,
        avgSalaryChange: 3,
      },
      {
        industryId: 1214,
        industryTitle: 'Insurance & Superannuation',
        roleTitle: 'Claims Consultant',
        rank: 7,
        avgSalary: 79067,
        avgSalaryChange: -3,
      },
      {
        industryId: 1214,
        industryTitle: 'Insurance & Superannuation',
        roleTitle: 'Claims Officer',
        rank: 8,
        avgSalary: 78589,
        avgSalaryChange: -2,
      },
    ],
  },
  {
    industryIdFilter: 1216,
    roles: [
      {
        industryId: 1216,
        industryTitle: 'Legal',
        roleTitle: 'Executive Assistant',
        rank: 1,
        avgSalary: 93158,
        avgSalaryChange: 4,
      },
      {
        industryId: 1216,
        industryTitle: 'Legal',
        roleTitle: 'Migration Agent',
        rank: 2,
        avgSalary: 86431,
        avgSalaryChange: 5,
      },
      {
        industryId: 1216,
        industryTitle: 'Legal',
        roleTitle: 'Conveyancer',
        rank: 3,
        avgSalary: 81195,
        avgSalaryChange: 4,
      },
      {
        industryId: 1216,
        industryTitle: 'Legal',
        roleTitle: 'Personal Assistant',
        rank: 4,
        avgSalary: 80139,
        avgSalaryChange: 3,
      },
      {
        industryId: 1216,
        industryTitle: 'Legal',
        roleTitle: 'Legal Assistant',
        rank: 5,
        avgSalary: 78603,
        avgSalaryChange: 2,
      },
      {
        industryId: 1216,
        industryTitle: 'Legal',
        roleTitle: 'Secretary',
        rank: 6,
        avgSalary: 77155,
        avgSalaryChange: 5,
      },
      {
        industryId: 1216,
        industryTitle: 'Legal',
        roleTitle: 'Paralegal',
        rank: 7,
        avgSalary: 76376,
        avgSalaryChange: 4,
      },
      {
        industryId: 1216,
        industryTitle: 'Legal',
        roleTitle: 'Legal Administrator',
        rank: 8,
        avgSalary: 74344,
        avgSalaryChange: 9,
      },
      {
        industryId: 1216,
        industryTitle: 'Legal',
        roleTitle: 'Law Graduate',
        rank: 9,
        avgSalary: 71167,
        avgSalaryChange: 4,
      },
      {
        industryId: 1216,
        industryTitle: 'Legal',
        roleTitle: 'Law Clerk',
        rank: 10,
        avgSalary: 68607,
        avgSalaryChange: 10,
      },
      {
        industryId: 1216,
        industryTitle: 'Legal',
        roleTitle: 'Administration Officer',
        rank: 11,
        avgSalary: 67353,
        avgSalaryChange: 4,
      },
    ],
  },
  {
    industryIdFilter: 1220,
    roles: [
      {
        industryId: 1220,
        industryTitle: 'Real Estate & Property',
        roleTitle: 'Facilities Manager',
        rank: 1,
        avgSalary: 112161,
        avgSalaryChange: 1,
      },
      {
        industryId: 1220,
        industryTitle: 'Real Estate & Property',
        roleTitle: 'Operations Manager',
        rank: 2,
        avgSalary: 111896,
        avgSalaryChange: 1,
      },
      {
        industryId: 1220,
        industryTitle: 'Real Estate & Property',
        roleTitle: 'Assistant Development Manager',
        rank: 3,
        avgSalary: 107858,
        avgSalaryChange: -2,
      },
      {
        industryId: 1220,
        industryTitle: 'Real Estate & Property',
        roleTitle: 'Owners Corporation Manager',
        rank: 4,
        avgSalary: 99994,
        avgSalaryChange: 8,
      },
      {
        industryId: 1220,
        industryTitle: 'Real Estate & Property',
        roleTitle: 'Strata Manager',
        rank: 5,
        avgSalary: 92766,
        avgSalaryChange: 10,
      },
      {
        industryId: 1220,
        industryTitle: 'Real Estate & Property',
        roleTitle: 'Valuer',
        rank: 6,
        avgSalary: 91852,
        avgSalaryChange: -2,
      },
      {
        industryId: 1220,
        industryTitle: 'Real Estate & Property',
        roleTitle: 'Executive Assistant',
        rank: 7,
        avgSalary: 91845,
        avgSalaryChange: 12,
      },
      {
        industryId: 1220,
        industryTitle: 'Real Estate & Property',
        roleTitle: 'Business Development Manager',
        rank: 8,
        avgSalary: 90027,
        avgSalaryChange: -2,
      },
      {
        industryId: 1220,
        industryTitle: 'Real Estate & Property',
        roleTitle: 'Accountant',
        rank: 9,
        avgSalary: 87767,
        avgSalaryChange: 5,
      },
      {
        industryId: 1220,
        industryTitle: 'Real Estate & Property',
        roleTitle: 'Sales Executive',
        rank: 10,
        avgSalary: 87686,
        avgSalaryChange: -8,
      },
      {
        industryId: 1220,
        industryTitle: 'Real Estate & Property',
        roleTitle: 'Building Manager',
        rank: 11,
        avgSalary: 83868,
        avgSalaryChange: 0,
      },
      {
        industryId: 1220,
        industryTitle: 'Real Estate & Property',
        roleTitle: 'Facilities Coordinator',
        rank: 12,
        avgSalary: 79755,
        avgSalaryChange: 2,
      },
      {
        industryId: 1220,
        industryTitle: 'Real Estate & Property',
        roleTitle: 'Property Manager',
        rank: 13,
        avgSalary: 79061,
        avgSalaryChange: 5,
      },
      {
        industryId: 1220,
        industryTitle: 'Real Estate & Property',
        roleTitle: 'Sales Agent',
        rank: 14,
        avgSalary: 72644,
        avgSalaryChange: 6,
      },
      {
        industryId: 1220,
        industryTitle: 'Real Estate & Property',
        roleTitle: 'Personal Assistant',
        rank: 15,
        avgSalary: 71898,
        avgSalaryChange: 6,
      },
      {
        industryId: 1220,
        industryTitle: 'Real Estate & Property',
        roleTitle: 'Sales Administrator',
        rank: 16,
        avgSalary: 69856,
        avgSalaryChange: 6,
      },
      {
        industryId: 1220,
        industryTitle: 'Real Estate & Property',
        roleTitle: 'Leasing Consultant',
        rank: 17,
        avgSalary: 65542,
        avgSalaryChange: 4,
      },
      {
        industryId: 1220,
        industryTitle: 'Real Estate & Property',
        roleTitle: 'Property Officer',
        rank: 18,
        avgSalary: 64962,
        avgSalaryChange: -7,
      },
      {
        industryId: 1220,
        industryTitle: 'Real Estate & Property',
        roleTitle: 'Sales Associate',
        rank: 19,
        avgSalary: 63462,
        avgSalaryChange: 5,
      },
      {
        industryId: 1220,
        industryTitle: 'Real Estate & Property',
        roleTitle: 'Administration Officer',
        rank: 20,
        avgSalary: 63329,
        avgSalaryChange: 5,
      },
    ],
  },
  {
    industryIdFilter: 1223,
    roles: [
      {
        industryId: 1223,
        industryTitle: 'Science & Technology',
        roleTitle: 'Ecologist',
        rank: 1,
        avgSalary: 118518,
        avgSalaryChange: 16,
      },
      {
        industryId: 1223,
        industryTitle: 'Science & Technology',
        roleTitle: 'Technologist',
        rank: 2,
        avgSalary: 90686,
        avgSalaryChange: -4,
      },
      {
        industryId: 1223,
        industryTitle: 'Science & Technology',
        roleTitle: 'Technical Officer',
        rank: 3,
        avgSalary: 86003,
        avgSalaryChange: 8,
      },
      {
        industryId: 1223,
        industryTitle: 'Science & Technology',
        roleTitle: 'Chemist',
        rank: 4,
        avgSalary: 82784,
        avgSalaryChange: 0,
      },
      {
        industryId: 1223,
        industryTitle: 'Science & Technology',
        roleTitle: 'Laboratory Technician',
        rank: 5,
        avgSalary: 66508,
        avgSalaryChange: 1,
      },
      {
        industryId: 1223,
        industryTitle: 'Science & Technology',
        roleTitle: 'Laboratory Assistant',
        rank: 6,
        avgSalary: 64439,
        avgSalaryChange: 8,
      },
    ],
  },
  {
    industryIdFilter: 1225,
    roles: [
      {
        industryId: 1225,
        industryTitle: 'Trades & Services',
        roleTitle: 'Operations Manager',
        rank: 1,
        avgSalary: 117824,
        avgSalaryChange: 6,
      },
      {
        industryId: 1225,
        industryTitle: 'Trades & Services',
        roleTitle: 'Workshop Manager',
        rank: 2,
        avgSalary: 112868,
        avgSalaryChange: 10,
      },
      {
        industryId: 1225,
        industryTitle: 'Trades & Services',
        roleTitle: 'Linesperson',
        rank: 3,
        avgSalary: 112014,
        avgSalaryChange: 8,
      },
      {
        industryId: 1225,
        industryTitle: 'Trades & Services',
        roleTitle: 'Diesel Mechanic Fitter',
        rank: 4,
        avgSalary: 109017,
        avgSalaryChange: 13,
      },
      {
        industryId: 1225,
        industryTitle: 'Trades & Services',
        roleTitle: 'Pipefitter',
        rank: 5,
        avgSalary: 108392,
        avgSalaryChange: -3,
      },
      {
        industryId: 1225,
        industryTitle: 'Trades & Services',
        roleTitle: 'Service Manager',
        rank: 6,
        avgSalary: 108345,
        avgSalaryChange: 4,
      },
      {
        industryId: 1225,
        industryTitle: 'Trades & Services',
        roleTitle: 'Site Manager',
        rank: 7,
        avgSalary: 105362,
        avgSalaryChange: 9,
      },
      {
        industryId: 1225,
        industryTitle: 'Trades & Services',
        roleTitle: 'Hydraulic Technician',
        rank: 8,
        avgSalary: 104982,
        avgSalaryChange: 8,
      },
      {
        industryId: 1225,
        industryTitle: 'Trades & Services',
        roleTitle: 'Workshop Supervisor',
        rank: 9,
        avgSalary: 103372,
        avgSalaryChange: 5,
      },
      {
        industryId: 1225,
        industryTitle: 'Trades & Services',
        roleTitle: 'Service Supervisor',
        rank: 10,
        avgSalary: 102647,
        avgSalaryChange: 8,
      },
      {
        industryId: 1225,
        industryTitle: 'Trades & Services',
        roleTitle: 'Communications Technician',
        rank: 11,
        avgSalary: 101864,
        avgSalaryChange: 7,
      },
      {
        industryId: 1225,
        industryTitle: 'Trades & Services',
        roleTitle: 'Refrigeration Technician',
        rank: 12,
        avgSalary: 99389,
        avgSalaryChange: 7,
      },
      {
        industryId: 1225,
        industryTitle: 'Trades & Services',
        roleTitle: 'Non Destructive Testing Technician',
        rank: 13,
        avgSalary: 99235,
        avgSalaryChange: 5,
      },
      {
        industryId: 1225,
        industryTitle: 'Trades & Services',
        roleTitle: 'Electrician',
        rank: 14,
        avgSalary: 98734,
        avgSalaryChange: 5,
      },
      {
        industryId: 1225,
        industryTitle: 'Trades & Services',
        roleTitle: 'Rigger',
        rank: 15,
        avgSalary: 98016,
        avgSalaryChange: 4,
      },
      {
        industryId: 1225,
        industryTitle: 'Trades & Services',
        roleTitle: 'Estimator',
        rank: 16,
        avgSalary: 97491,
        avgSalaryChange: 10,
      },
      {
        industryId: 1225,
        industryTitle: 'Trades & Services',
        roleTitle: 'Formworker',
        rank: 17,
        avgSalary: 96611,
        avgSalaryChange: 7,
      },
      {
        industryId: 1225,
        industryTitle: 'Trades & Services',
        roleTitle: 'Electrical Technician',
        rank: 18,
        avgSalary: 96592,
        avgSalaryChange: 1,
      },
      {
        industryId: 1225,
        industryTitle: 'Trades & Services',
        roleTitle: 'Security Technician',
        rank: 19,
        avgSalary: 96246,
        avgSalaryChange: 8,
      },
      {
        industryId: 1225,
        industryTitle: 'Trades & Services',
        roleTitle: 'Boilermaker',
        rank: 20,
        avgSalary: 95766,
        avgSalaryChange: 4,
      },
    ],
  },
  {
    industryIdFilter: 6008,
    roles: [
      {
        industryId: 6008,
        industryTitle: 'Marketing & Communications',
        roleTitle: 'Project Manager',
        rank: 1,
        avgSalary: 117926,
        avgSalaryChange: 12,
      },
      {
        industryId: 6008,
        industryTitle: 'Marketing & Communications',
        roleTitle: 'Marketing Lead',
        rank: 2,
        avgSalary: 113921,
        avgSalaryChange: 3,
      },
      {
        industryId: 6008,
        industryTitle: 'Marketing & Communications',
        roleTitle: 'Marketing Manager',
        rank: 3,
        avgSalary: 112844,
        avgSalaryChange: -2,
      },
      {
        industryId: 6008,
        industryTitle: 'Marketing & Communications',
        roleTitle: 'Communications Adviser',
        rank: 4,
        avgSalary: 107770,
        avgSalaryChange: 4,
      },
      {
        industryId: 6008,
        industryTitle: 'Marketing & Communications',
        roleTitle: 'Events Manager',
        rank: 5,
        avgSalary: 96222,
        avgSalaryChange: 2,
      },
      {
        industryId: 6008,
        industryTitle: 'Marketing & Communications',
        roleTitle: 'Writer',
        rank: 6,
        avgSalary: 94286,
        avgSalaryChange: -11,
      },
      {
        industryId: 6008,
        industryTitle: 'Marketing & Communications',
        roleTitle: 'Communications Officer',
        rank: 7,
        avgSalary: 92570,
        avgSalaryChange: 7,
      },
      {
        industryId: 6008,
        industryTitle: 'Marketing & Communications',
        roleTitle: 'Media Manager',
        rank: 8,
        avgSalary: 92428,
        avgSalaryChange: 3,
      },
      {
        industryId: 6008,
        industryTitle: 'Marketing & Communications',
        roleTitle: 'Content Specialist',
        rank: 9,
        avgSalary: 90903,
        avgSalaryChange: 3,
      },
      {
        industryId: 6008,
        industryTitle: 'Marketing & Communications',
        roleTitle: 'Producer',
        rank: 10,
        avgSalary: 89561,
        avgSalaryChange: 7,
      },
      {
        industryId: 6008,
        industryTitle: 'Marketing & Communications',
        roleTitle: 'Engagement Officer',
        rank: 11,
        avgSalary: 88886,
        avgSalaryChange: -2,
      },
      {
        industryId: 6008,
        industryTitle: 'Marketing & Communications',
        roleTitle: 'Media Officer',
        rank: 12,
        avgSalary: 86207,
        avgSalaryChange: -8,
      },
      {
        industryId: 6008,
        industryTitle: 'Marketing & Communications',
        roleTitle: 'Marketing Specialist',
        rank: 13,
        avgSalary: 85892,
        avgSalaryChange: -2,
      },
      {
        industryId: 6008,
        industryTitle: 'Marketing & Communications',
        roleTitle: 'Account Manager',
        rank: 14,
        avgSalary: 85593,
        avgSalaryChange: 1,
      },
      {
        industryId: 6008,
        industryTitle: 'Marketing & Communications',
        roleTitle: 'Strategist',
        rank: 15,
        avgSalary: 84393,
        avgSalaryChange: -10,
      },
      {
        industryId: 6008,
        industryTitle: 'Marketing & Communications',
        roleTitle: 'Communications Coordinator',
        rank: 16,
        avgSalary: 82352,
        avgSalaryChange: -4,
      },
      {
        industryId: 6008,
        industryTitle: 'Marketing & Communications',
        roleTitle: 'Marketing Executive',
        rank: 17,
        avgSalary: 82242,
        avgSalaryChange: 1,
      },
      {
        industryId: 6008,
        industryTitle: 'Marketing & Communications',
        roleTitle: 'Search Engine Optimisation Specialist',
        rank: 18,
        avgSalary: 80483,
        avgSalaryChange: -11,
      },
      {
        industryId: 6008,
        industryTitle: 'Marketing & Communications',
        roleTitle: 'Events Officer',
        rank: 19,
        avgSalary: 80226,
        avgSalaryChange: 6,
      },
      {
        industryId: 6008,
        industryTitle: 'Marketing & Communications',
        roleTitle: 'Designer',
        rank: 20,
        avgSalary: 78150,
        avgSalaryChange: 1,
      },
    ],
  },
  {
    industryIdFilter: 6043,
    roles: [
      {
        industryId: 6043,
        industryTitle: 'Retail & Consumer Products',
        roleTitle: 'Area Manager',
        rank: 1,
        avgSalary: 91209,
        avgSalaryChange: -3,
      },
      {
        industryId: 6043,
        industryTitle: 'Retail & Consumer Products',
        roleTitle: 'Showroom Manager',
        rank: 2,
        avgSalary: 79564,
        avgSalaryChange: 3,
      },
      {
        industryId: 6043,
        industryTitle: 'Retail & Consumer Products',
        roleTitle: 'Produce Manager',
        rank: 3,
        avgSalary: 79515,
        avgSalaryChange: 11,
      },
      {
        industryId: 6043,
        industryTitle: 'Retail & Consumer Products',
        roleTitle: 'Bakery Manager',
        rank: 4,
        avgSalary: 78356,
        avgSalaryChange: 10,
      },
      {
        industryId: 6043,
        industryTitle: 'Retail & Consumer Products',
        roleTitle: 'Delicatessen Manager',
        rank: 5,
        avgSalary: 75111,
        avgSalaryChange: 6,
      },
      {
        industryId: 6043,
        industryTitle: 'Retail & Consumer Products',
        roleTitle: 'Department Manager',
        rank: 6,
        avgSalary: 74642,
        avgSalaryChange: 7,
      },
      {
        industryId: 6043,
        industryTitle: 'Retail & Consumer Products',
        roleTitle: 'Nightfill Manager',
        rank: 7,
        avgSalary: 74594,
        avgSalaryChange: 6,
      },
      {
        industryId: 6043,
        industryTitle: 'Retail & Consumer Products',
        roleTitle: 'Sales Representative',
        rank: 8,
        avgSalary: 72500,
        avgSalaryChange: 20,
      },
      {
        industryId: 6043,
        industryTitle: 'Retail & Consumer Products',
        roleTitle: 'Grocery Manager',
        rank: 9,
        avgSalary: 72318,
        avgSalaryChange: 13,
      },
      {
        industryId: 6043,
        industryTitle: 'Retail & Consumer Products',
        roleTitle: 'Customer Service Manager',
        rank: 10,
        avgSalary: 72152,
        avgSalaryChange: 6,
      },
      {
        industryId: 6043,
        industryTitle: 'Retail & Consumer Products',
        roleTitle: 'Cluster Manager',
        rank: 11,
        avgSalary: 70867,
        avgSalaryChange: -3,
      },
      {
        industryId: 6043,
        industryTitle: 'Retail & Consumer Products',
        roleTitle: 'Boutique Manager',
        rank: 12,
        avgSalary: 68840,
        avgSalaryChange: -2,
      },
      {
        industryId: 6043,
        industryTitle: 'Retail & Consumer Products',
        roleTitle: 'Team Leader',
        rank: 13,
        avgSalary: 67753,
        avgSalaryChange: 21,
      },
      {
        industryId: 6043,
        industryTitle: 'Retail & Consumer Products',
        roleTitle: 'Duty Manager',
        rank: 14,
        avgSalary: 67088,
        avgSalaryChange: -3,
      },
      {
        industryId: 6043,
        industryTitle: 'Retail & Consumer Products',
        roleTitle: 'Ambassador',
        rank: 15,
        avgSalary: 65592,
        avgSalaryChange: -1,
      },
      {
        industryId: 6043,
        industryTitle: 'Retail & Consumer Products',
        roleTitle: 'Site Manager',
        rank: 16,
        avgSalary: 64740,
        avgSalaryChange: 14,
      },
      {
        industryId: 6043,
        industryTitle: 'Retail & Consumer Products',
        roleTitle: 'Store Manager',
        rank: 17,
        avgSalary: 64065,
        avgSalaryChange: 5,
      },
      {
        industryId: 6043,
        industryTitle: 'Retail & Consumer Products',
        roleTitle: 'Management Trainee',
        rank: 18,
        avgSalary: 63478,
        avgSalaryChange: 5,
      },
      {
        industryId: 6043,
        industryTitle: 'Retail & Consumer Products',
        roleTitle: 'Concession Manager',
        rank: 19,
        avgSalary: 62654,
        avgSalaryChange: 3,
      },
      {
        industryId: 6043,
        industryTitle: 'Retail & Consumer Products',
        roleTitle: 'Assistant Manager',
        rank: 20,
        avgSalary: 62165,
        avgSalaryChange: 4,
      },
    ],
  },
  {
    industryIdFilter: 6058,
    roles: [
      {
        industryId: 6058,
        industryTitle: 'Mining, Resources & Energy',
        roleTitle: 'Electrical Supervisor',
        rank: 1,
        avgSalary: 164545,
        avgSalaryChange: 2,
      },
      {
        industryId: 6058,
        industryTitle: 'Mining, Resources & Energy',
        roleTitle: 'Reliability Engineer',
        rank: 2,
        avgSalary: 158347,
        avgSalaryChange: 5,
      },
      {
        industryId: 6058,
        industryTitle: 'Mining, Resources & Energy',
        roleTitle: 'Electrician',
        rank: 3,
        avgSalary: 146425,
        avgSalaryChange: 3,
      },
      {
        industryId: 6058,
        industryTitle: 'Mining, Resources & Energy',
        roleTitle: 'Site Supervisor',
        rank: 4,
        avgSalary: 143818,
        avgSalaryChange: 4,
      },
      {
        industryId: 6058,
        industryTitle: 'Mining, Resources & Energy',
        roleTitle: 'Excavator Operator',
        rank: 5,
        avgSalary: 139208,
        avgSalaryChange: 3,
      },
      {
        industryId: 6058,
        industryTitle: 'Mining, Resources & Energy',
        roleTitle: 'Trainer',
        rank: 6,
        avgSalary: 138203,
        avgSalaryChange: 0,
      },
      {
        industryId: 6058,
        industryTitle: 'Mining, Resources & Energy',
        roleTitle: 'Mechanical Technician',
        rank: 7,
        avgSalary: 136584,
        avgSalaryChange: 12,
      },
      {
        industryId: 6058,
        industryTitle: 'Mining, Resources & Energy',
        roleTitle: 'Environmental Adviser',
        rank: 8,
        avgSalary: 135948,
        avgSalaryChange: 4,
      },
      {
        industryId: 6058,
        industryTitle: 'Mining, Resources & Energy',
        roleTitle: 'Health and Safety Adviser',
        rank: 9,
        avgSalary: 134461,
        avgSalaryChange: 1,
      },
      {
        industryId: 6058,
        industryTitle: 'Mining, Resources & Energy',
        roleTitle: 'Charge Up Operator',
        rank: 10,
        avgSalary: 134179,
        avgSalaryChange: 7,
      },
      {
        industryId: 6058,
        industryTitle: 'Mining, Resources & Energy',
        roleTitle: 'Boilermaker',
        rank: 11,
        avgSalary: 131742,
        avgSalaryChange: 4,
      },
      {
        industryId: 6058,
        industryTitle: 'Mining, Resources & Energy',
        roleTitle: 'Health Safety Environment and Quality Adviser',
        rank: 12,
        avgSalary: 127682,
        avgSalaryChange: 2,
      },
      {
        industryId: 6058,
        industryTitle: 'Mining, Resources & Energy',
        roleTitle: 'Shotfirer',
        rank: 13,
        avgSalary: 126950,
        avgSalaryChange: 5,
      },
      {
        industryId: 6058,
        industryTitle: 'Mining, Resources & Energy',
        roleTitle: 'Services Officer',
        rank: 14,
        avgSalary: 122464,
        avgSalaryChange: 4,
      },
      {
        industryId: 6058,
        industryTitle: 'Mining, Resources & Energy',
        roleTitle: 'Dozer Operator',
        rank: 15,
        avgSalary: 121979,
        avgSalaryChange: 1,
      },
      {
        industryId: 6058,
        industryTitle: 'Mining, Resources & Energy',
        roleTitle: 'Mobile Processing Unit Operator',
        rank: 16,
        avgSalary: 121062,
        avgSalaryChange: 8,
      },
      {
        industryId: 6058,
        industryTitle: 'Mining, Resources & Energy',
        roleTitle: 'Serviceperson',
        rank: 17,
        avgSalary: 118207,
        avgSalaryChange: 8,
      },
      {
        industryId: 6058,
        industryTitle: 'Mining, Resources & Energy',
        roleTitle: 'Allround Operator',
        rank: 18,
        avgSalary: 116811,
        avgSalaryChange: 5,
      },
      {
        industryId: 6058,
        industryTitle: 'Mining, Resources & Energy',
        roleTitle: 'Grader Operator',
        rank: 19,
        avgSalary: 115282,
        avgSalaryChange: -3,
      },
      {
        industryId: 6058,
        industryTitle: 'Mining, Resources & Energy',
        roleTitle: 'Process Operator',
        rank: 20,
        avgSalary: 114324,
        avgSalaryChange: 7,
      },
    ],
  },
  {
    industryIdFilter: 6076,
    roles: [
      {
        industryId: 6076,
        industryTitle: 'Consulting & Strategy',
        roleTitle: 'Project Manager',
        rank: 1,
        avgSalary: 138032,
        avgSalaryChange: 13,
      },
      {
        industryId: 6076,
        industryTitle: 'Consulting & Strategy',
        roleTitle: 'Policy Officer',
        rank: 2,
        avgSalary: 107879,
        avgSalaryChange: -4,
      },
      {
        industryId: 6076,
        industryTitle: 'Consulting & Strategy',
        roleTitle: 'Project Officer',
        rank: 3,
        avgSalary: 107046,
        avgSalaryChange: 4,
      },
      {
        industryId: 6076,
        industryTitle: 'Consulting & Strategy',
        roleTitle: 'Planner',
        rank: 4,
        avgSalary: 103420,
        avgSalaryChange: 2,
      },
      {
        industryId: 6076,
        industryTitle: 'Design & Architecture',
        roleTitle: 'Design Manager',
        rank: 1,
        avgSalary: 137820,
        avgSalaryChange: -4,
      },
      {
        industryId: 6076,
        industryTitle: 'Design & Architecture',
        roleTitle: 'Project Architect',
        rank: 2,
        avgSalary: 108836,
        avgSalaryChange: 6,
      },
      {
        industryId: 6076,
        industryTitle: 'Design & Architecture',
        roleTitle: 'Architect',
        rank: 3,
        avgSalary: 100249,
        avgSalaryChange: 2,
      },
      {
        industryId: 6076,
        industryTitle: 'Design & Architecture',
        roleTitle: 'Planner',
        rank: 4,
        avgSalary: 101162,
        avgSalaryChange: 2,
      },
      {
        industryId: 6076,
        industryTitle: 'Design & Architecture',
        roleTitle: 'Landscape Architect',
        rank: 5,
        avgSalary: 93735,
        avgSalaryChange: 3,
      },
      {
        industryId: 6076,
        industryTitle: 'Design & Architecture',
        roleTitle: 'Designer',
        rank: 6,
        avgSalary: 84773,
        avgSalaryChange: 1,
      },
      {
        industryId: 6076,
        industryTitle: 'Design & Architecture',
        roleTitle: 'Draftsperson',
        rank: 7,
        avgSalary: 82004,
        avgSalaryChange: 2,
      },
      {
        industryId: 6076,
        industryTitle: 'Design & Architecture',
        roleTitle: 'Graduate of Architecture',
        rank: 8,
        avgSalary: 76694,
        avgSalaryChange: 4,
      },
    ],
  },
  {
    industryIdFilter: 6092,
    roles: [
      {
        industryId: 6092,
        industryTitle: 'Manufacturing, Transport & Logistics',
        roleTitle: 'Category Manager',
        rank: 1,
        avgSalary: 142698,
        avgSalaryChange: -1,
      },
      {
        industryId: 6092,
        industryTitle: 'Manufacturing, Transport & Logistics',
        roleTitle: 'Quality Manager',
        rank: 2,
        avgSalary: 140664,
        avgSalaryChange: 15,
      },
      {
        industryId: 6092,
        industryTitle: 'Manufacturing, Transport & Logistics',
        roleTitle: 'Category Specialist',
        rank: 3,
        avgSalary: 123560,
        avgSalaryChange: -5,
      },
      {
        industryId: 6092,
        industryTitle: 'Manufacturing, Transport & Logistics',
        roleTitle: 'Service Manager',
        rank: 4,
        avgSalary: 122300,
        avgSalaryChange: 11,
      },
      {
        industryId: 6092,
        industryTitle: 'Manufacturing, Transport & Logistics',
        roleTitle: 'Transport Manager',
        rank: 5,
        avgSalary: 118554,
        avgSalaryChange: -3,
      },
      {
        industryId: 6092,
        industryTitle: 'Manufacturing, Transport & Logistics',
        roleTitle: 'Partner',
        rank: 6,
        avgSalary: 117588,
        avgSalaryChange: -8,
      },
      {
        industryId: 6092,
        industryTitle: 'Manufacturing, Transport & Logistics',
        roleTitle: 'Business Development Manager',
        rank: 7,
        avgSalary: 117352,
        avgSalaryChange: -1,
      },
      {
        industryId: 6092,
        industryTitle: 'Manufacturing, Transport & Logistics',
        roleTitle: 'Inventory Manager',
        rank: 8,
        avgSalary: 113044,
        avgSalaryChange: 9,
      },
      {
        industryId: 6092,
        industryTitle: 'Manufacturing, Transport & Logistics',
        roleTitle: 'Quality Assurance Manager',
        rank: 9,
        avgSalary: 109901,
        avgSalaryChange: -4,
      },
      {
        industryId: 6092,
        industryTitle: 'Manufacturing, Transport & Logistics',
        roleTitle: 'Roadtrain Operator',
        rank: 10,
        avgSalary: 103107,
        avgSalaryChange: -4,
      },
      {
        industryId: 6092,
        industryTitle: 'Manufacturing, Transport & Logistics',
        roleTitle: 'Planner',
        rank: 11,
        avgSalary: 96093,
        avgSalaryChange: 3,
      },
      {
        industryId: 6092,
        industryTitle: 'Manufacturing, Transport & Logistics',
        roleTitle: 'Linehaul Driver',
        rank: 12,
        avgSalary: 94399,
        avgSalaryChange: 3,
      },
      {
        industryId: 6092,
        industryTitle: 'Manufacturing, Transport & Logistics',
        roleTitle: 'Logistics Supervisor',
        rank: 13,
        avgSalary: 92108,
        avgSalaryChange: 17,
      },
      {
        industryId: 6092,
        industryTitle: 'Manufacturing, Transport & Logistics',
        roleTitle: 'Operations Supervisor',
        rank: 14,
        avgSalary: 88878,
        avgSalaryChange: 2,
      },
      {
        industryId: 6092,
        industryTitle: 'Manufacturing, Transport & Logistics',
        roleTitle: 'Procurement Officer',
        rank: 15,
        avgSalary: 88795,
        avgSalaryChange: 5,
      },
      {
        industryId: 6092,
        industryTitle: 'Manufacturing, Transport & Logistics',
        roleTitle: 'Warehouse Manager',
        rank: 16,
        avgSalary: 87907,
        avgSalaryChange: 2,
      },
      {
        industryId: 6092,
        industryTitle: 'Manufacturing, Transport & Logistics',
        roleTitle: 'Production Supervisor',
        rank: 17,
        avgSalary: 87846,
        avgSalaryChange: 5,
      },
      {
        industryId: 6092,
        industryTitle: 'Manufacturing, Transport & Logistics',
        roleTitle: 'Owner Driver',
        rank: 18,
        avgSalary: 87796,
        avgSalaryChange: 3,
      },
      {
        industryId: 6092,
        industryTitle: 'Manufacturing, Transport & Logistics',
        roleTitle: 'Mechanic',
        rank: 19,
        avgSalary: 87606,
        avgSalaryChange: -14,
      },
      {
        industryId: 6092,
        industryTitle: 'Manufacturing, Transport & Logistics',
        roleTitle: 'Scheduler',
        rank: 20,
        avgSalary: 87930,
        avgSalaryChange: 7,
      },
    ],
  },
  {
    industryIdFilter: 6123,
    roles: [
      {
        industryId: 6123,
        industryTitle: 'Education & Training',
        roleTitle: 'Academic',
        rank: 1,
        avgSalary: 117037,
        avgSalaryChange: 3,
      },
      {
        industryId: 6123,
        industryTitle: 'Education & Training',
        roleTitle: 'Lecturer',
        rank: 2,
        avgSalary: 116671,
        avgSalaryChange: 3,
      },
      {
        industryId: 6123,
        industryTitle: 'Education & Training',
        roleTitle: 'Operations Manager',
        rank: 3,
        avgSalary: 110761,
        avgSalaryChange: 2,
      },
      {
        industryId: 6123,
        industryTitle: 'Education & Training',
        roleTitle: 'Area Manager',
        rank: 4,
        avgSalary: 107121,
        avgSalaryChange: 6,
      },
      {
        industryId: 6123,
        industryTitle: 'Education & Training',
        roleTitle: 'Research Associate',
        rank: 5,
        avgSalary: 102493,
        avgSalaryChange: -1,
      },
      {
        industryId: 6123,
        industryTitle: 'Education & Training',
        roleTitle: 'Designer',
        rank: 6,
        avgSalary: 101972,
        avgSalaryChange: 1,
      },
      {
        industryId: 6123,
        industryTitle: 'Education & Training',
        roleTitle: 'Project Officer',
        rank: 7,
        avgSalary: 99449,
        avgSalaryChange: 1,
      },
      {
        industryId: 6123,
        industryTitle: 'Education & Training',
        roleTitle: 'Teacher',
        rank: 8,
        avgSalary: 98884,
        avgSalaryChange: 4,
      },
      {
        industryId: 6123,
        industryTitle: 'Education & Training',
        roleTitle: 'Centre Director',
        rank: 9,
        avgSalary: 95641,
        avgSalaryChange: 6,
      },
      {
        industryId: 6123,
        industryTitle: 'Education & Training',
        roleTitle: 'Trainer and Assessor',
        rank: 10,
        avgSalary: 94007,
        avgSalaryChange: 6,
      },
      {
        industryId: 6123,
        industryTitle: 'Education & Training',
        roleTitle: 'Librarian',
        rank: 11,
        avgSalary: 91141,
        avgSalaryChange: 4,
      },
      {
        industryId: 6123,
        industryTitle: 'Education & Training',
        roleTitle: 'Nominated Supervisor',
        rank: 12,
        avgSalary: 88251,
        avgSalaryChange: 7,
      },
      {
        industryId: 6123,
        industryTitle: 'Education & Training',
        roleTitle: 'Centre Manager',
        rank: 13,
        avgSalary: 87271,
        avgSalaryChange: 11,
      },
      {
        industryId: 6123,
        industryTitle: 'Education & Training',
        roleTitle: 'Early Childhood Teacher',
        rank: 14,
        avgSalary: 84959,
        avgSalaryChange: 7,
      },
      {
        industryId: 6123,
        industryTitle: 'Education & Training',
        roleTitle: 'Program Coordinator',
        rank: 15,
        avgSalary: 82825,
        avgSalaryChange: 6,
      },
      {
        industryId: 6123,
        industryTitle: 'Education & Training',
        roleTitle: 'Counsellor',
        rank: 16,
        avgSalary: 82768,
        avgSalaryChange: -6,
      },
      {
        industryId: 6123,
        industryTitle: 'Education & Training',
        roleTitle: 'Facilitator',
        rank: 17,
        avgSalary: 82537,
        avgSalaryChange: -1,
      },
      {
        industryId: 6123,
        industryTitle: 'Education & Training',
        roleTitle: 'Instructor',
        rank: 18,
        avgSalary: 81862,
        avgSalaryChange: -2,
      },
      {
        industryId: 6123,
        industryTitle: 'Education & Training',
        roleTitle: 'Compliance Officer',
        rank: 19,
        avgSalary: 81558,
        avgSalaryChange: 2,
      },
      {
        industryId: 6123,
        industryTitle: 'Education & Training',
        roleTitle: 'Assistant Director',
        rank: 20,
        avgSalary: 75680,
        avgSalaryChange: 5,
      },
    ],
  },
  {
    industryIdFilter: 6163,
    roles: [
      {
        industryId: 6163,
        industryTitle: 'Community Services & Development',
        roleTitle: 'Regional Manager',
        rank: 1,
        avgSalary: 118962,
        avgSalaryChange: 6,
      },
      {
        industryId: 6163,
        industryTitle: 'Community Services & Development',
        roleTitle: 'Operations Manager',
        rank: 2,
        avgSalary: 112431,
        avgSalaryChange: 1,
      },
      {
        industryId: 6163,
        industryTitle: 'Community Services & Development',
        roleTitle: 'Program Manager',
        rank: 3,
        avgSalary: 110278,
        avgSalaryChange: 6,
      },
      {
        industryId: 6163,
        industryTitle: 'Community Services & Development',
        roleTitle: 'Service Manager',
        rank: 4,
        avgSalary: 104415,
        avgSalaryChange: 1,
      },
      {
        industryId: 6163,
        industryTitle: 'Community Services & Development',
        roleTitle: 'Business Development Manager',
        rank: 5,
        avgSalary: 103848,
        avgSalaryChange: 6,
      },
      {
        industryId: 6163,
        industryTitle: 'Community Services & Development',
        roleTitle: 'Practice Leader',
        rank: 6,
        avgSalary: 102201,
        avgSalaryChange: 1,
      },
      {
        industryId: 6163,
        industryTitle: 'Community Services & Development',
        roleTitle: 'Child Protection Practitioner',
        rank: 7,
        avgSalary: 100916,
        avgSalaryChange: -3,
      },
      {
        industryId: 6163,
        industryTitle: 'Community Services & Development',
        roleTitle: 'Clinician',
        rank: 8,
        avgSalary: 97748,
        avgSalaryChange: 9,
      },
      {
        industryId: 6163,
        industryTitle: 'Community Services & Development',
        roleTitle: 'Family Violence Practitioner',
        rank: 9,
        avgSalary: 96369,
        avgSalaryChange: 6,
      },
      {
        industryId: 6163,
        industryTitle: 'Community Services & Development',
        roleTitle: 'Service Leader',
        rank: 10,
        avgSalary: 94934,
        avgSalaryChange: 5,
      },
      {
        industryId: 6163,
        industryTitle: 'Community Services & Development',
        roleTitle: 'Relationship Manager',
        rank: 11,
        avgSalary: 94098,
        avgSalaryChange: -6,
      },
      {
        industryId: 6163,
        industryTitle: 'Community Services & Development',
        roleTitle: 'Care Manager',
        rank: 12,
        avgSalary: 92856,
        avgSalaryChange: 5,
      },
      {
        industryId: 6163,
        industryTitle: 'Community Services & Development',
        roleTitle: 'Counsellor',
        rank: 13,
        avgSalary: 92161,
        avgSalaryChange: 6,
      },
      {
        industryId: 6163,
        industryTitle: 'Community Services & Development',
        roleTitle: 'Team Leader',
        rank: 14,
        avgSalary: 92121,
        avgSalaryChange: 6,
      },
      {
        industryId: 6163,
        industryTitle: 'Community Services & Development',
        roleTitle: 'Social Worker',
        rank: 15,
        avgSalary: 91954,
        avgSalaryChange: 5,
      },
      {
        industryId: 6163,
        industryTitle: 'Community Services & Development',
        roleTitle: 'Support Practitioner',
        rank: 16,
        avgSalary: 91770,
        avgSalaryChange: 3,
      },
      {
        industryId: 6163,
        industryTitle: 'Community Services & Development',
        roleTitle: 'House Manager',
        rank: 17,
        avgSalary: 90685,
        avgSalaryChange: 3,
      },
      {
        industryId: 6163,
        industryTitle: 'Community Services & Development',
        roleTitle: 'Coordinator',
        rank: 18,
        avgSalary: 90586,
        avgSalaryChange: 4,
      },
      {
        industryId: 6163,
        industryTitle: 'Community Services & Development',
        roleTitle: 'Planner',
        rank: 19,
        avgSalary: 90253,
        avgSalaryChange: 5,
      },
      {
        country: 'Australia',
        titlePreDates: 'Apr 2023 - Jun 2024',
        titlePostDates: 'Apr 2024 - Jun 2025',
        industryId: 6163,
        industryTitle: 'Community Services & Development',
        roleTitle: 'Project Officer',
        rank: 20,
        avgSalary: 89446,
        avgSalaryChange: 1,
      },
    ],
  },
  {
    industryIdFilter: 6205,
    roles: [
      {
        industryId: 6205,
        industryTitle: 'Farming, Animals & Conservation',
        roleTitle: 'Veterinarian',
        rank: 1,
        avgSalary: 109268,
        avgSalaryChange: 4,
      },
      {
        industryId: 6205,
        industryTitle: 'Farming, Animals & Conservation',
        roleTitle: 'Agronomist',
        rank: 2,
        avgSalary: 93210,
        avgSalaryChange: 3,
      },
      {
        industryId: 6205,
        industryTitle: 'Farming, Animals & Conservation',
        roleTitle: 'Ranger',
        rank: 3,
        avgSalary: 79193,
        avgSalaryChange: 10,
      },
      {
        industryId: 6205,
        industryTitle: 'Farming, Animals & Conservation',
        roleTitle: 'Leading Hand',
        rank: 4,
        avgSalary: 73257,
        avgSalaryChange: 6,
      },
      {
        industryId: 6205,
        industryTitle: 'Farming, Animals & Conservation',
        roleTitle: 'Machine Operator',
        rank: 5,
        avgSalary: 66199,
        avgSalaryChange: -5,
      },
      {
        industryId: 6205,
        industryTitle: 'Farming, Animals & Conservation',
        roleTitle: 'Farmhand',
        rank: 6,
        avgSalary: 64106,
        avgSalaryChange: 6,
      },
      {
        industryId: 6205,
        industryTitle: 'Farming, Animals & Conservation',
        roleTitle: 'Nurse',
        rank: 7,
        avgSalary: 61915,
        avgSalaryChange: 6,
      },
      {
        industryId: 6205,
        industryTitle: 'Farming, Animals & Conservation',
        roleTitle: 'Station Hand',
        rank: 8,
        avgSalary: 61691,
        avgSalaryChange: -3,
      },
      {
        industryId: 6205,
        industryTitle: 'Farming, Animals & Conservation',
        roleTitle: 'Groomer',
        rank: 9,
        avgSalary: 61410,
        avgSalaryChange: 3,
      },
      {
        industryId: 6205,
        industryTitle: 'Farming, Animals & Conservation',
        roleTitle: 'Attendant',
        rank: 10,
        avgSalary: 60419,
        avgSalaryChange: 4,
      },
      {
        industryId: 6205,
        industryTitle: 'Farming, Animals & Conservation',
        roleTitle: 'Animal Attendant',
        rank: 11,
        avgSalary: 55121,
        avgSalaryChange: 6,
      },
    ],
  },
  {
    industryIdFilter: 6246,
    roles: [
      {
        industryId: 6246,
        industryTitle: 'Sport & Recreation',
        roleTitle: 'Operations Manager',
        rank: 1,
        avgSalary: 89672,
        avgSalaryChange: 13,
      },
      {
        industryId: 6246,
        industryTitle: 'Sport & Recreation',
        roleTitle: 'Centre Manager',
        rank: 2,
        avgSalary: 87006,
        avgSalaryChange: 10,
      },
      {
        industryId: 6246,
        industryTitle: 'Sport & Recreation',
        roleTitle: 'General Manager',
        rank: 3,
        avgSalary: 86548,
        avgSalaryChange: -6,
      },
      {
        industryId: 6246,
        industryTitle: 'Sport & Recreation',
        roleTitle: 'Trainer',
        rank: 4,
        avgSalary: 83618,
        avgSalaryChange: 14,
      },
      {
        industryId: 6246,
        industryTitle: 'Sport & Recreation',
        roleTitle: 'Instructor',
        rank: 5,
        avgSalary: 75720,
        avgSalaryChange: 4,
      },
      {
        industryId: 6246,
        industryTitle: 'Sport & Recreation',
        roleTitle: 'Club Manager',
        rank: 6,
        avgSalary: 71095,
        avgSalaryChange: 8,
      },
      {
        industryId: 6246,
        industryTitle: 'Sport & Recreation',
        roleTitle: 'Coach',
        rank: 7,
        avgSalary: 70876,
        avgSalaryChange: 4,
      },
      {
        industryId: 6246,
        industryTitle: 'Sport & Recreation',
        roleTitle: 'Duty Manager',
        rank: 8,
        avgSalary: 67668,
        avgSalaryChange: -2,
      },
      {
        industryId: 6246,
        industryTitle: 'Sport & Recreation',
        roleTitle: 'Lifeguard',
        rank: 9,
        avgSalary: 63963,
        avgSalaryChange: 10,
      },
      {
        industryId: 6246,
        industryTitle: 'Sport & Recreation',
        roleTitle: 'Teacher',
        rank: 10,
        avgSalary: 61726,
        avgSalaryChange: 5,
      },
    ],
  },
  {
    industryIdFilter: 6251,
    roles: [
      {
        industryId: 6251,
        industryTitle: 'Administration & Office Support',
        roleTitle: 'Document Controller',
        rank: 1,
        avgSalary: 97405,
        avgSalaryChange: 2,
      },
      {
        industryId: 6251,
        industryTitle: 'Administration & Office Support',
        roleTitle: 'Operations Manager',
        rank: 2,
        avgSalary: 97080,
        avgSalaryChange: 5,
      },
      {
        industryId: 6251,
        industryTitle: 'Administration & Office Support',
        roleTitle: 'Executive Assistant',
        rank: 3,
        avgSalary: 95159,
        avgSalaryChange: 6,
      },
      {
        industryId: 6251,
        industryTitle: 'Administration & Office Support',
        roleTitle: 'Service Manager',
        rank: 4,
        avgSalary: 90721,
        avgSalaryChange: -6,
      },
      {
        industryId: 6251,
        industryTitle: 'Administration & Office Support',
        roleTitle: 'Project Officer',
        rank: 5,
        avgSalary: 89473,
        avgSalaryChange: 1,
      },
      {
        industryId: 6251,
        industryTitle: 'Administration & Office Support',
        roleTitle: 'Practice Manager',
        rank: 6,
        avgSalary: 84949,
        avgSalaryChange: -5,
      },
      {
        industryId: 6251,
        industryTitle: 'Administration & Office Support',
        roleTitle: 'Site Administrator',
        rank: 7,
        avgSalary: 83244,
        avgSalaryChange: 6,
      },
      {
        industryId: 6251,
        industryTitle: 'Administration & Office Support',
        roleTitle: 'Operations Officer',
        rank: 8,
        avgSalary: 82139,
        avgSalaryChange: 16,
      },
      {
        industryId: 6251,
        industryTitle: 'Administration & Office Support',
        roleTitle: 'Office Manager',
        rank: 9,
        avgSalary: 81610,
        avgSalaryChange: 5,
      },
      {
        industryId: 6251,
        industryTitle: 'Administration & Office Support',
        roleTitle: 'Administration Manager',
        rank: 10,
        avgSalary: 80236,
        avgSalaryChange: 2,
      },
      {
        industryId: 6251,
        industryTitle: 'Administration & Office Support',
        roleTitle: 'Administration Team Leader',
        rank: 11,
        avgSalary: 79960,
        avgSalaryChange: 7,
      },
      {
        industryId: 6251,
        industryTitle: 'Administration & Office Support',
        roleTitle: 'Team Assistant',
        rank: 12,
        avgSalary: 79154,
        avgSalaryChange: 4,
      },
      {
        industryId: 6251,
        industryTitle: 'Administration & Office Support',
        roleTitle: 'Project Coordinator',
        rank: 13,
        avgSalary: 78420,
        avgSalaryChange: -4,
      },
      {
        industryId: 6251,
        industryTitle: 'Administration & Office Support',
        roleTitle: 'Compliance Officer',
        rank: 14,
        avgSalary: 77906,
        avgSalaryChange: 4,
      },
      {
        industryId: 6251,
        industryTitle: 'Administration & Office Support',
        roleTitle: 'Management Officer',
        rank: 15,
        avgSalary: 77890,
        avgSalaryChange: 1,
      },
      {
        industryId: 6251,
        industryTitle: 'Administration & Office Support',
        roleTitle: 'Project Administrator',
        rank: 16,
        avgSalary: 77449,
        avgSalaryChange: 4,
      },
      {
        industryId: 6251,
        industryTitle: 'Administration & Office Support',
        roleTitle: 'Personal Assistant',
        rank: 17,
        avgSalary: 77203,
        avgSalaryChange: 1,
      },
      {
        industryId: 6251,
        industryTitle: 'Administration & Office Support',
        roleTitle: 'Team Coordinator',
        rank: 18,
        avgSalary: 77006,
        avgSalaryChange: 1,
      },
      {
        industryId: 6251,
        industryTitle: 'Administration & Office Support',
        roleTitle: 'Secretary',
        rank: 19,
        avgSalary: 76305,
        avgSalaryChange: 12,
      },
      {
        industryId: 6251,
        industryTitle: 'Administration & Office Support',
        roleTitle: 'Liaison Officer',
        rank: 20,
        avgSalary: 74177,
        avgSalaryChange: 10,
      },
    ],
  },
  {
    industryIdFilter: 6263,
    roles: [
      {
        industryId: 6263,
        industryTitle: 'Design & Architecture',
        roleTitle: 'Design Manager',
        rank: 1,
        avgSalary: 137820,
        avgSalaryChange: -4,
      },
      {
        industryId: 6263,
        industryTitle: 'Design & Architecture',
        roleTitle: 'Project Architect',
        rank: 2,
        avgSalary: 108836,
        avgSalaryChange: 6,
      },
      {
        industryId: 6263,
        industryTitle: 'Design & Architecture',
        roleTitle: 'Architect',
        rank: 3,
        avgSalary: 100249,
        avgSalaryChange: 2,
      },
      {
        industryId: 6263,
        industryTitle: 'Design & Architecture',
        roleTitle: 'Planner',
        rank: 4,
        avgSalary: 101162,
        avgSalaryChange: 2,
      },
      {
        industryId: 6263,
        industryTitle: 'Design & Architecture',
        roleTitle: 'Landscape Architect',
        rank: 5,
        avgSalary: 93735,
        avgSalaryChange: 3,
      },
      {
        industryId: 6263,
        industryTitle: 'Design & Architecture',
        roleTitle: 'Designer',
        rank: 6,
        avgSalary: 84773,
        avgSalaryChange: 1,
      },
      {
        industryId: 6263,
        industryTitle: 'Design & Architecture',
        roleTitle: 'Draftsperson',
        rank: 7,
        avgSalary: 82004,
        avgSalaryChange: 2,
      },
      {
        industryId: 6263,
        industryTitle: 'Design & Architecture',
        roleTitle: 'Graduate of Architecture',
        rank: 8,
        avgSalary: 76694,
        avgSalaryChange: 4,
      },
    ],
  },
  {
    industryIdFilter: 6281,
    roles: [
      {
        industryId: 6281,
        industryTitle: 'Information & Communication Technology',
        roleTitle: 'Engineering Manager',
        rank: 1,
        avgSalary: 181783,
        avgSalaryChange: 0,
      },
      {
        industryId: 6281,
        industryTitle: 'Information & Communication Technology',
        roleTitle: 'Engineering Lead',
        rank: 2,
        avgSalary: 171441,
        avgSalaryChange: 6,
      },
      {
        industryId: 6281,
        industryTitle: 'Information & Communication Technology',
        roleTitle: 'Functional Consultant',
        rank: 3,
        avgSalary: 155266,
        avgSalaryChange: 0,
      },
      {
        industryId: 6281,
        industryTitle: 'Information & Communication Technology',
        roleTitle: 'Delivery Lead',
        rank: 4,
        avgSalary: 154881,
        avgSalaryChange: 3,
      },
      {
        industryId: 6281,
        industryTitle: 'Information & Communication Technology',
        roleTitle: 'Site Reliability Engineer',
        rank: 5,
        avgSalary: 146532,
        avgSalaryChange: -3,
      },
      {
        industryId: 6281,
        industryTitle: 'Information & Communication Technology',
        roleTitle: 'Cloud Engineer',
        rank: 6,
        avgSalary: 142887,
        avgSalaryChange: -1,
      },
      {
        industryId: 6281,
        industryTitle: 'Information & Communication Technology',
        roleTitle: 'Technical Consultant',
        rank: 7,
        avgSalary: 138345,
        avgSalaryChange: 5,
      },
      {
        industryId: 6281,
        industryTitle: 'Information & Communication Technology',
        roleTitle: 'Automation Engineer',
        rank: 8,
        avgSalary: 136670,
        avgSalaryChange: 13,
      },
      {
        industryId: 6281,
        industryTitle: 'Information & Communication Technology',
        roleTitle: 'Infrastructure Engineer',
        rank: 9,
        avgSalary: 130462,
        avgSalaryChange: 3,
      },
      {
        industryId: 6281,
        industryTitle: 'Information & Communication Technology',
        roleTitle: 'Business Intelligence Software Developer',
        rank: 10,
        avgSalary: 127184,
        avgSalaryChange: 0,
      },
      {
        industryId: 6281,
        industryTitle: 'Information & Communication Technology',
        roleTitle: 'Support Manager',
        rank: 11,
        avgSalary: 125907,
        avgSalaryChange: -1,
      },
      {
        industryId: 6281,
        industryTitle: 'Information & Communication Technology',
        roleTitle: 'Business Intelligence Analyst',
        rank: 12,
        avgSalary: 121473,
        avgSalaryChange: 8,
      },
      {
        industryId: 6281,
        industryTitle: 'Information & Communication Technology',
        roleTitle: 'Application Developer',
        rank: 13,
        avgSalary: 116191,
        avgSalaryChange: -3,
      },
      {
        industryId: 6281,
        industryTitle: 'Information & Communication Technology',
        roleTitle: 'Project Engineer',
        rank: 14,
        avgSalary: 116000,
        avgSalaryChange: 1,
      },
      {
        industryId: 6281,
        industryTitle: 'Information & Communication Technology',
        roleTitle: 'Project Officer',
        rank: 15,
        avgSalary: 111073,
        avgSalaryChange: 12,
      },
      {
        industryId: 6281,
        industryTitle: 'Information & Communication Technology',
        roleTitle: 'Systems Analyst',
        rank: 16,
        avgSalary: 108572,
        avgSalaryChange: -4,
      },
      {
        industryId: 6281,
        industryTitle: 'Information & Communication Technology',
        roleTitle: 'Data Analyst',
        rank: 17,
        avgSalary: 105312,
        avgSalaryChange: -1,
      },
      {
        industryId: 6281,
        industryTitle: 'Information & Communication Technology',
        roleTitle: 'Systems Administrator',
        rank: 18,
        avgSalary: 105008,
        avgSalaryChange: 1,
      },
      {
        industryId: 6281,
        industryTitle: 'Information & Communication Technology',
        roleTitle: 'Reporting Analyst',
        rank: 19,
        avgSalary: 104640,
        avgSalaryChange: 2,
      },
      {
        industryId: 6281,
        industryTitle: 'Information & Communication Technology',
        roleTitle: 'Insights Analyst',
        rank: 20,
        avgSalary: 103060,
        avgSalaryChange: -10,
      },
    ],
  },
  {
    industryIdFilter: 6304,
    roles: [
      {
        industryId: 6304,
        industryTitle: 'Advertising, Arts & Media',
        roleTitle: 'Account Manager',
        rank: 1,
        avgSalary: 88740,
        avgSalaryChange: 6,
      },
      {
        industryId: 6304,
        industryTitle: 'Advertising, Arts & Media',
        roleTitle: 'Producer',
        rank: 2,
        avgSalary: 85626,
        avgSalaryChange: 6,
      },
      {
        industryId: 6304,
        industryTitle: 'Advertising, Arts & Media',
        roleTitle: 'Reporter',
        rank: 3,
        avgSalary: 80821,
        avgSalaryChange: 12,
      },
      {
        industryId: 6304,
        industryTitle: 'Advertising, Arts & Media',
        roleTitle: 'Photographer',
        rank: 4,
        avgSalary: 66629,
        avgSalaryChange: 8,
      },
      {
        industryId: 6304,
        industryTitle: 'Advertising, Arts & Media',
        roleTitle: 'Journalist',
        rank: 5,
        avgSalary: 66363,
        avgSalaryChange: 5,
      },
    ],
  },
  {
    industryIdFilter: 6317,
    roles: [
      {
        industryId: 6317,
        industryTitle: 'Human Resources & Recruitment',
        roleTitle: 'Acquisition Manager',
        rank: 1,
        avgSalary: 145901,
        avgSalaryChange: 12,
      },
      {
        industryId: 6317,
        industryTitle: 'Human Resources & Recruitment',
        roleTitle: 'Work Health and Safety Manager',
        rank: 2,
        avgSalary: 127680,
        avgSalaryChange: -1,
      },
      {
        industryId: 6317,
        industryTitle: 'Human Resources & Recruitment',
        roleTitle: 'Human Resources Business Partner',
        rank: 3,
        avgSalary: 121937,
        avgSalaryChange: 0,
      },
      {
        industryId: 6317,
        industryTitle: 'Human Resources & Recruitment',
        roleTitle: 'Workplace Relations Officer',
        rank: 4,
        avgSalary: 121202,
        avgSalaryChange: 2,
      },
      {
        industryId: 6317,
        industryTitle: 'Human Resources & Recruitment',
        roleTitle: 'Health, Safety and Environment Adviser',
        rank: 5,
        avgSalary: 118424,
        avgSalaryChange: 9,
      },
      {
        industryId: 6317,
        industryTitle: 'Human Resources & Recruitment',
        roleTitle: 'Partner',
        rank: 6,
        avgSalary: 116993,
        avgSalaryChange: 4,
      },
      {
        industryId: 6317,
        industryTitle: 'Human Resources & Recruitment',
        roleTitle: 'Human Resources Consultant',
        rank: 7,
        avgSalary: 106967,
        avgSalaryChange: 7,
      },
      {
        industryId: 6317,
        industryTitle: 'Human Resources & Recruitment',
        roleTitle: 'Occupational Health and Safety Adviser',
        rank: 8,
        avgSalary: 105269,
        avgSalaryChange: -1,
      },
      {
        industryId: 6317,
        industryTitle: 'Human Resources & Recruitment',
        roleTitle: 'People and Culture Adviser',
        rank: 9,
        avgSalary: 99223,
        avgSalaryChange: 6,
      },
      {
        industryId: 6317,
        industryTitle: 'Human Resources & Recruitment',
        roleTitle: 'Human Resources Adviser',
        rank: 10,
        avgSalary: 98992,
        avgSalaryChange: 1,
      },
      {
        industryId: 6317,
        industryTitle: 'Human Resources & Recruitment',
        roleTitle: 'Human Resources Generalist',
        rank: 11,
        avgSalary: 93655,
        avgSalaryChange: -1,
      },
      {
        industryId: 6317,
        industryTitle: 'Human Resources & Recruitment',
        roleTitle: 'Coordinator',
        rank: 12,
        avgSalary: 92468,
        avgSalaryChange: -2,
      },
      {
        industryId: 6317,
        industryTitle: 'Human Resources & Recruitment',
        roleTitle: 'Acquisition Specialist',
        rank: 13,
        avgSalary: 91912,
        avgSalaryChange: -4,
      },
      {
        industryId: 6317,
        industryTitle: 'Human Resources & Recruitment',
        roleTitle: 'Talent Acquisition Adviser',
        rank: 14,
        avgSalary: 91755,
        avgSalaryChange: 4,
      },
      {
        industryId: 6317,
        industryTitle: 'Human Resources & Recruitment',
        roleTitle: 'Work Health and Safety Coordinator',
        rank: 15,
        avgSalary: 91248,
        avgSalaryChange: 5,
      },
      {
        industryId: 6317,
        industryTitle: 'Human Resources & Recruitment',
        roleTitle: 'Training Coordinator',
        rank: 16,
        avgSalary: 88344,
        avgSalaryChange: 2,
      },
      {
        industryId: 6317,
        industryTitle: 'Human Resources & Recruitment',
        roleTitle: 'Account Manager',
        rank: 17,
        avgSalary: 87168,
        avgSalaryChange: -2,
      },
      {
        industryId: 6317,
        industryTitle: 'Human Resources & Recruitment',
        roleTitle: 'Human Resources Officer',
        rank: 18,
        avgSalary: 84484,
        avgSalaryChange: 10,
      },
      {
        industryId: 6317,
        industryTitle: 'Human Resources & Recruitment',
        roleTitle: 'Recruitment Consultant',
        rank: 19,
        avgSalary: 80102,
        avgSalaryChange: 1,
      },
      {
        industryId: 6317,
        industryTitle: 'Human Resources & Recruitment',
        roleTitle: 'People and Culture Officer',
        rank: 20,
        avgSalary: 79003,
        avgSalaryChange: 5,
      },
    ],
  },
  {
    industryIdFilter: 6362,
    roles: [
      {
        industryId: 6362,
        industryTitle: 'Sales',
        roleTitle: 'Sales Engineer',
        rank: 1,
        avgSalary: 103683,
        avgSalaryChange: 5,
      },
      {
        industryId: 6362,
        industryTitle: 'Sales',
        roleTitle: 'Sales and Business Development Manager',
        rank: 2,
        avgSalary: 100725,
        avgSalaryChange: 2,
      },
      {
        industryId: 6362,
        industryTitle: 'Sales',
        roleTitle: 'Relationship Manager',
        rank: 3,
        avgSalary: 96889,
        avgSalaryChange: 0,
      },
      {
        industryId: 6362,
        industryTitle: 'Sales',
        roleTitle: 'Account Executive',
        rank: 4,
        avgSalary: 94674,
        avgSalaryChange: -5,
      },
      {
        industryId: 6362,
        industryTitle: 'Sales',
        roleTitle: 'Success Manager',
        rank: 5,
        avgSalary: 93771,
        avgSalaryChange: -1,
      },
      {
        industryId: 6362,
        industryTitle: 'Sales',
        roleTitle: 'Area Manager',
        rank: 6,
        avgSalary: 93218,
        avgSalaryChange: 7,
      },
      {
        industryId: 6362,
        industryTitle: 'Sales',
        roleTitle: 'Business Development Executive',
        rank: 7,
        avgSalary: 90609,
        avgSalaryChange: 8,
      },
      {
        industryId: 6362,
        industryTitle: 'Sales',
        roleTitle: 'Sales Team Leader',
        rank: 8,
        avgSalary: 89984,
        avgSalaryChange: 5,
      },
      {
        industryId: 6362,
        industryTitle: 'Sales',
        roleTitle: 'Territory Manager',
        rank: 9,
        avgSalary: 85762,
        avgSalaryChange: 1,
      },
      {
        industryId: 6362,
        industryTitle: 'Sales',
        roleTitle: 'Business Development Representative',
        rank: 10,
        avgSalary: 85511,
        avgSalaryChange: 11,
      },
      {
        industryId: 6362,
        industryTitle: 'Sales',
        roleTitle: 'Sales Executive',
        rank: 11,
        avgSalary: 84780,
        avgSalaryChange: -2,
      },
      {
        industryId: 6362,
        industryTitle: 'Sales',
        roleTitle: 'Sales Representative',
        rank: 12,
        avgSalary: 77630,
        avgSalaryChange: 3,
      },
      {
        industryId: 6362,
        industryTitle: 'Sales',
        roleTitle: 'Recruitment Consultant',
        rank: 13,
        avgSalary: 73726,
        avgSalaryChange: 1,
      },
      {
        industryId: 6362,
        industryTitle: 'Sales',
        roleTitle: 'Customer Service and Sales Consultant',
        rank: 14,
        avgSalary: 70451,
        avgSalaryChange: 14,
      },
      {
        industryId: 6362,
        industryTitle: 'Sales',
        roleTitle: 'Sales Coordinator',
        rank: 15,
        avgSalary: 70207,
        avgSalaryChange: 4,
      },
      {
        industryId: 6362,
        industryTitle: 'Sales',
        roleTitle: 'Support Officer',
        rank: 16,
        avgSalary: 68624,
        avgSalaryChange: 6,
      },
      {
        industryId: 6362,
        industryTitle: 'Sales',
        roleTitle: 'Interpreter',
        rank: 17,
        avgSalary: 66511,
        avgSalaryChange: 7,
      },
      {
        industryId: 6362,
        industryTitle: 'Sales',
        roleTitle: 'Customer Service Representative',
        rank: 18,
        avgSalary: 65780,
        avgSalaryChange: 5,
      },
      {
        industryId: 6362,
        industryTitle: 'Sales',
        roleTitle: 'Cadet',
        rank: 19,
        avgSalary: 62985,
        avgSalaryChange: 7,
      },
      {
        industryId: 6362,
        industryTitle: 'Sales',
        roleTitle: 'Showroom Consultant',
        rank: 20,
        avgSalary: 62350,
        avgSalaryChange: -2,
      },
    ],
  },
];
