import { attributesToProps } from 'html-react-parser';

export const attributeMap: Record<string, string> = {
  boxshadow: 'boxShadow',
  borderradius: 'borderRadius',
  collapsebelow: 'collapseBelow',
  aligny: 'alignY',
  expandlabel: 'expandLabel',
  collapselabel: 'collapseLabel',
  justifycontent: 'justifyContent',
  alignitems: 'alignItems',
  textAlign: 'textAlign',
  flexdirection: 'flexDirection',
  flexgrow: 'flexGrow',
  flexshrink: 'flexShrink',
  flexwrap: 'flexWrap',
  marginleft: 'marginLeft',
  marginright: 'marginRight',
  margintop: 'marginTop',
  marginbottom: 'marginBottom',
  minwidth: 'minWidth',
  maxwidth: 'maxWidth',
  paddingleft: 'paddingLeft',
  paddingright: 'paddingRight',
  paddingTop: 'paddingTop',
  paddingBottom: 'paddingBottom',
  paddingy: 'paddingY',
  paddingx: 'paddingX',
  marginy: 'marginY',
  marginx: 'marginX',
  hreflang: 'hrefLang',
  defaultchecked: 'defaultChecked',
  defaultvalue: 'defaultValue',
  rowspan: 'rowSpan',
};

export const getAttribs = (attribs: Record<string, string>) => {
  const newAttribs: Record<string, string> = {};

  Object.keys(attribs).forEach((key) => {
    if (attributeMap[key]) {
      newAttribs[attributeMap[key]] = attribs[key];
    } else {
      newAttribs[key] = attribs[key];
    }
  });

  const props = attributesToProps(newAttribs);

  for (const [key, value] of Object.entries(newAttribs)) {
    if (value === 'true') {
      props[key] = true;
    } else if (value === 'false') {
      props[key] = false;
    }
  }

  return props;
};
