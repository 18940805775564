import {
  GetFilterFieldsDocument,
  type GetFilterFieldsQuery,
  GetSurveyDocument,
  type GetSurveyQuery,
} from './gql/tal/generated';
import { internalError } from './responses';
import type { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import {
  datalabHelper,
  type DatalabDriverData,
  type DatalabSurvey,
} from '@seek/cmsu-components/src/modules/Talent-Attraction-Lab/datalabHelper';
import type { Locale } from '@seek/melways-sites';
import { datalabMapper } from '@seek/cmsu-components/src/modules/Talent-Attraction-Lab/datalabMapper';

export interface DatalabLoaderProps {
  clientTAL: ApolloClient<NormalizedCacheObject>;
  locale: Locale;
  request: Request;
  requireSurveyResult?: boolean; // Add control to query for survey results when required
}

export type QueryFilter = {
  driverType: string;
  driverValues: string[];
  drilldownType?: string;
  drilldownValues: string[];
};

const getQueryFilters = async (
  clientTAL: ApolloClient<NormalizedCacheObject>,
  locale: string,
): Promise<GetFilterFieldsQuery> => {
  const { data, error } = await clientTAL.query<GetFilterFieldsQuery>({
    query: GetFilterFieldsDocument,
    variables: { locale },
    fetchPolicy: 'cache-first',
  });

  if (error) {
    throw internalError(error);
  }

  return data;
};

const getSurveyResult = async (
  clientTAL: ApolloClient<NormalizedCacheObject>,
  queryFilter: QueryFilter,
  locale: string,
): Promise<GetSurveyQuery> => {
  const drilldown =
    queryFilter.drilldownType && queryFilter.drilldownValues.length > 0
      ? {
          drilldown: {
            type: queryFilter.drilldownType?.toUpperCase().replaceAll(' ', '_'),
            value: queryFilter.drilldownValues,
          },
        }
      : undefined;

  const variables = {
    locale,
    driver: {
      type: queryFilter.driverType.toUpperCase().replaceAll(' ', '_'),
      value: queryFilter.driverValues,
    },
    ...drilldown,
  };

  const { data, error } = await clientTAL.query<GetSurveyQuery>({
    query: GetSurveyDocument,
    variables,
    fetchPolicy: 'no-cache',
  });

  if (error) {
    throw internalError(error);
  }

  return data;
};

export const loadDatalabData = async ({
  clientTAL,
  locale,
  request,
  requireSurveyResult = true, // Default to true to return all data
}: DatalabLoaderProps): Promise<DatalabSurvey> => {
  const searchParams = new URLSearchParams(
    request.url.substring(request.url.indexOf('?')),
  );
  const queryFilterParams = datalabHelper.getQueryFilterParams(searchParams);

  const surveyFilter: QueryFilter = {
    driverType: queryFilterParams.primaryDriver,
    driverValues: queryFilterParams.primaryDriverOptions || [],
    drilldownType: queryFilterParams.secondaryDriver,
    drilldownValues: queryFilterParams.secondaryDriverOptions || [],
  };

  const queryFilters: GetFilterFieldsQuery = await getQueryFilters(
    clientTAL,
    locale,
  );
  const surveyResults: GetSurveyQuery | null = requireSurveyResult
    ? await getSurveyResult(clientTAL, surveyFilter, locale)
    : null;

  const drivers: DatalabDriverData | null = surveyResults
    ? datalabMapper.mapToDatalabDriverData(surveyResults)
    : null;

  return {
    queryFilters,
    surveyResults,
    drivers,
    queryFilterParams,
  } as DatalabSurvey;
};
