export type CountryCode = 'au' | 'nz';
export type Generations = 'boomer' | 'genx' | 'geny' | 'genz';

export const genData = {
  au: {
    summary: {
      title: 'Australians',
      data: [
        // These are visual approximations, but who's counting
        { driver: 'Purpose at work', value: 92 },
        { driver: 'Their manager', value: 79 },
        { driver: 'Their day-to-day role responsibilities', value: 74 },
        { driver: 'Their company culture', value: 65 },
        { driver: 'Stress levels', value: 63 },
        { driver: 'Senior leadership', value: 62 },
        { driver: 'Workload demands', value: 60 },
        { driver: 'Work-life balance', value: 59 },
        { driver: 'Salary', value: 44 },
        { driver: 'Team / colleagues', value: 43.5 },
        { driver: 'Company values', value: 41 },
        { driver: 'Flexible working arrangements', value: 40 },
        { driver: 'Location of work', value: 36 },
        { driver: 'Job security', value: 35.5 },
        { driver: 'Career progression opportunities', value: 35 },
        { driver: 'Company commitment to ESG', value: 32 },
        { driver: 'The success of their company', value: 31 },
      ],
    },
    generations: {
      boomer: {
        title: 'Baby Boomers',
        happiness: 61,
        data: [
          { driver: 'Purpose at work', value: 66 },
          { driver: 'Stress levels', value: 38 },
          { driver: 'Company values', value: 56 },
          { driver: 'Their manager', value: 58 },
          { driver: 'Their company culture', value: 48 },
          { driver: 'Team / colleagues', value: 61 },
          { driver: 'Workload demand', value: 50 },
          { driver: 'Salary', value: 38 },
          { driver: 'Their day-to-day responsibilites', value: 72 },
          { driver: 'The success of their company', value: 39 },
          { driver: 'Job security', value: 59 },
          { driver: 'Location of work', value: 77 },
          { driver: 'Senior leadership', value: 45 },
          { driver: 'Flexible working arrangements', value: 60 },
          { driver: 'Work-life balance', value: 71 },
          { driver: 'Company commitment to ESG', value: 34 },
          { driver: 'Career progression opportunities', value: 29 },
        ],
      },
      genx: {
        title: 'Gen X',
        happiness: 52,
        data: [
          { driver: 'Their manager', value: 47 },
          { driver: 'Purpose at work', value: 62 },
          { driver: 'Work-life balance', value: 59 },
          { driver: 'Their company culture', value: 52 },
          { driver: 'Senior leadership', value: 40 },
          { driver: 'Flexible working arrangments', value: 56 },
          { driver: 'Their day-to-day role responsibilities', value: 60 },
          { driver: 'Job security', value: 56 },
          { driver: 'Stress values', value: 38 },
          { driver: 'Company values', value: 48 },
          { driver: 'Location of work', value: 62 },
          { driver: 'Team / colleagues', value: 56 },
          { driver: 'Career progression opportunities', value: 33 },
          { driver: 'Workload demands', value: 43 },
          { driver: 'Salary', value: 38 },
          { driver: 'Company commitment to ESG', value: 31 },
          { driver: 'Success of their company', value: 46 },
        ],
      },
      geny: {
        title: 'Millennials',
        happiness: 58,
        data: [
          { driver: 'Stress levels', value: 45 },
          { driver: 'Workload demands', value: 50 },
          { driver: 'Day to day responsibilities', value: 61 },
          { driver: 'Their company culture', value: 56 },
          { driver: 'Senior leadership', value: 47 },
          { driver: 'Purpose at work', value: 60 },
          { driver: 'Their manager', value: 54 },
          { driver: 'Salary', value: 47 },
          { driver: 'Company values', value: 58 },
          { driver: 'Work-life balance', value: 62 },
          { driver: 'Flexible working arrangements', value: 60 },
          { driver: 'The success of their company', value: 52 },
          { driver: 'Team / colleagues', value: 58 },
          { driver: 'Location of work', value: 67 },
          { driver: 'Career progression opportunities', value: 43 },
          { driver: 'Job security', value: 60 },
          { driver: 'Company commitment to ESG', value: 38 },
        ],
      },
      genz: {
        title: 'Gen Z',
        happiness: 50,
        data: [
          { driver: 'Their day-to-day responsibilities', value: 54 },
          { driver: 'Senior leadership', value: 39 },
          { driver: 'Team and colleagues', value: 62 },
          { driver: 'Work-life balance', value: 57 },
          { driver: 'Career progression opportunities', value: 39 },
          { driver: 'Purpose at work', value: 46 },
          { driver: 'Their manager', value: 55 },
          { driver: 'Stress levels', value: 41 },
          { driver: 'Their company culture', value: 42 },
          { driver: 'Company commitment to ESG', value: 37 },
          { driver: 'Job security', value: 54 },
          { driver: 'Company values', value: 54 },
          { driver: 'Location of work', value: 60 },
          { driver: 'Workload demands', value: 44 },
          { driver: 'Flexible working arrangements', value: 53 },
          { driver: 'Salary', value: 41 },
          { driver: 'Success of their company', value: 50 },
        ],
      },
    },
  },
  nz: {
    summary: {
      title: 'New Zealanders',
      data: [
        // These are visual approximations, but who's counting
        { driver: 'Purpose at work', value: 96 },
        { driver: 'Their day-to-day role responsibilities', value: 95.5 },
        { driver: 'Their company culture', value: 85.5 },
        { driver: 'Salary', value: 73 },
        { driver: 'Stress levels', value: 68.5 },
        { driver: 'Senior leadership', value: 67.5 },
        { driver: 'Workload demands', value: 61.5 },
        { driver: 'Work-life balance', value: 60 },
        { driver: 'Their manager', value: 53.5 },
        { driver: 'Job security', value: 50 },
        { driver: 'Career progression opportunities', value: 50 },
        { driver: 'Company values', value: 45.5 },
        { driver: 'Flexible working arrangements', value: 44.5 },
        { driver: 'Location of work', value: 43.5 },
        { driver: 'Team / colleagues', value: 42.5 },
        { driver: 'Company commitment to ESG', value: 40.5 },
        { driver: 'The success of their company', value: 37.5 },
      ],
    },
    generations: {
      boomer: {
        title: 'Baby Boomers',
        happiness: -1,
        data: [
          { driver: 'Purpose at work', value: 71 },
          { driver: 'Their day-to-day role responsibilities', value: 73 },
          { driver: 'Their company culture', value: 58 },
          { driver: 'Salary', value: 46 },
          { driver: 'Stress levels', value: 56 },
          { driver: 'Senior leadership', value: 57 },
          { driver: 'Workload demands', value: 56 },
          { driver: 'Work-life balance', value: 74 },
          { driver: 'Their manager', value: 62 },
          { driver: 'Job security', value: 65 },
          { driver: 'Career progression opportunities', value: 31 },
          { driver: 'Company values', value: 63 },
          { driver: 'Flexible working arrangements', value: 67 },
          { driver: 'Location of work', value: 81 },
          { driver: 'Team / colleagues', value: 74 },
          { driver: 'Company commitment to ESG', value: 39 },
          { driver: 'The success of their company', value: 52 },
        ],
      },
      genx: {
        title: 'Gen X',
        happiness: -1,
        data: [
          { driver: 'Purpose at work', value: 75 },
          { driver: 'Their day-to-day role responsibilities', value: 70 },
          { driver: 'Their company culture', value: 63 },
          { driver: 'Salary', value: 42 },
          { driver: 'Stress levels', value: 50 },
          { driver: 'Senior leadership', value: 53 },
          { driver: 'Workload demands', value: 63 },
          { driver: 'Work-life balance', value: 65 },
          { driver: 'Their manager', value: 62 },
          { driver: 'Job security', value: 65 },
          { driver: 'Career progression opportunities', value: 40 },
          { driver: 'Company values', value: 65 },
          { driver: 'Flexible working arrangements', value: 62 },
          { driver: 'Location of work', value: 78 },
          { driver: 'Team / colleagues', value: 68 },
          { driver: 'Company commitment to ESG', value: 47 },
          { driver: 'The success of their company', value: 46 },
        ],
      },
      geny: {
        title: 'Millennials',
        happiness: -1,
        data: [
          { driver: 'Purpose at work', value: 53 },
          { driver: 'Their day-to-day role responsibilities', value: 56 },
          { driver: 'Their company culture', value: 53 },
          { driver: 'Salary', value: 35 },
          { driver: 'Stress levels', value: 41 },
          { driver: 'Senior leadership', value: 44 },
          { driver: 'Workload demands', value: 52 },
          { driver: 'Work-life balance', value: 58 },
          { driver: 'Their manager', value: 56 },
          { driver: 'Job security', value: 58 },
          { driver: 'Career progression opportunities', value: 41 },
          { driver: 'Company values', value: 48 },
          { driver: 'Flexible working arrangements', value: 60 },
          { driver: 'Location of work', value: 65 },
          { driver: 'Team / colleagues', value: 63 },
          { driver: 'Company commitment to ESG', value: 42 },
          { driver: 'The success of their company', value: 45 },
        ],
      },
      genz: {
        title: 'Gen Z',
        happiness: -1,
        data: [
          { driver: 'Purpose at work', value: 55 },
          { driver: 'Their day-to-day role responsibilities', value: 60 },
          { driver: 'Their company culture', value: 51 },
          { driver: 'Salary', value: 39 },
          { driver: 'Stress levels', value: 32 },
          { driver: 'Senior leadership', value: 39 },
          { driver: 'Workload demands', value: 45 },
          { driver: 'Work-life balance', value: 56 },
          { driver: 'Their manager', value: 54 },
          { driver: 'Job security', value: 58 },
          { driver: 'Career progression opportunities', value: 42 },
          { driver: 'Company values', value: 53 },
          { driver: 'Flexible working arrangements', value: 50 },
          { driver: 'Location of work', value: 62 },
          { driver: 'Team / colleagues', value: 56 },
          { driver: 'Company commitment to ESG', value: 30 },
          { driver: 'The success of their company', value: 46 },
        ],
      },
    },
  },
};

export type GenerationDataType = typeof genData;
