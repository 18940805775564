import React, { type FC, type PropsWithChildren } from 'react';
import { Svg, Path, StyleSheet } from '@react-pdf/renderer';
type PdfIconProps = PropsWithChildren<Svg['props']>;
const styles = StyleSheet.create({
  icon: {
    width: '24px',
    height: '24px',
    color: '#2E3849',
    position: 'relative',
  },
});

const getColor = (props: PdfIconProps, defaultColor?: string) =>
  props?.style && 'color' in props?.style ? props?.style.color : defaultColor;

export const PdfIconTip: FC<PdfIconProps> = (props) => (
  <Svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    {...props}
    style={{ ...styles.icon, ...props.style }}
  >
    <Path
      d="M9 18.957a1 1 0 0 1-1-1V16a8.96 8.96 0 0 0-1.485-2.633l-.277-.391a7 7 0 1 1 11.524 0l-.277.391A8.96 8.96 0 0 0 16 16v1.932a1 1 0 0 1-2 0V16c0-1.095.878-2.419 1.856-3.793l.261-.368a5 5 0 1 0-8.234 0l.26.368C9.124 13.581 10 14.905 10 16v1.957a1 1 0 0 1-1 1Z"
      fill={getColor(props, styles.icon.color)}
    />
    <Path
      d="M13.286 22h-2.572A2.761 2.761 0 0 1 8 19.2V17a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2.2a2.761 2.761 0 0 1-2.714 2.8ZM10 18v1.2a.761.761 0 0 0 .714.8h2.572a.761.761 0 0 0 .714-.8V18Zm13-8h-2a1 1 0 0 1 0-2h2a1 1 0 0 1 0 2Zm-1.475 5.5a.994.994 0 0 1-.499-.134l-1.732-1a1 1 0 1 1 1-1.732l1.732 1a1 1 0 0 1-.5 1.866Zm-1.73-10a1 1 0 0 1-.501-1.866l1.732-1a1 1 0 1 1 1 1.732l-1.732 1a.994.994 0 0 1-.5.134ZM3 10H1a1 1 0 0 1 0-2h2a1 1 0 0 1 0 2Zm-.525 5.5a1 1 0 0 1-.501-1.866l1.732-1a1 1 0 1 1 1 1.732l-1.732 1a.994.994 0 0 1-.5.134Zm1.73-10a.994.994 0 0 1-.499-.134l-1.732-1a1 1 0 0 1 1-1.732l1.732 1a1 1 0 0 1-.5 1.866ZM15 10a1 1 0 0 1-1-1 2.003 2.003 0 0 0-2-2 1 1 0 0 1 0-2 4.004 4.004 0 0 1 4 4 1 1 0 0 1-1 1Z"
      fill={getColor(props, styles.icon.color)}
    />
    <Path d="M14 21a2 2 0 0 1-4 0" fill={getColor(props, styles.icon.color)} />
  </Svg>
);

export const PdfIconPromote: FC<PdfIconProps> = (props) => (
  <Svg
    viewBox="0 0 24 24"
    width={24}
    height={24}
    style={{ ...styles.icon, ...props.style }}
  >
    <Path
      d="M15.525 3.15a.998.998 0 0 0-.972-.045L10.763 5H7c-2.757 0-5 2.243-5 5 0 1.283.5 2.476 1.352 3.384l1.527 5.392a2.976 2.976 0 0 0 1.398 1.82 2.984 2.984 0 0 0 2.276.301 2.976 2.976 0 0 0 1.82-1.398c.401-.695.508-1.504.315-2.22L10.213 15h.55l3.79 1.895a1.003 1.003 0 0 0 .972-.044A1 1 0 0 0 16 16V4a1 1 0 0 0-.475-.85ZM7 7h3v6H7a2.94 2.94 0 0 1-1.327-.331 3.083 3.083 0 0 1-.74-.527c-.004-.005-.01-.007-.016-.011A2.949 2.949 0 0 1 4 10c0-1.654 1.346-3 3-3Zm1.742 10.741a.996.996 0 0 1-.101.758.994.994 0 0 1-1.364.365 1.004 1.004 0 0 1-.47-.619l-.96-3.388.015.003c.118.03.237.046.356.066.07.012.14.03.21.039.19.023.38.035.572.035h1.17l.572 2.741ZM14 14.381l-2-1V6.619l2-1v8.764ZM21 9h-2a1 1 0 1 0 0 2h2a1 1 0 1 0 0-2Zm-.974 4.634-1.732-1a1.001 1.001 0 0 0-1 1.732l1.732 1a1.001 1.001 0 0 0 1-1.732ZM17.795 7.5c.17 0 .342-.043.499-.134l1.732-1a1 1 0 1 0-1-1.732l-1.732 1a1 1 0 0 0 .5 1.866Z"
      fill={getColor(props, styles.icon.color)}
    />
  </Svg>
);
