import React, { useEffect, useState } from 'react';
import { useConfig } from '../../../shared/hooks';
import {
  ALL_INDUSTRIES,
  type FastestGrowingSalariesIndustriesInsights,
  getFastestGrowingSalaries,
  industriesBy,
} from './Data/fastestGrowingSalaries';
import {
  percentageWrapper,
  TableBuilder,
  textElementWrapper,
} from '@seek/cmsu-rich-text/src/components/Table/TableBuilder';
import { Dropdown, Stack } from 'braid-design-system';
import * as styles from './styles.css';
import { formatCurrency } from '@seek/cmsu-components/src/helpers/helper';

export const FastestGrowingSalaries = () => {
  const { country } = useConfig();
  const [data, setData] = useState<
    FastestGrowingSalariesIndustriesInsights | undefined
  >(undefined);
  const [selectedIndustry, setSelectedIndustry] = useState(-1);

  const fastestGrowingSalaries = getFastestGrowingSalaries(country);

  useEffect(() => {
    const result = fastestGrowingSalaries?.find(
      (item) => item.industryIdFilter === selectedIndustry,
    );
    setData(result);
  }, [fastestGrowingSalaries, country, selectedIndustry]);

  if (!fastestGrowingSalaries) return;

  const industries = industriesBy(fastestGrowingSalaries);

  // Convert to Table Data with ROWS and COLUMNS
  // Map<string, JSX.Element> is a map of ROWS with column name and column values
  const tableData: Map<string, JSX.Element>[] | undefined = data?.roles.map(
    (item, index) => {
      const percentageIcon = (
        <div
          className={
            item.salaryGrowth > 0 ? styles.arrow_up : styles.arrow_down
          }
        />
      );
      return new Map<string, JSX.Element>([
        ['Rank', textElementWrapper(`${index + 1}`)],
        ['Industry', textElementWrapper(item.industryTitle)],
        ['Role', textElementWrapper(item.roleTitle)],
        [
          'Average salary',
          textElementWrapper(formatCurrency(item.avgSalary, country)),
        ],
        ['Salary Growth', percentageWrapper(item.salaryGrowth, percentageIcon)],
      ]);
    },
  );

  return (
    <Stack space="gutter">
      <Dropdown
        id="INDUSTRY_SELECTION"
        label="Select an industry"
        onChange={(event) => {
          setSelectedIndustry(Number(event.currentTarget.value));
        }}
        value={selectedIndustry}
        placeholder="Select an industry"
      >
        <>
          <option value="-1">{ALL_INDUSTRIES}</option>
          {industries.map((industry) => (
            <option key={industry.id} value={industry.id}>
              {industry.description}
            </option>
          ))}
        </>
      </Dropdown>
      {tableData && <TableBuilder tableData={tableData} showHeader={true} />}
    </Stack>
  );
};
