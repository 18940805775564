import { Box } from 'braid-design-system';
import React from 'react';

type Props = {
  mediaUrl: string | null | undefined;
};

export const PodcastPlayer = ({ mediaUrl }: Props) => {
  if (!mediaUrl) return;
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      data={{ testid: 'PodcastPlayerTestId' }}
    >
      <iframe src={mediaUrl} width="100%" height="220" frameBorder="0" />
    </Box>
  );
};
