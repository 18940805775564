import type { TipsCardFragment } from '@seek/cmsu-cms-connect';
import type { Country, SiteName } from '@seek/melways-sites';
import { useTranslations } from '@vocab/react';
import {
  Box,
  Button,
  Column,
  Columns,
  Divider,
  Hidden,
  Inline,
  Stack,
  Text,
  ToastProvider,
} from 'braid-design-system';
import { vars } from 'braid-design-system/css';
import { useState, type Dispatch, type SetStateAction } from 'react';
import { useTargetBlank } from '../../../../../apps/unified-apac/src/client/shared/hooks/useTargetBlank';
import type { GetSurveyQuery } from '../../../../../apps/unified-apac/src/gql/tal/generated';
import translations_tal from './.vocab';
import { CandidateInformation } from './components/CandidateInformation/CandidateInformation';
import { DriverBreakdown } from './components/DriverBreakdown/DriverBreakdown';
import { DriverSummary } from './components/DriverSummary/DriverSummary';
import { NoDataDisplay } from './components/NoDataDisplay/NoDataDisplay';
import {
  PdfDownloadProvider,
  usePdfDownloadContext,
} from './components/PDF/PdfDownloadProvider';
import type { DriverList } from './components/QueryFilter';
import { QueryFilterDrawer } from './components/QueryFilterDrawer';
import { QueryFilterForm } from './components/QueryFilterForm';
import { TipsCard } from './components/TipsCard/TipsCard';
import {
  datalabHelper,
  type DatalabDriverData,
  type DatalabQueryParams,
  type DatalabSurvey,
} from './datalabHelper';
import { tipCardWrapperDesktop, tipCardWrapperMobile } from './styles.css';

// TODO!: Replace with actual page title
const pageTitle = 'Talent Acquisition Lab';

interface DatalabProps {
  datalabSurvey: DatalabSurvey;
  tipsCards?: ({ __typename?: 'TipsCard' | undefined } & TipsCardFragment)[];
  fullURL: string;
  country: Country;
  site: SiteName;
}

export const Datalab = ({
  datalabSurvey: { queryFilters, drivers, surveyResults, queryFilterParams },
  tipsCards,
  fullURL,
  country,
  site,
}: DatalabProps) => {
  useTargetBlank();
  // Get the params from context.

  const driverList: DriverList = datalabHelper.getDriverListFrom(queryFilters);
  const [showFilterDrawer, setShowDrawerFilter] = useState<boolean>(false);
  const showNoData: boolean = datalabHelper.hasNoSurveyData(surveyResults);
  return (
    <ToastProvider>
      <PdfDownloadProvider drivers={drivers}>
        <Box
          paddingY={{ mobile: 'medium', desktop: 'none' }}
          paddingX={{ mobile: 'none', desktop: 'medium' }}
          style={{
            margin: '-16px 0',
          }}
        >
          <Columns
            space={{ mobile: 'medium', desktop: 'xlarge' }}
            collapseBelow="desktop"
          >
            <Column width="content">
              <DatalabNavigation
                country={country}
                driverList={driverList}
                queryFilterParams={queryFilterParams}
                setShowDrawerFilter={setShowDrawerFilter}
                showFilterDrawer={showFilterDrawer}
              />
              {tipsCards?.length && (
                <Box className={tipCardWrapperDesktop}>
                  <TipsCard tipsCards={tipsCards} />
                </Box>
              )}
            </Column>
            <VerticalDivider />
            <Column>
              <Box
                paddingY={{ mobile: 'none', desktop: 'xxlarge' }}
                paddingTop={{ mobile: 'xsmall', desktop: 'xlarge' }}
                position={'relative'}
              >
                {showNoData ? (
                  <NoDataDisplay site={site} />
                ) : (
                  drivers &&
                  surveyResults && (
                    <Stack space="xlarge">
                      <DatalabResult
                        country={country}
                        fullURL={fullURL}
                        queryFilterParams={queryFilterParams}
                        drivers={drivers}
                        surveyResults={surveyResults}
                      />
                      <CandidateInformation
                        demographic={surveyResults?.demographic}
                      />
                    </Stack>
                  )
                )}
                {!showNoData && (
                  <Box paddingTop="xlarge">
                    <Stack space="medium">
                      <Divider />
                      <Inline space="small">
                        <DownloadButton />
                      </Inline>
                    </Stack>
                  </Box>
                )}
                {tipsCards?.length && (
                  <Box className={tipCardWrapperMobile}>
                    <TipsCard tipsCards={tipsCards} />
                  </Box>
                )}
              </Box>
            </Column>
          </Columns>
        </Box>
      </PdfDownloadProvider>
    </ToastProvider>
  );
};

const DownloadButton = () => {
  const { downloadPdf } = usePdfDownloadContext();
  return (
    <Button variant="solid" tone="formAccent" onClick={downloadPdf}>
      Download report
    </Button>
  );
};

interface DatalabNavigationProps {
  driverList: DriverList;
  queryFilterParams: DatalabQueryParams;
  setShowDrawerFilter: Dispatch<SetStateAction<boolean>>;
  country: Country;
  showFilterDrawer: boolean;
}

const DatalabNavigation = ({
  driverList,
  queryFilterParams,
  setShowDrawerFilter,
  country,
  showFilterDrawer,
}: DatalabNavigationProps) => (
  <Box paddingTop={{ mobile: 'none', desktop: 'xlarge' }}>
    <Hidden below="desktop">
      <Box display={'flex'} flexShrink={0} style={{ width: '351px' }}>
        <QueryFilterForm
          country={country}
          driverList={driverList}
          queryFilterParams={queryFilterParams}
        />
      </Box>
    </Hidden>
    <Hidden above="tablet">
      <QueryFilterDrawer
        country={country}
        driverList={driverList}
        queryFilterParams={queryFilterParams}
        setShowFilterDrawer={setShowDrawerFilter}
        showFilterDrawer={showFilterDrawer}
      />
    </Hidden>
  </Box>
);

interface DatalabResultProps {
  fullURL: string;
  queryFilterParams: DatalabQueryParams;
  drivers: DatalabDriverData;
  surveyResults: GetSurveyQuery;
  country: Country;
}

const DatalabResult = ({
  fullURL,
  queryFilterParams,
  drivers,
  surveyResults,
  country,
}: DatalabResultProps) => {
  const filteredDriverOptions: string =
    datalabHelper.generateDriverSummaryTitleFromFilteredDriverOptionsInQueryParam(
      queryFilterParams,
    );

  return (
    <Box>
      <Stack space="xlarge">
        <DriverSummary
          country={country}
          pageUrl={fullURL}
          pageTitle={pageTitle}
          filteredDriverOptions={filteredDriverOptions}
          totalRespondentCount={surveyResults.totalRespondents}
          respondentCount={surveyResults.respondents}
        />
        <Box>
          <DriverDescription />
        </Box>

        <DriverBreakdown drivers={drivers} />
      </Stack>
    </Box>
  );
};

const DriverDescription = () => {
  const { t: t_tal } = useTranslations(translations_tal);
  return (
    <Stack space="small">
      <Text size="large" weight="strong">
        {t_tal('Drivers that attract these candidates')}{' '}
      </Text>
      <Text>
        {t_tal(
          'These are the drivers candidates consider when looking at roles.',
        )}{' '}
        {t_tal('Drivers are ranked with the most important driver at the top.')}{' '}
        {t_tal('Expand each driver for more detail.')}
      </Text>
    </Stack>
  );
};

const VerticalDivider = () => (
  <Column width="content" hideBelow="desktop">
    <Box
      style={{
        height: '100%',
        width: '2px',
        backgroundColor: vars.backgroundColor.neutralLight,
      }}
    />
  </Column>
);
