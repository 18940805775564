import {
  Badge,
  Box,
  IconChevron,
  IconFilter,
  Inline,
  Text,
} from 'braid-design-system';
import type { Dispatch, SetStateAction } from 'react';

type QueryFilterDrawerActionProps = {
  setShowFilterDrawer: Dispatch<SetStateAction<boolean>>;
  filterTitle: string;
  totalFilters: number;
};
export const QueryFilterDrawerAction = ({
  setShowFilterDrawer,
  filterTitle,
  totalFilters,
}: QueryFilterDrawerActionProps) => (
  <Box
    boxShadow="borderNeutralLight"
    borderRadius={'large'}
    padding={'medium'}
    display={'flex'}
    alignItems={'center'}
    onClick={() => {
      setShowFilterDrawer(true);
    }}
  >
    <Inline space={'small'} alignY={'center'}>
      <IconFilter size="xsmall" />
      <Text weight="strong" tone="neutral">
        {filterTitle}
      </Text>
      <Badge tone="neutral">{totalFilters}</Badge>
    </Inline>
    <Box style={{ marginTop: '0px', marginLeft: 'auto' }}>
      <IconChevron direction={'right'} size="xsmall" />
    </Box>
  </Box>
);
