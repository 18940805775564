const DISALLOWED_TAGS: Set<string> = new Set([
  'script',
  'iframe',
  'object',
  'embed',
  'form',
]);
const DISALLOWED_ATTRIBUTES: Set<string> = new Set([
  'onclick',
  'onload',
  'onerror',
]);

export class HTMLSanitizer {
  private parser: DOMParser;

  constructor() {
    this.parser = new DOMParser();
  }

  sanitize(html: string): string {
    const doc = this.parser.parseFromString(html, 'text/html');
    this.cleanNode(doc.body);
    return doc.body.innerHTML;
  }

  private cleanNode(node: Node): void {
    if (node.nodeType === Node.ELEMENT_NODE) {
      const element = node as Element;
      if (DISALLOWED_TAGS.has(element.tagName.toLowerCase())) {
        element.remove();
        return;
      }

      // Remove disallowed attributes
      for (const attr of Array.from(element.attributes)) {
        if (DISALLOWED_ATTRIBUTES.has(attr.name.toLowerCase())) {
          element.removeAttribute(attr.name);
        } else {
          // Escape attribute values
          element.setAttribute(attr.name, this.escapeAttribute(attr.value));
        }
      }
    }

    // Recursively clean child nodes
    for (const child of Array.from(node.childNodes)) {
      this.cleanNode(child);
    }
  }

  private escapeAttribute(value: string): string {
    const div = document.createElement('div');
    div.textContent = value;
    return div.innerHTML;
  }
}
