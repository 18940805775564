export const PATHS = {
  LANGUAGE: '/:language?',
  ARTICLE: 'articles',
  AUTHOR: 'author',
  HIRING_ADVICE: 'hiring-advice',
  MARKET_INSIGHTS: 'market-insights',
  PRODUCTS: 'products',
  PAGE: 'page',
  SUPPORT: 'support',
  ABOUT: 'about',
  SECURITY_PRIVACY: 'security-privacy',
  TERMS: 'terms',
  SAFE_JOB_SEARCHING: 'safe-job-searching',
  MY_PRIVACY: 'my-privacy',
  PRIVACY: 'privacy',
  PROTECT_YOURSELF_ONLINE: 'protect-yourself-online',
  PROFILE: 'profile',
  EMPLOYER_BENEFITS: 'employer-benefits',
  PARTNERS: 'partners',
};
