/* eslint-disable no-console */
import {
  type AuthStrategy,
  createHirerAuthStrategy,
  type TokenData,
} from '@seek/online-identity';
import graphQLClient from '@seek/hirer-graphql-client';
import gql from 'graphql-tag';
import type { EmployerSiteName } from '../types';

const QUERY_HEADER_FOOTER_CONTEXT = gql`
  query getHeaderFooterContext {
    headerFooterContext {
      identityContext {
        email
        act {
          operatorEmail
          csToggleHref
          isCsView
        }
      }
      user {
        id
        name
        firstName
      }
      advertiser {
        billingId
        switchAccountsHref
        isActivationPending
        isAgency
        name
        permissionOptions {
          canCreateJobs
          canManageBrand
          canManageCompanyProfile
          canManageUsers
          canPayInvoices
          canViewInvoiceHistory
          canViewManagerReports
          canViewInsightsRoleReport
        }
        showOnCreditHoldMessage
      }
    }
  }
`;

let strategy: AuthStrategy;

const getToken = (): Promise<TokenData> => {
  if (!strategy) {
    strategy = createHirerAuthStrategy();
  }

  return strategy.get();
};

const getAuthToken = async (): Promise<string | null> => {
  try {
    const data = await getToken();
    if (data.authenticated) {
      return data.token;
    }
  } catch (error) {
    console.error('Failed to get auth token', error);
  }
  return null;
};

const login = () => {};

const logMetrics = () => {};

const logGraphQLError = (
  graphQlError: any,
  operationName: any,
  operationMetadata: any,
) => {
  console.log('graphQlError: ', graphQlError);
  console.log('operationName: ', operationName);
  console.log('operationMetadata: ', operationMetadata);
};

const logError = (
  networkError: any,
  operationName: any,
  operationMetadata: any,
) => {
  console.log('networkError: ', networkError);
  console.log('operationName: ', operationName);
  console.log('operationMetadata: ', operationMetadata);
};

const getHeaders = (): Headers => new Headers();

export const getHirerAccountContext = async (site: EmployerSiteName) => {
  const response = await graphQLClient({
    appName: 'wpt-cms-unification',
    site,
    getAuthToken,
    renewAuthToken: getAuthToken,
    login,
    logMetrics,
    logGraphQLError,
    logError,
    getHeaders: typeof window === 'undefined' ? undefined : getHeaders,
  }).query({
    query: QUERY_HEADER_FOOTER_CONTEXT,
  });

  const { headerFooterContext } = response.data;

  return {
    hirerAccountContext: headerFooterContext,
  };
};
