import { paginationHelper } from '@seek/cmsu-components/src/helpers/paginationHelper';

export const pageConfig = {
  pageSize: 12,
};

export const getTotalPages = (totalRecords: number) =>
  paginationHelper.getTotalPages(totalRecords, pageConfig.pageSize);

export const getPageNumberFrom = (pageNumber?: number | string | undefined) => {
  const resolvedPageNumber = Number(pageNumber) ?? 1;
  if (isNaN(resolvedPageNumber) || resolvedPageNumber < 1) return 1;
  return Math.trunc(resolvedPageNumber);
};

export const getRecordsToSkip = (pageNumber?: number | string | undefined) => {
  const resolvedPageNumber =
    typeof pageNumber === 'number' && pageNumber >= 1
      ? pageNumber
      : getPageNumberFrom(pageNumber);
  return (Math.trunc(resolvedPageNumber) - 1) * pageConfig.pageSize;
};

export const showPagination = (totalRecords: number = 0) =>
  totalRecords > pageConfig.pageSize;
