import type { UserProfile } from '@seek/apac-candidate-header-footer/types';
import React, {
  createContext,
  useEffect,
  useState,
  useContext,
  type ReactNode,
} from 'react';
import { auth } from '../auth/auth';
import { AUTHENTICATION_TYPES } from '../types';
import { useConfig } from './useConfig';
import type { HeaderFooterContext } from 'src/gql/generated';

type AuthProps =
  | undefined
  | {
      authenticationStatus: AUTHENTICATION_TYPES;
      hirerAccountContext?: HeaderFooterContext;
      caAccountContext?: UserProfile;
      error?: any;
      loginId?: number | string;
      seekAdvertiserId?: number | string;
    };

const AuthContext = createContext<AuthProps>(undefined);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [authStatus, setAuthStatus] = useState<AuthProps>({
    authenticationStatus: AUTHENTICATION_TYPES.AUTH_PENDING,
  });
  const config = useConfig();

  useEffect(() => {
    const authenticate = async () => {
      try {
        const data = await auth(config);
        setAuthStatus(data);
      } catch (error: any) {
        setAuthStatus({
          error,
          authenticationStatus: AUTHENTICATION_TYPES.UNAUTHENTICATED,
        });
      }
    };
    authenticate();
  }, [config]);

  return (
    <AuthContext.Provider value={authStatus}>{children}</AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }

  return context;
};
