import type * as React from 'react';
import { FastestGrowingSalaries } from './FastestGrowingSalaries/FastestGrowingSalaries';
import { HighestPayingJobs } from './HighestPayingJobs/HighestPayingJobs';
import { GenerationalDriversSummary } from './GenerationalDrivers/GenerationalDrivers';
import { useConfig } from 'src/client/shared/hooks';
import { GenerationalDriversInteractive } from './GenerationalDrivers/GenerationalDriversInteractive';

export type CustomContentTitle =
  | 'FASTEST_GROWING_SALARIES'
  | 'HIGHEST_PAYING_JOBS'
  | 'GENERATION_DRIVERS_SUMMARY'
  | 'GENERATION_DRIVERS_INTERACTIVE';

interface Props {
  title: string;
}

export const CustomContent = ({ title }: Props) => {
  const { country } = useConfig();
  const content = CustomContentMapping[title](country);
  return <>{content}</>;
};

export const CustomContentMapping: {
  [key: string]: (country: string) => React.ReactNode;
} = {
  FASTEST_GROWING_SALARIES: () => <FastestGrowingSalaries />,
  HIGHEST_PAYING_JOBS: () => <HighestPayingJobs />,
  GENERATION_DRIVERS_SUMMARY: (country: string) => (
    <GenerationalDriversSummary country={country} />
  ),
  GENERATION_DRIVERS_INTERACTIVE: (country: string) => (
    <GenerationalDriversInteractive country={country} />
  ),
};
