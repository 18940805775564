import {
  Box,
  ContentBlock,
  IconChevron,
  Stack,
  Strong,
  Text,
  TextLinkButton,
} from 'braid-design-system';
import { vars } from 'braid-design-system/css';
import { useState } from 'react';
import { genData } from './data';

type CountryCode = 'au' | 'nz';

const minHighlightVal = 3; // Highlight the first 3 items
const maxSummaryItems = 5; // Show only 5 items initially

// Component from SKL-Carrer-guide-site
export const GenerationalDriversSummary = ({
  country,
}: {
  country: string;
}) => {
  const [revealed, setRevealed] = useState(false);

  // Only render for AU and NZ
  if (!['au', 'nz'].includes(country)) {
    return null;
  }

  const filteredCountry = country as CountryCode;

  return (
    <ContentBlock width="small">
      <Box
        background="brandAccentSoft"
        padding={{ tablet: 'large', mobile: 'medium' }}
        borderRadius="xlarge"
      >
        <Stack space="large">
          <Text size="large" tone="brandAccent" weight="strong">
            What impacts happiness at work for&nbsp;
            {genData[filteredCountry].summary.title.replace(' ', '\u00a0')}
          </Text>

          {genData[filteredCountry].summary.data.map((item, idx) => {
            if (!revealed && idx >= maxSummaryItems) {
              return null;
            }
            return (
              <Stack key={item.driver} space="small">
                <GenerationalDriversSummaryBarTitle
                  driver={item.driver}
                  index={idx}
                />
                <GenerationalDriversSummaryBar index={idx} value={item.value} />
              </Stack>
            );
          })}
          {!revealed ? (
            <Text>
              <TextLinkButton onClick={() => setRevealed(true)}>
                Show all 17 factors <IconChevron />
              </TextLinkButton>
            </Text>
          ) : null}
        </Stack>
      </Box>
    </ContentBlock>
  );
};

const GenerationalDriversSummaryBarTitle = ({
  index,
  driver,
}: {
  index: number;
  driver: string;
}) => (
  <Text
    size={`${index < minHighlightVal ? 'large' : 'standard'}`}
    tone="brandAccent"
  >
    <Strong>{index + 1}.</Strong> {driver}
  </Text>
);

const GenerationalDriversSummaryBar = ({
  value,
  index,
}: {
  value: number;
  index: number;
}) => (
  <Box overflow="hidden" borderRadius="large" background="body">
    <Box
      style={{
        width: `${value}%`,
        height: vars.space.xsmall,
        backgroundColor:
          index < minHighlightVal
            ? vars.backgroundColor.brandAccent
            : vars.backgroundColor.brandAccentSoftActive,
      }}
    >
      &nbsp;
    </Box>
  </Box>
);
