import {
  ButtonIcon,
  IconCopy,
  IconShare,
  IconSocialFacebook,
  IconSocialLinkedIn,
  IconSocialX,
  MenuItem,
  MenuItemDivider,
  MenuRenderer,
  useToast,
} from 'braid-design-system';
import { useCallback } from 'react';
import { useTranslations } from '@vocab/react';
import translations_tal from '../../.vocab';
import { generateSocialShareUrl } from '../../../../helpers/socialShareHelper';

type Props = {
  pageUrl: string;
  title: string;
};

const sharedIconProps: Partial<React.ComponentProps<typeof ButtonIcon>> = {
  size: 'large',
  variant: 'soft',
};

const ShareDropdown = ({ pageUrl, title }: Props) => {
  const showToast = useToast();

  const facebookUri = generateSocialShareUrl('facebook', {
    pageUrl,
    title,
  });
  const twitterUri = generateSocialShareUrl('twitter', {
    pageUrl,
    title,
  });
  const linkedinUri = generateSocialShareUrl('linkedin', {
    pageUrl,
    title,
  });

  const onSocialTagClick = useCallback(
    (url: string) => () => {
      window.open(url, '_blank');
    },
    [],
  );

  const onCopyLinkClick = () => {
    // navigator.clipboard only works over https.
    if (navigator.clipboard?.writeText) {
      navigator.clipboard
        .writeText(pageUrl)
        .then(() => {
          showToast({
            message: 'Link copied to clipboard',
            tone: 'positive',
          });
        })
        .catch(() => {
          showToast({
            message: 'Failed to copy link',
            tone: 'critical',
          });
        });
    } else {
      showToast({
        message: 'Clipboard access not available',
        tone: 'critical',
      });
    }
  };
  const { t } = useTranslations(translations_tal);

  return (
    <>
      <MenuRenderer
        offsetSpace="small"
        align="right"
        trigger={(triggerProps) => (
          <ButtonIcon
            {...sharedIconProps}
            id="share-dropdown-trigger"
            icon={<IconShare />}
            label={t('Share')}
            variant="soft"
            size="standard"
            {...triggerProps}
          />
        )}
      >
        <MenuItem
          onClick={onSocialTagClick(facebookUri)}
          icon={<IconSocialFacebook />}
        >
          {t('Share on Facebook')}
        </MenuItem>
        <MenuItem onClick={onSocialTagClick(twitterUri)} icon={<IconSocialX />}>
          {t('Share on X')}
        </MenuItem>
        <MenuItem
          onClick={onSocialTagClick(linkedinUri)}
          icon={<IconSocialLinkedIn />}
        >
          {t('Share on LinkedIn')}
        </MenuItem>
        <MenuItemDivider />
        <MenuItem onClick={onCopyLinkClick} icon={<IconCopy />}>
          {t('Copy Link')}
        </MenuItem>
      </MenuRenderer>
    </>
  );
};

export { ShareDropdown };
