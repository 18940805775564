import {
  useGetLatestArticlesByCategoryQuery,
  Locale,
  Stage,
  type Category,
} from '@seek/cmsu-cms-connect';
import { LatestArticlesByCategory } from '@seek/cmsu-components';
import { CMSUProvider } from '@seek/cmsu-components/src/hooks/useCMSUContext';
import React from 'react';
import { useConfig } from 'src/client/shared/hooks';
import { filterObjectsByContentLocaleOrDefault } from 'src/helpers/localizationHelper';

type Props = {
  heading: string | null | undefined;
  description: string | null | undefined;
  categorySlug: string;
  isDraft?: boolean;
};

export const LatestArticlesByCategoryLoader = ({
  heading,
  description,
  categorySlug,
  isDraft,
}: Props) => {
  const { hygraphSite, hygraphLocale, language } = useConfig();

  const locales =
    hygraphLocale === Locale.IdId || hygraphLocale === Locale.ThTh
      ? [hygraphLocale]
      : [hygraphLocale, Locale.Default];

  const { data } = useGetLatestArticlesByCategoryQuery({
    variables: {
      where: {
        slug: categorySlug,
        sites_some: { name: hygraphSite },
      },
      site: hygraphSite,
      locale: locales,
      localesWithDefault: [hygraphLocale, Locale.Default],
      stage: isDraft ? Stage.Draft : Stage.Published,
    },
  });

  if (
    !(
      data &&
      data.categories.length > 0 &&
      data.categories[0].localizations.length > 0
    )
  )
    return;

  const category = (
    filterObjectsByContentLocaleOrDefault(
      data.categories[0].localizations,
      hygraphLocale,
    ) as Category[]
  )[0];

  return (
    <CMSUProvider config={{ language }}>
      <LatestArticlesByCategory
        heading={heading}
        description={description}
        category={category}
        language={language}
      />
    </CMSUProvider>
  );
};
