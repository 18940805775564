import type { Locale } from '@seek/cmsu-cms-connect';
import {
  type Country,
  type Product,
  type SiteName,
  createAbsoluteUrl,
} from '@seek/melways-sites';

export type LocaleInfo = {
  locale: string;
  url: string;
};

export type LocalesInfo = {
  locales: LocaleInfo[];
};

const getLocales = (
  siteName: SiteName,
  route: string,
  sites: {
    __typename?: 'Site' | undefined;
    name: string;
  }[],
  localizations: {
    locale: Locale;
    title?: string;
  }[],
) => {
  const product = siteName.substring(0, siteName.indexOf('-')) as Product;
  const locales: LocaleInfo[] = [];
  const getRouteString = (path: string) =>
    path.startsWith('/') ? path : `/${path}`;

  for (const site of sites
    .map((s) => s.name as SiteName)
    .filter((s) => s.includes(product))) {
    const c = site.substring(site.lastIndexOf('-') + 1); // last two chars
    if (c === 'root') {
      locales.push({
        locale: 'default',
        url: `https://www.seek.com${getRouteString(route)}`,
      });
    } else if (c !== 'id' && c !== 'th') {
      const url = createAbsoluteUrl({
        country: c as Country,
        path: getRouteString(route),
        product,
        language: 'en',
      });
      locales.push({ locale: `en-${c}`, url });
    } else {
      const enLocale = localizations.find((x) =>
        x.locale.includes(`en_${c.toUpperCase()}`),
      );
      if (enLocale) {
        // en_ID or en_TH
        const url = createAbsoluteUrl({
          country: c as Country,
          path: getRouteString(route),
          product,
          language: 'en',
        });
        locales.push({ locale: `en-${c}`, url });
      } else if (
        localizations.find(
          (x) => x.locale.includes('default') && x.title !== '-',
        )
      ) {
        // add if default exists and not '-'
        const url = createAbsoluteUrl({
          country: c as Country,
          path: getRouteString(route),
          product,
          language: 'en',
        });
        locales.push({ locale: `en-${c}`, url });
      }

      const nativeLocale = localizations.find((x) =>
        x.locale.includes(`${c}_${c.toUpperCase()}`),
      ); // id_ID or th_TH
      if (nativeLocale) {
        const url = createAbsoluteUrl({
          country: c as Country,
          path: getRouteString(route),
          product,
          language: c,
        });
        locales.push({
          locale: nativeLocale.locale.replace('_', '-').toLowerCase(),
          url,
        });
      }
    }
  }
  return locales;
};
export { getLocales };
