import {
  type Dispatch,
  type SetStateAction,
  type ReactElement,
  useEffect,
  useCallback,
} from 'react';
import type { BraidDrawerProps } from '../types';
import { Drawer } from 'braid-design-system';
import { useScreenSize } from '../../hooks/useScreenSize';

type DrawerOverlayProps = {
  open: boolean;
  setShowFilterDrawer: Dispatch<SetStateAction<boolean>>;
  title: string;
  description?: ReactElement | boolean | null | undefined;
  children: React.ReactNode;
  width?: BraidDrawerProps['width'];
  id: string;
};

export const DrawerOverlay = ({
  open,
  setShowFilterDrawer,
  title,
  description,
  children,
  width = 'medium',
  id,
}: DrawerOverlayProps) => {
  const isMobile = useScreenSize().isMobile;

  // Close the mobile drawer filter when the user resize the screen to desktop view while the drawer is still open
  const closeOverlay = useCallback(() => {
    setShowFilterDrawer(false);
  }, [setShowFilterDrawer]);

  useEffect(() => {
    if (!isMobile) {
      closeOverlay();
    }
  }, [isMobile, closeOverlay]);

  return (
    <Drawer
      id={id}
      title={title ?? ''}
      description={description}
      open={open}
      onClose={() => setShowFilterDrawer(false)}
      width={width}
    >
      {children}
    </Drawer>
  );
};
