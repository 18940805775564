import { Heading, Stack, Text } from 'braid-design-system';
import React from 'react';
type QueryFilterHeadingProps = {
  title: string;
  description: string;
};
export const QueryFilterHeading = ({
  title,
  description,
}: QueryFilterHeadingProps) => (
  <Stack space="medium">
    <Heading level="4">{title}</Heading>
    <Text>{description}</Text>
  </Stack>
);
