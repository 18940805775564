import { Stack, Text, Tiles } from 'braid-design-system';
import type {
  DriverSubCategory,
  PrimaryDriverOption,
} from '../../../datalabHelper';
import { BarChartGroup } from '../../BarChart/BarChartGroup';
import { useDriverBreakdown } from '../DriverBreakdownProvider';
import React from 'react';
import { useTranslations } from '@vocab/react';
import translations_tal from '../../../.vocab';
import {
  levelOfImportanceHelper,
  refineByOptions,
  type RefineByLabel,
} from './levelOfImportanceHelper';

interface LevelOfImportanceSubCategoryDriverOptionItem {
  label: string;
  width: string;
  value: number;
  color: string;
  hidden: boolean;
}

interface LevelOfImportanceSubCategoryDriverOption {
  driverOptionName: string;
  items: LevelOfImportanceSubCategoryDriverOptionItem[];
}

interface LevelOfImportanceSubCategory {
  subCategoryName: string;
  driverOptions: LevelOfImportanceSubCategoryDriverOption[];
}

interface LevelOfImportanceProps {
  driverOptions: PrimaryDriverOption[];
  categoryRow: string;
  refineBy: Record<string, boolean>;
  tilesColumns: number;
}

export const LevelOfImportance: React.FC<LevelOfImportanceProps> = ({
  driverOptions,
  categoryRow,
  refineBy,
  tilesColumns,
}) => {
  const { t: t_tal } = useTranslations(translations_tal);

  // ToDo: move to helper and add test coverage
  const mapToSubCategories = (
    subCategories: DriverSubCategory[],
  ): LevelOfImportanceSubCategory[] =>
    subCategories.map((subCategory) => ({
      subCategoryName: subCategory.name,
      driverOptions: subCategory.driverOptions.map(
        (subCategoryDriverOption) =>
          ({
            driverOptionName: subCategoryDriverOption.name,
            items: subCategoryDriverOption.results
              .sort(
                (a, b) =>
                  refineByOptions.indexOf(a.name as RefineByLabel) -
                  refineByOptions.indexOf(b.name as RefineByLabel),
              )
              .map(
                (item, index) =>
                  ({
                    label: t_tal(
                      levelOfImportanceHelper.getRefineByLabel(item.name),
                    ),
                    value: item.percentage,
                    color: levelOfImportanceHelper.getBarColor(index),
                    hidden: !refineBy[item.name as RefineByLabel],
                  } as LevelOfImportanceSubCategoryDriverOptionItem),
              ),
          } as LevelOfImportanceSubCategoryDriverOption),
      ),
    }));

  // ToDo: move to helper and add test coverage
  const levelOfImportances = driverOptions.map((driverOption) => ({
    driverOptionName: driverOption.name,
    categoryName: categoryRow,
    subCategories: driverOption.categories
      .filter((category) => category.name === categoryRow)
      .flatMap((category) => mapToSubCategories(category.subCategories)),
  }));

  const subCategoryRows = [
    ...new Set(
      levelOfImportances.flatMap((driverOption) =>
        driverOption.subCategories.flatMap(
          (subCategory) => subCategory.subCategoryName,
        ),
      ),
    ),
  ];

  if (subCategoryRows.length === 0) return;

  return (
    <Stack space={{ mobile: 'gutter', desktop: 'xlarge' }}>
      {subCategoryRows.map((subCategoryRow) => (
        <Stack space="medium" key={`ROW_${subCategoryRow}_LEVELOFIMPORTANCE}`}>
          <Tiles
            columns={{
              mobile: 1,
              tablet: tilesColumns,
              desktop: tilesColumns,
              wide: tilesColumns,
            }}
            space="medium"
          >
            {levelOfImportances.map((driverOption) => (
              <Text
                size="small"
                weight="strong"
                component="h3"
                key={`TITLE_${driverOption.driverOptionName}_${subCategoryRow}_LEVELOFIMPORTANCE}`}
              >
                {subCategoryRow}
              </Text>
            ))}
          </Tiles>

          <Tiles
            columns={{
              mobile: 1,
              tablet: tilesColumns,
              desktop: tilesColumns,
              wide: tilesColumns,
            }}
            space="medium"
          >
            {levelOfImportances.map((driverOption) => {
              const subCategory = driverOption.subCategories.find(
                (item) => item.subCategoryName === subCategoryRow,
              );
              return (
                <React.Fragment
                  key={`ROW_${driverOption.driverOptionName}_LEVEL_OF_IMPORTANCE`}
                >
                  <LevelOfImportanceRow subCategory={subCategory} />
                </React.Fragment>
              );
            })}
          </Tiles>
        </Stack>
      ))}
    </Stack>
  );
};

LevelOfImportance.displayName = 'LevelOfImportance';

interface LevelOfImportanceRowProps {
  subCategory: LevelOfImportanceSubCategory | undefined;
}

const LevelOfImportanceRow = ({ subCategory }: LevelOfImportanceRowProps) => {
  const { sortBy } = useDriverBreakdown();
  const { t: t_tal } = useTranslations(translations_tal);
  const valueFormatter = (
    val: number,
    maxVal: number,
    decimalPoints: number = 0,
  ) => `${((val * 100) / maxVal).toFixed(decimalPoints)}%`;
  return (
    <Stack space="small">
      {subCategory &&
        subCategory.driverOptions
          .sort((a) => (a.driverOptionName === sortBy ? -1 : 1))
          .map((subCategoryDriverOption) => (
            <BarChartGroup
              label={subCategoryDriverOption.driverOptionName}
              valueFormatter={valueFormatter}
              tooltip={
                <Text>
                  {t_tal(
                    'This is how candidates sort their preferences for the components of selected filter. Candidates have the option to choose more than one must-have.',
                    { filter: subCategoryDriverOption.driverOptionName },
                  )}
                </Text>
              }
              items={subCategoryDriverOption.items}
              key={`BARCHART_${subCategory.subCategoryName}_${subCategoryDriverOption.driverOptionName}`}
            />
          ))}
    </Stack>
  );
};
