import { AuthN, getConfig } from '@seek/online-identity';
import { AUTHENTICATION_TYPES, type EmployerSiteName } from '../types';
import { getCaAccountContext } from './candidate';
import { getHirerAccountContext } from './hirer';
import { mockHirerAccount, mockCaAccount } from './mockAccount';
import type { ConfigStateContext } from '../hooks/useConfig';

enum Site {
  hirer = 'seekAnz-hirer',
  candidate = 'seekAnz-candidate',
}

let authN: AuthN;

const getAuthN = (siteName: Site) => {
  if (!authN) {
    const clientConfig = getConfig(siteName, window.location);
    authN = new AuthN(clientConfig);
  }

  return authN;
};

const notAuthenticated = {
  authenticationStatus: AUTHENTICATION_TYPES.UNAUTHENTICATED,
  hirerAccountContext: {
    identityContext: null,
    user: null,
    advertiser: null,
  },
  id: undefined,
};

export const auth = async (config: ConfigStateContext) => {
  const { environment, site } = config;
  const isCandidate = site.startsWith('candidate');

  if (environment === 'development') {
    return {
      authenticationStatus: AUTHENTICATION_TYPES.AUTHENTICATED,
      hirerAccountContext: mockHirerAccount,
      caAccountContext: mockCaAccount,
      loginId: isCandidate ? mockCaAccount.id : mockHirerAccount.user?.id,
      seekAdvertiserId: isCandidate
        ? undefined
        : mockHirerAccount.advertiser?.billingId,
    };
  }

  const siteName = isCandidate ? Site.candidate : Site.hirer;
  const isAuthenticated = await getAuthN(siteName).isAuthenticated();

  if (!isAuthenticated) {
    return notAuthenticated;
  }

  if (isCandidate) {
    const { caAccountContext } = await getCaAccountContext(config);

    return {
      authenticationStatus: AUTHENTICATION_TYPES.AUTHENTICATED,
      caAccountContext,
      loginId: caAccountContext?.id,
    };
  }

  const { hirerAccountContext } = await getHirerAccountContext(
    site as EmployerSiteName,
  );

  return {
    authenticationStatus: AUTHENTICATION_TYPES.AUTHENTICATED,
    hirerAccountContext,
    loginId: hirerAccountContext?.user?.id,
    seekAdvertiserId: hirerAccountContext.advertiser?.billingId,
  };
};
