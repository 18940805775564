import { useMemo } from 'react';
import { useFields } from '../components/useFields';
import type { DriverList } from '../components/QueryFilter';
import { ALL_DRIVER } from '../datalabHelper';

export const useGenerateDriverList = (driverList: DriverList) => {
  const { primaryDriverSelect, secondaryDriverSelect } = useFields();

  return {
    primaryDriverList: useMemo(
      () => ({
        drivers: [{ name: ALL_DRIVER, options: [] }, ...driverList.drivers],
      }),
      [driverList],
    ),
    secondaryDriverList: useMemo(
      () => ({
        drivers: [{ name: '', options: [] }, ...driverList.drivers],
      }),
      [driverList],
    ),
    primaryOptionsList: driverList.drivers
      .find((d) => d.name === primaryDriverSelect.value)
      ?.options.map((option) => ({ value: option.name, label: option.name })),
    secondaryOptionsList: driverList.drivers
      .find((d) => d.name === secondaryDriverSelect.value)
      ?.options.map((option) => ({ value: option.name, label: option.name })),
  };
};
