import type { Country } from '@seek/melways-sites';
import format from 'format-number';

export const currencySymbolByCountry = (country: Country) => {
  switch (country) {
    case 'my':
      return 'RM';
    case 'id':
      return 'RP';
    case 'th':
      return '฿';
    case 'ph':
      return '₱';
    default:
      return '$';
  }
};

export const getCountryName = (country: Country): string => {
  try {
    return (
      new Intl.DisplayNames(['en'], { type: 'region' }).of(
        country.toUpperCase(),
      ) || country
    );
  } catch (e) {
    return country;
  }
};

export const formatCurrency = (amount: number, country: Country) =>
  format({ prefix: currencySymbolByCountry(country) })(amount);
