import {
  Box,
  IconHelp,
  Stack,
  Text,
  TooltipRenderer,
} from 'braid-design-system';
import { useId, type CSSProperties } from 'react';

interface BarProps {
  color: string;
  borderRadius: string;
  style?: CSSProperties;
}

const Bar: React.FC<BarProps> = ({ color, borderRadius, style, ...props }) => (
  <div
    style={{
      flex: 1,
      height: '8px',
      minWidth: '8px',
      backgroundColor: color,
      borderRadius,
      ...style,
    }}
    {...props}
  />
);

export interface BarChartItemPropsBase {
  label: string;
  value: number;
  maxValue: number;
  color?: string;
  showLabel?: boolean;
  showValue?: boolean;
  /**
   * If true, displays a tooltip over the bar when hovered.
   */
  showTooltip?: boolean;
  borderRadiusLeft?: CSSProperties['borderRadius'];
  borderRadiusRight?: CSSProperties['borderRadius'];
  valuePosition?: 'right' | 'bottom';
  width?: CSSProperties['width'];
  /**
   * Function to format the value.
   *
   * @default
   * (val: number, maxVal: number) => `${(val * 100 / maxVal).toFixed(decimalPoints)}%`
   */
  valueFormatter?: (
    value: number,
    maxValue: number,
    decimalPoints?: number,
  ) => string;
}

export type BarChartItemProps = BarChartItemPropsBase &
  (
    | { showInfoBox?: false; infoBoxContent?: never }
    | {
        showInfoBox: true;
        infoBoxContent: Parameters<typeof TooltipRenderer>[0]['tooltip'];
      }
  );

const defaultValueFormatter = (
  val: number,
  maxVal: number,
  decimalPoints: number = 1,
) => `${((val * 100) / maxVal).toFixed(decimalPoints)}%`;

/**
 * BarChartItem component displays a single bar chart item.
 *
 * @remarks
 * The percentage value is displayed by default. This can be changed via the `valueFormatter` property.
 *
 * @default
 * - color: '#3E8FE0'
 * - showLabel: true
 * - showValue: true
 * - borderRadiusLeft: '4px'
 * - borderRadiusRight: '4px'
 * - valuePosition: 'right'
 * - valueFormatter: (val: number, maxVal: number) => `${(val * 100 / maxVal)}%`
 */
export const BarChartItem: React.FC<BarChartItemProps> = ({
  label,
  value,
  color = '#3E8FE0',
  maxValue,
  showLabel = true,
  showValue = true,
  showTooltip = false,
  showInfoBox = false,
  borderRadiusLeft = '4px',
  borderRadiusRight = '4px',
  valuePosition = 'right',
  width,
  valueFormatter = defaultValueFormatter,
  infoBoxContent,
}) => {
  const id = useId();
  const formattedValue = valueFormatter(value, maxValue);
  const borderRadius = `${borderRadiusLeft} ${borderRadiusRight} ${borderRadiusRight} ${borderRadiusLeft}`;
  const hoverText = `${label.replaceAll('-', ' ')}: ${formattedValue}`;

  const renderBar = () => {
    const barProps = {
      color,

      borderRadius,
      style: { flex: 1, height: '8px' },
    };
    return showTooltip ? (
      <TooltipRenderer id={id} tooltip={<Text size="small">{hoverText}</Text>}>
        {({ triggerProps }) => (
          // Cannot reuse Bar component even with triggerProps because it doesn't render the tooltip correctly
          <div
            style={{
              flex: 1,
              width,
              height: '8px',
              backgroundColor: color,
              borderRadius,
            }}
            {...triggerProps}
          />
        )}
      </TooltipRenderer>
    ) : (
      <Bar {...barProps} />
    );
  };

  const renderValue = () => <Text size="small">{formattedValue}</Text>;

  const renderInfoBox = () =>
    showInfoBox &&
    infoBoxContent && (
      <TooltipRenderer id={id} tooltip={infoBoxContent}>
        {({ triggerProps }) => (
          <Box aria-label="Help" {...triggerProps}>
            <IconHelp size="small" />
          </Box>
        )}
      </TooltipRenderer>
    );

  return (
    <Box style={{ width: '100%', position: 'relative' }}>
      {label && showLabel && (
        <Box style={{ paddingBottom: '8px' }}>
          <Text size="small" component="label">
            {label}
          </Text>
        </Box>
      )}
      <Stack space="xsmall">
        <Box
          display="flex"
          alignItems="center"
          style={{
            width,

            gap: '8px',
          }}
        >
          {renderBar()}
          {showValue && valuePosition === 'right' && renderValue()}
          {renderInfoBox()}
        </Box>
        {showValue && valuePosition === 'bottom' && renderValue()}
      </Stack>
    </Box>
  );
};
