import type { DatalabDriverData } from '../../datalabHelper';

export const getDownloadableSummary = ({ driverOptions }: DatalabDriverData) =>
  driverOptions.map((driver) => ({
    driverName: driver.name,
    category: driver.categories.slice(0, 3).map((category) => ({
      name: category.name,
      mustHave: category.subCategories[0].name.toLowerCase(),
    })),
  }));
