import {
  ContentBlock,
  Box,
  TabsProvider,
  Stack,
  Tabs,
  Tab,
  TabPanels,
  TabPanel,
  Columns,
  Column,
  Heading,
  Spread,
  Strong,
  Divider,
  Text,
} from 'braid-design-system';
import { vars } from 'braid-design-system/css';
import { genData, type CountryCode, type Generations } from './data';

const skipToLeastHappinessIndex = 14;
const mostHappinessIndex = 5;
// Component from SKL-Carrer-guide-site
export const GenerationalDriversInteractive = ({
  country,
}: {
  country: string;
}) => {
  // Only render for AU and NZ
  if (!['au', 'nz'].includes(country)) {
    return null;
  }
  const filteredCountry = country as CountryCode;
  return (
    <ContentBlock width="small">
      <Box
        padding={{ tablet: 'large', mobile: 'medium' }}
        borderRadius="xlarge"
        boxShadow="borderNeutralLight"
      >
        <TabsProvider id="generational">
          <Stack space="medium">
            <TabSelection filteredCountry={filteredCountry} />
            <TabPanels>
              {Object.keys(genData[filteredCountry].generations).map((key) => (
                <TabPanel key={`panel-${key}`}>
                  <Stack space="large">
                    <Columns space="small" alignY="center">
                      <HeadingTitle
                        filteredCountry={filteredCountry}
                        generations={key as Generations}
                      />
                      <HappinessIndicator
                        filteredCountry={filteredCountry}
                        generations={key as Generations}
                      />
                    </Columns>
                    <HappinessTitle />
                    <MostHappinessStatistic
                      filteredCountry={filteredCountry}
                      generations={key}
                    />
                    <Columns space="medium" alignY="center">
                      <Column width="content">
                        <Text size="large">Least important</Text>
                      </Column>
                      <Column>
                        <Divider />
                      </Column>
                    </Columns>
                    <LeastHappinessStatistic
                      filteredCountry={filteredCountry}
                      generations={key}
                    />
                    <StatisticFooter
                      filteredCountry={filteredCountry}
                      generations={key}
                    />
                  </Stack>
                </TabPanel>
              ))}
            </TabPanels>
          </Stack>
        </TabsProvider>
      </Box>
    </ContentBlock>
  );
};

const TabSelection = ({
  filteredCountry,
}: {
  filteredCountry: CountryCode;
}) => (
  <Tabs label="Generation" align="center">
    {Object.keys(genData[filteredCountry].generations).map((key) => (
      <Tab key={`tab-${key}`}>
        {genData[filteredCountry].generations[key as Generations].title}
      </Tab>
    ))}
  </Tabs>
);

const HeadingTitle = ({
  filteredCountry,
  generations,
}: {
  filteredCountry: CountryCode;
  generations: Generations;
}) => (
  <Column>
    <Heading level="3">
      What impacts happiness at work for{' '}
      {genData[filteredCountry].generations[generations].title.replace(
        ' ',
        '\u00a0',
      )}
      ?
    </Heading>
  </Column>
);

const HappinessIndicator = ({
  filteredCountry,
  generations,
}: {
  filteredCountry: CountryCode;
  generations: Generations;
}) => {
  if (genData[filteredCountry].generations[generations].happiness > 0) {
    return (
      <Column width="content">
        <Box
          display="flex"
          borderRadius="full"
          background="brandAccent"
          textAlign="center"
          alignItems="center"
          justifyContent="center"
          style={{
            flexWrap: 'wrap',
            width: '84px',
            height: '84px',
            marginTop: `calc(-1 * ${vars.space.small})`,
            marginRight: `calc(-1 * ${vars.space.small})`,
            // Yes, an odd calculation, but it helps the % feel better
            paddingTop: `calc(${vars.space.xxsmall} / 3)`,
          }}
        >
          <Stack space="xxsmall">
            <Heading level="2">
              {genData[filteredCountry].generations[generations].happiness}%
            </Heading>
            <Text size="small">happy*</Text>
          </Stack>
        </Box>
      </Column>
    );
  }

  return null;
};

const HappinessTitle = () => (
  <Spread space="gutter">
    <Text size="large">Most important</Text>
    <Text size="large">% happy</Text>
  </Spread>
);

const MostHappinessStatistic = ({
  filteredCountry,
  generations,
}: {
  filteredCountry: CountryCode;
  generations: string;
}) => (
  <Stack space="medium">
    {genData[filteredCountry].generations[generations as Generations].data
      .slice(0, mostHappinessIndex) // Only show the top 5
      .map((item, idx) => (
        <Stack key={`high-${generations}-${item.driver}`} space="small">
          <Spread space="gutter" alignY="bottom">
            <Text>
              <Strong>{idx + 1}.</Strong> {item.driver}
            </Text>
            <Text size="small">{item.value}%</Text>
          </Spread>
          <Box overflow="hidden" borderRadius="large" background="neutralSoft">
            <Box
              style={{
                width: `${item.value}%`,
                height: vars.space.xsmall,
                backgroundColor: vars.backgroundColor.brandAccent,
                borderRight: '3px solid white',
              }}
            >
              &nbsp;
            </Box>
          </Box>
        </Stack>
      ))}
  </Stack>
);

const LeastHappinessStatistic = ({
  filteredCountry,
  generations,
}: {
  filteredCountry: CountryCode;
  generations: string;
}) => (
  <Stack space={'medium'}>
    {genData[filteredCountry].generations[generations as Generations]?.data
      .slice(skipToLeastHappinessIndex) // Only show after 15th
      .map((item, idx) => (
        <Stack key={`low-${item.driver}`} space="small">
          <Spread space="gutter" alignY="bottom">
            <Text>
              <Strong>{idx + 1 + skipToLeastHappinessIndex}.</Strong>{' '}
              {item.driver}
            </Text>
            <Text size="small">{item.value}%</Text>
          </Spread>
          <Box overflow="hidden" borderRadius="large" background="neutralSoft">
            <Box
              style={{
                width: `${item.value}%`,
                height: vars.space.xsmall,
                backgroundColor: vars.backgroundColor.brandAccentSoftActive,
                borderRight: '3px solid white',
              }}
            >
              &nbsp;
            </Box>
          </Box>
        </Stack>
      ))}
  </Stack>
);

const StatisticFooter = ({
  filteredCountry,
  generations,
}: {
  filteredCountry: CountryCode;
  generations: string;
}) => {
  if (
    genData[filteredCountry].generations[generations as Generations].happiness >
    0
  ) {
    return (
      <Text align="right" size="xsmall" tone="secondary">
        * somewhat / extremely happy at work overall
      </Text>
    );
  }
  return null;
};
