import type { GetPagesQuery } from '@seek/cmsu-cms-connect';
import React from 'react';
import { useParams, useLoaderData } from 'react-router';
import { useConfig } from 'src/client/shared/hooks';

import { PageComponentWrapper } from '../../components/PageComponent/PageComponentWrapper';
import { usePageTracking } from 'src/client/shared/hooks/usePageTracking';

export const PageWithoutSection = () => {
  const { fullURL, melwaysLocale } = useConfig();
  const { slug } = useParams();
  const page = useLoaderData() as GetPagesQuery['pages'][0];

  usePageTracking(page.title.toLocaleLowerCase(), slug);

  return (
    <PageComponentWrapper
      page={page}
      fullURL={fullURL}
      melwaysLocale={melwaysLocale}
    />
  );
};
