import type { FC, PropsWithChildren } from 'react';
import { View, StyleSheet, type Styles } from '@react-pdf/renderer';
type PdfCardProps = PropsWithChildren<View['props']>;

const styles = StyleSheet.create({
  card: {
    display: 'flex',
    backgroundColor: '#ffffff',
    padding: '29px 30px 29px 30px',
    paddingRight: '30px',
    border: '2px solid #F3F5F7',
    borderRadius: '16px',
  },
});

export const PdfCard: FC<PdfCardProps> = ({ children, ...props }) => (
  <View {...props} style={{ ...styles.card, ...(props.style as Styles) }}>
    {children}
  </View>
);
