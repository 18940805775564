import { Document, Page, StyleSheet, View } from '@react-pdf/renderer';
import { TalPdfFooter } from './footer';
import { TalPdfHeader } from './header';
import { TalPdfSummary, type PdfDataSummary } from './summary';

const style = StyleSheet.create({
  container: {
    maxWidth: '660px',
    margin: '60px auto',
    display: 'flex',
    flexDirection: 'column',
  },
});

export const PdfDoc = ({ data }: { data: PdfDataSummary[] }) => (
  <Document>
    <Page size="A2" style={{ fontFamily: 'Helvetica' }}>
      <View>
        <TalPdfHeader />
        <View style={style.container}>
          <TalPdfSummary data={data} />
          <TalPdfFooter />
        </View>
      </View>
    </Page>
  </Document>
);
