import type { Locale } from '@seek/melways-sites';

export const getPrivacyLink = (locale: Locale) => {
  switch (locale) {
    case 'en-MY':
    case 'en-SG':
    case 'en-ID':
    case 'en-PH':
    case 'en-HK':
    case 'en-TH':
      return 'privacy policy';
    case 'id-ID':
      return 'kebijakan privasi';
    case 'th-TH':
      return 'นโยบายความเป็นส่วนตัว';
    default:
      return 'SEEK Privacy Statement';
  }
};
