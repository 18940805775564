import type React from 'react';
import { BraidProvider } from 'braid-design-system';
import apacTheme from 'braid-design-system/themes/apac';
import seekJobs from 'braid-design-system/themes/seekJobs';

const getBraidTheme = (theme: string) => {
  switch (theme) {
    case 'seekJobs':
      return seekJobs;
    default:
      return apacTheme;
  }
};

export const BraidWrapper = ({
  children,
  theme = 'apac',
}: {
  children: React.ReactNode;
  theme?: string;
}) => <BraidProvider theme={getBraidTheme(theme)}>{children}</BraidProvider>;
