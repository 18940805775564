import { Box, CheckboxStandalone, Text } from 'braid-design-system';
import { type FC, useId } from 'react';
import {
  barchartFilterContainer,
  barchartFilterTile,
} from './BarChartFilter.css';

type BarChartFilterOption = {
  id: string;
  label: string;
  color: string;
};

type BarChartFilterProps = {
  filterOptions: BarChartFilterOption[];
  onChange: (selectedValues: Record<string, boolean>) => void;
  values: Record<string, boolean>;
};

export const BarChartFilter: FC<BarChartFilterProps> = ({
  filterOptions,
  onChange,
  values,
}) => {
  const id = useId();

  const handleCheckboxChange = (valueId: string) => {
    onChange({ ...values, [valueId]: !values[valueId] });
  };

  return (
    <Box background="neutralLight" padding={'small'} borderRadius={'large'}>
      <Box className={barchartFilterContainer}>
        <Text weight="strong" size="small">
          Refine data
        </Text>

        <Box className={barchartFilterTile}>
          {filterOptions.map((item) => (
            <Box key={`${id}_${item.id}`}>
              <Box display="flex" alignItems="center" style={{ gap: '12px' }}>
                <CheckboxStandalone
                  size="small"
                  id={`${id}_${item.id}`}
                  aria-label={item.label}
                  checked={values[item.id]}
                  onChange={() => handleCheckboxChange(item.id)}
                />
                <Box
                  component="label"
                  htmlFor={`${id}_${item.id}`}
                  display="flex"
                  alignItems="center"
                  style={{ gap: '12px' }}
                >
                  <Box
                    borderRadius={'full'}
                    style={{
                      backgroundColor: item.color,
                      width: '12px',
                      height: '12px',
                    }}
                  />
                  <Box style={{ whiteSpace: 'nowrap' }}>
                    <Text size="small" component={'span'}>
                      {item.label}
                    </Text>
                  </Box>
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};
