export const AU_DATA = [
  {
    industryIdFilter: -1,
    roles: [
      {
        rank: 454,
        industryId: 1225,
        industryTitle: 'Trades & Services',
        roleTitle: 'Fitter Technician',
        avgSalary: 93400,
        salaryGrowth: 28,
        jobs: 55,
      },
      {
        rank: 321,
        industryId: 6092,
        industryTitle: 'Manufacturing, Transport & Logistics',
        roleTitle: 'Licenced Aircraft Maintenance Engineer',
        avgSalary: 144800,
        salaryGrowth: 27,
        jobs: 82,
      },
      {
        rank: 218,
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        roleTitle: 'Personal Carer Residential',
        avgSalary: 62400,
        salaryGrowth: 25,
        jobs: 78,
      },
      {
        rank: 86,
        industryId: 6163,
        industryTitle: 'Community Services & Development',
        roleTitle: 'Support Assistant',
        avgSalary: 69700,
        salaryGrowth: 24,
        jobs: 93,
      },
      {
        rank: 322,
        industryId: 6092,
        industryTitle: 'Manufacturing, Transport & Logistics',
        roleTitle: 'Roadtrain Operator',
        avgSalary: 113200,
        salaryGrowth: 24,
        jobs: 163,
      },
      {
        rank: 401,
        industryId: 6043,
        industryTitle: 'Retail & Consumer Products',
        roleTitle: 'Retail Team Leader',
        avgSalary: 60200,
        salaryGrowth: 21,
        jobs: 94,
      },
      {
        rank: 402,
        industryId: 6043,
        industryTitle: 'Retail & Consumer Products',
        roleTitle: 'Team Member',
        avgSalary: 54400,
        salaryGrowth: 21,
        jobs: 1201,
      },
      {
        rank: 21,
        industryId: 6251,
        industryTitle: 'Administration & Office Support',
        roleTitle: 'Planner',
        avgSalary: 82800,
        salaryGrowth: 21,
        jobs: 52,
      },
      {
        rank: 165,
        industryId: 1209,
        industryTitle: 'Engineering',
        roleTitle: 'Operations Engineer',
        avgSalary: 123500,
        salaryGrowth: 19,
        jobs: 50,
      },
      {
        rank: 219,
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        roleTitle: 'Carer',
        avgSalary: 63200,
        salaryGrowth: 18,
        jobs: 450,
      },
      {
        rank: 87,
        industryId: 6163,
        industryTitle: 'Community Services & Development',
        roleTitle: 'Carer',
        avgSalary: 65300,
        salaryGrowth: 18,
        jobs: 523,
      },
      {
        rank: 323,
        industryId: 6092,
        industryTitle: 'Manufacturing, Transport & Logistics',
        roleTitle: 'Service Manager',
        avgSalary: 129200,
        salaryGrowth: 18,
        jobs: 77,
      },
      {
        rank: 361,
        industryId: 6058,
        industryTitle: 'Mining, Resources & Energy',
        roleTitle: 'Estimator',
        avgSalary: 142800,
        salaryGrowth: 17,
        jobs: 64,
      },
      {
        rank: 88,
        industryId: 6163,
        industryTitle: 'Community Services & Development',
        roleTitle: 'Lifestyle Assistant',
        avgSalary: 63300,
        salaryGrowth: 17,
        jobs: 144,
      },
      {
        rank: 89,
        industryId: 6163,
        industryTitle: 'Community Services & Development',
        roleTitle: 'Cleaner',
        avgSalary: 61400,
        salaryGrowth: 16,
        jobs: 153,
      },
      {
        rank: 403,
        industryId: 6043,
        industryTitle: 'Retail & Consumer Products',
        roleTitle: 'Sales Representative',
        avgSalary: 66100,
        salaryGrowth: 16,
        jobs: 151,
      },
      {
        rank: 106,
        industryId: 1206,
        industryTitle: 'Construction',
        roleTitle: 'Traffic Controller',
        avgSalary: 75500,
        salaryGrowth: 16,
        jobs: 141,
      },
      {
        rank: 298,
        industryId: 1214,
        industryTitle: 'Insurance & Superannuation',
        roleTitle: 'Claims Officer',
        avgSalary: 86300,
        salaryGrowth: 16,
        jobs: 155,
      },
      {
        rank: 90,
        industryId: 6163,
        industryTitle: 'Community Services & Development',
        roleTitle: 'Careworker',
        avgSalary: 65900,
        salaryGrowth: 16,
        jobs: 2017,
      },
      {
        rank: 91,
        industryId: 6163,
        industryTitle: 'Community Services & Development',
        roleTitle: 'Personal Care Assistant',
        avgSalary: 65400,
        salaryGrowth: 16,
        jobs: 337,
      },
    ],
  },
  {
    industryIdFilter: 1200,
    roles: [
      {
        industryId: 1200,
        industryTitle: 'Accounting',
        roleTitle: 'Consultant',
        avgSalary: 102200,
        salaryGrowth: 15,
        jobs: 67,
      },
      {
        industryId: 1200,
        industryTitle: 'Accounting',
        roleTitle: 'Business Manager',
        avgSalary: 137500,
        salaryGrowth: 14,
        jobs: 62,
      },
      {
        industryId: 1200,
        industryTitle: 'Accounting',
        roleTitle: 'Pricing Analyst',
        avgSalary: 112100,
        salaryGrowth: 13,
        jobs: 64,
      },
      {
        industryId: 1200,
        industryTitle: 'Accounting',
        roleTitle: 'Reporting Manager',
        avgSalary: 160300,
        salaryGrowth: 13,
        jobs: 88,
      },
      {
        industryId: 1200,
        industryTitle: 'Accounting',
        roleTitle: 'Finance and Administration Manager',
        avgSalary: 113900,
        salaryGrowth: 11,
        jobs: 51,
      },
      {
        industryId: 1200,
        industryTitle: 'Accounting',
        roleTitle: 'Payroll Coordinator',
        avgSalary: 87300,
        salaryGrowth: 10,
        jobs: 50,
      },
      {
        industryId: 1200,
        industryTitle: 'Accounting',
        roleTitle: 'Payroll Specialist',
        avgSalary: 99200,
        salaryGrowth: 9,
        jobs: 188,
      },
      {
        industryId: 1200,
        industryTitle: 'Accounting',
        roleTitle: 'Payroll and Accounts Officer',
        avgSalary: 76200,
        salaryGrowth: 8,
        jobs: 149,
      },
      {
        industryId: 1200,
        industryTitle: 'Accounting',
        roleTitle: 'Financial Planning and Analysis Analyst',
        avgSalary: 128200,
        salaryGrowth: 8,
        jobs: 92,
      },
      {
        industryId: 1200,
        industryTitle: 'Accounting',
        roleTitle: 'Financial Administrator',
        avgSalary: 76000,
        salaryGrowth: 8,
        jobs: 137,
      },
      {
        industryId: 1200,
        industryTitle: 'Accounting',
        roleTitle: 'Finance and Administration Officer',
        avgSalary: 73800,
        salaryGrowth: 8,
        jobs: 63,
      },
      {
        industryId: 1200,
        industryTitle: 'Accounting',
        roleTitle: 'Commercial Manager',
        avgSalary: 176200,
        salaryGrowth: 7,
        jobs: 119,
      },
      {
        industryId: 1200,
        industryTitle: 'Accounting',
        roleTitle: 'Office Administrator',
        avgSalary: 69200,
        salaryGrowth: 7,
        jobs: 81,
      },
      {
        industryId: 1200,
        industryTitle: 'Accounting',
        roleTitle: 'Administration Officer',
        avgSalary: 68900,
        salaryGrowth: 6,
        jobs: 262,
      },
      {
        industryId: 1200,
        industryTitle: 'Accounting',
        roleTitle: 'Taxation Consultant',
        avgSalary: 100300,
        salaryGrowth: 6,
        jobs: 77,
      },
      {
        industryId: 1200,
        industryTitle: 'Accounting',
        roleTitle: 'Financial Assistant',
        avgSalary: 68400,
        salaryGrowth: 6,
        jobs: 97,
      },
      {
        industryId: 1200,
        industryTitle: 'Accounting',
        roleTitle: 'Taxation Manager',
        avgSalary: 149700,
        salaryGrowth: 6,
        jobs: 203,
      },
      {
        industryId: 1200,
        industryTitle: 'Accounting',
        roleTitle: 'Accounts Payable and Receivable Officer',
        avgSalary: 69400,
        salaryGrowth: 6,
        jobs: 176,
      },
      {
        industryId: 1200,
        industryTitle: 'Accounting',
        roleTitle: 'Collections Officer',
        avgSalary: 72300,
        salaryGrowth: 6,
        jobs: 81,
      },
      {
        industryId: 1200,
        industryTitle: 'Accounting',
        roleTitle: 'Accounts Receivable Clerk',
        avgSalary: 63900,
        salaryGrowth: 5,
        jobs: 56,
      },
    ],
  },
  {
    industryIdFilter: 1203,
    roles: [
      {
        industryId: 1203,
        industryTitle: 'Banking & Financial Services',
        roleTitle: 'Accountant',
        avgSalary: 105400,
        salaryGrowth: 12,
        jobs: 98,
      },
      {
        industryId: 1203,
        industryTitle: 'Banking & Financial Services',
        roleTitle: 'Risk Analyst',
        avgSalary: 118200,
        salaryGrowth: 12,
        jobs: 88,
      },
      {
        industryId: 1203,
        industryTitle: 'Banking & Financial Services',
        roleTitle: 'Lending Manager',
        avgSalary: 119700,
        salaryGrowth: 11,
        jobs: 62,
      },
      {
        industryId: 1203,
        industryTitle: 'Banking & Financial Services',
        roleTitle: 'Banking Manager',
        avgSalary: 132700,
        salaryGrowth: 9,
        jobs: 63,
      },
      {
        industryId: 1203,
        industryTitle: 'Banking & Financial Services',
        roleTitle: 'Settlements Officer',
        avgSalary: 73300,
        salaryGrowth: 9,
        jobs: 64,
      },
      {
        industryId: 1203,
        industryTitle: 'Banking & Financial Services',
        roleTitle: 'Partner',
        avgSalary: 143600,
        salaryGrowth: 7,
        jobs: 120,
      },
      {
        industryId: 1203,
        industryTitle: 'Banking & Financial Services',
        roleTitle: 'Team Leader',
        avgSalary: 102900,
        salaryGrowth: 7,
        jobs: 64,
      },
      {
        industryId: 1203,
        industryTitle: 'Banking & Financial Services',
        roleTitle: 'Banking Consultant',
        avgSalary: 63000,
        salaryGrowth: 7,
        jobs: 265,
      },
      {
        industryId: 1203,
        industryTitle: 'Banking & Financial Services',
        roleTitle: 'Business Development Manager',
        avgSalary: 138300,
        salaryGrowth: 7,
        jobs: 136,
      },
      {
        industryId: 1203,
        industryTitle: 'Banking & Financial Services',
        roleTitle: 'Manager',
        avgSalary: 155400,
        salaryGrowth: 6,
        jobs: 171,
      },
      {
        industryId: 1203,
        industryTitle: 'Banking & Financial Services',
        roleTitle: 'Loan Specialist',
        avgSalary: 98300,
        salaryGrowth: 6,
        jobs: 69,
      },
      {
        industryId: 1203,
        industryTitle: 'Banking & Financial Services',
        roleTitle: 'Services Officer',
        avgSalary: 71800,
        salaryGrowth: 5,
        jobs: 345,
      },
      {
        industryId: 1203,
        industryTitle: 'Banking & Financial Services',
        roleTitle: 'Risk Manager',
        avgSalary: 157500,
        salaryGrowth: 4,
        jobs: 172,
      },
      {
        industryId: 1203,
        industryTitle: 'Banking & Financial Services',
        roleTitle: 'Finance Manager',
        avgSalary: 131800,
        salaryGrowth: 4,
        jobs: 77,
      },
      {
        industryId: 1203,
        industryTitle: 'Banking & Financial Services',
        roleTitle: 'Compliance Officer',
        avgSalary: 94500,
        salaryGrowth: 4,
        jobs: 87,
      },
      {
        industryId: 1203,
        industryTitle: 'Banking & Financial Services',
        roleTitle: 'Investment Analyst',
        avgSalary: 129200,
        salaryGrowth: 4,
        jobs: 109,
      },
      {
        industryId: 1203,
        industryTitle: 'Banking & Financial Services',
        roleTitle: 'Banking Adviser',
        avgSalary: 60600,
        salaryGrowth: 4,
        jobs: 57,
      },
      {
        industryId: 1203,
        industryTitle: 'Banking & Financial Services',
        roleTitle: 'Branch Manager',
        avgSalary: 102200,
        salaryGrowth: 4,
        jobs: 189,
      },
      {
        industryId: 1203,
        industryTitle: 'Banking & Financial Services',
        roleTitle: 'Analyst',
        avgSalary: 110900,
        salaryGrowth: 3,
        jobs: 221,
      },
      {
        industryId: 1203,
        industryTitle: 'Banking & Financial Services',
        roleTitle: 'Customer Service Representative',
        avgSalary: 61600,
        salaryGrowth: 3,
        jobs: 348,
      },
    ],
  },
  {
    industryIdFilter: 1204,
    roles: [
      {
        industryId: 1204,
        industryTitle: 'Call Centre & Customer Service',
        roleTitle: 'Centre Manager',
        avgSalary: 112300,
        salaryGrowth: 14,
        jobs: 60,
      },
      {
        industryId: 1204,
        industryTitle: 'Call Centre & Customer Service',
        roleTitle: 'Sales Representative',
        avgSalary: 66900,
        salaryGrowth: 9,
        jobs: 204,
      },
      {
        industryId: 1204,
        industryTitle: 'Call Centre & Customer Service',
        roleTitle: 'Support Specialist',
        avgSalary: 67800,
        salaryGrowth: 9,
        jobs: 111,
      },
      {
        industryId: 1204,
        industryTitle: 'Call Centre & Customer Service',
        roleTitle: 'Claims Consultant',
        avgSalary: 64500,
        salaryGrowth: 9,
        jobs: 53,
      },
      {
        industryId: 1204,
        industryTitle: 'Call Centre & Customer Service',
        roleTitle: 'Parking Attendant',
        avgSalary: 57100,
        salaryGrowth: 8,
        jobs: 55,
      },
      {
        industryId: 1204,
        industryTitle: 'Call Centre & Customer Service',
        roleTitle: 'Collections Officer',
        avgSalary: 68500,
        salaryGrowth: 7,
        jobs: 162,
      },
      {
        industryId: 1204,
        industryTitle: 'Call Centre & Customer Service',
        roleTitle: 'Customer Service Coordinator',
        avgSalary: 68600,
        salaryGrowth: 7,
        jobs: 161,
      },
      {
        industryId: 1204,
        industryTitle: 'Call Centre & Customer Service',
        roleTitle: 'Customer Service Team Leader',
        avgSalary: 83400,
        salaryGrowth: 7,
        jobs: 162,
      },
      {
        industryId: 1204,
        industryTitle: 'Call Centre & Customer Service',
        roleTitle: 'Consultant',
        avgSalary: 64600,
        salaryGrowth: 6,
        jobs: 82,
      },
      {
        industryId: 1204,
        industryTitle: 'Call Centre & Customer Service',
        roleTitle: 'Customer Service Specialist',
        avgSalary: 62300,
        salaryGrowth: 6,
        jobs: 344,
      },
      {
        industryId: 1204,
        industryTitle: 'Call Centre & Customer Service',
        roleTitle: 'Service Adviser',
        avgSalary: 68900,
        salaryGrowth: 6,
        jobs: 95,
      },
      {
        industryId: 1204,
        industryTitle: 'Call Centre & Customer Service',
        roleTitle: 'Centre Operator',
        avgSalary: 62700,
        salaryGrowth: 6,
        jobs: 428,
      },
      {
        industryId: 1204,
        industryTitle: 'Call Centre & Customer Service',
        roleTitle: 'Service Coordinator',
        avgSalary: 69000,
        salaryGrowth: 5,
        jobs: 50,
      },
      {
        industryId: 1204,
        industryTitle: 'Call Centre & Customer Service',
        roleTitle: 'Support Officer',
        avgSalary: 65200,
        salaryGrowth: 5,
        jobs: 426,
      },
      {
        industryId: 1204,
        industryTitle: 'Call Centre & Customer Service',
        roleTitle: 'Contact Officer',
        avgSalary: 60300,
        salaryGrowth: 5,
        jobs: 64,
      },
      {
        industryId: 1204,
        industryTitle: 'Call Centre & Customer Service',
        roleTitle: 'Customer Service Representative',
        avgSalary: 62800,
        salaryGrowth: 4,
        jobs: 3964,
      },
      {
        industryId: 1204,
        industryTitle: 'Call Centre & Customer Service',
        roleTitle: 'Team Leader',
        avgSalary: 86100,
        salaryGrowth: 4,
        jobs: 132,
      },
      {
        industryId: 1204,
        industryTitle: 'Call Centre & Customer Service',
        roleTitle: 'Customer Service Manager',
        avgSalary: 96700,
        salaryGrowth: 4,
        jobs: 170,
      },
      {
        industryId: 1204,
        industryTitle: 'Call Centre & Customer Service',
        roleTitle: 'Call Centre Operator',
        avgSalary: 60800,
        salaryGrowth: 3,
        jobs: 119,
      },
      {
        industryId: 1204,
        industryTitle: 'Call Centre & Customer Service',
        roleTitle: 'Care Consultant',
        avgSalary: 60500,
        salaryGrowth: 3,
        jobs: 406,
      },
    ],
  },
  {
    industryIdFilter: 1206,
    roles: [
      {
        industryId: 1206,
        industryTitle: 'Construction',
        roleTitle: 'Traffic Controller',
        avgSalary: 75500,
        salaryGrowth: 16,
        jobs: 141,
      },
      {
        industryId: 1206,
        industryTitle: 'Construction',
        roleTitle: 'Pump Operator',
        avgSalary: 95400,
        salaryGrowth: 15,
        jobs: 81,
      },
      {
        industryId: 1206,
        industryTitle: 'Construction',
        roleTitle: 'Health Safety and Environment Manager',
        avgSalary: 167100,
        salaryGrowth: 13,
        jobs: 57,
      },
      {
        industryId: 1206,
        industryTitle: 'Construction',
        roleTitle: 'Construction Supervisor',
        avgSalary: 119000,
        salaryGrowth: 13,
        jobs: 365,
      },
      {
        industryId: 1206,
        industryTitle: 'Construction',
        roleTitle: 'Site Engineer',
        avgSalary: 114700,
        salaryGrowth: 10,
        jobs: 111,
      },
      {
        industryId: 1206,
        industryTitle: 'Construction',
        roleTitle: 'Health and Safety Adviser',
        avgSalary: 136400,
        salaryGrowth: 10,
        jobs: 91,
      },
      {
        industryId: 1206,
        industryTitle: 'Construction',
        roleTitle: 'Crane Operator',
        avgSalary: 97700,
        salaryGrowth: 10,
        jobs: 290,
      },
      {
        industryId: 1206,
        industryTitle: 'Construction',
        roleTitle: 'Project Administrator',
        avgSalary: 88800,
        salaryGrowth: 9,
        jobs: 108,
      },
      {
        industryId: 1206,
        industryTitle: 'Construction',
        roleTitle: 'Scraper Operator',
        avgSalary: 100600,
        salaryGrowth: 9,
        jobs: 74,
      },
      {
        industryId: 1206,
        industryTitle: 'Construction',
        roleTitle: 'Quantity Surveyor',
        avgSalary: 133400,
        salaryGrowth: 9,
        jobs: 273,
      },
      {
        industryId: 1206,
        industryTitle: 'Construction',
        roleTitle: 'Team Leader',
        avgSalary: 92200,
        salaryGrowth: 9,
        jobs: 77,
      },
      {
        industryId: 1206,
        industryTitle: 'Construction',
        roleTitle: 'Administration Officer',
        avgSalary: 77500,
        salaryGrowth: 8,
        jobs: 71,
      },
      {
        industryId: 1206,
        industryTitle: 'Construction',
        roleTitle: 'Bobcat Operator',
        avgSalary: 77700,
        salaryGrowth: 8,
        jobs: 58,
      },
      {
        industryId: 1206,
        industryTitle: 'Construction',
        roleTitle: 'Dozer Operator',
        avgSalary: 100500,
        salaryGrowth: 8,
        jobs: 101,
      },
      {
        industryId: 1206,
        industryTitle: 'Construction',
        roleTitle: 'Draftsperson',
        avgSalary: 85800,
        salaryGrowth: 8,
        jobs: 60,
      },
      {
        industryId: 1206,
        industryTitle: 'Construction',
        roleTitle: 'Project Officer',
        avgSalary: 102400,
        salaryGrowth: 7,
        jobs: 98,
      },
      {
        industryId: 1206,
        industryTitle: 'Construction',
        roleTitle: 'Operator',
        avgSalary: 85200,
        salaryGrowth: 7,
        jobs: 249,
      },
      {
        industryId: 1206,
        industryTitle: 'Construction',
        roleTitle: 'Project Engineer',
        avgSalary: 156800,
        salaryGrowth: 7,
        jobs: 380,
      },
      {
        industryId: 1206,
        industryTitle: 'Construction',
        roleTitle: 'Grader Operator',
        avgSalary: 100400,
        salaryGrowth: 6,
        jobs: 226,
      },
      {
        industryId: 1206,
        industryTitle: 'Construction',
        roleTitle: 'Health Safety and Environment Adviser',
        avgSalary: 135100,
        salaryGrowth: 6,
        jobs: 384,
      },
    ],
  },
  {
    industryIdFilter: 1209,
    roles: [
      {
        industryId: 1209,
        industryTitle: 'Engineering',
        roleTitle: 'Operations Engineer',
        avgSalary: 123500,
        salaryGrowth: 19,
        jobs: 50,
      },
      {
        industryId: 1209,
        industryTitle: 'Engineering',
        roleTitle: 'Maintenance Supervisor',
        avgSalary: 119800,
        salaryGrowth: 14,
        jobs: 55,
      },
      {
        industryId: 1209,
        industryTitle: 'Engineering',
        roleTitle: 'Coordinator',
        avgSalary: 128700,
        salaryGrowth: 13,
        jobs: 93,
      },
      {
        industryId: 1209,
        industryTitle: 'Engineering',
        roleTitle: 'Mechatronics Engineer',
        avgSalary: 103500,
        salaryGrowth: 12,
        jobs: 89,
      },
      {
        industryId: 1209,
        industryTitle: 'Engineering',
        roleTitle: 'Electrical Engineer',
        avgSalary: 133000,
        salaryGrowth: 12,
        jobs: 1269,
      },
      {
        industryId: 1209,
        industryTitle: 'Engineering',
        roleTitle: 'Field Engineer',
        avgSalary: 113400,
        salaryGrowth: 11,
        jobs: 60,
      },
      {
        industryId: 1209,
        industryTitle: 'Engineering',
        roleTitle: 'Commissioning Engineer',
        avgSalary: 139300,
        salaryGrowth: 10,
        jobs: 63,
      },
      {
        industryId: 1209,
        industryTitle: 'Engineering',
        roleTitle: 'Applications Engineer',
        avgSalary: 104000,
        salaryGrowth: 9,
        jobs: 97,
      },
      {
        industryId: 1209,
        industryTitle: 'Engineering',
        roleTitle: 'Manager',
        avgSalary: 176300,
        salaryGrowth: 8,
        jobs: 122,
      },
      {
        industryId: 1209,
        industryTitle: 'Engineering',
        roleTitle: 'Licenced Aircraft Maintenance Engineer',
        avgSalary: 127400,
        salaryGrowth: 8,
        jobs: 279,
      },
      {
        industryId: 1209,
        industryTitle: 'Engineering',
        roleTitle: 'Protection Engineer',
        avgSalary: 141900,
        salaryGrowth: 8,
        jobs: 92,
      },
      {
        industryId: 1209,
        industryTitle: 'Engineering',
        roleTitle: 'Designer',
        avgSalary: 121700,
        salaryGrowth: 8,
        jobs: 851,
      },
      {
        industryId: 1209,
        industryTitle: 'Engineering',
        roleTitle: 'Water Engineer',
        avgSalary: 122300,
        salaryGrowth: 7,
        jobs: 90,
      },
      {
        industryId: 1209,
        industryTitle: 'Engineering',
        roleTitle: 'Design Engineer',
        avgSalary: 120600,
        salaryGrowth: 7,
        jobs: 979,
      },
      {
        industryId: 1209,
        industryTitle: 'Engineering',
        roleTitle: 'Automation Engineer',
        avgSalary: 124100,
        salaryGrowth: 7,
        jobs: 163,
      },
      {
        industryId: 1209,
        industryTitle: 'Engineering',
        roleTitle: 'Process Engineer',
        avgSalary: 134300,
        salaryGrowth: 6,
        jobs: 357,
      },
      {
        industryId: 1209,
        industryTitle: 'Engineering',
        roleTitle: 'Asset Engineer',
        avgSalary: 131700,
        salaryGrowth: 6,
        jobs: 132,
      },
      {
        industryId: 1209,
        industryTitle: 'Engineering',
        roleTitle: 'Structural Engineer',
        avgSalary: 126300,
        salaryGrowth: 6,
        jobs: 832,
      },
      {
        industryId: 1209,
        industryTitle: 'Engineering',
        roleTitle: 'Development Engineer',
        avgSalary: 113000,
        salaryGrowth: 6,
        jobs: 167,
      },
      {
        industryId: 1209,
        industryTitle: 'Engineering',
        roleTitle: 'Specialist',
        avgSalary: 137900,
        salaryGrowth: 5,
        jobs: 52,
      },
    ],
  },
  {
    industryIdFilter: 1210,
    roles: [
      {
        industryId: 1210,
        industryTitle: 'Government & Defence',
        roleTitle: 'Support Officer',
        avgSalary: 83800,
        salaryGrowth: 8,
        jobs: 296,
      },
      {
        industryId: 1210,
        industryTitle: 'Government & Defence',
        roleTitle: 'Ranger',
        avgSalary: 81300,
        salaryGrowth: 8,
        jobs: 108,
      },
      {
        industryId: 1210,
        industryTitle: 'Government & Defence',
        roleTitle: 'Management Officer',
        avgSalary: 91500,
        salaryGrowth: 7,
        jobs: 104,
      },
      {
        industryId: 1210,
        industryTitle: 'Government & Defence',
        roleTitle: 'Project Manager',
        avgSalary: 135100,
        salaryGrowth: 7,
        jobs: 163,
      },
      {
        industryId: 1210,
        industryTitle: 'Government & Defence',
        roleTitle: 'Inspector',
        avgSalary: 103700,
        salaryGrowth: 7,
        jobs: 82,
      },
      {
        industryId: 1210,
        industryTitle: 'Government & Defence',
        roleTitle: 'Compliance Officer',
        avgSalary: 92200,
        salaryGrowth: 5,
        jobs: 162,
      },
      {
        industryId: 1210,
        industryTitle: 'Government & Defence',
        roleTitle: 'Service Manager',
        avgSalary: 137300,
        salaryGrowth: 5,
        jobs: 58,
      },
      {
        industryId: 1210,
        industryTitle: 'Government & Defence',
        roleTitle: 'Project Officer',
        avgSalary: 105000,
        salaryGrowth: 4,
        jobs: 379,
      },
      {
        industryId: 1210,
        industryTitle: 'Government & Defence',
        roleTitle: 'Health Officer',
        avgSalary: 91700,
        salaryGrowth: 4,
        jobs: 105,
      },
      {
        industryId: 1210,
        industryTitle: 'Government & Defence',
        roleTitle: 'Assistant Director',
        avgSalary: 120600,
        salaryGrowth: 4,
        jobs: 519,
      },
      {
        industryId: 1210,
        industryTitle: 'Government & Defence',
        roleTitle: 'Planner',
        avgSalary: 99800,
        salaryGrowth: 3,
        jobs: 150,
      },
      {
        industryId: 1210,
        industryTitle: 'Government & Defence',
        roleTitle: 'Manager',
        avgSalary: 141100,
        salaryGrowth: 3,
        jobs: 340,
      },
      {
        industryId: 1210,
        industryTitle: 'Government & Defence',
        roleTitle: 'Coordinator',
        avgSalary: 108600,
        salaryGrowth: 3,
        jobs: 195,
      },
      {
        industryId: 1210,
        industryTitle: 'Government & Defence',
        roleTitle: 'Adviser',
        avgSalary: 113100,
        salaryGrowth: 2,
        jobs: 179,
      },
      {
        industryId: 1210,
        industryTitle: 'Government & Defence',
        roleTitle: 'Officer',
        avgSalary: 89300,
        salaryGrowth: 2,
        jobs: 190,
      },
      {
        industryId: 1210,
        industryTitle: 'Government & Defence',
        roleTitle: 'Program Officer',
        avgSalary: 96100,
        salaryGrowth: 2,
        jobs: 130,
      },
      {
        industryId: 1210,
        industryTitle: 'Government & Defence',
        roleTitle: 'Procurement Officer',
        avgSalary: 103000,
        salaryGrowth: 2,
        jobs: 66,
      },
      {
        industryId: 1210,
        industryTitle: 'Government & Defence',
        roleTitle: 'Executive Assistant',
        avgSalary: 89100,
        salaryGrowth: 1,
        jobs: 57,
      },
      {
        industryId: 1210,
        industryTitle: 'Government & Defence',
        roleTitle: 'Planning Officer',
        avgSalary: 99900,
        salaryGrowth: 1,
        jobs: 74,
      },
    ],
  },
  {
    industryIdFilter: 1211,
    roles: [
      {
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        roleTitle: 'Personal Carer Residential',
        avgSalary: 62400,
        salaryGrowth: 25,
        jobs: 78,
      },
      {
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        roleTitle: 'Carer',
        avgSalary: 63200,
        salaryGrowth: 18,
        jobs: 450,
      },
      {
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        roleTitle: 'Nursing Assistant',
        avgSalary: 67000,
        salaryGrowth: 15,
        jobs: 108,
      },
      {
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        roleTitle: 'Service Employee',
        avgSalary: 59300,
        salaryGrowth: 15,
        jobs: 267,
      },
      {
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        roleTitle: 'Lifestyle Assistant',
        avgSalary: 59600,
        salaryGrowth: 13,
        jobs: 104,
      },
      {
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        roleTitle: 'Care Attendant',
        avgSalary: 66100,
        salaryGrowth: 13,
        jobs: 124,
      },
      {
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        roleTitle: 'Support Worker',
        avgSalary: 67000,
        salaryGrowth: 13,
        jobs: 356,
      },
      {
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        roleTitle: 'Therapy Assistant',
        avgSalary: 64300,
        salaryGrowth: 11,
        jobs: 95,
      },
      {
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        roleTitle: 'General Practice Sales Representative',
        avgSalary: 108900,
        salaryGrowth: 11,
        jobs: 73,
      },
      {
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        roleTitle: 'Application Specialist',
        avgSalary: 121700,
        salaryGrowth: 10,
        jobs: 59,
      },
      {
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        roleTitle: 'Pharmacist',
        avgSalary: 95300,
        salaryGrowth: 10,
        jobs: 2541,
      },
      {
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        roleTitle: 'Health Team Leader',
        avgSalary: 116400,
        salaryGrowth: 8,
        jobs: 84,
      },
      {
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        roleTitle: 'Pharmacy Technician',
        avgSalary: 65100,
        salaryGrowth: 8,
        jobs: 276,
      },
      {
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        roleTitle: 'Technologist',
        avgSalary: 102000,
        salaryGrowth: 8,
        jobs: 313,
      },
      {
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        roleTitle: 'Second in Charge',
        avgSalary: 63100,
        salaryGrowth: 8,
        jobs: 50,
      },
      {
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        roleTitle: 'Service Manager',
        avgSalary: 122900,
        salaryGrowth: 8,
        jobs: 354,
      },
      {
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        roleTitle: 'Coder',
        avgSalary: 91200,
        salaryGrowth: 8,
        jobs: 115,
      },
      {
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        roleTitle: 'Dentist',
        avgSalary: 180800,
        salaryGrowth: 8,
        jobs: 1575,
      },
      {
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        roleTitle: 'General Practitioner',
        avgSalary: 228800,
        salaryGrowth: 8,
        jobs: 2197,
      },
      {
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        roleTitle: 'Careworker',
        avgSalary: 60500,
        salaryGrowth: 8,
        jobs: 481,
      },
    ],
  },
  {
    industryIdFilter: 1212,
    roles: [
      {
        industryId: 1212,
        industryTitle: 'Hospitality & Tourism',
        roleTitle: 'Shift Manager',
        avgSalary: 66900,
        salaryGrowth: 15,
        jobs: 66,
      },
      {
        industryId: 1212,
        industryTitle: 'Hospitality & Tourism',
        roleTitle: 'Room Attendant',
        avgSalary: 62500,
        salaryGrowth: 14,
        jobs: 366,
      },
      {
        industryId: 1212,
        industryTitle: 'Hospitality & Tourism',
        roleTitle: 'Hospitality Assistant',
        avgSalary: 56500,
        salaryGrowth: 14,
        jobs: 72,
      },
      {
        industryId: 1212,
        industryTitle: 'Hospitality & Tourism',
        roleTitle: 'Service Agent',
        avgSalary: 60800,
        salaryGrowth: 14,
        jobs: 59,
      },
      {
        industryId: 1212,
        industryTitle: 'Hospitality & Tourism',
        roleTitle: 'Coordinator',
        avgSalary: 75500,
        salaryGrowth: 13,
        jobs: 67,
      },
      {
        industryId: 1212,
        industryTitle: 'Hospitality & Tourism',
        roleTitle: 'Travel Consultant',
        avgSalary: 75600,
        salaryGrowth: 12,
        jobs: 538,
      },
      {
        industryId: 1212,
        industryTitle: 'Hospitality & Tourism',
        roleTitle: 'Gaming Attendant',
        avgSalary: 64500,
        salaryGrowth: 11,
        jobs: 284,
      },
      {
        industryId: 1212,
        industryTitle: 'Hospitality & Tourism',
        roleTitle: 'Attendant',
        avgSalary: 61900,
        salaryGrowth: 10,
        jobs: 193,
      },
      {
        industryId: 1212,
        industryTitle: 'Hospitality & Tourism',
        roleTitle: 'Shift Supervisor',
        avgSalary: 65000,
        salaryGrowth: 10,
        jobs: 101,
      },
      {
        industryId: 1212,
        industryTitle: 'Hospitality & Tourism',
        roleTitle: 'Guest Services Agent',
        avgSalary: 58600,
        salaryGrowth: 10,
        jobs: 786,
      },
      {
        industryId: 1212,
        industryTitle: 'Hospitality & Tourism',
        roleTitle: 'Steward',
        avgSalary: 62400,
        salaryGrowth: 10,
        jobs: 111,
      },
      {
        industryId: 1212,
        industryTitle: 'Hospitality & Tourism',
        roleTitle: 'Food and Beverage Team Leader',
        avgSalary: 61700,
        salaryGrowth: 10,
        jobs: 94,
      },
      {
        industryId: 1212,
        industryTitle: 'Hospitality & Tourism',
        roleTitle: 'Front of House Staff',
        avgSalary: 62700,
        salaryGrowth: 10,
        jobs: 152,
      },
      {
        industryId: 1212,
        industryTitle: 'Hospitality & Tourism',
        roleTitle: 'Service Manager',
        avgSalary: 85000,
        salaryGrowth: 10,
        jobs: 99,
      },
      {
        industryId: 1212,
        industryTitle: 'Hospitality & Tourism',
        roleTitle: 'Front of House Team Member',
        avgSalary: 55000,
        salaryGrowth: 10,
        jobs: 56,
      },
      {
        industryId: 1212,
        industryTitle: 'Hospitality & Tourism',
        roleTitle: 'Waitperson',
        avgSalary: 63700,
        salaryGrowth: 9,
        jobs: 1234,
      },
      {
        industryId: 1212,
        industryTitle: 'Hospitality & Tourism',
        roleTitle: 'Concierge',
        avgSalary: 65500,
        salaryGrowth: 9,
        jobs: 217,
      },
      {
        industryId: 1212,
        industryTitle: 'Hospitality & Tourism',
        roleTitle: 'Cleaner',
        avgSalary: 60300,
        salaryGrowth: 9,
        jobs: 309,
      },
      {
        industryId: 1212,
        industryTitle: 'Hospitality & Tourism',
        roleTitle: 'Retail Sales Associate',
        avgSalary: 63400,
        salaryGrowth: 9,
        jobs: 72,
      },
      {
        industryId: 1212,
        industryTitle: 'Hospitality & Tourism',
        roleTitle: 'Gaming Manager',
        avgSalary: 78100,
        salaryGrowth: 9,
        jobs: 107,
      },
    ],
  },
  {
    industryIdFilter: 1214,
    roles: [
      {
        industryId: 1214,
        industryTitle: 'Insurance & Superannuation',
        roleTitle: 'Claims Officer',
        avgSalary: 86300,
        salaryGrowth: 16,
        jobs: 155,
      },
      {
        industryId: 1214,
        industryTitle: 'Insurance & Superannuation',
        roleTitle: 'Adjuster',
        avgSalary: 101400,
        salaryGrowth: 10,
        jobs: 76,
      },
      {
        industryId: 1214,
        industryTitle: 'Insurance & Superannuation',
        roleTitle: 'Claims Consultant',
        avgSalary: 83300,
        salaryGrowth: 9,
        jobs: 295,
      },
      {
        industryId: 1214,
        industryTitle: 'Insurance & Superannuation',
        roleTitle: 'Assessor',
        avgSalary: 100800,
        salaryGrowth: 8,
        jobs: 301,
      },
      {
        industryId: 1214,
        industryTitle: 'Insurance & Superannuation',
        roleTitle: 'Case Manager',
        avgSalary: 85300,
        salaryGrowth: 7,
        jobs: 225,
      },
      {
        industryId: 1214,
        industryTitle: 'Insurance & Superannuation',
        roleTitle: 'Account Manager',
        avgSalary: 109800,
        salaryGrowth: 7,
        jobs: 174,
      },
      {
        industryId: 1214,
        industryTitle: 'Insurance & Superannuation',
        roleTitle: 'Broker Assistant',
        avgSalary: 66800,
        salaryGrowth: 6,
        jobs: 71,
      },
      {
        industryId: 1214,
        industryTitle: 'Insurance & Superannuation',
        roleTitle: 'Support Officer',
        avgSalary: 70500,
        salaryGrowth: 5,
        jobs: 51,
      },
      {
        industryId: 1214,
        industryTitle: 'Insurance & Superannuation',
        roleTitle: 'Team Leader',
        avgSalary: 107300,
        salaryGrowth: 4,
        jobs: 67,
      },
      {
        industryId: 1214,
        industryTitle: 'Insurance & Superannuation',
        roleTitle: 'Investigator',
        avgSalary: 86900,
        salaryGrowth: 4,
        jobs: 69,
      },
      {
        industryId: 1214,
        industryTitle: 'Insurance & Superannuation',
        roleTitle: 'Account Executive',
        avgSalary: 91400,
        salaryGrowth: 3,
        jobs: 358,
      },
    ],
  },
  {
    industryIdFilter: 1216,
    roles: [
      {
        industryId: 1216,
        industryTitle: 'Legal',
        roleTitle: 'Legal Assistant',
        avgSalary: 76600,
        salaryGrowth: 8,
        jobs: 1133,
      },
      {
        industryId: 1216,
        industryTitle: 'Legal',
        roleTitle: 'Administration Officer',
        avgSalary: 66300,
        salaryGrowth: 5,
        jobs: 96,
      },
      {
        industryId: 1216,
        industryTitle: 'Legal',
        roleTitle: 'Conveyancer',
        avgSalary: 79400,
        salaryGrowth: 5,
        jobs: 196,
      },
      {
        industryId: 1216,
        industryTitle: 'Legal',
        roleTitle: 'Senior Associate',
        avgSalary: 161000,
        salaryGrowth: 5,
        jobs: 458,
      },
      {
        industryId: 1216,
        industryTitle: 'Legal',
        roleTitle: 'Executive Assistant',
        avgSalary: 89600,
        salaryGrowth: 4,
        jobs: 54,
      },
      {
        industryId: 1216,
        industryTitle: 'Legal',
        roleTitle: 'Legal Administrator',
        avgSalary: 67800,
        salaryGrowth: 4,
        jobs: 84,
      },
      {
        industryId: 1216,
        industryTitle: 'Legal',
        roleTitle: 'Paralegal',
        avgSalary: 76100,
        salaryGrowth: 4,
        jobs: 790,
      },
      {
        industryId: 1216,
        industryTitle: 'Legal',
        roleTitle: 'Counsel',
        avgSalary: 172000,
        salaryGrowth: 3,
        jobs: 1054,
      },
      {
        industryId: 1216,
        industryTitle: 'Legal',
        roleTitle: 'Migration Agent',
        avgSalary: 81000,
        salaryGrowth: 3,
        jobs: 123,
      },
      {
        industryId: 1216,
        industryTitle: 'Legal',
        roleTitle: 'Solicitor',
        avgSalary: 102800,
        salaryGrowth: 3,
        jobs: 1035,
      },
      {
        industryId: 1216,
        industryTitle: 'Legal',
        roleTitle: 'Personal Assistant',
        avgSalary: 79000,
        salaryGrowth: 2,
        jobs: 127,
      },
      {
        industryId: 1216,
        industryTitle: 'Legal',
        roleTitle: 'Law Graduate',
        avgSalary: 73900,
        salaryGrowth: 2,
        jobs: 105,
      },
    ],
  },
  {
    industryIdFilter: 1220,
    roles: [
      {
        industryId: 1220,
        industryTitle: 'Real Estate & Property',
        roleTitle: 'Owners Corporation Manager',
        avgSalary: 98800,
        salaryGrowth: 15,
        jobs: 147,
      },
      {
        industryId: 1220,
        industryTitle: 'Real Estate & Property',
        roleTitle: 'Project Manager',
        avgSalary: 141300,
        salaryGrowth: 11,
        jobs: 59,
      },
      {
        industryId: 1220,
        industryTitle: 'Real Estate & Property',
        roleTitle: 'Assistant Development Manager',
        avgSalary: 110300,
        salaryGrowth: 11,
        jobs: 114,
      },
      {
        industryId: 1220,
        industryTitle: 'Real Estate & Property',
        roleTitle: 'Sales Assistant',
        avgSalary: 62700,
        salaryGrowth: 10,
        jobs: 152,
      },
      {
        industryId: 1220,
        industryTitle: 'Real Estate & Property',
        roleTitle: 'Accountant',
        avgSalary: 86900,
        salaryGrowth: 8,
        jobs: 78,
      },
      {
        industryId: 1220,
        industryTitle: 'Real Estate & Property',
        roleTitle: 'Executive Assistant',
        avgSalary: 87900,
        salaryGrowth: 7,
        jobs: 115,
      },
      {
        industryId: 1220,
        industryTitle: 'Real Estate & Property',
        roleTitle: 'Lease Administrator',
        avgSalary: 91900,
        salaryGrowth: 7,
        jobs: 60,
      },
      {
        industryId: 1220,
        industryTitle: 'Real Estate & Property',
        roleTitle: 'Administration Officer',
        avgSalary: 66400,
        salaryGrowth: 6,
        jobs: 166,
      },
      {
        industryId: 1220,
        industryTitle: 'Real Estate & Property',
        roleTitle: 'Sales Administrator',
        avgSalary: 67300,
        salaryGrowth: 5,
        jobs: 194,
      },
      {
        industryId: 1220,
        industryTitle: 'Real Estate & Property',
        roleTitle: 'Personal Assistant',
        avgSalary: 68400,
        salaryGrowth: 5,
        jobs: 122,
      },
      {
        industryId: 1220,
        industryTitle: 'Real Estate & Property',
        roleTitle: 'Sales Associate',
        avgSalary: 63000,
        salaryGrowth: 5,
        jobs: 536,
      },
      {
        industryId: 1220,
        industryTitle: 'Real Estate & Property',
        roleTitle: 'Facilities Coordinator',
        avgSalary: 81100,
        salaryGrowth: 5,
        jobs: 181,
      },
      {
        industryId: 1220,
        industryTitle: 'Real Estate & Property',
        roleTitle: 'Centre Manager',
        avgSalary: 133400,
        salaryGrowth: 5,
        jobs: 100,
      },
      {
        industryId: 1220,
        industryTitle: 'Real Estate & Property',
        roleTitle: 'Receptionist',
        avgSalary: 55500,
        salaryGrowth: 5,
        jobs: 173,
      },
      {
        industryId: 1220,
        industryTitle: 'Real Estate & Property',
        roleTitle: 'Property Manager',
        avgSalary: 79100,
        salaryGrowth: 5,
        jobs: 4421,
      },
      {
        industryId: 1220,
        industryTitle: 'Real Estate & Property',
        roleTitle: 'Operations Manager',
        avgSalary: 115900,
        salaryGrowth: 5,
        jobs: 193,
      },
      {
        industryId: 1220,
        industryTitle: 'Real Estate & Property',
        roleTitle: 'Leasing Consultant',
        avgSalary: 63600,
        salaryGrowth: 5,
        jobs: 279,
      },
      {
        industryId: 1220,
        industryTitle: 'Real Estate & Property',
        roleTitle: 'Administration Manager',
        avgSalary: 88500,
        salaryGrowth: 3,
        jobs: 59,
      },
      {
        industryId: 1220,
        industryTitle: 'Real Estate & Property',
        roleTitle: 'Building Manager',
        avgSalary: 81100,
        salaryGrowth: 3,
        jobs: 210,
      },
      {
        industryId: 1220,
        industryTitle: 'Real Estate & Property',
        roleTitle: 'Facilities Manager',
        avgSalary: 111800,
        salaryGrowth: 2,
        jobs: 529,
      },
    ],
  },
  {
    industryIdFilter: 1223,
    roles: [
      {
        industryId: 1223,
        industryTitle: 'Science & Technology',
        roleTitle: 'Ecologist',
        avgSalary: 108200,
        salaryGrowth: 10,
        jobs: 72,
      },
      {
        industryId: 1223,
        industryTitle: 'Science & Technology',
        roleTitle: 'Laboratory Assistant',
        avgSalary: 65300,
        salaryGrowth: 10,
        jobs: 114,
      },
      {
        industryId: 1223,
        industryTitle: 'Science & Technology',
        roleTitle: 'Soil Technician',
        avgSalary: 64700,
        salaryGrowth: 7,
        jobs: 51,
      },
      {
        industryId: 1223,
        industryTitle: 'Science & Technology',
        roleTitle: 'Project Officer',
        avgSalary: 99200,
        salaryGrowth: 6,
        jobs: 57,
      },
      {
        industryId: 1223,
        industryTitle: 'Science & Technology',
        roleTitle: 'Laboratory Manager',
        avgSalary: 106600,
        salaryGrowth: 6,
        jobs: 54,
      },
      {
        industryId: 1223,
        industryTitle: 'Science & Technology',
        roleTitle: 'Chemist',
        avgSalary: 85700,
        salaryGrowth: 5,
        jobs: 216,
      },
      {
        industryId: 1223,
        industryTitle: 'Science & Technology',
        roleTitle: 'Technologist',
        avgSalary: 92200,
        salaryGrowth: 5,
        jobs: 149,
      },
      {
        industryId: 1223,
        industryTitle: 'Science & Technology',
        roleTitle: 'Laboratory Technician',
        avgSalary: 70000,
        salaryGrowth: 5,
        jobs: 439,
      },
      {
        industryId: 1223,
        industryTitle: 'Science & Technology',
        roleTitle: 'Technical Officer',
        avgSalary: 84100,
        salaryGrowth: 2,
        jobs: 179,
      },
    ],
  },
  {
    industryIdFilter: 1225,
    roles: [
      {
        industryId: 1225,
        industryTitle: 'Trades & Services',
        roleTitle: 'Fitter Technician',
        avgSalary: 93400,
        salaryGrowth: 28,
        jobs: 55,
      },
      {
        industryId: 1225,
        industryTitle: 'Trades & Services',
        roleTitle: 'Hydraulic Technician',
        avgSalary: 98500,
        salaryGrowth: 16,
        jobs: 77,
      },
      {
        industryId: 1225,
        industryTitle: 'Trades & Services',
        roleTitle: 'Crowd Controller',
        avgSalary: 80400,
        salaryGrowth: 13,
        jobs: 76,
      },
      {
        industryId: 1225,
        industryTitle: 'Trades & Services',
        roleTitle: 'Glazier',
        avgSalary: 75000,
        salaryGrowth: 13,
        jobs: 220,
      },
      {
        industryId: 1225,
        industryTitle: 'Trades & Services',
        roleTitle: 'Trainer',
        avgSalary: 99900,
        salaryGrowth: 13,
        jobs: 98,
      },
      {
        industryId: 1225,
        industryTitle: 'Trades & Services',
        roleTitle: 'Maintenance Team Leader',
        avgSalary: 75500,
        salaryGrowth: 12,
        jobs: 147,
      },
      {
        industryId: 1225,
        industryTitle: 'Trades & Services',
        roleTitle: 'Maintenance Assistant',
        avgSalary: 62900,
        salaryGrowth: 11,
        jobs: 98,
      },
      {
        industryId: 1225,
        industryTitle: 'Trades & Services',
        roleTitle: 'Signwriter',
        avgSalary: 68100,
        salaryGrowth: 11,
        jobs: 91,
      },
      {
        industryId: 1225,
        industryTitle: 'Trades & Services',
        roleTitle: 'Printer',
        avgSalary: 75000,
        salaryGrowth: 10,
        jobs: 94,
      },
      {
        industryId: 1225,
        industryTitle: 'Trades & Services',
        roleTitle: 'Workshop Assistant',
        avgSalary: 63200,
        salaryGrowth: 10,
        jobs: 83,
      },
      {
        industryId: 1225,
        industryTitle: 'Trades & Services',
        roleTitle: 'Facilities Manager',
        avgSalary: 100800,
        salaryGrowth: 10,
        jobs: 63,
      },
      {
        industryId: 1225,
        industryTitle: 'Trades & Services',
        roleTitle: 'Builder',
        avgSalary: 81500,
        salaryGrowth: 10,
        jobs: 252,
      },
      {
        industryId: 1225,
        industryTitle: 'Trades & Services',
        roleTitle: 'Audio Visual Technician',
        avgSalary: 84800,
        salaryGrowth: 10,
        jobs: 108,
      },
      {
        industryId: 1225,
        industryTitle: 'Trades & Services',
        roleTitle: 'Finisher',
        avgSalary: 82200,
        salaryGrowth: 9,
        jobs: 154,
      },
      {
        industryId: 1225,
        industryTitle: 'Trades & Services',
        roleTitle: 'Maintenance Person',
        avgSalary: 69100,
        salaryGrowth: 9,
        jobs: 136,
      },
      {
        industryId: 1225,
        industryTitle: 'Trades & Services',
        roleTitle: 'Workshop Manager',
        avgSalary: 108100,
        salaryGrowth: 9,
        jobs: 228,
      },
      {
        industryId: 1225,
        industryTitle: 'Trades & Services',
        roleTitle: 'Security Guard',
        avgSalary: 67300,
        salaryGrowth: 8,
        jobs: 244,
      },
      {
        industryId: 1225,
        industryTitle: 'Trades & Services',
        roleTitle: 'Landscaper',
        avgSalary: 72700,
        salaryGrowth: 8,
        jobs: 476,
      },
      {
        industryId: 1225,
        industryTitle: 'Trades & Services',
        roleTitle: 'Heating, Ventilation and Air Conditioning Technician',
        avgSalary: 100700,
        salaryGrowth: 8,
        jobs: 288,
      },
      {
        industryId: 1225,
        industryTitle: 'Trades & Services',
        roleTitle: 'Service Supervisor',
        avgSalary: 99400,
        salaryGrowth: 8,
        jobs: 112,
      },
    ],
  },
  {
    industryIdFilter: 6008,
    roles: [
      {
        industryId: 6008,
        industryTitle: 'Marketing & Communications',
        roleTitle: 'Marketing Lead',
        avgSalary: 119500,
        salaryGrowth: 10,
        jobs: 104,
      },
      {
        industryId: 6008,
        industryTitle: 'Marketing & Communications',
        roleTitle: 'Automation Specialist',
        avgSalary: 106800,
        salaryGrowth: 9,
        jobs: 55,
      },
      {
        industryId: 6008,
        industryTitle: 'Marketing & Communications',
        roleTitle: 'Manager',
        avgSalary: 134800,
        salaryGrowth: 8,
        jobs: 115,
      },
      {
        industryId: 6008,
        industryTitle: 'Marketing & Communications',
        roleTitle: 'Content Coordinator',
        avgSalary: 73700,
        salaryGrowth: 8,
        jobs: 109,
      },
      {
        industryId: 6008,
        industryTitle: 'Marketing & Communications',
        roleTitle: 'Content Creator',
        avgSalary: 73400,
        salaryGrowth: 8,
        jobs: 188,
      },
      {
        industryId: 6008,
        industryTitle: 'Marketing & Communications',
        roleTitle: 'Communications Manager',
        avgSalary: 132500,
        salaryGrowth: 7,
        jobs: 308,
      },
      {
        industryId: 6008,
        industryTitle: 'Marketing & Communications',
        roleTitle: 'Content Manager',
        avgSalary: 103400,
        salaryGrowth: 7,
        jobs: 56,
      },
      {
        industryId: 6008,
        industryTitle: 'Marketing & Communications',
        roleTitle: 'Bid Coordinator',
        avgSalary: 96300,
        salaryGrowth: 7,
        jobs: 58,
      },
      {
        industryId: 6008,
        industryTitle: 'Marketing & Communications',
        roleTitle: 'Communications Lead',
        avgSalary: 116100,
        salaryGrowth: 6,
        jobs: 65,
      },
      {
        industryId: 6008,
        industryTitle: 'Marketing & Communications',
        roleTitle: 'Content Specialist',
        avgSalary: 90600,
        salaryGrowth: 6,
        jobs: 88,
      },
      {
        industryId: 6008,
        industryTitle: 'Marketing & Communications',
        roleTitle: 'Communications Coordinator',
        avgSalary: 84500,
        salaryGrowth: 5,
        jobs: 192,
      },
      {
        industryId: 6008,
        industryTitle: 'Marketing & Communications',
        roleTitle: 'Marketing and Communications Coordinator',
        avgSalary: 78200,
        salaryGrowth: 5,
        jobs: 132,
      },
      {
        industryId: 6008,
        industryTitle: 'Marketing & Communications',
        roleTitle: 'Marketing Assistant',
        avgSalary: 62900,
        salaryGrowth: 5,
        jobs: 394,
      },
      {
        industryId: 6008,
        industryTitle: 'Marketing & Communications',
        roleTitle: 'Marketing and Communications Officer',
        avgSalary: 81500,
        salaryGrowth: 5,
        jobs: 105,
      },
      {
        industryId: 6008,
        industryTitle: 'Marketing & Communications',
        roleTitle: 'Campaign Manager',
        avgSalary: 108700,
        salaryGrowth: 4,
        jobs: 86,
      },
      {
        industryId: 6008,
        industryTitle: 'Marketing & Communications',
        roleTitle: 'Media Coordinator',
        avgSalary: 69200,
        salaryGrowth: 4,
        jobs: 238,
      },
      {
        industryId: 6008,
        industryTitle: 'Marketing & Communications',
        roleTitle: 'Marketing Executive',
        avgSalary: 84300,
        salaryGrowth: 4,
        jobs: 422,
      },
      {
        industryId: 6008,
        industryTitle: 'Marketing & Communications',
        roleTitle: 'Coordinator',
        avgSalary: 80100,
        salaryGrowth: 4,
        jobs: 107,
      },
      {
        industryId: 6008,
        industryTitle: 'Marketing & Communications',
        roleTitle: 'Marketing Coordinator',
        avgSalary: 74000,
        salaryGrowth: 3,
        jobs: 1591,
      },
      {
        industryId: 6008,
        industryTitle: 'Marketing & Communications',
        roleTitle: 'eCommerce Manager',
        avgSalary: 114500,
        salaryGrowth: 3,
        jobs: 109,
      },
    ],
  },
  {
    industryIdFilter: 6043,
    roles: [
      {
        industryId: 6043,
        industryTitle: 'Retail & Consumer Products',
        roleTitle: 'Retail Team Leader',
        avgSalary: 60200,
        salaryGrowth: 21,
        jobs: 94,
      },
      {
        industryId: 6043,
        industryTitle: 'Retail & Consumer Products',
        roleTitle: 'Team Member',
        avgSalary: 54400,
        salaryGrowth: 21,
        jobs: 1201,
      },
      {
        industryId: 6043,
        industryTitle: 'Retail & Consumer Products',
        roleTitle: 'Sales Representative',
        avgSalary: 66100,
        salaryGrowth: 16,
        jobs: 151,
      },
      {
        industryId: 6043,
        industryTitle: 'Retail & Consumer Products',
        roleTitle: 'Concession Manager',
        avgSalary: 62400,
        salaryGrowth: 14,
        jobs: 109,
      },
      {
        industryId: 6043,
        industryTitle: 'Retail & Consumer Products',
        roleTitle: 'Buyer',
        avgSalary: 106600,
        salaryGrowth: 13,
        jobs: 138,
      },
      {
        industryId: 6043,
        industryTitle: 'Retail & Consumer Products',
        roleTitle: 'Boutique Manager',
        avgSalary: 70400,
        salaryGrowth: 13,
        jobs: 270,
      },
      {
        industryId: 6043,
        industryTitle: 'Retail & Consumer Products',
        roleTitle: 'Storage Consultant',
        avgSalary: 55100,
        salaryGrowth: 12,
        jobs: 58,
      },
      {
        industryId: 6043,
        industryTitle: 'Retail & Consumer Products',
        roleTitle: 'Business Manager',
        avgSalary: 81800,
        salaryGrowth: 11,
        jobs: 94,
      },
      {
        industryId: 6043,
        industryTitle: 'Retail & Consumer Products',
        roleTitle: 'Merchandiser',
        avgSalary: 60000,
        salaryGrowth: 10,
        jobs: 1516,
      },
      {
        industryId: 6043,
        industryTitle: 'Retail & Consumer Products',
        roleTitle: 'Concession Supervisor',
        avgSalary: 60800,
        salaryGrowth: 9,
        jobs: 67,
      },
      {
        industryId: 6043,
        industryTitle: 'Retail & Consumer Products',
        roleTitle: 'Deli Assistant',
        avgSalary: 57900,
        salaryGrowth: 8,
        jobs: 82,
      },
      {
        industryId: 6043,
        industryTitle: 'Retail & Consumer Products',
        roleTitle: 'Customer Service Representative',
        avgSalary: 55800,
        salaryGrowth: 8,
        jobs: 1245,
      },
      {
        industryId: 6043,
        industryTitle: 'Retail & Consumer Products',
        roleTitle: 'Dispenser',
        avgSalary: 55500,
        salaryGrowth: 7,
        jobs: 127,
      },
      {
        industryId: 6043,
        industryTitle: 'Retail & Consumer Products',
        roleTitle: 'Buyers Assistant',
        avgSalary: 68000,
        salaryGrowth: 6,
        jobs: 50,
      },
      {
        industryId: 6043,
        industryTitle: 'Retail & Consumer Products',
        roleTitle: 'Grocery Manager',
        avgSalary: 68400,
        salaryGrowth: 6,
        jobs: 180,
      },
      {
        industryId: 6043,
        industryTitle: 'Retail & Consumer Products',
        roleTitle: 'Sales Associate',
        avgSalary: 58700,
        salaryGrowth: 6,
        jobs: 2115,
      },
      {
        industryId: 6043,
        industryTitle: 'Retail & Consumer Products',
        roleTitle: 'Store Supervisor',
        avgSalary: 58100,
        salaryGrowth: 6,
        jobs: 274,
      },
      {
        industryId: 6043,
        industryTitle: 'Retail & Consumer Products',
        roleTitle: 'Third in Charge',
        avgSalary: 54600,
        salaryGrowth: 6,
        jobs: 71,
      },
      {
        industryId: 6043,
        industryTitle: 'Retail & Consumer Products',
        roleTitle: 'Regional Manager',
        avgSalary: 103000,
        salaryGrowth: 5,
        jobs: 86,
      },
      {
        industryId: 6043,
        industryTitle: 'Retail & Consumer Products',
        roleTitle: 'Planner',
        avgSalary: 104000,
        salaryGrowth: 5,
        jobs: 204,
      },
    ],
  },
  {
    industryIdFilter: 6058,
    roles: [
      {
        industryId: 6058,
        industryTitle: 'Mining, Resources & Energy',
        roleTitle: 'Estimator',
        avgSalary: 142800,
        salaryGrowth: 17,
        jobs: 64,
      },
      {
        industryId: 6058,
        industryTitle: 'Mining, Resources & Energy',
        roleTitle: 'Maintainer',
        avgSalary: 150400,
        salaryGrowth: 15,
        jobs: 176,
      },
      {
        industryId: 6058,
        industryTitle: 'Mining, Resources & Energy',
        roleTitle: 'Service Technician',
        avgSalary: 111600,
        salaryGrowth: 14,
        jobs: 145,
      },
      {
        industryId: 6058,
        industryTitle: 'Mining, Resources & Energy',
        roleTitle: 'Roadtrain Operator',
        avgSalary: 104000,
        salaryGrowth: 13,
        jobs: 79,
      },
      {
        industryId: 6058,
        industryTitle: 'Mining, Resources & Energy',
        roleTitle: 'Production Supervisor',
        avgSalary: 176200,
        salaryGrowth: 12,
        jobs: 119,
      },
      {
        industryId: 6058,
        industryTitle: 'Mining, Resources & Energy',
        roleTitle: 'Process Operator',
        avgSalary: 108100,
        salaryGrowth: 12,
        jobs: 126,
      },
      {
        industryId: 6058,
        industryTitle: 'Mining, Resources & Energy',
        roleTitle: 'Grader Operator',
        avgSalary: 125100,
        salaryGrowth: 11,
        jobs: 116,
      },
      {
        industryId: 6058,
        industryTitle: 'Mining, Resources & Energy',
        roleTitle: 'Underground Operator',
        avgSalary: 136600,
        salaryGrowth: 10,
        jobs: 150,
      },
      {
        industryId: 6058,
        industryTitle: 'Mining, Resources & Energy',
        roleTitle: 'Project Engineer',
        avgSalary: 166900,
        salaryGrowth: 10,
        jobs: 143,
      },
      {
        industryId: 6058,
        industryTitle: 'Mining, Resources & Energy',
        roleTitle: 'Blaster',
        avgSalary: 110800,
        salaryGrowth: 10,
        jobs: 97,
      },
      {
        industryId: 6058,
        industryTitle: 'Mining, Resources & Energy',
        roleTitle: 'Electrical Technician',
        avgSalary: 132000,
        salaryGrowth: 10,
        jobs: 90,
      },
      {
        industryId: 6058,
        industryTitle: 'Mining, Resources & Energy',
        roleTitle: 'Engineer',
        avgSalary: 161300,
        salaryGrowth: 10,
        jobs: 149,
      },
      {
        industryId: 6058,
        industryTitle: 'Mining, Resources & Energy',
        roleTitle: 'Site Administrator',
        avgSalary: 89300,
        salaryGrowth: 9,
        jobs: 69,
      },
      {
        industryId: 6058,
        industryTitle: 'Mining, Resources & Energy',
        roleTitle: 'Assessor',
        avgSalary: 142700,
        salaryGrowth: 9,
        jobs: 99,
      },
      {
        industryId: 6058,
        industryTitle: 'Mining, Resources & Energy',
        roleTitle: 'Mining Engineer',
        avgSalary: 171200,
        salaryGrowth: 9,
        jobs: 651,
      },
      {
        industryId: 6058,
        industryTitle: 'Mining, Resources & Energy',
        roleTitle: 'Maintenance Supervisor',
        avgSalary: 167800,
        salaryGrowth: 9,
        jobs: 299,
      },
      {
        industryId: 6058,
        industryTitle: 'Mining, Resources & Energy',
        roleTitle: 'Charge Up Operator',
        avgSalary: 139700,
        salaryGrowth: 9,
        jobs: 68,
      },
      {
        industryId: 6058,
        industryTitle: 'Mining, Resources & Energy',
        roleTitle: 'Tradesperson',
        avgSalary: 125700,
        salaryGrowth: 9,
        jobs: 167,
      },
      {
        industryId: 6058,
        industryTitle: 'Mining, Resources & Energy',
        roleTitle: 'Mechanical Engineer',
        avgSalary: 162500,
        salaryGrowth: 8,
        jobs: 123,
      },
      {
        industryId: 6058,
        industryTitle: 'Mining, Resources & Energy',
        roleTitle: 'Loader Operator',
        avgSalary: 112300,
        salaryGrowth: 8,
        jobs: 132,
      },
    ],
  },
  {
    industryIdFilter: 6076,
    roles: [
      {
        industryId: 6076,
        industryTitle: 'Consulting & Strategy',
        roleTitle: 'Director',
        avgSalary: 186800,
        salaryGrowth: 10,
        jobs: 60,
      },
      {
        industryId: 6076,
        industryTitle: 'Consulting & Strategy',
        roleTitle: 'Change Manager',
        avgSalary: 158500,
        salaryGrowth: 7,
        jobs: 149,
      },
      {
        industryId: 6076,
        industryTitle: 'Consulting & Strategy',
        roleTitle: 'Adviser',
        avgSalary: 121500,
        salaryGrowth: 5,
        jobs: 61,
      },
      {
        industryId: 6076,
        industryTitle: 'Consulting & Strategy',
        roleTitle: 'Policy Officer',
        avgSalary: 110600,
        salaryGrowth: 4,
        jobs: 121,
      },
      {
        industryId: 6076,
        industryTitle: 'Consulting & Strategy',
        roleTitle: 'Strategy Manager',
        avgSalary: 162200,
        salaryGrowth: 3,
        jobs: 68,
      },
      {
        industryId: 6076,
        industryTitle: 'Consulting & Strategy',
        roleTitle: 'Consultant',
        avgSalary: 115500,
        salaryGrowth: 3,
        jobs: 200,
      },
      {
        industryId: 6076,
        industryTitle: 'Consulting & Strategy',
        roleTitle: 'Partner',
        avgSalary: 130200,
        salaryGrowth: 3,
        jobs: 50,
      },
      {
        industryId: 6076,
        industryTitle: 'Consulting & Strategy',
        roleTitle: 'Project Manager',
        avgSalary: 132700,
        salaryGrowth: 2,
        jobs: 110,
      },
      {
        industryId: 6076,
        industryTitle: 'Consulting & Strategy',
        roleTitle: 'Manager',
        avgSalary: 153800,
        salaryGrowth: 2,
        jobs: 159,
      },
    ],
  },
  {
    industryIdFilter: 6092,
    roles: [
      {
        industryId: 6092,
        industryTitle: 'Manufacturing, Transport & Logistics',
        roleTitle: 'Licenced Aircraft Maintenance Engineer',
        avgSalary: 144800,
        salaryGrowth: 27,
        jobs: 82,
      },
      {
        industryId: 6092,
        industryTitle: 'Manufacturing, Transport & Logistics',
        roleTitle: 'Roadtrain Operator',
        avgSalary: 113200,
        salaryGrowth: 24,
        jobs: 163,
      },
      {
        industryId: 6092,
        industryTitle: 'Manufacturing, Transport & Logistics',
        roleTitle: 'Service Manager',
        avgSalary: 129200,
        salaryGrowth: 18,
        jobs: 77,
      },
      {
        industryId: 6092,
        industryTitle: 'Manufacturing, Transport & Logistics',
        roleTitle: 'Logistics Manager',
        avgSalary: 125700,
        salaryGrowth: 15,
        jobs: 182,
      },
      {
        industryId: 6092,
        industryTitle: 'Manufacturing, Transport & Logistics',
        roleTitle: 'Production Coordinator',
        avgSalary: 82300,
        salaryGrowth: 13,
        jobs: 103,
      },
      {
        industryId: 6092,
        industryTitle: 'Manufacturing, Transport & Logistics',
        roleTitle: 'Fleet Manager',
        avgSalary: 121300,
        salaryGrowth: 12,
        jobs: 77,
      },
      {
        industryId: 6092,
        industryTitle: 'Manufacturing, Transport & Logistics',
        roleTitle: 'Transport Supervisor',
        avgSalary: 92900,
        salaryGrowth: 11,
        jobs: 122,
      },
      {
        industryId: 6092,
        industryTitle: 'Manufacturing, Transport & Logistics',
        roleTitle: 'Contracts Manager',
        avgSalary: 144400,
        salaryGrowth: 10,
        jobs: 71,
      },
      {
        industryId: 6092,
        industryTitle: 'Manufacturing, Transport & Logistics',
        roleTitle: 'Assembly Technician',
        avgSalary: 70800,
        salaryGrowth: 10,
        jobs: 60,
      },
      {
        industryId: 6092,
        industryTitle: 'Manufacturing, Transport & Logistics',
        roleTitle: 'Assistant',
        avgSalary: 64500,
        salaryGrowth: 10,
        jobs: 99,
      },
      {
        industryId: 6092,
        industryTitle: 'Manufacturing, Transport & Logistics',
        roleTitle: 'Production Technician',
        avgSalary: 68200,
        salaryGrowth: 10,
        jobs: 97,
      },
      {
        industryId: 6092,
        industryTitle: 'Manufacturing, Transport & Logistics',
        roleTitle: 'Warehouse Staff',
        avgSalary: 61600,
        salaryGrowth: 10,
        jobs: 80,
      },
      {
        industryId: 6092,
        industryTitle: 'Manufacturing, Transport & Logistics',
        roleTitle: 'Pickup and Delivery Driver',
        avgSalary: 64600,
        salaryGrowth: 10,
        jobs: 86,
      },
      {
        industryId: 6092,
        industryTitle: 'Manufacturing, Transport & Logistics',
        roleTitle: 'Depot Manager',
        avgSalary: 107800,
        salaryGrowth: 10,
        jobs: 62,
      },
      {
        industryId: 6092,
        industryTitle: 'Manufacturing, Transport & Logistics',
        roleTitle: 'Trainer',
        avgSalary: 96700,
        salaryGrowth: 9,
        jobs: 77,
      },
      {
        industryId: 6092,
        industryTitle: 'Manufacturing, Transport & Logistics',
        roleTitle: 'Courier',
        avgSalary: 63100,
        salaryGrowth: 9,
        jobs: 141,
      },
      {
        industryId: 6092,
        industryTitle: 'Manufacturing, Transport & Logistics',
        roleTitle: 'Customer Service Representative',
        avgSalary: 65900,
        salaryGrowth: 9,
        jobs: 241,
      },
      {
        industryId: 6092,
        industryTitle: 'Manufacturing, Transport & Logistics',
        roleTitle: 'Buyer',
        avgSalary: 103900,
        salaryGrowth: 9,
        jobs: 54,
      },
      {
        industryId: 6092,
        industryTitle: 'Manufacturing, Transport & Logistics',
        roleTitle: 'Leading Hand',
        avgSalary: 76100,
        salaryGrowth: 9,
        jobs: 261,
      },
      {
        industryId: 6092,
        industryTitle: 'Manufacturing, Transport & Logistics',
        roleTitle: 'Baggage Handler',
        avgSalary: 63000,
        salaryGrowth: 9,
        jobs: 68,
      },
    ],
  },
  {
    industryIdFilter: 6123,
    roles: [
      {
        industryId: 6123,
        industryTitle: 'Education & Training',
        roleTitle: 'Adviser',
        avgSalary: 100300,
        salaryGrowth: 12,
        jobs: 89,
      },
      {
        industryId: 6123,
        industryTitle: 'Education & Training',
        roleTitle: 'Outside School Hours Care Coordinator',
        avgSalary: 70600,
        salaryGrowth: 10,
        jobs: 812,
      },
      {
        industryId: 6123,
        industryTitle: 'Education & Training',
        roleTitle: 'Academic',
        avgSalary: 121400,
        salaryGrowth: 9,
        jobs: 132,
      },
      {
        industryId: 6123,
        industryTitle: 'Education & Training',
        roleTitle: 'Early Childhood Teacher',
        avgSalary: 83200,
        salaryGrowth: 9,
        jobs: 5712,
      },
      {
        industryId: 6123,
        industryTitle: 'Education & Training',
        roleTitle: 'Project Manager',
        avgSalary: 127500,
        salaryGrowth: 8,
        jobs: 66,
      },
      {
        industryId: 6123,
        industryTitle: 'Education & Training',
        roleTitle: 'Nominated Supervisor',
        avgSalary: 88100,
        salaryGrowth: 8,
        jobs: 182,
      },
      {
        industryId: 6123,
        industryTitle: 'Education & Training',
        roleTitle: 'Assistant Coordinator',
        avgSalary: 65200,
        salaryGrowth: 8,
        jobs: 250,
      },
      {
        industryId: 6123,
        industryTitle: 'Education & Training',
        roleTitle: 'Centre Manager',
        avgSalary: 83600,
        salaryGrowth: 7,
        jobs: 764,
      },
      {
        industryId: 6123,
        industryTitle: 'Education & Training',
        roleTitle: 'Educational Leader',
        avgSalary: 73500,
        salaryGrowth: 7,
        jobs: 737,
      },
      {
        industryId: 6123,
        industryTitle: 'Education & Training',
        roleTitle: 'Enrolments Officer',
        avgSalary: 68500,
        salaryGrowth: 6,
        jobs: 54,
      },
      {
        industryId: 6123,
        industryTitle: 'Education & Training',
        roleTitle: 'Officer',
        avgSalary: 86300,
        salaryGrowth: 6,
        jobs: 124,
      },
      {
        industryId: 6123,
        industryTitle: 'Education & Training',
        roleTitle: 'Centre Director',
        avgSalary: 92200,
        salaryGrowth: 6,
        jobs: 742,
      },
      {
        industryId: 6123,
        industryTitle: 'Education & Training',
        roleTitle: 'Second in Charge',
        avgSalary: 69100,
        salaryGrowth: 6,
        jobs: 67,
      },
      {
        industryId: 6123,
        industryTitle: 'Education & Training',
        roleTitle: 'Room Leader',
        avgSalary: 68000,
        salaryGrowth: 6,
        jobs: 981,
      },
      {
        industryId: 6123,
        industryTitle: 'Education & Training',
        roleTitle: 'Psychologist',
        avgSalary: 104600,
        salaryGrowth: 5,
        jobs: 71,
      },
      {
        industryId: 6123,
        industryTitle: 'Education & Training',
        roleTitle: 'Research Assistant',
        avgSalary: 86600,
        salaryGrowth: 5,
        jobs: 50,
      },
      {
        industryId: 6123,
        industryTitle: 'Education & Training',
        roleTitle: 'Admissions Officer',
        avgSalary: 71900,
        salaryGrowth: 5,
        jobs: 70,
      },
      {
        industryId: 6123,
        industryTitle: 'Education & Training',
        roleTitle: 'Counsellor',
        avgSalary: 90200,
        salaryGrowth: 5,
        jobs: 135,
      },
      {
        industryId: 6123,
        industryTitle: 'Education & Training',
        roleTitle: 'Cook',
        avgSalary: 55200,
        salaryGrowth: 5,
        jobs: 482,
      },
      {
        industryId: 6123,
        industryTitle: 'Education & Training',
        roleTitle: 'Trainer',
        avgSalary: 94400,
        salaryGrowth: 5,
        jobs: 957,
      },
    ],
  },
  {
    industryIdFilter: 6163,
    roles: [
      {
        industryId: 6163,
        industryTitle: 'Community Services & Development',
        roleTitle: 'Support Assistant',
        avgSalary: 69700,
        salaryGrowth: 24,
        jobs: 93,
      },
      {
        industryId: 6163,
        industryTitle: 'Community Services & Development',
        roleTitle: 'Carer',
        avgSalary: 65300,
        salaryGrowth: 18,
        jobs: 523,
      },
      {
        industryId: 6163,
        industryTitle: 'Community Services & Development',
        roleTitle: 'Lifestyle Assistant',
        avgSalary: 63300,
        salaryGrowth: 17,
        jobs: 144,
      },
      {
        industryId: 6163,
        industryTitle: 'Community Services & Development',
        roleTitle: 'Cleaner',
        avgSalary: 61400,
        salaryGrowth: 16,
        jobs: 153,
      },
      {
        industryId: 6163,
        industryTitle: 'Community Services & Development',
        roleTitle: 'Careworker',
        avgSalary: 65900,
        salaryGrowth: 16,
        jobs: 2017,
      },
      {
        industryId: 6163,
        industryTitle: 'Community Services & Development',
        roleTitle: 'Personal Care Assistant',
        avgSalary: 65400,
        salaryGrowth: 16,
        jobs: 337,
      },
      {
        industryId: 6163,
        industryTitle: 'Community Services & Development',
        roleTitle: 'Activities Officer',
        avgSalary: 58800,
        salaryGrowth: 14,
        jobs: 112,
      },
      {
        industryId: 6163,
        industryTitle: 'Community Services & Development',
        roleTitle: 'Assistant in Nursing',
        avgSalary: 72200,
        salaryGrowth: 13,
        jobs: 106,
      },
      {
        industryId: 6163,
        industryTitle: 'Community Services & Development',
        roleTitle: 'Case Coordinator',
        avgSalary: 87200,
        salaryGrowth: 13,
        jobs: 61,
      },
      {
        industryId: 6163,
        industryTitle: 'Community Services & Development',
        roleTitle: 'Care Adviser',
        avgSalary: 84700,
        salaryGrowth: 12,
        jobs: 75,
      },
      {
        industryId: 6163,
        industryTitle: 'Community Services & Development',
        roleTitle: 'Compliance Officer',
        avgSalary: 81700,
        salaryGrowth: 12,
        jobs: 58,
      },
      {
        industryId: 6163,
        industryTitle: 'Community Services & Development',
        roleTitle: 'Lifestyle Officer',
        avgSalary: 58300,
        salaryGrowth: 11,
        jobs: 104,
      },
      {
        industryId: 6163,
        industryTitle: 'Community Services & Development',
        roleTitle: 'Nurse',
        avgSalary: 92000,
        salaryGrowth: 10,
        jobs: 212,
      },
      {
        industryId: 6163,
        industryTitle: 'Community Services & Development',
        roleTitle: 'Area Coordinator',
        avgSalary: 85300,
        salaryGrowth: 10,
        jobs: 120,
      },
      {
        industryId: 6163,
        industryTitle: 'Community Services & Development',
        roleTitle: 'Lifestyle Coordinator',
        avgSalary: 66200,
        salaryGrowth: 9,
        jobs: 132,
      },
      {
        industryId: 6163,
        industryTitle: 'Community Services & Development',
        roleTitle: 'Practice Leader',
        avgSalary: 104800,
        salaryGrowth: 9,
        jobs: 107,
      },
      {
        industryId: 6163,
        industryTitle: 'Community Services & Development',
        roleTitle: 'Program Coordinator',
        avgSalary: 85600,
        salaryGrowth: 9,
        jobs: 200,
      },
      {
        industryId: 6163,
        industryTitle: 'Community Services & Development',
        roleTitle: 'Facilitator',
        avgSalary: 84000,
        salaryGrowth: 9,
        jobs: 538,
      },
      {
        industryId: 6163,
        industryTitle: 'Community Services & Development',
        roleTitle: 'Domestic Assistant',
        avgSalary: 59600,
        salaryGrowth: 8,
        jobs: 221,
      },
      {
        industryId: 6163,
        industryTitle: 'Community Services & Development',
        roleTitle: 'Support Worker',
        avgSalary: 72300,
        salaryGrowth: 8,
        jobs: 6701,
      },
    ],
  },
  {
    industryIdFilter: 6205,
    roles: [
      {
        industryId: 6205,
        industryTitle: 'Farming, Animals & Conservation',
        roleTitle: 'Agronomist',
        avgSalary: 95600,
        salaryGrowth: 11,
        jobs: 135,
      },
      {
        industryId: 6205,
        industryTitle: 'Farming, Animals & Conservation',
        roleTitle: 'Veterinarian',
        avgSalary: 112200,
        salaryGrowth: 7,
        jobs: 188,
      },
      {
        industryId: 6205,
        industryTitle: 'Farming, Animals & Conservation',
        roleTitle: 'Nurse',
        avgSalary: 60600,
        salaryGrowth: 6,
        jobs: 401,
      },
      {
        industryId: 6205,
        industryTitle: 'Farming, Animals & Conservation',
        roleTitle: 'Farm Manager',
        avgSalary: 97700,
        salaryGrowth: 6,
        jobs: 261,
      },
      {
        industryId: 6205,
        industryTitle: 'Farming, Animals & Conservation',
        roleTitle: 'Farmhand',
        avgSalary: 62800,
        salaryGrowth: 5,
        jobs: 593,
      },
      {
        industryId: 6205,
        industryTitle: 'Farming, Animals & Conservation',
        roleTitle: 'Project Officer',
        avgSalary: 98200,
        salaryGrowth: 5,
        jobs: 76,
      },
      {
        industryId: 6205,
        industryTitle: 'Farming, Animals & Conservation',
        roleTitle: 'Groomer',
        avgSalary: 60000,
        salaryGrowth: 5,
        jobs: 138,
      },
      {
        industryId: 6205,
        industryTitle: 'Farming, Animals & Conservation',
        roleTitle: 'Horticulturist',
        avgSalary: 69800,
        salaryGrowth: 4,
        jobs: 52,
      },
      {
        industryId: 6205,
        industryTitle: 'Farming, Animals & Conservation',
        roleTitle: 'Operations Manager',
        avgSalary: 111900,
        salaryGrowth: 4,
        jobs: 84,
      },
      {
        industryId: 6205,
        industryTitle: 'Farming, Animals & Conservation',
        roleTitle: 'Attendant',
        avgSalary: 58800,
        salaryGrowth: 4,
        jobs: 66,
      },
      {
        industryId: 6205,
        industryTitle: 'Farming, Animals & Conservation',
        roleTitle: 'Nursery Hand',
        avgSalary: 55100,
        salaryGrowth: 4,
        jobs: 120,
      },
      {
        industryId: 6205,
        industryTitle: 'Farming, Animals & Conservation',
        roleTitle: 'Ranger',
        avgSalary: 74000,
        salaryGrowth: 3,
        jobs: 181,
      },
      {
        industryId: 6205,
        industryTitle: 'Farming, Animals & Conservation',
        roleTitle: 'Machine Operator',
        avgSalary: 68700,
        salaryGrowth: 3,
        jobs: 107,
      },
      {
        industryId: 6205,
        industryTitle: 'Farming, Animals & Conservation',
        roleTitle: 'Station Hand',
        avgSalary: 62900,
        salaryGrowth: 3,
        jobs: 72,
      },
    ],
  },
  {
    industryIdFilter: 6246,
    roles: [
      {
        industryId: 6246,
        industryTitle: 'Sport & Recreation',
        roleTitle: 'Centre Manager',
        avgSalary: 84800,
        salaryGrowth: 10,
        jobs: 56,
      },
      {
        industryId: 6246,
        industryTitle: 'Sport & Recreation',
        roleTitle: 'Club Manager',
        avgSalary: 71000,
        salaryGrowth: 8,
        jobs: 398,
      },
      {
        industryId: 6246,
        industryTitle: 'Sport & Recreation',
        roleTitle: 'Coach',
        avgSalary: 73200,
        salaryGrowth: 6,
        jobs: 709,
      },
      {
        industryId: 6246,
        industryTitle: 'Sport & Recreation',
        roleTitle: 'Coordinator',
        avgSalary: 76200,
        salaryGrowth: 2,
        jobs: 89,
      },
    ],
  },
  {
    industryIdFilter: 6251,
    roles: [
      {
        industryId: 6251,
        industryTitle: 'Administration & Office Support',
        roleTitle: 'Planner',
        avgSalary: 82800,
        salaryGrowth: 21,
        jobs: 52,
      },
      {
        industryId: 6251,
        industryTitle: 'Administration & Office Support',
        roleTitle: 'Construction Administrator',
        avgSalary: 74200,
        salaryGrowth: 11,
        jobs: 93,
      },
      {
        industryId: 6251,
        industryTitle: 'Administration & Office Support',
        roleTitle: 'Team Leader',
        avgSalary: 91000,
        salaryGrowth: 10,
        jobs: 81,
      },
      {
        industryId: 6251,
        industryTitle: 'Administration & Office Support',
        roleTitle: 'Liaison Officer',
        avgSalary: 72600,
        salaryGrowth: 9,
        jobs: 127,
      },
      {
        industryId: 6251,
        industryTitle: 'Administration & Office Support',
        roleTitle: 'Legal Administrator',
        avgSalary: 63400,
        salaryGrowth: 8,
        jobs: 81,
      },
      {
        industryId: 6251,
        industryTitle: 'Administration & Office Support',
        roleTitle: 'Support Manager',
        avgSalary: 92100,
        salaryGrowth: 8,
        jobs: 51,
      },
      {
        industryId: 6251,
        industryTitle: 'Administration & Office Support',
        roleTitle: 'Accounts and Administration Assistant',
        avgSalary: 62600,
        salaryGrowth: 8,
        jobs: 99,
      },
      {
        industryId: 6251,
        industryTitle: 'Administration & Office Support',
        roleTitle: 'Compliance Officer',
        avgSalary: 77700,
        salaryGrowth: 8,
        jobs: 101,
      },
      {
        industryId: 6251,
        industryTitle: 'Administration & Office Support',
        roleTitle: 'Client Services Administrator',
        avgSalary: 68200,
        salaryGrowth: 8,
        jobs: 171,
      },
      {
        industryId: 6251,
        industryTitle: 'Administration & Office Support',
        roleTitle: 'Operations Assistant',
        avgSalary: 65900,
        salaryGrowth: 8,
        jobs: 152,
      },
      {
        industryId: 6251,
        industryTitle: 'Administration & Office Support',
        roleTitle: 'Rostering Coordinator',
        avgSalary: 72400,
        salaryGrowth: 8,
        jobs: 90,
      },
      {
        industryId: 6251,
        industryTitle: 'Administration & Office Support',
        roleTitle: 'Financial Administrator',
        avgSalary: 75200,
        salaryGrowth: 8,
        jobs: 55,
      },
      {
        industryId: 6251,
        industryTitle: 'Administration & Office Support',
        roleTitle: 'Office Allrounder',
        avgSalary: 61500,
        salaryGrowth: 8,
        jobs: 221,
      },
      {
        industryId: 6251,
        industryTitle: 'Administration & Office Support',
        roleTitle: 'Delivery Coordinator',
        avgSalary: 71800,
        salaryGrowth: 6,
        jobs: 57,
      },
      {
        industryId: 6251,
        industryTitle: 'Administration & Office Support',
        roleTitle: 'Administration Coordinator',
        avgSalary: 70600,
        salaryGrowth: 6,
        jobs: 603,
      },
      {
        industryId: 6251,
        industryTitle: 'Administration & Office Support',
        roleTitle: 'Customer Service Assistant',
        avgSalary: 58400,
        salaryGrowth: 6,
        jobs: 54,
      },
      {
        industryId: 6251,
        industryTitle: 'Administration & Office Support',
        roleTitle: 'Practice Manager',
        avgSalary: 88600,
        salaryGrowth: 6,
        jobs: 76,
      },
      {
        industryId: 6251,
        industryTitle: 'Administration & Office Support',
        roleTitle: 'Accounts Assistant',
        avgSalary: 62000,
        salaryGrowth: 6,
        jobs: 64,
      },
      {
        industryId: 6251,
        industryTitle: 'Administration & Office Support',
        roleTitle: 'Administration Manager',
        avgSalary: 80200,
        salaryGrowth: 6,
        jobs: 423,
      },
      {
        industryId: 6251,
        industryTitle: 'Administration & Office Support',
        roleTitle: 'Service Coordinator',
        avgSalary: 71500,
        salaryGrowth: 6,
        jobs: 532,
      },
    ],
  },
  {
    industryIdFilter: 6263,
    roles: [
      {
        industryId: 6263,
        industryTitle: 'Design & Architecture',
        roleTitle: 'Architect',
        avgSalary: 103000,
        salaryGrowth: 9,
        jobs: 263,
      },
      {
        industryId: 6263,
        industryTitle: 'Design & Architecture',
        roleTitle: 'Documenter',
        avgSalary: 94000,
        salaryGrowth: 9,
        jobs: 59,
      },
      {
        industryId: 6263,
        industryTitle: 'Design & Architecture',
        roleTitle: 'Design Manager',
        avgSalary: 153700,
        salaryGrowth: 7,
        jobs: 81,
      },
      {
        industryId: 6263,
        industryTitle: 'Design & Architecture',
        roleTitle: 'Draftsperson',
        avgSalary: 80500,
        salaryGrowth: 5,
        jobs: 490,
      },
      {
        industryId: 6263,
        industryTitle: 'Design & Architecture',
        roleTitle: 'Landscape Architect',
        avgSalary: 93900,
        salaryGrowth: 4,
        jobs: 169,
      },
      {
        industryId: 6263,
        industryTitle: 'Design & Architecture',
        roleTitle: 'Product Developer',
        avgSalary: 81400,
        salaryGrowth: 3,
        jobs: 62,
      },
      {
        industryId: 6263,
        industryTitle: 'Design & Architecture',
        roleTitle: 'Planner',
        avgSalary: 99500,
        salaryGrowth: 3,
        jobs: 500,
      },
      {
        industryId: 6263,
        industryTitle: 'Design & Architecture',
        roleTitle: 'Architectural Technician',
        avgSalary: 91800,
        salaryGrowth: 3,
        jobs: 53,
      },
      {
        industryId: 6263,
        industryTitle: 'Design & Architecture',
        roleTitle: 'Graduate of Architecture',
        avgSalary: 77000,
        salaryGrowth: 3,
        jobs: 103,
      },
      {
        industryId: 6263,
        industryTitle: 'Design & Architecture',
        roleTitle: 'Project Architect',
        avgSalary: 106800,
        salaryGrowth: 2,
        jobs: 151,
      },
    ],
  },
  {
    industryIdFilter: 6281,
    roles: [
      {
        industryId: 6281,
        industryTitle: 'Information & Communication Technology',
        roleTitle: 'Modeller',
        avgSalary: 162800,
        salaryGrowth: 15,
        jobs: 55,
      },
      {
        industryId: 6281,
        industryTitle: 'Information & Communication Technology',
        roleTitle: 'Administration Officer',
        avgSalary: 112400,
        salaryGrowth: 13,
        jobs: 127,
      },
      {
        industryId: 6281,
        industryTitle: 'Information & Communication Technology',
        roleTitle: 'Automation Engineer',
        avgSalary: 134200,
        salaryGrowth: 9,
        jobs: 121,
      },
      {
        industryId: 6281,
        industryTitle: 'Information & Communication Technology',
        roleTitle: 'Integration Specialist',
        avgSalary: 134200,
        salaryGrowth: 8,
        jobs: 56,
      },
      {
        industryId: 6281,
        industryTitle: 'Information & Communication Technology',
        roleTitle: 'Test Lead',
        avgSalary: 145000,
        salaryGrowth: 8,
        jobs: 66,
      },
      {
        industryId: 6281,
        industryTitle: 'Information & Communication Technology',
        roleTitle: 'Communications Technician',
        avgSalary: 104800,
        salaryGrowth: 8,
        jobs: 80,
      },
      {
        industryId: 6281,
        industryTitle: 'Information & Communication Technology',
        roleTitle: 'Testing Analyst',
        avgSalary: 118000,
        salaryGrowth: 8,
        jobs: 180,
      },
      {
        industryId: 6281,
        industryTitle: 'Information & Communication Technology',
        roleTitle: 'Functional Consultant',
        avgSalary: 153300,
        salaryGrowth: 7,
        jobs: 152,
      },
      {
        industryId: 6281,
        industryTitle: 'Information & Communication Technology',
        roleTitle: 'Tester',
        avgSalary: 121500,
        salaryGrowth: 7,
        jobs: 173,
      },
      {
        industryId: 6281,
        industryTitle: 'Information & Communication Technology',
        roleTitle: 'Service Desk Officer',
        avgSalary: 77200,
        salaryGrowth: 6,
        jobs: 92,
      },
      {
        industryId: 6281,
        industryTitle: 'Information & Communication Technology',
        roleTitle: 'Applications Developer',
        avgSalary: 114600,
        salaryGrowth: 6,
        jobs: 102,
      },
      {
        industryId: 6281,
        industryTitle: 'Information & Communication Technology',
        roleTitle: 'Service Desk Team Leader',
        avgSalary: 100700,
        salaryGrowth: 6,
        jobs: 74,
      },
      {
        industryId: 6281,
        industryTitle: 'Information & Communication Technology',
        roleTitle: 'Security Architect',
        avgSalary: 179600,
        salaryGrowth: 6,
        jobs: 130,
      },
      {
        industryId: 6281,
        industryTitle: 'Information & Communication Technology',
        roleTitle: 'Machine Learning Engineer',
        avgSalary: 153900,
        salaryGrowth: 6,
        jobs: 65,
      },
      {
        industryId: 6281,
        industryTitle: 'Information & Communication Technology',
        roleTitle: 'Quality Engineer',
        avgSalary: 131700,
        salaryGrowth: 6,
        jobs: 70,
      },
      {
        industryId: 6281,
        industryTitle: 'Information & Communication Technology',
        roleTitle: 'Operations Analyst',
        avgSalary: 118800,
        salaryGrowth: 6,
        jobs: 99,
      },
      {
        industryId: 6281,
        industryTitle: 'Information & Communication Technology',
        roleTitle: 'Field Technician',
        avgSalary: 76800,
        salaryGrowth: 6,
        jobs: 70,
      },
      {
        industryId: 6281,
        industryTitle: 'Information & Communication Technology',
        roleTitle: 'Information Technology Manager',
        avgSalary: 136400,
        salaryGrowth: 5,
        jobs: 262,
      },
      {
        industryId: 6281,
        industryTitle: 'Information & Communication Technology',
        roleTitle: 'Implementation Consultant',
        avgSalary: 117900,
        salaryGrowth: 5,
        jobs: 79,
      },
      {
        industryId: 6281,
        industryTitle: 'Information & Communication Technology',
        roleTitle: 'Network and Systems Engineer',
        avgSalary: 100900,
        salaryGrowth: 5,
        jobs: 80,
      },
    ],
  },
  {
    industryIdFilter: 6304,
    roles: [
      {
        industryId: 6304,
        industryTitle: 'Advertising, Arts & Media',
        roleTitle: 'Journalist',
        avgSalary: 71300,
        salaryGrowth: 9,
        jobs: 174,
      },
      {
        industryId: 6304,
        industryTitle: 'Advertising, Arts & Media',
        roleTitle: 'Designer',
        avgSalary: 83100,
        salaryGrowth: 6,
        jobs: 58,
      },
      {
        industryId: 6304,
        industryTitle: 'Advertising, Arts & Media',
        roleTitle: 'Account Manager',
        avgSalary: 86300,
        salaryGrowth: 2,
        jobs: 180,
      },
      {
        industryId: 6304,
        industryTitle: 'Advertising, Arts & Media',
        roleTitle: 'Producer',
        avgSalary: 86600,
        salaryGrowth: 2,
        jobs: 290,
      },
      {
        industryId: 6304,
        industryTitle: 'Advertising, Arts & Media',
        roleTitle: 'Reporter',
        avgSalary: 74400,
        salaryGrowth: 2,
        jobs: 125,
      },
    ],
  },
  {
    industryIdFilter: 6317,
    roles: [
      {
        industryId: 6317,
        industryTitle: 'Human Resources & Recruitment',
        roleTitle: 'Trainer',
        avgSalary: 101700,
        salaryGrowth: 14,
        jobs: 59,
      },
      {
        industryId: 6317,
        industryTitle: 'Human Resources & Recruitment',
        roleTitle: 'Learning and Development Specialist',
        avgSalary: 112600,
        salaryGrowth: 14,
        jobs: 81,
      },
      {
        industryId: 6317,
        industryTitle: 'Human Resources & Recruitment',
        roleTitle: 'People Manager',
        avgSalary: 151200,
        salaryGrowth: 13,
        jobs: 58,
      },
      {
        industryId: 6317,
        industryTitle: 'Human Resources & Recruitment',
        roleTitle: 'Operations Manager',
        avgSalary: 135400,
        salaryGrowth: 11,
        jobs: 51,
      },
      {
        industryId: 6317,
        industryTitle: 'Human Resources & Recruitment',
        roleTitle: 'Training Coordinator',
        avgSalary: 87500,
        salaryGrowth: 9,
        jobs: 99,
      },
      {
        industryId: 6317,
        industryTitle: 'Human Resources & Recruitment',
        roleTitle: 'Health and Safety Adviser',
        avgSalary: 112400,
        salaryGrowth: 9,
        jobs: 110,
      },
      {
        industryId: 6317,
        industryTitle: 'Human Resources & Recruitment',
        roleTitle: 'Work Health and Safety Coordinator',
        avgSalary: 92000,
        salaryGrowth: 9,
        jobs: 118,
      },
      {
        industryId: 6317,
        industryTitle: 'Human Resources & Recruitment',
        roleTitle: 'Occupational Health and Safety Adviser',
        avgSalary: 101600,
        salaryGrowth: 8,
        jobs: 170,
      },
      {
        industryId: 6317,
        industryTitle: 'Human Resources & Recruitment',
        roleTitle: 'Recruitment Adviser',
        avgSalary: 93100,
        salaryGrowth: 8,
        jobs: 165,
      },
      {
        industryId: 6317,
        industryTitle: 'Human Resources & Recruitment',
        roleTitle: 'Work Health and Safety Manager',
        avgSalary: 132100,
        salaryGrowth: 7,
        jobs: 141,
      },
      {
        industryId: 6317,
        industryTitle: 'Human Resources & Recruitment',
        roleTitle: 'Workplace Relations Officer',
        avgSalary: 131900,
        salaryGrowth: 7,
        jobs: 67,
      },
      {
        industryId: 6317,
        industryTitle: 'Human Resources & Recruitment',
        roleTitle: 'Human Resources Assistant',
        avgSalary: 67600,
        salaryGrowth: 7,
        jobs: 72,
      },
      {
        industryId: 6317,
        industryTitle: 'Human Resources & Recruitment',
        roleTitle: 'People and Culture Adviser',
        avgSalary: 97700,
        salaryGrowth: 7,
        jobs: 325,
      },
      {
        industryId: 6317,
        industryTitle: 'Human Resources & Recruitment',
        roleTitle: 'Director',
        avgSalary: 189500,
        salaryGrowth: 6,
        jobs: 52,
      },
      {
        industryId: 6317,
        industryTitle: 'Human Resources & Recruitment',
        roleTitle: 'Health Safety and Environment Manager',
        avgSalary: 149100,
        salaryGrowth: 6,
        jobs: 57,
      },
      {
        industryId: 6317,
        industryTitle: 'Human Resources & Recruitment',
        roleTitle: 'Health Safety and Environment Adviser',
        avgSalary: 113000,
        salaryGrowth: 6,
        jobs: 98,
      },
      {
        industryId: 6317,
        industryTitle: 'Human Resources & Recruitment',
        roleTitle: 'Return to Work Coordinator',
        avgSalary: 87900,
        salaryGrowth: 6,
        jobs: 61,
      },
      {
        industryId: 6317,
        industryTitle: 'Human Resources & Recruitment',
        roleTitle: 'Account Manager',
        avgSalary: 89400,
        salaryGrowth: 6,
        jobs: 144,
      },
      {
        industryId: 6317,
        industryTitle: 'Human Resources & Recruitment',
        roleTitle: 'Human Resources Consultant',
        avgSalary: 103000,
        salaryGrowth: 5,
        jobs: 151,
      },
      {
        industryId: 6317,
        industryTitle: 'Human Resources & Recruitment',
        roleTitle: 'Work Health and Safety Officer',
        avgSalary: 96400,
        salaryGrowth: 5,
        jobs: 186,
      },
    ],
  },
  {
    industryIdFilter: 6362,
    roles: [
      {
        industryId: 6362,
        industryTitle: 'Sales',
        roleTitle: 'Appointment Setter',
        avgSalary: 74200,
        salaryGrowth: 15,
        jobs: 56,
      },
      {
        industryId: 6362,
        industryTitle: 'Sales',
        roleTitle: 'Business Development Officer',
        avgSalary: 84000,
        salaryGrowth: 15,
        jobs: 60,
      },
      {
        industryId: 6362,
        industryTitle: 'Sales',
        roleTitle: 'Business Development Specialist',
        avgSalary: 103000,
        salaryGrowth: 12,
        jobs: 77,
      },
      {
        industryId: 6362,
        industryTitle: 'Sales',
        roleTitle: 'Success Manager',
        avgSalary: 106400,
        salaryGrowth: 9,
        jobs: 121,
      },
      {
        industryId: 6362,
        industryTitle: 'Sales',
        roleTitle: 'Employment Consultant',
        avgSalary: 66500,
        salaryGrowth: 9,
        jobs: 68,
      },
      {
        industryId: 6362,
        industryTitle: 'Sales',
        roleTitle: 'Operations Manager',
        avgSalary: 128900,
        salaryGrowth: 8,
        jobs: 53,
      },
      {
        industryId: 6362,
        industryTitle: 'Sales',
        roleTitle: 'Area Manager',
        avgSalary: 93300,
        salaryGrowth: 7,
        jobs: 140,
      },
      {
        industryId: 6362,
        industryTitle: 'Sales',
        roleTitle: 'Representative',
        avgSalary: 77700,
        salaryGrowth: 7,
        jobs: 98,
      },
      {
        industryId: 6362,
        industryTitle: 'Sales',
        roleTitle: 'Cadet',
        avgSalary: 61200,
        salaryGrowth: 6,
        jobs: 99,
      },
      {
        industryId: 6362,
        industryTitle: 'Sales',
        roleTitle: 'Partnerships Manager',
        avgSalary: 113800,
        salaryGrowth: 5,
        jobs: 106,
      },
      {
        industryId: 6362,
        industryTitle: 'Sales',
        roleTitle: 'Showroom Consultant',
        avgSalary: 63200,
        salaryGrowth: 5,
        jobs: 79,
      },
      {
        industryId: 6362,
        industryTitle: 'Sales',
        roleTitle: 'Support Officer',
        avgSalary: 69600,
        salaryGrowth: 5,
        jobs: 100,
      },
      {
        industryId: 6362,
        industryTitle: 'Sales',
        roleTitle: 'Sales Director',
        avgSalary: 173100,
        salaryGrowth: 5,
        jobs: 94,
      },
      {
        industryId: 6362,
        industryTitle: 'Sales',
        roleTitle: 'Sales Representative',
        avgSalary: 79100,
        salaryGrowth: 4,
        jobs: 4192,
      },
      {
        industryId: 6362,
        industryTitle: 'Sales',
        roleTitle: 'Customer Service and Sales Consultant',
        avgSalary: 70600,
        salaryGrowth: 4,
        jobs: 105,
      },
      {
        industryId: 6362,
        industryTitle: 'Sales',
        roleTitle: 'Branch Manager',
        avgSalary: 100200,
        salaryGrowth: 4,
        jobs: 110,
      },
      {
        industryId: 6362,
        industryTitle: 'Sales',
        roleTitle: 'Recruitment Consultant',
        avgSalary: 77900,
        salaryGrowth: 4,
        jobs: 129,
      },
      {
        industryId: 6362,
        industryTitle: 'Sales',
        roleTitle: 'Account Manager',
        avgSalary: 101000,
        salaryGrowth: 4,
        jobs: 3744,
      },
      {
        industryId: 6362,
        industryTitle: 'Sales',
        roleTitle: 'Territory Manager',
        avgSalary: 87400,
        salaryGrowth: 4,
        jobs: 763,
      },
      {
        industryId: 6362,
        industryTitle: 'Sales',
        roleTitle: 'Sales Coordinator',
        avgSalary: 69800,
        salaryGrowth: 3,
        jobs: 507,
      },
    ],
  },
];
