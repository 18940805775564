import type { Locale } from '@seek/melways-sites';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends Record<string, unknown>> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<T extends Record<string, unknown>, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Locale: { input: Locale; output: Locale };
}

export interface Article {
  __typename?: 'Article';
  image: Scalars['String']['output'];
  link: Link;
  text: Scalars['String']['output'];
  title: Scalars['String']['output'];
}

export interface Asset {
  __typename?: 'Asset';
  src: Scalars['String']['output'];
  type: Scalars['String']['output'];
}

export type Block = CustomComponent | Feature | Grid | RichText;

export interface Breakdown {
  __typename?: 'Breakdown';
  name: Scalars['String']['output'];
  results: BreakdownResults;
}

export interface BreakdownCultureResult {
  __typename?: 'BreakdownCultureResult';
  name: Scalars['String']['output'];
  value: Scalars['Int']['output'];
}

export interface BreakdownCultureResults {
  __typename?: 'BreakdownCultureResults';
  culture: BreakdownCultureResult[];
}

export interface BreakdownNormalResults {
  __typename?: 'BreakdownNormalResults';
  delight: Scalars['Int']['output'];
  must: Scalars['Int']['output'];
  neutral: Scalars['Int']['output'];
  putOff: Scalars['Int']['output'];
}

export type BreakdownResults = BreakdownCultureResults | BreakdownNormalResults;

export interface Characteristic {
  __typename?: 'Characteristic';
  icon: Scalars['String']['output'];
  name: Scalars['String']['output'];
  results: CharacteristicResult[];
  sum: Scalars['Int']['output'];
}

export interface CharacteristicResult {
  __typename?: 'CharacteristicResult';
  count: Scalars['Int']['output'];
  name: Scalars['String']['output'];
}

export interface ChildResult {
  __typename?: 'ChildResult';
  name: Scalars['String']['output'];
  sum: Scalars['Int']['output'];
}

export type CustomComponent = {
  __typename?: 'CustomComponent';
  name: Scalars['String']['output'];
};

export interface Demographic {
  __typename?: 'Demographic';
  age: Characteristic;
  children: Characteristic;
  education: Characteristic;
  gender: Characteristic;
  income: Characteristic;
  location: Characteristic;
  nationality: Characteristic;
  respondants: Scalars['Int']['output'];
  seniority: Characteristic;
}

export interface Drilldown {
  __typename?: 'Drilldown';
  drilldownName: Scalars['String']['output'];
  indicative?: Maybe<Scalars['Boolean']['output']>;
  maxDiffs?: Maybe<MaxDiff[]>;
  respondents: Scalars['Float']['output'];
}

export interface DriverOption {
  __typename?: 'DriverOption';
  hasSummaries?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  sum: Scalars['Int']['output'];
}

export enum Drivers {
  All = 'ALL',
  Gender = 'GENDER',
  Generation = 'GENERATION',
  Industry = 'INDUSTRY',
  Location = 'LOCATION',
  Region = 'REGION',
  Seniority = 'SENIORITY',
  WorkType = 'WORK_TYPE',
}

export interface Feature {
  __typename?: 'Feature';
  content: RichText;
  heading?: Maybe<Scalars['String']['output']>;
  mediaItem: Asset;
}

export interface Filter {
  type: Drivers;
  value: Array<InputMaybe<Scalars['String']['input']>>;
}

export interface Grid {
  __typename?: 'Grid';
  columns?: Maybe<Scalars['Int']['output']>;
  heading?: Maybe<Scalars['String']['output']>;
  items: Article[];
  text?: Maybe<Scalars['String']['output']>;
}

export interface Link {
  __typename?: 'Link';
  tracking?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
}

export interface MaxDiff {
  __typename?: 'MaxDiff';
  breakdowns: Breakdown[];
  name: Scalars['String']['output'];
  result: Scalars['Float']['output'];
}

export interface Page {
  __typename?: 'Page';
  blocks: Block[];
  slug: Scalars['String']['output'];
}

export interface Query {
  __typename?: 'Query';
  childIndustries: ChildResult[];
  childSeniorities: ChildResult[];
  demographic: Demographic;
  genders: DriverOption[];
  generations: DriverOption[];
  industries: DriverOption[];
  locations: DriverOption[];
  page: Page;
  pages: Page[];
  regions: DriverOption[];
  respondents: Scalars['Int']['output'];
  senioritys: DriverOption[];
  summary: SummaryResult;
  survey: SurveyResults[];
  totalRespondents: Scalars['Int']['output'];
  workTypes: DriverOption[];
}

export interface QueryChildIndustriesArgs {
  locale: Scalars['Locale']['input'];
  parentIndustry: Scalars['String']['input'];
}

export interface QueryChildSenioritiesArgs {
  locale: Scalars['Locale']['input'];
  parentSeniority: Scalars['String']['input'];
}

export type QueryDemographicArgs = {
  drilldown?: InputMaybe<Filter>;
  driver: Filter;
  locale: Scalars['Locale']['input'];
};

export interface QueryGendersArgs {
  locale: Scalars['Locale']['input'];
}

export interface QueryGenerationsArgs {
  locale: Scalars['Locale']['input'];
}

export interface QueryIndustriesArgs {
  locale: Scalars['Locale']['input'];
}

export interface QueryLocationsArgs {
  locale: Scalars['Locale']['input'];
}

export interface QueryPageArgs {
  locale: Scalars['Locale']['input'];
  slug?: InputMaybe<Scalars['String']['input']>;
}

export interface QueryRegionsArgs {
  locale: Scalars['Locale']['input'];
}

export interface QueryRespondentsArgs {
  drilldown?: InputMaybe<Filter>;
  driver?: InputMaybe<Filter>;
  locale: Scalars['Locale']['input'];
}

export interface QuerySenioritysArgs {
  locale: Scalars['Locale']['input'];
}

export interface QuerySummaryArgs {
  driver: Filter;
  locale: Scalars['Locale']['input'];
}

export interface QuerySurveyArgs {
  drilldown?: InputMaybe<Filter>;
  driver: Filter;
  locale: Scalars['Locale']['input'];
}

export interface QueryTotalRespondentsArgs {
  locale: Scalars['Locale']['input'];
}

export interface QueryWorkTypesArgs {
  locale: Scalars['Locale']['input'];
}

export interface RichText {
  __typename?: 'RichText';
  html: Scalars['String']['output'];
}

export interface SummaryDriver {
  __typename?: 'SummaryDriver';
  name: Scalars['String']['output'];
  sentence: Scalars['String']['output'];
}

export interface SummaryResult {
  __typename?: 'SummaryResult';
  driverName: Scalars['String']['output'];
  overall: Scalars['String']['output'];
  summaryDrivers: Array<Maybe<SummaryDriver>>;
}

export interface SurveyResults {
  __typename?: 'SurveyResults';
  drilldowns: Drilldown[];
  driverName: Scalars['String']['output'];
}

export type GetChildIndustriesQueryVariables = Exact<{
  locale: Scalars['Locale']['input'];
  parentIndustry: Scalars['String']['input'];
}>;

export interface GetChildIndustriesQuery {
  __typename?: 'Query';
  childIndustries: Array<{
    __typename: 'ChildResult';
    name: string;
    sum: number;
  }>;
}

export type GetChildSenioritiesQueryVariables = Exact<{
  locale: Scalars['Locale']['input'];
  parentSeniority: Scalars['String']['input'];
}>;

export interface GetChildSenioritiesQuery {
  __typename?: 'Query';
  childSeniorities: Array<{
    __typename: 'ChildResult';
    name: string;
    sum: number;
  }>;
}

export type GetFilterFieldsQueryVariables = Exact<{
  locale: Scalars['Locale']['input'];
}>;

export interface GetFilterFieldsQuery {
  __typename?: 'Query';
  industries: Array<{
    __typename?: 'DriverOption';
    name: string;
    sum: number;
    hasSummaries?: boolean | null;
  }>;
  genders: Array<{ __typename?: 'DriverOption'; name: string }>;
  senioritys: Array<{ __typename?: 'DriverOption'; name: string }>;
  generations: Array<{ __typename?: 'DriverOption'; name: string }>;
  locations: Array<{ __typename?: 'DriverOption'; name: string }>;
  workTypes: Array<{ __typename?: 'DriverOption'; name: string }>;
}

export type GetPageQueryVariables = Exact<{
  slug: Scalars['String']['input'];
  locale: Scalars['Locale']['input'];
}>;

export interface GetPageQuery {
  __typename?: 'Query';
  page: {
    __typename?: 'Page';
    slug: string;
    blocks: Array<
      | { __typename: 'CustomComponent'; name: string }
      | {
          __typename: 'Feature';
          heading?: string | null;
          content: { __typename?: 'RichText'; html: string };
          mediaItem: { __typename?: 'Asset'; type: string; src: string };
        }
      | {
          __typename: 'Grid';
          heading?: string | null;
          text?: string | null;
          columns?: number | null;
          items: Array<{
            __typename?: 'Article';
            title: string;
            text: string;
            image: string;
            link: {
              __typename?: 'Link';
              url: string;
              tracking?: string | null;
            };
          }>;
        }
      | { __typename: 'RichText'; html: string }
    >;
  };
}

export type GetSummaryQueryVariables = Exact<{
  locale: Scalars['Locale']['input'];
  driver: Filter;
}>;

export interface GetSummaryQuery {
  __typename?: 'Query';
  summary: {
    __typename?: 'SummaryResult';
    driverName: string;
    overall: string;
    summaryDrivers: Array<{
      __typename?: 'SummaryDriver';
      name: string;
      sentence: string;
    } | null>;
  };
}

export type GetSurveyQueryVariables = Exact<{
  locale: Scalars['Locale']['input'];
  driver: Filter;
  drilldown?: InputMaybe<Filter>;
}>;

export interface GetSurveyQuery {
  __typename?: 'Query';
  respondents: number;
  totalRespondents: number;
  survey: Array<{
    __typename?: 'SurveyResults';
    driverName: string;
    drilldowns: Array<{
      __typename?: 'Drilldown';
      drilldownName: string;
      respondents: number;
      indicative?: boolean | null;
      maxDiffs?: Array<{
        __typename?: 'MaxDiff';
        name: string;
        result: number;
        breakdowns: Array<{
          __typename?: 'Breakdown';
          name: string;
          results:
            | {
                __typename?: 'BreakdownCultureResults';
                culture: Array<{
                  __typename?: 'BreakdownCultureResult';
                  name: string;
                  value: number;
                }>;
              }
            | {
                __typename?: 'BreakdownNormalResults';
                must: number;
                delight: number;
                putOff: number;
                neutral: number;
              };
        }>;
      }> | null;
    }>;
  }>;
  demographic: {
    __typename?: 'Demographic';
    respondants: number;
    gender: {
      __typename?: 'Characteristic';
      name: string;
      icon: string;
      sum: number;
      results: Array<{
        __typename?: 'CharacteristicResult';
        name: string;
        count: number;
      }>;
    };
    age: {
      __typename?: 'Characteristic';
      name: string;
      icon: string;
      sum: number;
      results: Array<{
        __typename?: 'CharacteristicResult';
        name: string;
        count: number;
      }>;
    };
    location: {
      __typename?: 'Characteristic';
      name: string;
      icon: string;
      sum: number;
      results: Array<{
        __typename?: 'CharacteristicResult';
        name: string;
        count: number;
      }>;
    };
    seniority: {
      __typename?: 'Characteristic';
      name: string;
      icon: string;
      sum: number;
      results: Array<{
        __typename?: 'CharacteristicResult';
        name: string;
        count: number;
      }>;
    };
    income: {
      __typename?: 'Characteristic';
      name: string;
      icon: string;
      sum: number;
      results: Array<{
        __typename?: 'CharacteristicResult';
        name: string;
        count: number;
      }>;
    };
    education: {
      __typename?: 'Characteristic';
      name: string;
      icon: string;
      sum: number;
      results: Array<{
        __typename?: 'CharacteristicResult';
        name: string;
        count: number;
      }>;
    };
    nationality: {
      __typename?: 'Characteristic';
      name: string;
      icon: string;
      sum: number;
      results: Array<{
        __typename?: 'CharacteristicResult';
        name: string;
        count: number;
      }>;
    };
    children: {
      __typename?: 'Characteristic';
      name: string;
      icon: string;
      sum: number;
      results: Array<{
        __typename?: 'CharacteristicResult';
        name: string;
        count: number;
      }>;
    };
  };
}

export const GetChildIndustriesDocument = gql`
  query getChildIndustries($locale: Locale!, $parentIndustry: String!) {
    childIndustries(locale: $locale, parentIndustry: $parentIndustry) {
      __typename
      name
      sum
    }
  }
`;

export const GetChildSenioritiesDocument = gql`
  query getChildSeniorities($locale: Locale!, $parentSeniority: String!) {
    childSeniorities(locale: $locale, parentSeniority: $parentSeniority) {
      __typename
      name
      sum
    }
  }
`;

export const GetFilterFieldsDocument = gql`
  query getFilterFields($locale: Locale!) {
    industries(locale: $locale) {
      name
      sum
      hasSummaries
    }
    genders(locale: $locale) {
      name
    }
    senioritys(locale: $locale) {
      name
    }
    generations(locale: $locale) {
      name
    }
    locations(locale: $locale) {
      name
    }
    workTypes(locale: $locale) {
      name
    }
  }
`;

export const GetPageDocument = gql`
  query getPage($slug: String!, $locale: Locale!) {
    page(slug: $slug, locale: $locale) {
      slug
      blocks {
        __typename
        ... on Feature {
          heading
          content {
            html
          }
          mediaItem {
            type
            src
          }
        }
        ... on Grid {
          heading
          text
          columns
          items {
            title
            text
            image
            link {
              url
              tracking
            }
          }
        }
        ... on RichText {
          html
        }
        ... on CustomComponent {
          name
        }
      }
    }
  }
`;

export const GetSummaryDocument = gql`
  query getSummary($locale: Locale!, $driver: Filter!) {
    summary(locale: $locale, driver: $driver) {
      driverName
      summaryDrivers {
        name
        sentence
      }
      overall
    }
  }
`;

export const GetSurveyDocument = gql`
  query getSurvey($locale: Locale!, $driver: Filter!, $drilldown: Filter) {
    respondents(locale: $locale, driver: $driver, drilldown: $drilldown)
    totalRespondents(locale: $locale)
    survey(locale: $locale, driver: $driver, drilldown: $drilldown) {
      driverName
      drilldowns {
        drilldownName
        respondents
        indicative
        maxDiffs {
          name
          result
          breakdowns {
            name
            results {
              ... on BreakdownNormalResults {
                must
                delight
                putOff
                neutral
              }
              ... on BreakdownCultureResults {
                culture {
                  name
                  value
                }
              }
            }
          }
        }
      }
    }
    demographic(driver: $driver, drilldown: $drilldown, locale: $locale) {
      respondants
      gender {
        name
        icon
        sum
        results {
          name
          count
        }
      }
      age {
        name
        icon
        sum
        results {
          name
          count
        }
      }
      location {
        name
        icon
        sum
        results {
          name
          count
        }
      }
      seniority {
        name
        icon
        sum
        results {
          name
          count
        }
      }
      income {
        name
        icon
        sum
        results {
          name
          count
        }
      }
      education {
        name
        icon
        sum
        results {
          name
          count
        }
      }
      nationality {
        name
        icon
        sum
        results {
          name
          count
        }
      }
      children {
        name
        icon
        sum
        results {
          name
          count
        }
      }
    }
  }
`;
