import { Root } from './client/Root';
import { Article, Page, PageWithoutSection } from './client/pages';
import type { RouteObject } from 'react-router-dom';
import type { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import type { ConfigStateContext } from './client/shared/hooks/useConfig';
import { ErrorBoundary } from './ErrorBoundary';
import { ArticlesCategory } from './client/pages/ArticlesCategory';
import { PATHS } from './paths';
import {
  articleLoader,
  authorWithArticlesLoader,
  categoryLoader,
  pageLoader,
  pageWithoutSectionLoader,
  previewDocumentLoader,
} from './loaders';
import { SearchResults } from './client/pages/SearchResults';
import { PreviewPage } from './client/pages/Page/PreviewPage';
import { Author } from './client/pages/Author';

export type RouteProps = {
  client: ApolloClient<NormalizedCacheObject>;
  clientTAL: ApolloClient<NormalizedCacheObject>;
  config: ConfigStateContext;
};

export const makeRoutes = ({
  config,
  client,
  clientTAL,
}: RouteProps): RouteObject[] => {
  const configProps = { config, client, clientTAL };
  const children = makeChildRoutes(configProps);

  return [
    {
      /**
       * This route added to support the language param in the URL.
       * Note: we are not validating that the dynamic param is a language. /xy and /en would both work in this current state.
       * The validation could be added to this route in the future.
       * @ref https://stackoverflow.com/questions/73738776/restricting-dynamic-segments-to-certain-values-in-react-router-v6
       */
      path: PATHS.LANGUAGE,
      errorElement: <ErrorBoundary />,
      element: <Root client={client} clientTAL={clientTAL} config={config} />,
      children: [
        {
          path: 'article-search/:term/:pageNumber?',
          element: <SearchResults />,
        },
        {
          path: 'about/search/:term/:pageNumber?',
          element: <SearchResults />,
        },
        {
          path: 'author/:slug/:pageNumber?',
          element: <Author />,
          loader: authorWithArticlesLoader(configProps),
        },
        {
          // Pages that have no section assigned are served from /page/<page-slug>
          path: PATHS.PAGE,
          children: [
            {
              index: true,
              element: <PageWithoutSection />,
              loader: pageWithoutSectionLoader(configProps),
            },
            {
              path: ':slug',
              element: <PageWithoutSection />,
              loader: pageWithoutSectionLoader(configProps),
            },
            {
              path: 'view/:id',
              element: <PreviewPage />,
              loader: previewDocumentLoader(configProps),
            },
            {
              path: 'preview/:id',
              element: <PreviewPage isDraft={true} />,
              loader: previewDocumentLoader(configProps),
            },
          ],
        },
        {
          path: ':section/*',
          children,
        },
        {
          path: 'career-advice/article-preview/:id',
          element: (
            <Article
              site={config.site}
              section={config.section}
              isPreview={true}
            />
          ),
          loader: articleLoader(configProps, true),
        },
      ],
    },
  ];
};

/**
 * These routes are shared across all root routes
 */
const makeChildRoutes = ({
  config,
  client,
  clientTAL,
}: RouteProps): RouteObject[] => {
  const configProps = { config, client, clientTAL };
  return [
    {
      path: 'article/:slug',
      element: (
        <Article
          site={config.site}
          section={config.section}
          isPreview={false}
        />
      ),
      loader: articleLoader(configProps),
    },
    {
      path: 'category/:slug/:pageNumber?',
      element: <ArticlesCategory />,
      loader: categoryLoader(configProps),
    },
    {
      path: ':subsection',
      children: [
        {
          path: 'article/:slug',
          element: (
            <Article
              site={config.site}
              section={config.section}
              isPreview={false}
            />
          ),
          loader: articleLoader(configProps),
        },
        {
          path: 'category/:slug/:pageNumber?',
          element: <ArticlesCategory />,
          loader: categoryLoader(configProps),
        },
        {
          path: '*',
          element: <Page />,
          loader: pageLoader(configProps),
        },
      ],
    },
    {
      path: ':slug?',
      element: <Page />,
      loader: pageLoader(configProps),
    },
  ];
};
