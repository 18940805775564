import { useCallback, useState, useEffect, type ReactNode } from 'react';
import * as BraidComponents from 'braid-design-system';
import parse, { type DOMNode, domToReact } from 'html-react-parser';
import { getAttribs } from './helper';
import { HTMLSanitizer } from '../../utils/htmlSanitizer';

const getBraidComponent = (braidStr: string) => {
  const braidComponentsMap = Object.keys(BraidComponents).reduce((acc, key) => {
    // @ts-ignore
    acc[key.toLowerCase()] = BraidComponents[key];
    return acc;
  }, {} as Record<string, any>);

  return braidComponentsMap[braidStr];
};

export const BraidComponent = ({ braidCode }: { braidCode: string }) => {
  const [Component, setComponent] = useState<ReactNode | null>(null);

  const createComponent = useCallback(async () => {
    const options = {
      trim: true,
      replace(domNode: DOMNode) {
        if (domNode.type === 'tag') {
          const { name, attribs, children } = domNode;

          // There are some issues rendering Divider so it is
          // directly returned here
          if (name === 'divider') {
            return <BraidComponents.Divider {...getAttribs(attribs)} />;
          }

          const HTMLComponent = getBraidComponent(name);

          if (typeof HTMLComponent === 'undefined') {
            return domNode;
          }

          return (
            <HTMLComponent {...getAttribs(attribs)}>
              {domToReact(children as DOMNode[], options)}
            </HTMLComponent>
          );
        }
      },
    };

    const sanitizer = new HTMLSanitizer();
    const sanitizedHTML = sanitizer.sanitize(braidCode);
    const parsed = parse(sanitizedHTML, options);
    setComponent(parsed);
  }, [setComponent, braidCode]);

  useEffect(() => {
    createComponent();
  }, [createComponent]);

  return <>{Component}</>;
};
