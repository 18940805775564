export const NZ_DATA = [
  {
    industryIdFilter: -1,
    roles: [
      {
        industryId: 1225,
        industryTitle: 'Trades & Services',
        roleTitle: 'Detailer',
        avgSalary: 57900,
        salaryGrowth: 21,
        jobs: 60,
      },
      {
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        roleTitle: 'Clinical Manager',
        avgSalary: 114700,
        salaryGrowth: 20,
        jobs: 91,
      },
      {
        industryId: 6205,
        industryTitle: 'Farming, Animals & Conservation',
        roleTitle: 'Ranger',
        avgSalary: 68600,
        salaryGrowth: 18,
        jobs: 75,
      },
      {
        industryId: 6043,
        industryTitle: 'Retail & Consumer Products',
        roleTitle: 'Sales Professional',
        avgSalary: 54500,
        salaryGrowth: 17,
        jobs: 83,
      },
      {
        industryId: 1206,
        industryTitle: 'Construction',
        roleTitle: 'Estimator',
        avgSalary: 114400,
        salaryGrowth: 17,
        jobs: 211,
      },
      {
        industryId: 6123,
        industryTitle: 'Education & Training',
        roleTitle: 'Centre Manager',
        avgSalary: 87100,
        salaryGrowth: 17,
        jobs: 92,
      },
      {
        industryId: 6281,
        industryTitle: 'Information & Communication Technology',
        roleTitle: 'Designer',
        avgSalary: 114200,
        salaryGrowth: 15,
        jobs: 50,
      },
      {
        industryId: 1209,
        industryTitle: 'Engineering',
        roleTitle: 'Civil Engineer',
        avgSalary: 116000,
        salaryGrowth: 14,
        jobs: 373,
      },
      {
        industryId: 6163,
        industryTitle: 'Community Services & Development',
        roleTitle: 'Coach',
        avgSalary: 61900,
        salaryGrowth: 14,
        jobs: 102,
      },
      {
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        roleTitle: 'Team Leader',
        avgSalary: 100700,
        salaryGrowth: 14,
        jobs: 61,
      },
      {
        industryId: 6163,
        industryTitle: 'Community Services & Development',
        roleTitle: 'Social Worker',
        avgSalary: 81300,
        salaryGrowth: 14,
        jobs: 360,
      },
      {
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        roleTitle: 'Midwife',
        avgSalary: 91300,
        salaryGrowth: 13,
        jobs: 79,
      },
      {
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        roleTitle: 'Therapist',
        avgSalary: 79900,
        salaryGrowth: 13,
        jobs: 307,
      },
      {
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        roleTitle: 'General Practitioner',
        avgSalary: 211600,
        salaryGrowth: 13,
        jobs: 226,
      },
      {
        industryId: 6281,
        industryTitle: 'Information & Communication Technology',
        roleTitle: 'Technical Consultant',
        avgSalary: 131900,
        salaryGrowth: 13,
        jobs: 57,
      },
      {
        industryId: 6281,
        industryTitle: 'Information & Communication Technology',
        roleTitle: 'Technical Lead',
        avgSalary: 156200,
        salaryGrowth: 12,
        jobs: 111,
      },
      {
        industryId: 6123,
        industryTitle: 'Education & Training',
        roleTitle: 'Trainer',
        avgSalary: 78800,
        salaryGrowth: 12,
        jobs: 82,
      },
      {
        industryId: 6043,
        industryTitle: 'Retail & Consumer Products',
        roleTitle: 'Team Member',
        avgSalary: 52500,
        salaryGrowth: 12,
        jobs: 163,
      },
      {
        industryId: 1225,
        industryTitle: 'Trades & Services',
        roleTitle: 'Foreperson',
        avgSalary: 83700,
        salaryGrowth: 12,
        jobs: 88,
      },
      {
        industryId: 1225,
        industryTitle: 'Trades & Services',
        roleTitle: 'Hairstylist',
        avgSalary: 60300,
        salaryGrowth: 12,
        jobs: 61,
      },
    ],
  },
  {
    industryIdFilter: 1200,
    roles: [
      {
        industryId: 1200,
        industryTitle: 'Accounting',
        roleTitle: 'Chief Financial Officer',
        avgSalary: 210400,
        salaryGrowth: 11,
        jobs: 68,
      },
      {
        industryId: 1200,
        industryTitle: 'Accounting',
        roleTitle: 'Financial Controller',
        avgSalary: 156900,
        salaryGrowth: 7,
        jobs: 129,
      },
      {
        industryId: 1200,
        industryTitle: 'Accounting',
        roleTitle: 'Financial Administrator',
        avgSalary: 65700,
        salaryGrowth: 6,
        jobs: 112,
      },
      {
        industryId: 1200,
        industryTitle: 'Accounting',
        roleTitle: 'Accounts Administrator',
        avgSalary: 63800,
        salaryGrowth: 6,
        jobs: 240,
      },
      {
        industryId: 1200,
        industryTitle: 'Accounting',
        roleTitle: 'Accounts Assistant',
        avgSalary: 62000,
        salaryGrowth: 5,
        jobs: 110,
      },
      {
        industryId: 1200,
        industryTitle: 'Accounting',
        roleTitle: 'Finance Manager',
        avgSalary: 129200,
        salaryGrowth: 5,
        jobs: 291,
      },
      {
        industryId: 1200,
        industryTitle: 'Accounting',
        roleTitle: 'Business Advisory Manager',
        avgSalary: 118500,
        salaryGrowth: 4,
        jobs: 75,
      },
      {
        industryId: 1200,
        industryTitle: 'Accounting',
        roleTitle: 'Payroll Officer',
        avgSalary: 75500,
        salaryGrowth: 4,
        jobs: 100,
      },
      {
        industryId: 1200,
        industryTitle: 'Accounting',
        roleTitle: 'Accounts Payable Administrator',
        avgSalary: 62600,
        salaryGrowth: 4,
        jobs: 79,
      },
      {
        industryId: 1200,
        industryTitle: 'Accounting',
        roleTitle: 'Partner',
        avgSalary: 126400,
        salaryGrowth: 4,
        jobs: 215,
      },
      {
        industryId: 1200,
        industryTitle: 'Accounting',
        roleTitle: 'Accountant',
        avgSalary: 90300,
        salaryGrowth: 3,
        jobs: 2026,
      },
      {
        industryId: 1200,
        industryTitle: 'Accounting',
        roleTitle: 'Credit Controller',
        avgSalary: 68100,
        salaryGrowth: 3,
        jobs: 52,
      },
      {
        industryId: 1200,
        industryTitle: 'Accounting',
        roleTitle: 'Financial Analyst',
        avgSalary: 103900,
        salaryGrowth: 2,
        jobs: 88,
      },
      {
        industryId: 1200,
        industryTitle: 'Accounting',
        roleTitle: 'Payroll Administrator',
        avgSalary: 64600,
        salaryGrowth: 2,
        jobs: 80,
      },
    ],
  },
  {
    industryIdFilter: 1203,
    roles: [
      {
        industryId: 1203,
        industryTitle: 'Banking & Financial Services',
        roleTitle: 'Analyst',
        avgSalary: 100400,
        salaryGrowth: 8,
        jobs: 59,
      },
      {
        industryId: 1203,
        industryTitle: 'Banking & Financial Services',
        roleTitle: 'Manager',
        avgSalary: 152400,
        salaryGrowth: 6,
        jobs: 55,
      },
    ],
  },
  {
    industryIdFilter: 1204,
    roles: [
      {
        industryId: 1204,
        industryTitle: 'Call Centre & Customer Service',
        roleTitle: 'Care Consultant',
        avgSalary: 55500,
        salaryGrowth: 7,
        jobs: 88,
      },
      {
        industryId: 1204,
        industryTitle: 'Call Centre & Customer Service',
        roleTitle: 'Centre Operator',
        avgSalary: 55100,
        salaryGrowth: 6,
        jobs: 108,
      },
      {
        industryId: 1204,
        industryTitle: 'Call Centre & Customer Service',
        roleTitle: 'Customer Experience Consultant',
        avgSalary: 56900,
        salaryGrowth: 5,
        jobs: 76,
      },
    ],
  },
  {
    industryIdFilter: 1206,
    roles: [
      {
        industryId: 1206,
        industryTitle: 'Construction',
        roleTitle: 'Construction Manager',
        avgSalary: 171400,
        salaryGrowth: 11,
        jobs: 104,
      },
      {
        industryId: 1206,
        industryTitle: 'Construction',
        roleTitle: 'Quantity Surveyor',
        avgSalary: 122400,
        salaryGrowth: 7,
        jobs: 574,
      },
      {
        industryId: 1206,
        industryTitle: 'Construction',
        roleTitle: 'Contracts Manager',
        avgSalary: 122000,
        salaryGrowth: 6,
        jobs: 90,
      },
      {
        industryId: 1206,
        industryTitle: 'Construction',
        roleTitle: 'Surveyor',
        avgSalary: 107300,
        salaryGrowth: 6,
        jobs: 216,
      },
      {
        industryId: 1206,
        industryTitle: 'Construction',
        roleTitle: 'Excavator Operator',
        avgSalary: 63700,
        salaryGrowth: 3,
        jobs: 94,
      },
      {
        industryId: 1206,
        industryTitle: 'Construction',
        roleTitle: 'Project Manager',
        avgSalary: 129900,
        salaryGrowth: 3,
        jobs: 1003,
      },
      {
        industryId: 1206,
        industryTitle: 'Construction',
        roleTitle: 'Truck Driver',
        avgSalary: 59200,
        salaryGrowth: 2,
        jobs: 99,
      },
    ],
  },
  {
    industryIdFilter: 1209,
    roles: [
      {
        industryId: 1209,
        industryTitle: 'Engineering',
        roleTitle: 'Water Engineer',
        avgSalary: 116100,
        salaryGrowth: 10,
        jobs: 67,
      },
      {
        industryId: 1209,
        industryTitle: 'Engineering',
        roleTitle: 'Electrical Engineer',
        avgSalary: 107000,
        salaryGrowth: 10,
        jobs: 154,
      },
      {
        industryId: 1209,
        industryTitle: 'Engineering',
        roleTitle: 'Geotechnical Engineer',
        avgSalary: 114400,
        salaryGrowth: 9,
        jobs: 189,
      },
      {
        industryId: 1209,
        industryTitle: 'Engineering',
        roleTitle: 'Process Engineer',
        avgSalary: 108100,
        salaryGrowth: 8,
        jobs: 71,
      },
      {
        industryId: 1209,
        industryTitle: 'Engineering',
        roleTitle: 'Geologist',
        avgSalary: 91800,
        salaryGrowth: 7,
        jobs: 73,
      },
      {
        industryId: 1209,
        industryTitle: 'Engineering',
        roleTitle: 'Development Engineer',
        avgSalary: 98500,
        salaryGrowth: 7,
        jobs: 104,
      },
      {
        industryId: 1209,
        industryTitle: 'Engineering',
        roleTitle: 'Mechanical Engineer',
        avgSalary: 106400,
        salaryGrowth: 6,
        jobs: 185,
      },
      {
        industryId: 1209,
        industryTitle: 'Engineering',
        roleTitle: 'Design Engineer',
        avgSalary: 104900,
        salaryGrowth: 6,
        jobs: 267,
      },
      {
        industryId: 1209,
        industryTitle: 'Engineering',
        roleTitle: 'Planner',
        avgSalary: 107400,
        salaryGrowth: 5,
        jobs: 79,
      },
      {
        industryId: 1209,
        industryTitle: 'Engineering',
        roleTitle: 'Designer',
        avgSalary: 98700,
        salaryGrowth: 5,
        jobs: 104,
      },
      {
        industryId: 1209,
        industryTitle: 'Engineering',
        roleTitle: 'Project Manager',
        avgSalary: 126600,
        salaryGrowth: 5,
        jobs: 299,
      },
      {
        industryId: 1209,
        industryTitle: 'Engineering',
        roleTitle: 'Structural Engineer',
        avgSalary: 111700,
        salaryGrowth: 5,
        jobs: 279,
      },
      {
        industryId: 1209,
        industryTitle: 'Engineering',
        roleTitle: 'Automation Engineer',
        avgSalary: 104900,
        salaryGrowth: 4,
        jobs: 61,
      },
      {
        industryId: 1209,
        industryTitle: 'Engineering',
        roleTitle: 'Systems Engineer',
        avgSalary: 107700,
        salaryGrowth: 4,
        jobs: 87,
      },
      {
        industryId: 1209,
        industryTitle: 'Engineering',
        roleTitle: 'Site Engineer',
        avgSalary: 95600,
        salaryGrowth: 3,
        jobs: 117,
      },
    ],
  },
  {
    industryIdFilter: 1210,
    roles: [
      {
        industryId: 1210,
        industryTitle: 'Government & Defence',
        roleTitle: 'Adviser',
        avgSalary: 110500,
        salaryGrowth: 8,
        jobs: 404,
      },
      {
        industryId: 1210,
        industryTitle: 'Government & Defence',
        roleTitle: 'Team Leader',
        avgSalary: 110400,
        salaryGrowth: 6,
        jobs: 59,
      },
    ],
  },
  {
    industryIdFilter: 1211,
    roles: [
      {
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        roleTitle: 'Nurse Manager',
        avgSalary: 108500,
        salaryGrowth: 11,
        jobs: 58,
      },
      {
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        roleTitle: 'Nurse Practitioner',
        avgSalary: 138300,
        salaryGrowth: 11,
        jobs: 115,
      },
      {
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        roleTitle: 'Dentist',
        avgSalary: 160200,
        salaryGrowth: 10,
        jobs: 52,
      },
      {
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        roleTitle: 'Social Worker',
        avgSalary: 78800,
        salaryGrowth: 10,
        jobs: 154,
      },
      {
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        roleTitle: 'Dispensary Technician',
        avgSalary: 58000,
        salaryGrowth: 10,
        jobs: 90,
      },
      {
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        roleTitle: 'Sonographer',
        avgSalary: 134100,
        salaryGrowth: 9,
        jobs: 91,
      },
      {
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        roleTitle: 'Pharmacist',
        avgSalary: 93600,
        salaryGrowth: 8,
        jobs: 316,
      },
      {
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        roleTitle: 'Nurse',
        avgSalary: 82900,
        salaryGrowth: 8,
        jobs: 2271,
      },
      {
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        roleTitle: 'Occupational Therapist',
        avgSalary: 80900,
        salaryGrowth: 8,
        jobs: 440,
      },
      {
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        roleTitle: 'Physiotherapist',
        avgSalary: 80400,
        salaryGrowth: 7,
        jobs: 536,
      },
      {
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        roleTitle: 'Counsellor',
        avgSalary: 75600,
        salaryGrowth: 7,
        jobs: 176,
      },
      {
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        roleTitle: 'Administration Officer',
        avgSalary: 60500,
        salaryGrowth: 6,
        jobs: 67,
      },
      {
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        roleTitle: 'Receptionist',
        avgSalary: 54700,
        salaryGrowth: 6,
        jobs: 436,
      },
      {
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        roleTitle: 'Dental Assistant',
        avgSalary: 52500,
        salaryGrowth: 6,
        jobs: 233,
      },
      {
        industryId: 1211,
        industryTitle: 'Healthcare & Medical',
        roleTitle: 'Service Manager',
        avgSalary: 103500,
        salaryGrowth: 6,
        jobs: 97,
      },
    ],
  },
  {
    industryIdFilter: 1212,
    roles: [
      {
        industryId: 1212,
        industryTitle: 'Hospitality & Tourism',
        roleTitle: 'Assistant Manager',
        avgSalary: 60200,
        salaryGrowth: 8,
        jobs: 74,
      },
      {
        industryId: 1212,
        industryTitle: 'Hospitality & Tourism',
        roleTitle: 'Bartender',
        avgSalary: 54500,
        salaryGrowth: 8,
        jobs: 112,
      },
      {
        industryId: 1212,
        industryTitle: 'Hospitality & Tourism',
        roleTitle: 'Kitchen Assistant',
        avgSalary: 48800,
        salaryGrowth: 7,
        jobs: 139,
      },
      {
        industryId: 1212,
        industryTitle: 'Hospitality & Tourism',
        roleTitle: 'Kitchen Hand',
        avgSalary: 51100,
        salaryGrowth: 7,
        jobs: 166,
      },
      {
        industryId: 1212,
        industryTitle: 'Hospitality & Tourism',
        roleTitle: 'Restaurant Manager',
        avgSalary: 64300,
        salaryGrowth: 7,
        jobs: 347,
      },
      {
        industryId: 1212,
        industryTitle: 'Hospitality & Tourism',
        roleTitle: 'Barista',
        avgSalary: 52100,
        salaryGrowth: 7,
        jobs: 218,
      },
      {
        industryId: 1212,
        industryTitle: 'Hospitality & Tourism',
        roleTitle: 'Guest Services Agent',
        avgSalary: 50600,
        salaryGrowth: 7,
        jobs: 147,
      },
      {
        industryId: 1212,
        industryTitle: 'Hospitality & Tourism',
        roleTitle: 'Night Manager',
        avgSalary: 58900,
        salaryGrowth: 7,
        jobs: 78,
      },
      {
        industryId: 1212,
        industryTitle: 'Hospitality & Tourism',
        roleTitle: 'Waitperson',
        avgSalary: 53200,
        salaryGrowth: 6,
        jobs: 121,
      },
      {
        industryId: 1212,
        industryTitle: 'Hospitality & Tourism',
        roleTitle: 'Porter',
        avgSalary: 50300,
        salaryGrowth: 6,
        jobs: 75,
      },
      {
        industryId: 1212,
        industryTitle: 'Hospitality & Tourism',
        roleTitle: 'Auditor',
        avgSalary: 53300,
        salaryGrowth: 6,
        jobs: 51,
      },
      {
        industryId: 1212,
        industryTitle: 'Hospitality & Tourism',
        roleTitle: 'Food and Beverage Attendant',
        avgSalary: 50500,
        salaryGrowth: 6,
        jobs: 167,
      },
      {
        industryId: 1212,
        industryTitle: 'Hospitality & Tourism',
        roleTitle: 'Housekeeping Supervisor',
        avgSalary: 55300,
        salaryGrowth: 6,
        jobs: 100,
      },
      {
        industryId: 1212,
        industryTitle: 'Hospitality & Tourism',
        roleTitle: 'Receptionist',
        avgSalary: 50700,
        salaryGrowth: 5,
        jobs: 116,
      },
      {
        industryId: 1212,
        industryTitle: 'Hospitality & Tourism',
        roleTitle: 'Chef',
        avgSalary: 63500,
        salaryGrowth: 5,
        jobs: 1423,
      },
      {
        industryId: 1212,
        industryTitle: 'Hospitality & Tourism',
        roleTitle: 'Room Attendant',
        avgSalary: 49300,
        salaryGrowth: 5,
        jobs: 129,
      },
      {
        industryId: 1212,
        industryTitle: 'Hospitality & Tourism',
        roleTitle: 'Duty Manager',
        avgSalary: 56500,
        salaryGrowth: 4,
        jobs: 427,
      },
      {
        industryId: 1212,
        industryTitle: 'Hospitality & Tourism',
        roleTitle: 'Cook',
        avgSalary: 54700,
        salaryGrowth: 4,
        jobs: 193,
      },
      {
        industryId: 1212,
        industryTitle: 'Hospitality & Tourism',
        roleTitle: 'Travel Consultant',
        avgSalary: 69900,
        salaryGrowth: 3,
        jobs: 88,
      },
      {
        industryId: 1212,
        industryTitle: 'Hospitality & Tourism',
        roleTitle: 'Food and Beverage Manager',
        avgSalary: 73100,
        salaryGrowth: 3,
        jobs: 56,
      },
    ],
  },
  {
    industryIdFilter: 1216,
    roles: [
      {
        industryId: 1216,
        industryTitle: 'Legal',
        roleTitle: 'Lawyer',
        avgSalary: 108400,
        salaryGrowth: 6,
        jobs: 380,
      },
      {
        industryId: 1216,
        industryTitle: 'Legal',
        roleTitle: 'Senior Associate',
        avgSalary: 157200,
        salaryGrowth: 5,
        jobs: 79,
      },
    ],
  },
  {
    industryIdFilter: 1220,
    roles: [
      {
        industryId: 1220,
        industryTitle: 'Real Estate & Property',
        roleTitle: 'Facilities Manager',
        avgSalary: 102100,
        salaryGrowth: 4,
        jobs: 97,
      },
    ],
  },
  {
    industryIdFilter: 1223,
    roles: [
      {
        industryId: 1223,
        industryTitle: 'Science & Technology',
        roleTitle: 'Laboratory Technician',
        avgSalary: 60700,
        salaryGrowth: 6,
        jobs: 156,
      },
    ],
  },
  {
    industryIdFilter: 1225,
    roles: [
      {
        industryId: 1225,
        industryTitle: 'Trades & Services',
        roleTitle: 'Inspector',
        avgSalary: 77900,
        salaryGrowth: 11,
        jobs: 63,
      },
      {
        industryId: 1225,
        industryTitle: 'Trades & Services',
        roleTitle: 'Landscaper',
        avgSalary: 63800,
        salaryGrowth: 9,
        jobs: 63,
      },
      {
        industryId: 1225,
        industryTitle: 'Trades & Services',
        roleTitle: 'Mower Operator',
        avgSalary: 54700,
        salaryGrowth: 9,
        jobs: 51,
      },
      {
        industryId: 1225,
        industryTitle: 'Trades & Services',
        roleTitle: 'Machinist',
        avgSalary: 72100,
        salaryGrowth: 9,
        jobs: 62,
      },
      {
        industryId: 1225,
        industryTitle: 'Trades & Services',
        roleTitle: 'Technician',
        avgSalary: 67700,
        salaryGrowth: 8,
        jobs: 190,
      },
      {
        industryId: 1225,
        industryTitle: 'Trades & Services',
        roleTitle: 'Trades Assistant',
        avgSalary: 60900,
        salaryGrowth: 8,
        jobs: 73,
      },
      {
        industryId: 1225,
        industryTitle: 'Trades & Services',
        roleTitle: 'Roofer',
        avgSalary: 66700,
        salaryGrowth: 6,
        jobs: 60,
      },
      {
        industryId: 1225,
        industryTitle: 'Trades & Services',
        roleTitle: 'Service Technician',
        avgSalary: 74200,
        salaryGrowth: 6,
        jobs: 538,
      },
      {
        industryId: 1225,
        industryTitle: 'Trades & Services',
        roleTitle: 'Caretaker',
        avgSalary: 56200,
        salaryGrowth: 6,
        jobs: 87,
      },
      {
        industryId: 1225,
        industryTitle: 'Trades & Services',
        roleTitle: 'Electrician',
        avgSalary: 83200,
        salaryGrowth: 5,
        jobs: 873,
      },
      {
        industryId: 1225,
        industryTitle: 'Trades & Services',
        roleTitle: 'Automotive Technician',
        avgSalary: 69500,
        salaryGrowth: 5,
        jobs: 237,
      },
      {
        industryId: 1225,
        industryTitle: 'Trades & Services',
        roleTitle: 'Security Technician',
        avgSalary: 76700,
        salaryGrowth: 5,
        jobs: 122,
      },
      {
        industryId: 1225,
        industryTitle: 'Trades & Services',
        roleTitle: 'Diesel Technician',
        avgSalary: 78000,
        salaryGrowth: 5,
        jobs: 94,
      },
      {
        industryId: 1225,
        industryTitle: 'Trades & Services',
        roleTitle: 'Operator',
        avgSalary: 56300,
        salaryGrowth: 5,
        jobs: 62,
      },
      {
        industryId: 1225,
        industryTitle: 'Trades & Services',
        roleTitle: 'Fabricator',
        avgSalary: 68900,
        salaryGrowth: 4,
        jobs: 198,
      },
      {
        industryId: 1225,
        industryTitle: 'Trades & Services',
        roleTitle: 'Installer',
        avgSalary: 64400,
        salaryGrowth: 4,
        jobs: 303,
      },
      {
        industryId: 1225,
        industryTitle: 'Trades & Services',
        roleTitle: 'Service Manager',
        avgSalary: 105200,
        salaryGrowth: 4,
        jobs: 91,
      },
    ],
  },
  {
    industryIdFilter: 6008,
    roles: [
      {
        industryId: 6008,
        industryTitle: 'Marketing & Communications',
        roleTitle: 'Marketing Executive',
        avgSalary: 78500,
        salaryGrowth: 8,
        jobs: 96,
      },
      {
        industryId: 6008,
        industryTitle: 'Marketing & Communications',
        roleTitle: 'Marketing Manager',
        avgSalary: 112100,
        salaryGrowth: 6,
        jobs: 227,
      },
      {
        industryId: 6008,
        industryTitle: 'Marketing & Communications',
        roleTitle: 'Marketing Coordinator',
        avgSalary: 66100,
        salaryGrowth: 5,
        jobs: 192,
      },
      {
        industryId: 6008,
        industryTitle: 'Marketing & Communications',
        roleTitle: 'Communications Adviser',
        avgSalary: 99100,
        salaryGrowth: 4,
        jobs: 180,
      },
      {
        industryId: 6008,
        industryTitle: 'Marketing & Communications',
        roleTitle: 'Brand Manager',
        avgSalary: 100200,
        salaryGrowth: 3,
        jobs: 85,
      },
      {
        industryId: 6008,
        industryTitle: 'Marketing & Communications',
        roleTitle: 'Events Coordinator',
        avgSalary: 63300,
        salaryGrowth: 2,
        jobs: 60,
      },
    ],
  },
  {
    industryIdFilter: 6043,
    roles: [
      {
        industryId: 6043,
        industryTitle: 'Retail & Consumer Products',
        roleTitle: 'Customer Service Representative',
        avgSalary: 49800,
        salaryGrowth: 11,
        jobs: 282,
      },
      {
        industryId: 6043,
        industryTitle: 'Retail & Consumer Products',
        roleTitle: 'Sales Associate',
        avgSalary: 51300,
        salaryGrowth: 7,
        jobs: 282,
      },
      {
        industryId: 6043,
        industryTitle: 'Retail & Consumer Products',
        roleTitle: 'Merchandiser',
        avgSalary: 49400,
        salaryGrowth: 7,
        jobs: 426,
      },
      {
        industryId: 6043,
        industryTitle: 'Retail & Consumer Products',
        roleTitle: 'Sales Assistant',
        avgSalary: 49000,
        salaryGrowth: 7,
        jobs: 1412,
      },
      {
        industryId: 6043,
        industryTitle: 'Retail & Consumer Products',
        roleTitle: 'Salesperson',
        avgSalary: 54200,
        salaryGrowth: 7,
        jobs: 65,
      },
      {
        industryId: 6043,
        industryTitle: 'Retail & Consumer Products',
        roleTitle: 'Store Manager',
        avgSalary: 63100,
        salaryGrowth: 6,
        jobs: 1398,
      },
      {
        industryId: 6043,
        industryTitle: 'Retail & Consumer Products',
        roleTitle: 'Assistant Manager',
        avgSalary: 56100,
        salaryGrowth: 5,
        jobs: 202,
      },
      {
        industryId: 6043,
        industryTitle: 'Retail & Consumer Products',
        roleTitle: 'Department Manager',
        avgSalary: 64000,
        salaryGrowth: 4,
        jobs: 58,
      },
      {
        industryId: 6043,
        industryTitle: 'Retail & Consumer Products',
        roleTitle: 'Second in Charge',
        avgSalary: 52700,
        salaryGrowth: 4,
        jobs: 52,
      },
      {
        industryId: 6043,
        industryTitle: 'Retail & Consumer Products',
        roleTitle: 'Stylist',
        avgSalary: 48300,
        salaryGrowth: 3,
        jobs: 84,
      },
      {
        industryId: 6043,
        industryTitle: 'Retail & Consumer Products',
        roleTitle: 'Sales Consultant',
        avgSalary: 53400,
        salaryGrowth: 2,
        jobs: 232,
      },
    ],
  },
  {
    industryIdFilter: 6076,
    roles: [
      {
        industryId: 6076,
        industryTitle: 'Consulting & Strategy',
        roleTitle: 'Planner',
        avgSalary: 104500,
        salaryGrowth: 8,
        jobs: 83,
      },
      {
        industryId: 6076,
        industryTitle: 'Consulting & Strategy',
        roleTitle: 'Adviser',
        avgSalary: 122000,
        salaryGrowth: 7,
        jobs: 91,
      },
    ],
  },
  {
    industryIdFilter: 6092,
    roles: [
      {
        industryId: 6092,
        industryTitle: 'Manufacturing, Transport & Logistics',
        roleTitle: 'Bus Driver',
        avgSalary: 58700,
        salaryGrowth: 11,
        jobs: 50,
      },
      {
        industryId: 6092,
        industryTitle: 'Manufacturing, Transport & Logistics',
        roleTitle: 'Crane Operator',
        avgSalary: 67900,
        salaryGrowth: 10,
        jobs: 64,
      },
      {
        industryId: 6092,
        industryTitle: 'Manufacturing, Transport & Logistics',
        roleTitle: 'Processing Officer',
        avgSalary: 51900,
        salaryGrowth: 8,
        jobs: 108,
      },
      {
        industryId: 6092,
        industryTitle: 'Manufacturing, Transport & Logistics',
        roleTitle: 'Production Manager',
        avgSalary: 106200,
        salaryGrowth: 7,
        jobs: 111,
      },
      {
        industryId: 6092,
        industryTitle: 'Manufacturing, Transport & Logistics',
        roleTitle: 'Process Worker',
        avgSalary: 50400,
        salaryGrowth: 6,
        jobs: 140,
      },
      {
        industryId: 6092,
        industryTitle: 'Manufacturing, Transport & Logistics',
        roleTitle: 'Machine Operator',
        avgSalary: 55500,
        salaryGrowth: 6,
        jobs: 280,
      },
      {
        industryId: 6092,
        industryTitle: 'Manufacturing, Transport & Logistics',
        roleTitle: 'Warehouse Officer',
        avgSalary: 53200,
        salaryGrowth: 6,
        jobs: 74,
      },
      {
        industryId: 6092,
        industryTitle: 'Manufacturing, Transport & Logistics',
        roleTitle: 'Freight Handler',
        avgSalary: 48400,
        salaryGrowth: 6,
        jobs: 61,
      },
      {
        industryId: 6092,
        industryTitle: 'Manufacturing, Transport & Logistics',
        roleTitle: 'Truck Driver',
        avgSalary: 60700,
        salaryGrowth: 5,
        jobs: 594,
      },
      {
        industryId: 6092,
        industryTitle: 'Manufacturing, Transport & Logistics',
        roleTitle: 'Driver',
        avgSalary: 57500,
        salaryGrowth: 5,
        jobs: 174,
      },
      {
        industryId: 6092,
        industryTitle: 'Manufacturing, Transport & Logistics',
        roleTitle: 'Coordinator',
        avgSalary: 64300,
        salaryGrowth: 5,
        jobs: 54,
      },
      {
        industryId: 6092,
        industryTitle: 'Manufacturing, Transport & Logistics',
        roleTitle: 'Warehouse Assistant',
        avgSalary: 52200,
        salaryGrowth: 5,
        jobs: 201,
      },
      {
        industryId: 6092,
        industryTitle: 'Manufacturing, Transport & Logistics',
        roleTitle: 'Inventory Controller',
        avgSalary: 65900,
        salaryGrowth: 4,
        jobs: 87,
      },
      {
        industryId: 6092,
        industryTitle: 'Manufacturing, Transport & Logistics',
        roleTitle: 'Production Operator',
        avgSalary: 56400,
        salaryGrowth: 4,
        jobs: 147,
      },
      {
        industryId: 6092,
        industryTitle: 'Manufacturing, Transport & Logistics',
        roleTitle: 'Planner',
        avgSalary: 85900,
        salaryGrowth: 4,
        jobs: 173,
      },
      {
        industryId: 6092,
        industryTitle: 'Manufacturing, Transport & Logistics',
        roleTitle: 'Storeperson',
        avgSalary: 54100,
        salaryGrowth: 4,
        jobs: 940,
      },
      {
        industryId: 6092,
        industryTitle: 'Manufacturing, Transport & Logistics',
        roleTitle: 'Picker and Packer',
        avgSalary: 49500,
        salaryGrowth: 3,
        jobs: 113,
      },
      {
        industryId: 6092,
        industryTitle: 'Manufacturing, Transport & Logistics',
        roleTitle: 'Forklift Driver',
        avgSalary: 54600,
        salaryGrowth: 3,
        jobs: 286,
      },
      {
        industryId: 6092,
        industryTitle: 'Manufacturing, Transport & Logistics',
        roleTitle: 'Delivery Driver',
        avgSalary: 55500,
        salaryGrowth: 3,
        jobs: 257,
      },
      {
        industryId: 6092,
        industryTitle: 'Manufacturing, Transport & Logistics',
        roleTitle: 'Operator',
        avgSalary: 56600,
        salaryGrowth: 3,
        jobs: 179,
      },
    ],
  },
  {
    industryIdFilter: 6123,
    roles: [
      {
        industryId: 6123,
        industryTitle: 'Education & Training',
        roleTitle: 'Adviser',
        avgSalary: 97200,
        salaryGrowth: 11,
        jobs: 100,
      },
      {
        industryId: 6123,
        industryTitle: 'Education & Training',
        roleTitle: 'Early Childhood Teacher',
        avgSalary: 65300,
        salaryGrowth: 11,
        jobs: 268,
      },
      {
        industryId: 6123,
        industryTitle: 'Education & Training',
        roleTitle: 'Librarian',
        avgSalary: 67300,
        salaryGrowth: 9,
        jobs: 65,
      },
      {
        industryId: 6123,
        industryTitle: 'Education & Training',
        roleTitle: 'Learning Assistant',
        avgSalary: 54000,
        salaryGrowth: 6,
        jobs: 56,
      },
      {
        industryId: 6123,
        industryTitle: 'Education & Training',
        roleTitle: 'Teachers Aide',
        avgSalary: 54600,
        salaryGrowth: 5,
        jobs: 192,
      },
      {
        industryId: 6123,
        industryTitle: 'Education & Training',
        roleTitle: 'Lecturer',
        avgSalary: 92400,
        salaryGrowth: 2,
        jobs: 139,
      },
    ],
  },
  {
    industryIdFilter: 6163,
    roles: [
      {
        industryId: 6163,
        industryTitle: 'Community Services & Development',
        roleTitle: 'Facilitator',
        avgSalary: 63100,
        salaryGrowth: 12,
        jobs: 130,
      },
      {
        industryId: 6163,
        industryTitle: 'Community Services & Development',
        roleTitle: 'Youth Worker',
        avgSalary: 57900,
        salaryGrowth: 11,
        jobs: 189,
      },
      {
        industryId: 6163,
        industryTitle: 'Community Services & Development',
        roleTitle: 'Adviser',
        avgSalary: 99700,
        salaryGrowth: 9,
        jobs: 94,
      },
      {
        industryId: 6163,
        industryTitle: 'Community Services & Development',
        roleTitle: 'Service Manager',
        avgSalary: 88200,
        salaryGrowth: 7,
        jobs: 71,
      },
      {
        industryId: 6163,
        industryTitle: 'Community Services & Development',
        roleTitle: 'Navigator',
        avgSalary: 62500,
        salaryGrowth: 7,
        jobs: 154,
      },
      {
        industryId: 6163,
        industryTitle: 'Community Services & Development',
        roleTitle: 'Caregiver',
        avgSalary: 50900,
        salaryGrowth: 6,
        jobs: 179,
      },
      {
        industryId: 6163,
        industryTitle: 'Community Services & Development',
        roleTitle: 'Employment Consultant',
        avgSalary: 62600,
        salaryGrowth: 5,
        jobs: 60,
      },
      {
        industryId: 6163,
        industryTitle: 'Community Services & Development',
        roleTitle: 'Case Manager',
        avgSalary: 73100,
        salaryGrowth: 4,
        jobs: 50,
      },
      {
        industryId: 6163,
        industryTitle: 'Community Services & Development',
        roleTitle: 'Support Worker',
        avgSalary: 51800,
        salaryGrowth: 2,
        jobs: 1661,
      },
    ],
  },
  {
    industryIdFilter: 6205,
    roles: [
      {
        industryId: 6205,
        industryTitle: 'Farming, Animals & Conservation',
        roleTitle: 'Nurse',
        avgSalary: 56800,
        salaryGrowth: 6,
        jobs: 214,
      },
      {
        industryId: 6205,
        industryTitle: 'Farming, Animals & Conservation',
        roleTitle: 'Animal Attendant',
        avgSalary: 47000,
        salaryGrowth: 5,
        jobs: 55,
      },
      {
        industryId: 6205,
        industryTitle: 'Farming, Animals & Conservation',
        roleTitle: 'Veterinarian',
        avgSalary: 107200,
        salaryGrowth: 4,
        jobs: 137,
      },
    ],
  },
  {
    industryIdFilter: 6246,
    roles: [
      {
        industryId: 6246,
        industryTitle: 'Sport & Recreation',
        roleTitle: 'Lifeguard',
        avgSalary: 51500,
        salaryGrowth: 9,
        jobs: 136,
      },
    ],
  },
  {
    industryIdFilter: 6251,
    roles: [
      {
        industryId: 6251,
        industryTitle: 'Administration & Office Support',
        roleTitle: 'Office Assistant',
        avgSalary: 55900,
        salaryGrowth: 10,
        jobs: 56,
      },
      {
        industryId: 6251,
        industryTitle: 'Administration & Office Support',
        roleTitle: 'Registry Officer',
        avgSalary: 65700,
        salaryGrowth: 10,
        jobs: 52,
      },
      {
        industryId: 6251,
        industryTitle: 'Administration & Office Support',
        roleTitle: 'Project Coordinator',
        avgSalary: 73500,
        salaryGrowth: 9,
        jobs: 76,
      },
      {
        industryId: 6251,
        industryTitle: 'Administration & Office Support',
        roleTitle: 'Team Administrator',
        avgSalary: 63700,
        salaryGrowth: 8,
        jobs: 112,
      },
      {
        industryId: 6251,
        industryTitle: 'Administration & Office Support',
        roleTitle: 'Administration Coordinator',
        avgSalary: 60300,
        salaryGrowth: 6,
        jobs: 50,
      },
      {
        industryId: 6251,
        industryTitle: 'Administration & Office Support',
        roleTitle: 'Support Administrator',
        avgSalary: 61100,
        salaryGrowth: 6,
        jobs: 148,
      },
      {
        industryId: 6251,
        industryTitle: 'Administration & Office Support',
        roleTitle: 'Support Officer',
        avgSalary: 60500,
        salaryGrowth: 6,
        jobs: 272,
      },
      {
        industryId: 6251,
        industryTitle: 'Administration & Office Support',
        roleTitle: 'Administration Officer',
        avgSalary: 59600,
        salaryGrowth: 6,
        jobs: 1340,
      },
      {
        industryId: 6251,
        industryTitle: 'Administration & Office Support',
        roleTitle: 'Customer Service Representative',
        avgSalary: 57100,
        salaryGrowth: 6,
        jobs: 55,
      },
      {
        industryId: 6251,
        industryTitle: 'Administration & Office Support',
        roleTitle: 'Office Administrator',
        avgSalary: 59500,
        salaryGrowth: 5,
        jobs: 583,
      },
      {
        industryId: 6251,
        industryTitle: 'Administration & Office Support',
        roleTitle: 'Support Coordinator',
        avgSalary: 63300,
        salaryGrowth: 4,
        jobs: 65,
      },
      {
        industryId: 6251,
        industryTitle: 'Administration & Office Support',
        roleTitle: 'Operations Administrator',
        avgSalary: 61000,
        salaryGrowth: 4,
        jobs: 51,
      },
      {
        industryId: 6251,
        industryTitle: 'Administration & Office Support',
        roleTitle: 'Administration Manager',
        avgSalary: 73500,
        salaryGrowth: 4,
        jobs: 62,
      },
      {
        industryId: 6251,
        industryTitle: 'Administration & Office Support',
        roleTitle: 'Service Coordinator',
        avgSalary: 62500,
        salaryGrowth: 3,
        jobs: 90,
      },
      {
        industryId: 6251,
        industryTitle: 'Administration & Office Support',
        roleTitle: 'Executive Assistant',
        avgSalary: 81000,
        salaryGrowth: 3,
        jobs: 426,
      },
      {
        industryId: 6251,
        industryTitle: 'Administration & Office Support',
        roleTitle: 'Project Administrator',
        avgSalary: 64500,
        salaryGrowth: 3,
        jobs: 69,
      },
      {
        industryId: 6251,
        industryTitle: 'Administration & Office Support',
        roleTitle: 'Receptionist',
        avgSalary: 53300,
        salaryGrowth: 3,
        jobs: 531,
      },
      {
        industryId: 6251,
        industryTitle: 'Administration & Office Support',
        roleTitle: 'Personal Assistant',
        avgSalary: 69500,
        salaryGrowth: 3,
        jobs: 268,
      },
      {
        industryId: 6251,
        industryTitle: 'Administration & Office Support',
        roleTitle: 'Accounts Administrator',
        avgSalary: 61900,
        salaryGrowth: 3,
        jobs: 75,
      },
      {
        industryId: 6251,
        industryTitle: 'Administration & Office Support',
        roleTitle: 'Office Manager',
        avgSalary: 71600,
        salaryGrowth: 2,
        jobs: 237,
      },
    ],
  },
  {
    industryIdFilter: 6263,
    roles: [
      {
        industryId: 6263,
        industryTitle: 'Design & Architecture',
        roleTitle: 'Planner',
        avgSalary: 104100,
        salaryGrowth: 8,
        jobs: 121,
      },
      {
        industryId: 6263,
        industryTitle: 'Design & Architecture',
        roleTitle: 'Architectural Technician',
        avgSalary: 90000,
        salaryGrowth: 3,
        jobs: 52,
      },
    ],
  },
  {
    industryIdFilter: 6281,
    roles: [
      {
        industryId: 6281,
        industryTitle: 'Information & Communication Technology',
        roleTitle: 'Web Developer',
        avgSalary: 105400,
        salaryGrowth: 11,
        jobs: 79,
      },
      {
        industryId: 6281,
        industryTitle: 'Information & Communication Technology',
        roleTitle: 'Business Intelligence Software Developer',
        avgSalary: 119000,
        salaryGrowth: 10,
        jobs: 52,
      },
      {
        industryId: 6281,
        industryTitle: 'Information & Communication Technology',
        roleTitle: 'Data Analyst',
        avgSalary: 97900,
        salaryGrowth: 9,
        jobs: 116,
      },
      {
        industryId: 6281,
        industryTitle: 'Information & Communication Technology',
        roleTitle: 'Support Technician',
        avgSalary: 65700,
        salaryGrowth: 7,
        jobs: 55,
      },
      {
        industryId: 6281,
        industryTitle: 'Information & Communication Technology',
        roleTitle: 'Support Specialist',
        avgSalary: 74400,
        salaryGrowth: 6,
        jobs: 143,
      },
      {
        industryId: 6281,
        industryTitle: 'Information & Communication Technology',
        roleTitle: 'Delivery Manager',
        avgSalary: 135300,
        salaryGrowth: 6,
        jobs: 82,
      },
      {
        industryId: 6281,
        industryTitle: 'Information & Communication Technology',
        roleTitle: 'Software Developer',
        avgSalary: 121200,
        salaryGrowth: 6,
        jobs: 645,
      },
      {
        industryId: 6281,
        industryTitle: 'Information & Communication Technology',
        roleTitle: 'Service Desk Analyst',
        avgSalary: 64400,
        salaryGrowth: 5,
        jobs: 117,
      },
      {
        industryId: 6281,
        industryTitle: 'Information & Communication Technology',
        roleTitle: 'Solutions Architect',
        avgSalary: 159000,
        salaryGrowth: 5,
        jobs: 197,
      },
      {
        industryId: 6281,
        industryTitle: 'Information & Communication Technology',
        roleTitle: 'Adviser',
        avgSalary: 113000,
        salaryGrowth: 5,
        jobs: 50,
      },
      {
        industryId: 6281,
        industryTitle: 'Information & Communication Technology',
        roleTitle: 'Support Engineer',
        avgSalary: 82000,
        salaryGrowth: 5,
        jobs: 152,
      },
      {
        industryId: 6281,
        industryTitle: 'Information & Communication Technology',
        roleTitle: 'Delivery Lead',
        avgSalary: 137100,
        salaryGrowth: 5,
        jobs: 62,
      },
      {
        industryId: 6281,
        industryTitle: 'Information & Communication Technology',
        roleTitle: 'Systems Engineer',
        avgSalary: 104100,
        salaryGrowth: 4,
        jobs: 361,
      },
      {
        industryId: 6281,
        industryTitle: 'Information & Communication Technology',
        roleTitle: 'Systems Administrator',
        avgSalary: 88500,
        salaryGrowth: 4,
        jobs: 105,
      },
      {
        industryId: 6281,
        industryTitle: 'Information & Communication Technology',
        roleTitle: 'Security Engineer',
        avgSalary: 125900,
        salaryGrowth: 4,
        jobs: 70,
      },
      {
        industryId: 6281,
        industryTitle: 'Information & Communication Technology',
        roleTitle: 'Testing Analyst',
        avgSalary: 105800,
        salaryGrowth: 4,
        jobs: 106,
      },
      {
        industryId: 6281,
        industryTitle: 'Information & Communication Technology',
        roleTitle: 'Project Manager',
        avgSalary: 131800,
        salaryGrowth: 3,
        jobs: 326,
      },
    ],
  },
  {
    industryIdFilter: 6317,
    roles: [
      {
        industryId: 6317,
        industryTitle: 'Human Resources & Recruitment',
        roleTitle: 'Adviser',
        avgSalary: 113100,
        salaryGrowth: 10,
        jobs: 64,
      },
      {
        industryId: 6317,
        industryTitle: 'Human Resources & Recruitment',
        roleTitle: 'Human Resources Adviser',
        avgSalary: 97000,
        salaryGrowth: 6,
        jobs: 229,
      },
      {
        industryId: 6317,
        industryTitle: 'Human Resources & Recruitment',
        roleTitle: 'Health and Safety Adviser',
        avgSalary: 99000,
        salaryGrowth: 5,
        jobs: 129,
      },
      {
        industryId: 6317,
        industryTitle: 'Human Resources & Recruitment',
        roleTitle: 'Human Resources Business Partner',
        avgSalary: 126700,
        salaryGrowth: 3,
        jobs: 107,
      },
      {
        industryId: 6317,
        industryTitle: 'Human Resources & Recruitment',
        roleTitle: 'Human Resources Coordinator',
        avgSalary: 66200,
        salaryGrowth: 3,
        jobs: 86,
      },
      {
        industryId: 6317,
        industryTitle: 'Human Resources & Recruitment',
        roleTitle: 'People and Culture Manager',
        avgSalary: 139500,
        salaryGrowth: 3,
        jobs: 54,
      },
      {
        industryId: 6317,
        industryTitle: 'Human Resources & Recruitment',
        roleTitle: 'Partner',
        avgSalary: 117000,
        salaryGrowth: 2,
        jobs: 274,
      },
      {
        industryId: 6317,
        industryTitle: 'Human Resources & Recruitment',
        roleTitle: 'Human Resources Manager',
        avgSalary: 117900,
        salaryGrowth: 2,
        jobs: 92,
      },
      {
        industryId: 6317,
        industryTitle: 'Human Resources & Recruitment',
        roleTitle: 'People and Culture Adviser',
        avgSalary: 89600,
        salaryGrowth: 1,
        jobs: 90,
      },
    ],
  },
  {
    industryIdFilter: 6362,
    roles: [
      {
        industryId: 6362,
        industryTitle: 'Sales',
        roleTitle: 'Account Manager',
        avgSalary: 92100,
        salaryGrowth: 5,
        jobs: 822,
      },
      {
        industryId: 6362,
        industryTitle: 'Sales',
        roleTitle: 'Sales Engineer',
        avgSalary: 95500,
        salaryGrowth: 5,
        jobs: 52,
      },
      {
        industryId: 6362,
        industryTitle: 'Sales',
        roleTitle: 'Sales Executive',
        avgSalary: 82800,
        salaryGrowth: 4,
        jobs: 116,
      },
      {
        industryId: 6362,
        industryTitle: 'Sales',
        roleTitle: 'Territory Manager',
        avgSalary: 82600,
        salaryGrowth: 4,
        jobs: 249,
      },
      {
        industryId: 6362,
        industryTitle: 'Sales',
        roleTitle: 'Sales Specialist',
        avgSalary: 84800,
        salaryGrowth: 2,
        jobs: 104,
      },
      {
        industryId: 6362,
        industryTitle: 'Sales',
        roleTitle: 'Business Development Manager',
        avgSalary: 103900,
        salaryGrowth: 1,
        jobs: 752,
      },
    ],
  },
];
