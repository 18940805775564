import { StyleSheet, type Styles, View } from '@react-pdf/renderer';
import type { FC, PropsWithChildren } from 'react';
type PdfButtonIconProps = PropsWithChildren<View['props']>;

const styles = StyleSheet.create({
  buttonIcon: {
    backgroundColor: '#1E47A9',
    padding: '12px',
    borderRadius: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
});

export const PdfButtonIcon: FC<PdfButtonIconProps> = ({
  children,
  ...props
}) => (
  <View {...props} style={{ ...styles.buttonIcon, ...(props.style as Styles) }}>
    {children}
  </View>
);
