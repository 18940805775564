import { createClient, type ClientOptions } from '@seek/ca-graphql-client';
import { browserHttpLink } from '@seek/ca-graphql-client/browser';
import gql from 'graphql-tag';
import type { ConfigStateContext } from '../hooks/useConfig';
import { nodeHttpLink } from '@seek/ca-graphql-client/node';

const CANDIDATE_USER_QUERY = gql`
  query GetUser {
    viewer {
      ... on Candidate {
        id
        emailAddress
        personalDetails {
          firstName
          lastName
        }
      }
    }
  }
`;

export const getCaAccountContext = async (config: ConfigStateContext) => {
  const client = createClient({
    appName: 'wpt-cms-unification',
    headers: {},
    httpLink: typeof window === 'undefined' ? nodeHttpLink : browserHttpLink,
    authOptions: {
      useAuth: true,
      forceStrategy: 'AUTH0',
    },
    brand: config.brand as ClientOptions['brand'],
    country: config.country as ClientOptions['country'],
  });

  const response = await client.query({
    query: CANDIDATE_USER_QUERY,
    fetchPolicy: 'network-only',
  });

  const { viewer } = response.data;

  return {
    caAccountContext:
      viewer && viewer.personalDetails
        ? {
            firstName: viewer.personalDetails.firstName,
            lastName: viewer.personalDetails.lastName,
            email: viewer.emailAddress,
            id: viewer.id,
          }
        : undefined,
  };
};
