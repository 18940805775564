import { createContext, useContext, useState } from 'react';

type DriverBreakdownContext = {
  sortBy: string | undefined;
  setSortBy: (value: string) => void;
};

export const DriverBreakdownContext = createContext<DriverBreakdownContext>({
  sortBy: undefined,
  setSortBy: () => {},
});

export const DriverBreakdownProvider = ({
  defaultSortBy,
  children,
}: {
  defaultSortBy: string | undefined;
  children: React.ReactNode;
}) => {
  const [sortBy, setSortBy] =
    useState<DriverBreakdownContext['sortBy']>(defaultSortBy);

  return (
    <DriverBreakdownContext.Provider value={{ sortBy, setSortBy }}>
      {children}
    </DriverBreakdownContext.Provider>
  );
};

export const useDriverBreakdown = () => {
  const context = useContext(DriverBreakdownContext);
  if (!context) {
    throw new Error(
      'useDriverBreakdown must be used within a DriverBreakdownProvider',
    );
  }
  return context;
};
