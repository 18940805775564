import { RichText } from '@seek/cmsu-rich-text';
import { Box, Disclosure, Heading, Stack } from 'braid-design-system';
import React from 'react';
import type { AuthorLocalization } from '../../../helpers/loadersHelper';
import { richTextRenderers } from '../modules';

import AuthorProfileImage from './images/author-profile-image.png';
import translations from '../../.vocab';
import { useTranslations } from '@vocab/react';

type Props = {
  author: AuthorLocalization;
};

export const AuthorProfile = ({
  author: { name, description, image },
}: Props) => {
  const { t } = useTranslations(translations);
  const hasMoreContent = description?.raw.children.length > 1;
  let shortDesc;
  let remaniningDesc;
  if (hasMoreContent) {
    shortDesc = {
      ...description,
      raw: {
        ...description?.raw,
        children: [description?.raw.children[0]],
      },
    };
    remaniningDesc = {
      ...description,
      raw: {
        ...description?.raw,
        children: description?.raw.children.slice(1),
      },
    };
  }

  return (
    <Stack space="gutter">
      <img
        src={image?.url || AuthorProfileImage}
        alt={image?.alt || ''}
        style={{
          height: '96px',
          width: '96px',
          borderRadius: '50%',
        }}
      />
      <Heading level="2" data={{ testid: 'Author_Name_TestId' }}>
        {name}
      </Heading>
      {hasMoreContent ? (
        <>
          <RichText content={shortDesc?.raw} renderers={richTextRenderers} />
          <Disclosure
            expandLabel={t('Read more')}
            collapseLabel={t('Read less')}
            id="author-read-more"
          >
            <Box data={{ testid: 'Author_Desc_TestId' }} paddingTop="small">
              <RichText content={remaniningDesc?.raw} />
            </Box>
          </Disclosure>
        </>
      ) : (
        <RichText content={description?.raw} renderers={richTextRenderers} />
      )}
    </Stack>
  );
};
