import type { Locale } from '@seek/melways-sites';

export const getIndustryOptions = (locale: Locale, t: any): string[] => {
  let options: string[] = [];
  switch (locale) {
    case 'en-AU':
    case 'en-HK':
    case 'en-ID':
    case 'en-MY':
    case 'en-NZ':
    case 'en-PH':
    case 'en-SG':
    case 'id-ID':
      options = [
        t('Accounting'),
        t('Advertising'),
        t('Automobile'),
        t('Banking'),
        t('Call Center'),
        t('Computer'),
        t('Construction'),
        t('Consulting Business'),
        t('Consulting IT'),
        t('Consumer'),
        t('eCommerce'),
        t('Education'),
        t('Electrical'),
        t('Entertainment'),
        t('Food'),
        t('General'),
        t('Healthcare'),
        t('Hotel'),
        t('Insurance'),
        t('Manufacturing'),
        t('Oil'),
        t('Property'),
        t('Retail'),
        t('Telecommunication'),
        t('Transportation'),
        t('Others'),
      ];
      break;
    case 'en-TH':
    case 'th-TH':
      options = [
        t('Accounting-TH'),
        t('Advertising-TH'),
        t('Banking-TH'),
        t('Beauty-TH'),
        t('Building-TH'),
        t('Education-TH'),
        t('Engineering-TH'),
        t('Food-TH'),
        t('Government-TH'),
        t('Hospitality-TH'),
        t('IT-TH'),
        t('Insurance-TH'),
        t('Internet-TH'),
        t('Medical-TH'),
        t('Property-TH'),
        t('Recruitment-TH'),
        t('Retail-TH'),
        t('Trading-TH'),
        t('Transportation-TH'),
        t('Travel-TH'),
        t('Others-TH'),
        t('Electronics-TH'),
        t('Energy-TH'),
        t('Manufacturing-TH'),
        t('Motor-TH'),
        t('Telecommunication-TH'),
      ];
      break;
    default:
      break;
  }
  return options;
};
