import type { Category } from '@seek/cmsu-cms-connect';
import { useMelwaysLink } from '@seek/melways-react';
import type { Language } from '@seek/melways-sites';
import { useTranslations } from '@vocab/react';
import translations from './.vocab';

import {
  Actions,
  Box,
  ButtonLink,
  Heading,
  Spread,
  Text,
} from 'braid-design-system';

import { ArticleItem } from '../ArticleItem/ArticleItem';
import { ITEM_DISPLAY_TYPE } from '../../helpers/articleHelper';
import { useScreenSize, type ViewPoints } from '../../hooks/useScreenSize';
import { Tiles } from '../Tiles/Tiles';

type Props = {
  heading?: string | null | undefined;
  description?: string | null | undefined;
  category: Category;
  language: Language;
  sectionOverride?: string;
};
export const LatestArticlesByCategory = ({
  category,
  heading,
  description,
  language,
  sectionOverride,
}: Props) => {
  const urlResolver = useMelwaysLink();
  const { t } = useTranslations(translations);
  const screenSize = useScreenSize();

  if (
    category.relatedArticlesInCategory &&
    category.relatedArticlesInCategory.length &&
    category.relatedArticlesInCategory.length === 0
  )
    return;

  const articlesToDisplay = getArticlesToDisplayByScreenSize(screenSize);
  const showBrowseAll =
    category.relatedArticlesInCategory.length > articlesToDisplay;

  const section = sectionOverride
    ? sectionOverride
    : getSectionFromCategory(category);

  const categoryLink = urlResolver({
    language,
    path: `/${section}/category/${category.slug}`,
  });

  return (
    <Spread space="gutter" direction="vertical">
      <Heading level="2">
        <Box component="span" data={{ testid: 'Category_Name_TestId' }}>
          {Boolean(heading) ? heading : category.name}
        </Box>
      </Heading>
      {Boolean(description) && <Text>{description}</Text>}
      <Tiles
        space="gutter"
        columns={{
          mobile: 1, // on mobile, we still show 3 articles but in a single column
          tablet: articlesToDisplay,
        }}
      >
        {category.relatedArticlesInCategory
          .slice(0, articlesToDisplay)
          .map((article) => (
            <Box
              component="span"
              key={article.id}
              data={{ testid: `ArticleItem_${article.id}_TestId` }}
            >
              <ArticleItem
                displayType={ITEM_DISPLAY_TYPE.CARD}
                article={article}
                language={language}
                section={section}
              />
            </Box>
          ))}
      </Tiles>
      {showBrowseAll && (
        <Actions data={{ testid: 'BrowseAllButtonTestId' }}>
          <ButtonLink href={categoryLink} variant="ghost" tone="neutral">
            {t('Browse all')}
          </ButtonLink>
        </Actions>
      )}
    </Spread>
  );
};

export const getArticlesToDisplayByScreenSize = (
  screenSize: ViewPoints,
): 1 | 2 | 3 => {
  if (screenSize.isTablet) return 2;
  return 3;
};

const getSectionFromCategory = (category: Category) =>
  category.relatedCategorySection?.parentSection?.sectionName
    ? `${category.relatedCategorySection?.parentSection?.sectionName}/${category.relatedCategorySection?.sectionName}`
    : category.relatedCategorySection?.sectionName;
