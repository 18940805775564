import {
  type CustomHeader,
  HeaderLayout,
  type Section,
} from '@seek/cmsu-cms-connect';

export const isNumber = (value: any): boolean => !isNaN(Number(value));

export const enabledSubscriptionForm = (section: string) =>
  ['hiring-advice', 'market-insights'].includes(section);

export const removeLanguagePath = (pageUrl: string, language: string) => {
  // remove extra language path from non-english language
  if (pageUrl.startsWith(`/${language}/`)) {
    return pageUrl.substring(3);
  }

  return pageUrl;
};

interface HeaderProps {
  type: 'SeekHeader' | 'CustomHeader';
  layout?: HeaderLayout;
  logo?: CustomHeader['logo'];
  links?: CustomHeader['links'];
  divider?: CustomHeader['divider'];
}

export const getHeaderConfig = (
  headerOptions: Section['headerOptions'],
): HeaderProps => {
  if (headerOptions?.headerType?.__typename === 'CustomHeader') {
    return {
      type: 'CustomHeader',
      logo: headerOptions.headerType.logo,
      links: headerOptions.headerType.links,
      divider: headerOptions.headerType.divider,
    };
  }

  if (headerOptions?.headerType?.__typename === 'SeekHeader') {
    return {
      type: 'SeekHeader',
      layout: headerOptions.headerType.layout || HeaderLayout.Full,
    };
  }

  return { type: 'SeekHeader' };
};
