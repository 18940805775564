import type { Country } from '@seek/melways-sites';
import { AU_DATA } from './fastestGrowingSalariesAU';
import { NZ_DATA } from './fastestGrowingSalariesNZ';

export interface FastestGrowingSalariesInsight {
  rank?: number;
  jobs: number;
  roleTitle: string;
  industryTitle: string;
  industryId: number;
  avgSalary: number;
  salaryGrowth: number;
}

export interface FastestGrowingSalariesIndustriesInsights {
  industryIdFilter: number;
  roles: FastestGrowingSalariesInsight[];
}

export const ALL_INDUSTRIES = 'All industries';

const fastesGrowingSalariesByCountry: Partial<
  Record<Country, FastestGrowingSalariesIndustriesInsights[]>
> = {
  ['au']: AU_DATA,
  ['nz']: NZ_DATA,
};

export const getFastestGrowingSalaries = (countryCode: Country) =>
  fastesGrowingSalariesByCountry[countryCode];

export const industriesBy = (
  fastestGrowingSalaries: FastestGrowingSalariesIndustriesInsights[],
) =>
  fastestGrowingSalaries
    .filter((item) => item.industryIdFilter !== -1 && item.roles.length > 0)
    .map((item) => ({
      id: item.roles[0].industryId.toString(),
      description: item.roles[0].industryTitle,
    }))
    .sort((a, b) => a.description.localeCompare(b.description));
