
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJtaW4gcmVhZCI6Im1pbiByZWFkIiwibWluIGxpc3RlbiI6Im1pbiBsaXN0ZW4iLCJtaW4gd2F0Y2giOiJtaW4gd2F0Y2gifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJtaW4gcmVhZCI6Im1pbiByZWFkIiwibWluIGxpc3RlbiI6Im1pbiBsaXN0ZW4iLCJtaW4gd2F0Y2giOiJtaW4gd2F0Y2gifQ%3D%3D!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJtaW4gcmVhZCI6IuC4meC4suC4l%2BC4teC5g%2BC4meC4geC4suC4o%2BC4reC5iOC4suC4mSIsIm1pbiBsaXN0ZW4iOiLguJnguLLguJfguLXguYPguJnguIHguLLguKPguJ%2FguLHguIciLCJtaW4gd2F0Y2giOiLguJnguLLguJfguLXguYPguJnguIHguLLguKPguKPguLHguJrguIrguKEifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJtaW4gcmVhZCI6IuC4meC4suC4l%2BC4teC5g%2BC4meC4geC4suC4o%2BC4reC5iOC4suC4mSIsIm1pbiBsaXN0ZW4iOiLguJnguLLguJfguLXguYPguJnguIHguLLguKPguJ%2FguLHguIciLCJtaW4gd2F0Y2giOiLguJnguLLguJfguLXguYPguJnguIHguLLguKPguKPguLHguJrguIrguKEifQ%3D%3D!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJtaW4gcmVhZCI6Im1lbml0IG1lbWJhY2EiLCJtaW4gbGlzdGVuIjoibWVuaXQgbWVuZGVuZ2FyIiwibWluIHdhdGNoIjoibWVuaXQgbWVsaWhhdCJ9!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJtaW4gcmVhZCI6Im1lbml0IG1lbWJhY2EiLCJtaW4gbGlzdGVuIjoibWVuaXQgbWVuZGVuZ2FyIiwibWluIHdhdGNoIjoibWVuaXQgbWVsaWhhdCJ9!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJtaW4gcmVhZCI6Im1pbiByZWFkIiwibWluIGxpc3RlbiI6Im1pbiBsaXN0ZW4iLCJtaW4gd2F0Y2giOiJtaW4gd2F0Y2gifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJtaW4gcmVhZCI6Im1pbiByZWFkIiwibWluIGxpc3RlbiI6Im1pbiBsaXN0ZW4iLCJtaW4gd2F0Y2giOiJtaW4gd2F0Y2gifQ%3D%3D!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJtaW4gcmVhZCI6Im1pbiByZWFkIiwibWluIGxpc3RlbiI6Im1pbiBsaXN0ZW4iLCJtaW4gd2F0Y2giOiJtaW4gd2F0Y2gifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJtaW4gcmVhZCI6Im1pbiByZWFkIiwibWluIGxpc3RlbiI6Im1pbiBsaXN0ZW4iLCJtaW4gd2F0Y2giOiJtaW4gd2F0Y2gifQ%3D%3D!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJtaW4gcmVhZCI6Im1pbiByZWFkIiwibWluIGxpc3RlbiI6Im1pbiBsaXN0ZW4iLCJtaW4gd2F0Y2giOiJtaW4gd2F0Y2gifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJtaW4gcmVhZCI6Im1pbiByZWFkIiwibWluIGxpc3RlbiI6Im1pbiBsaXN0ZW4iLCJtaW4gd2F0Y2giOiJtaW4gd2F0Y2gifQ%3D%3D!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJtaW4gcmVhZCI6Im1pbiByZWFkIiwibWluIGxpc3RlbiI6Im1pbiBsaXN0ZW4iLCJtaW4gd2F0Y2giOiJtaW4gd2F0Y2gifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJtaW4gcmVhZCI6Im1pbiByZWFkIiwibWluIGxpc3RlbiI6Im1pbiBsaXN0ZW4iLCJtaW4gd2F0Y2giOiJtaW4gd2F0Y2gifQ%3D%3D!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJtaW4gcmVhZCI6Im1pbiByZWFkIiwibWluIGxpc3RlbiI6Im1pbiBsaXN0ZW4iLCJtaW4gd2F0Y2giOiJtaW4gd2F0Y2gifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJtaW4gcmVhZCI6Im1pbiByZWFkIiwibWluIGxpc3RlbiI6Im1pbiBsaXN0ZW4iLCJtaW4gd2F0Y2giOiJtaW4gd2F0Y2gifQ%3D%3D!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJtaW4gcmVhZCI6Im1pbiByZWFkIiwibWluIGxpc3RlbiI6Im1pbiBsaXN0ZW4iLCJtaW4gd2F0Y2giOiJtaW4gd2F0Y2gifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJtaW4gcmVhZCI6Im1pbiByZWFkIiwibWluIGxpc3RlbiI6Im1pbiBsaXN0ZW4iLCJtaW4gd2F0Y2giOiJtaW4gd2F0Y2gifQ%3D%3D!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJtaW4gcmVhZCI6Im1pbiByZWFkIiwibWluIGxpc3RlbiI6Im1pbiBsaXN0ZW4iLCJtaW4gd2F0Y2giOiJtaW4gd2F0Y2gifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJtaW4gcmVhZCI6Im1pbiByZWFkIiwibWluIGxpc3RlbiI6Im1pbiBsaXN0ZW4iLCJtaW4gd2F0Y2giOiJtaW4gd2F0Y2gifQ%3D%3D!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJtaW4gcmVhZCI6Im1pbiByZWFkIiwibWluIGxpc3RlbiI6Im1pbiBsaXN0ZW4iLCJtaW4gd2F0Y2giOiJtaW4gd2F0Y2gifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJtaW4gcmVhZCI6Im1pbiByZWFkIiwibWluIGxpc3RlbiI6Im1pbiBsaXN0ZW4iLCJtaW4gd2F0Y2giOiJtaW4gd2F0Y2gifQ%3D%3D!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJtaW4gcmVhZCI6IuC4meC4suC4l%2BC4teC5g%2BC4meC4geC4suC4o%2BC4reC5iOC4suC4mSIsIm1pbiBsaXN0ZW4iOiLguJnguLLguJfguLXguYPguJnguIHguLLguKPguJ%2FguLHguIciLCJtaW4gd2F0Y2giOiLguJnguLLguJfguLXguYPguJnguIHguLLguKPguKPguLHguJrguIrguKEifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJtaW4gcmVhZCI6IuC4meC4suC4l%2BC4teC5g%2BC4meC4geC4suC4o%2BC4reC5iOC4suC4mSIsIm1pbiBsaXN0ZW4iOiLguJnguLLguJfguLXguYPguJnguIHguLLguKPguJ%2FguLHguIciLCJtaW4gd2F0Y2giOiLguJnguLLguJfguLXguYPguJnguIHguLLguKPguKPguLHguJrguIrguKEifQ%3D%3D!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJtaW4gcmVhZCI6Im1lbml0IG1lbWJhY2EiLCJtaW4gbGlzdGVuIjoibWVuaXQgbWVuZGVuZ2FyIiwibWluIHdhdGNoIjoibWVuaXQgbWVsaWhhdCJ9!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJtaW4gcmVhZCI6Im1lbml0IG1lbWJhY2EiLCJtaW4gbGlzdGVuIjoibWVuaXQgbWVuZGVuZ2FyIiwibWluIHdhdGNoIjoibWVuaXQgbWVsaWhhdCJ9!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJtaW4gcmVhZCI6IlttzILDrMOsw6zguIHguLXguYkgxZnhur3hur3hur3Eg8SDxIPGjF0iLCJtaW4gbGlzdGVuIjoiW23MgsOsw6zDrOC4geC4teC5iSDGmsOsw6zDrMWh4bmv4bq94bq94bq94LiB4Li14LmJXSIsIm1pbiB3YXRjaCI6IlttzILDrMOsw6zguIHguLXguYkgxbXEg8SDxIPhua%2FDp%2BG4qV0ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJtaW4gcmVhZCI6IlttzILDrMOsw6zguIHguLXguYkgxZnhur3hur3hur3Eg8SDxIPGjF0iLCJtaW4gbGlzdGVuIjoiW23MgsOsw6zDrOC4geC4teC5iSDGmsOsw6zDrMWh4bmv4bq94bq94bq94LiB4Li14LmJXSIsIm1pbiB3YXRjaCI6IlttzILDrMOsw6zguIHguLXguYkgxbXEg8SDxIPhua%2FDp%2BG4qV0ifQ%3D%3D!"
        )
      )
      });
  
      export { translations as default };
    