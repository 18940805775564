import { datadogRum } from '@datadog/browser-rum';
import { ENABLE_DATADOG_RUM } from 'src/config';

import type { ConfigStateContext } from '../hooks/useConfig';

export const setupDatadogRum = (
  environment: string,
  config: ConfigStateContext,
): typeof datadogRum => {
  datadogRum.init({
    applicationId: '43e46adb-8493-4f83-a5bb-31ffd2ace643',
    clientToken: 'pub3f29eb72fb3f852e31bdaa00804bd0c6',
    site: 'datadoghq.com',
    service: 'unified-apac',
    version: '1.0.0',
    env: environment,
    sessionSampleRate: ENABLE_DATADOG_RUM ? 100 : 0,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });
  datadogRum.setGlobalContext(config);

  return datadogRum;
};
