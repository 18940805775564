const barColorList = ['#1FA575', '#EF672A', '#A04BCB', '#5B2084'] as const;

export const refineByOptions = [
  'must',
  'delight',
  'neutral',
  'putOff',
] as const;
export type RefineByLabel = (typeof refineByOptions)[number];

type LabelMap = {
  readonly [key in RefineByLabel]: string;
};

type LevelOfImportanceCategory =
  | 'Must-have'
  | 'Delighted-if'
  | 'Not-bothered'
  | 'Put-off';

const refineByLabelMap: LabelMap = {
  must: 'Must-have',
  delight: 'Delighted-if',
  neutral: 'Not-bothered',
  putOff: 'Put-off',
} as const;

const getBarColor = (index: number) => {
  if (index > -1 && index < barColorList.length) return barColorList[index];
  return barColorList[0];
};

const getRefineByLabel = (label: string): LevelOfImportanceCategory =>
  (refineByLabelMap[label as RefineByLabel] ||
    label) as LevelOfImportanceCategory;

const importanceOptions = refineByOptions.map((option) => ({
  id: option,
  label: getRefineByLabel(option),
  color: getBarColor(refineByOptions.indexOf(option)),
}));

export const levelOfImportanceHelper = {
  importanceOptions,
  getRefineByLabel,
  getBarColor,
};
