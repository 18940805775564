import type { Country } from '@seek/melways-sites';
import { HIGHEST_PAYING_SALARIES_AU } from './HighestPayingSalariesAU';
import { HIGHEST_PAYING_SALARIES_NZ } from './HighestPayingSalariesNZ';

export interface HighestPayingJobsInsight {
  industryId: number;
  industryTitle: string;
  roleTitle: string;
  rank: number;
  avgSalary: number;
  avgSalaryChange: number;
}

export interface HighestPayingJobsIndustriesInsights {
  industryIdFilter: number;
  roles: HighestPayingJobsInsight[];
}

export const ALL_INDUSTRIES_HIGHEST_PAID = 'All industries';

const highestPayingJobsByCountry: Partial<
  Record<Country, HighestPayingJobsIndustriesInsights[]>
> = {
  ['au']: HIGHEST_PAYING_SALARIES_AU,
  ['nz']: HIGHEST_PAYING_SALARIES_NZ,
};

export const getHighestPayingJobs = (countryCode: Country) =>
  highestPayingJobsByCountry[countryCode];

export const industriesByHighestPayingJobs = (
  highestPayingJobs: HighestPayingJobsIndustriesInsights[],
) =>
  highestPayingJobs
    .filter((item) => item.industryIdFilter !== -1 && item.roles.length > 0)
    .map((item) => ({
      id: item.roles[0].industryId.toString(),
      description: item.roles[0].industryTitle,
    }))
    .sort((a, b) => a.description.localeCompare(b.description));
