import type { LayoutProps } from '../../shared/types';
import { useConfig } from 'src/client/shared/hooks';
import { Helm as Helmet } from '../../components/modules/Helmet/Helmet';

import { EmployerLayout } from './EmployerLayout';
import { CandidateLayout } from './CandidateLayout';
import { useSearchParams } from 'react-router-dom';
import { Box } from 'braid-design-system';
import { BraidWrapper } from './BraidWrapper';

type Props = {
  layoutProps: LayoutProps;
  children: React.ReactNode;
};

export const PageLayout = ({ children, layoutProps }: Props) => {
  const [searchParams] = useSearchParams();
  const { site, hostname, pathname } = useConfig();
  const { title, section, categoryName, seo, locales } = layoutProps;
  const { headerOptions, theme } = section || {};

  const isEmployerSite = site.includes('employer');

  const helmet = (
    <Helmet
      title={seo?.title || title}
      seoTitle={seo?.title || title}
      description={seo?.description}
      noArchive={seo?.noArchive}
      noIndex={seo?.noIndex}
      url={`https://${hostname}${pathname}`}
      locales={locales}
    />
  );

  const isNative = searchParams.get('native-embed');
  return (
    <BraidWrapper theme={theme}>
      {isNative || headerOptions?.hideHeader ? (
        <Box>
          {helmet}
          {children}
        </Box>
      ) : (
        <>
          {isEmployerSite ? (
            <EmployerLayout categoryName={categoryName} section={section}>
              {helmet}
              {children}
            </EmployerLayout>
          ) : (
            <CandidateLayout section={section}>
              {helmet}
              {children}
            </CandidateLayout>
          )}
        </>
      )}
    </BraidWrapper>
  );
};
