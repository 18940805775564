import type { FormFragment } from '@seek/cmsu-cms-connect';
import { CForm } from '@seek/cmsu-components';
import { BannerItem } from '@seek/cmsu-components/src/modules/BannerItem/BannerItem';
import { Box, Stack } from 'braid-design-system';
import { useConfig } from 'src/client/shared/hooks';
import { WPT_SALESFORCE_API } from 'src/config';
import type {
  ContactUsType,
  SubscriptionFormType,
} from 'src/helpers/loadersHelper';

// This component is use to render the subscription form and contact us Banner
// Currently it is render only for about/news/article and about/news/category page only

type SubscribeForm_ContactUsWrapperProps = {
  formData: SubscriptionFormType;
  contactUs: ContactUsType;
};

export const SubscribeFormContactUsWrapper = ({
  contactUs,
  formData,
}: SubscribeForm_ContactUsWrapperProps) => {
  const { melwaysLocale, sourceName, privacyUrl, country, recaptchaKey } =
    useConfig();

  return (
    <Box paddingTop={'xxlarge'}>
      <Stack space={'xxxlarge'}>
        {formData && (
          <Box
            background="neutralLight"
            borderRadius={{ desktop: 'large', mobile: 'none' }}
            paddingX={{ tablet: 'xlarge', mobile: 'medium' }}
            paddingY={{ tablet: 'xlarge', mobile: 'large' }}
          >
            <CForm
              locale={melwaysLocale}
              sourceName={sourceName}
              privacyUrl={privacyUrl}
              recaptchaKey={recaptchaKey}
              wptSalesForceApi={WPT_SALESFORCE_API}
              country={country}
              form={formData as FormFragment}
            />
          </Box>
          // </Box>
        )}
        {contactUs && <BannerItem item={contactUs} />}
      </Stack>
    </Box>
  );
};
